import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "./Button";
import Stack from "../layout/Stack";
import TextField from "./TextField";
import ButtonIcon from "./ButtonIcon";
import { CloseRounded } from "@mui/icons-material";
import { InputAdornment, Typography } from "@mui/material";

const Input = styled("input")({
  display: "none",
});

const ButtonUploadVideo = ({
  id,
  onChange,
  onClear,
  defaultValue,
  ...props
}) => {
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  return (
    <Typography variant="body1" color="initial" htmlFor={id} component="label">
      <Typography sx={{ mb: 1.5 }} variant="body1" color="initial">
        File size must be less than 50MB, and file type must be MP4
      </Typography>
      <Stack direction="row" alignItems="flex-end">
        <TextField
          id={`name-${id}`}
          value={defaultValue}
          // disabled
          sx={{ overflow: "hidden" }}
          placeholder="Select file"
          InputProps={{
            readOnly: true,
          }}
          endAdornment={
            <InputAdornment position="end">
              {defaultValue && (
                <ButtonIcon
                  aria-label="toggle password visibility"
                  onClick={onClear}
                  // onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  <CloseRounded />
                </ButtonIcon>
              )}
            </InputAdornment>
          }
        />
        <Input
          accept="*"
          // accept="video/mp4,video/x-m4v,video/*"

          id={id}
          onChange={onChange}
          //    multiple
          type="file"
        />
        <Button component="span" {...props}>
          Browse
        </Button>
      </Stack>
    </Typography>
  );
};

export default ButtonUploadVideo;
