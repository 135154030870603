import {
  GET_CAMPAIGN_LIST,
  GET_EXAM_QUESTION_LIST,
  GET_CAMPAIGN_BY_ID,
  GET_QUESTION_BY_ID,
  GET_EXAMSET_BY_ID,
  GET_EXAMSET_QUESTION_BY_ID,
  GET_CHOICE_DETAIL,
  CREATE_CAMPAIGN,
  CREATE_INVITE_EXAMER,
  CREATE_EXAM_QUESTION,
  CREATE_INVITE_SEND_EMAIL,
  GET_CUSTOM_QUESTION_LIST,
  GET_EXAM_SET_LIST,
  GET_CAMPAIGN_SUMMARY_BY_ID,
  GET_EXAM_HISTORY_BY_UID,
  GET_EXAM_HISTORY_DETAIL_BY_ID,
  UPDATE_EXAMSET_BY_ID,
  UPDATE_TO_MODE_EDIT_CAMPAIGN,
  UPDATE_FORM_ASSESSMENT_DETAIL,
  UPDATE_FORM_ASSESSMENT_SELECT_TEST,
  UPDATE_FORM_ASSESSMENT_CREATE_EXAM,
  UPDATE_FORM_ASSESSMENT_REVIEW,
  UPDATE_EXAM_CUSTOM_LIST,
  UPDATE_CAMPAIGN_EMAIL,
  UPDATE_FORM_EXAM_SET,
  GET_QUESTION_LIST,
  GET_QUESTION_LIST_RAW,
  CREATE_EXAM_SET,
  CREATE_RANDOM_EXAM,
  CLEAR_FORM_EXAM_SET,
  CLEAR_FORM_CAMPAIGN,
  GET_CAMPAIGN_INVITE_LIST,
  UPDATE_INVITE_EXAMER,
  UPDATE_CAMPAIGN,
  UPDATE_QUESTION_INFO,
  UPDATE_QUESTION_STATUS,
  UPDATE_QUESTION_CHOICE,
  DELETE_INVITE_EXAMER,
  DELETE_CAMPAIGN,
  DELETE_QUESTION,
  DELETE_EXAMSET_BY_ID,
  APPROVE_QUESTION,
  SET_SUMMARY_TEST,
} from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { getAPI, postAPI, deleteAPI, patchAPI } from "utils/api";

export const saveFormAssessmentDetail = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_ASSESSMENT_DETAIL, payload: form });
  dispatch(hideLoading());
};

export const saveSummaryTest = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: SET_SUMMARY_TEST, payload: form });
  dispatch(hideLoading());
};

export const saveFormAssessmentSelcetTest = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_ASSESSMENT_SELECT_TEST, payload: form });
  dispatch(hideLoading());
};

export const saveFormAssessmentCreateExam = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_ASSESSMENT_CREATE_EXAM, payload: form });
  dispatch(hideLoading());
};

export const saveFormAssessmentReview = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_ASSESSMENT_REVIEW, payload: form });
  dispatch(hideLoading());
};

export const updateToModeEditCampaign = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_TO_MODE_EDIT_CAMPAIGN, payload: true });
  dispatch(hideLoading());
};

export const updateExamCustomList = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_EXAM_CUSTOM_LIST, payload: form });
  dispatch(hideLoading());
};

export const saveFormExamSet = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_EXAM_SET, payload: form });
  dispatch(hideLoading());
};

export const clearFormExamSet = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CLEAR_FORM_EXAM_SET });
  dispatch(hideLoading());
};

export const clearFormCampaign = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CLEAR_FORM_CAMPAIGN });
  dispatch(hideLoading());
};

export const getCampaignList = () => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_CAMPAIGN_LIST.REQUEST });
  return getAPI({
    url: "front/campaignList?limit=200&skip=0",
  })
    .then((data) =>
      dispatch({ type: GET_CAMPAIGN_LIST.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_CAMPAIGN_LIST.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getChoiceDetail = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_CHOICE_DETAIL.REQUEST });
  return getAPI({
    url: `front/examChoiceDetail/${id}`,
  })
    .then((data) =>
      dispatch({ type: GET_CHOICE_DETAIL.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_CHOICE_DETAIL.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamQuestionsList = (filter) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_QUESTION_LIST.REQUEST });
  return getAPI({
    url: "front/examQuestion",
    params: {
      skip: filter?.skip,
      limit: 20,
      code: filter?.code?.toString(),
      exam_owner: filter?.exam_owner,
      subject_id: filter?.subject_id,
      topic_name: filter?.topic_name,
      level: filter?.level,
      status: filter?.status,
      question: filter?.question,
      is_published: filter?.is_published,
    },
  })
    .then((data) =>
      dispatch({ type: GET_EXAM_QUESTION_LIST.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_EXAM_QUESTION_LIST.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCampaignById = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_CAMPAIGN_BY_ID.REQUEST });
  return getAPI({
    url: `front/campaignList/${id}`,
  })
    .then((data) => {
      dispatch({ type: GET_CAMPAIGN_BY_ID.SUCCESS, payload: data });
      return data;
    })
    .catch((error) => dispatch({ type: GET_CAMPAIGN_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getQuestionById = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_QUESTION_BY_ID.REQUEST });
  return getAPI({
    url: `front/examQuestionChoiceDetail/${id}`,
  })
    .then((data) => {
      dispatch({ type: GET_QUESTION_BY_ID.SUCCESS, payload: data });
      return data;
    })
    .catch((error) => dispatch({ type: GET_QUESTION_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getCampaignSummaryById = (slug) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_CAMPAIGN_SUMMARY_BY_ID.REQUEST });
  return getAPI({
    url: `front/campaignSummary`,
    params: {
      slug,
    },
  })
    .then((data) => {
      dispatch({ type: GET_CAMPAIGN_SUMMARY_BY_ID.SUCCESS, payload: data });
    })
    .catch((error) =>
      dispatch({ type: GET_CAMPAIGN_SUMMARY_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamSetById = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_EXAMSET_BY_ID.REQUEST });
  return getAPI({
    url: `front/examSet/${id}`,
  })
    .then((data) => {
      dispatch({
        type: GET_EXAMSET_BY_ID.SUCCESS,
        payload: data,
      });
      return data;
    })
    .catch((error) => dispatch({ type: GET_EXAMSET_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamSetQuestionById = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_EXAMSET_QUESTION_BY_ID.REQUEST });
  return getAPI({
    url: `front/examSet/${id}`,
  })
    .then((data) => {
      dispatch({
        type: GET_EXAMSET_QUESTION_BY_ID.SUCCESS,
        payload: data?.examSetQuestion,
      });
      return data;
    })
    .catch((error) =>
      dispatch({ type: GET_EXAMSET_QUESTION_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamerHistoryByUserUid = (userUid) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_HISTORY_BY_UID.REQUEST });
  return getAPI({
    url: `front/examerHistory}`,
    params: {
      userUid,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_EXAM_HISTORY_BY_UID.SUCCESS,
        payload: data?.examSetQuestion,
      });
      return data;
    })
    .catch((error) =>
      dispatch({ type: GET_EXAM_HISTORY_BY_UID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamerHistoryDetailById = (id, userUid) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_HISTORY_DETAIL_BY_ID.REQUEST });
  return getAPI({
    url: `front/examerHistoryDetail`,
    params: {
      id,
      userUid,
    },
  })
    .then((data) => {
      dispatch({
        type: GET_EXAM_HISTORY_DETAIL_BY_ID.SUCCESS,
        payload: data?.examSetQuestion,
      });
      return data;
    })
    .catch((error) =>
      dispatch({ type: GET_EXAM_HISTORY_DETAIL_BY_ID.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamSetList = (filter) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_EXAM_SET_LIST.REQUEST });
  return getAPI({
    url: "front/searchExamSet",
    params: {
      skip: filter.skip,
      limit: 12,
      is_published: filter.is_published,
      // date: filter.date, ยังไม่ได้
      question_type: filter.question_type.join(",") ?? null,
      level: filter.level.join(",") ?? null,
    },
  })
    .then((data) =>
      dispatch({ type: GET_EXAM_SET_LIST.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_EXAM_SET_LIST.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getExamCustomQuestionList = (filter) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_CUSTOM_QUESTION_LIST.REQUEST });
  return getAPI({
    url: "front/searchExamCustomQuestion",
    params: {
      title: filter.title,
      is_published: filter.is_published,
      mylibrary: 10,
      question_type: filter.question_type.join(",") ?? null,
    },
  })
    .then((data) =>
      dispatch({ type: GET_CUSTOM_QUESTION_LIST.SUCCESS, payload: data })
    )
    .catch((error) =>
      dispatch({ type: GET_CUSTOM_QUESTION_LIST.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createCampaign = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_CAMPAIGN.REQUEST });
    return postAPI({
      url: "front/campaign",
      data: body,
    })
      .then((data) => {
        dispatch({
          type: CREATE_CAMPAIGN.SUCCESS,
          showAlert: true,
          alertMessage: "Create Campaign Success",
        });
        dispatch({
          type: CLEAR_FORM_CAMPAIGN,
        });

        return data?.id;
      })
      .catch((error) => dispatch({ type: CREATE_CAMPAIGN.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  } catch (error) {
    dispatch({ type: CREATE_CAMPAIGN.FAILURE, error });
  }
};

export const createInviteExamer = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_INVITE_EXAMER.REQUEST });
    return postAPI({
      url: "front/inviteExamer",
      data: body,
    })
      .then((data) =>
        dispatch({
          type: CREATE_INVITE_EXAMER.SUCCESS,
          payload: data,
          showAlert: true,
          alertMessage: "Import E-mail Success",
        })
      )
      .catch((error) => dispatch({ type: CREATE_INVITE_EXAMER.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  } catch (error) {
    dispatch({ type: CREATE_INVITE_EXAMER.FAILURE, error });
  }
};

export const createExamQuestion = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_EXAM_QUESTION.REQUEST });
    return postAPI({
      url: "front/examQuestion",
      data: body,
    })
      .then((data) =>
        dispatch({
          type: CREATE_EXAM_QUESTION.SUCCESS,
          payload: data,
          showAlert: true,
          alertMessage: "Create Question Success",
        })
      )
      .catch((error) => dispatch({ type: CREATE_EXAM_QUESTION.FAILURE, error }))
      .finally(() => {
        dispatch(hideLoading());
      });
  } catch (error) {
    dispatch({ type: CREATE_EXAM_QUESTION.FAILURE, error });
  }
};

export const createInviteSendEmail = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_INVITE_SEND_EMAIL.REQUEST });
    return postAPI({
      url: "front/inviteSendEmail",
      data: body,
    })
      .then((data) =>
        dispatch({
          type: CREATE_INVITE_SEND_EMAIL.SUCCESS,
          payload: data,
          showAlert: true,
          alertMessage: "Publish Success",
        })
      )
      .catch((error) =>
        dispatch({ type: CREATE_INVITE_SEND_EMAIL.FAILURE, error })
      )
      .finally(() => {
        setTimeout(() => {
          dispatch(hideLoading());
        }, 1000);
      });
  } catch (error) {
    dispatch({ type: CREATE_INVITE_SEND_EMAIL.FAILURE, error });
  }
};

export const updateExamSetById = (body) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_EXAMSET_BY_ID.REQUEST });
  return patchAPI({
    url: `front/examSet/${body?.id}`,
    data: body,
  })
    .then((data) => {
      dispatch({
        type: UPDATE_EXAMSET_BY_ID.SUCCESS,

        showAlert: true,
        alertMessage: "Update Exam-Set Success",
      });
      return data;
    })
    .catch((error) => dispatch({ type: UPDATE_EXAMSET_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateCampaignEmail = (id, body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: UPDATE_CAMPAIGN_EMAIL.REQUEST });
    return patchAPI({
      url: `front/campaignList/${id}`,
      data: body,
    })
      .then((data) =>
        dispatch({ type: UPDATE_CAMPAIGN_EMAIL.SUCCESS, payload: data })
      )
      .catch((error) =>
        dispatch({ type: UPDATE_CAMPAIGN_EMAIL.FAILURE, error })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  } catch (error) {
    dispatch({ type: CREATE_INVITE_SEND_EMAIL.FAILURE, error });
  }
};

export const getQuestionListRaw = () => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_QUESTION_LIST_RAW.REQUEST });
  return getAPI({
    url: "front/getAllQuestionActive",
    params: {
      skip: 0,
      limit: 20,
      
    },
  })
    .then((data) =>
      dispatch({ type: GET_QUESTION_LIST_RAW.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_QUESTION_LIST_RAW.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const getQuestionList = (filter) => (dispatch) => {
  
  dispatch(showLoading());
  dispatch({ type: GET_QUESTION_LIST.REQUEST });
  return getAPI({
    url: "front/getAllQuestionActive",
    params: {
      skip: filter?.skip,
      limit: 20,
      code: filter?.code,
      exam_owner: filter?.exam_owner,
      subject_id: filter?.subject_id,
      topic_name: filter?.topic_name,
      level: filter?.level,
      question: filter?.question,
      organization: JSON.parse(localStorage.getItem('user')).organization.organization_id
    },
  })
    .then((data) =>
      dispatch({ type: GET_QUESTION_LIST.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: GET_QUESTION_LIST.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createExamSet = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_EXAM_SET.REQUEST });
    return postAPI({
      url: "front/examSet",
      data: body,
    })
      .then((data) =>
        dispatch({
          type: CREATE_EXAM_SET.SUCCESS,
          payload: data,
          showAlert: true,
          alertMessage: "Create Exam-Set Success",
        })
      )
      .catch((error) => dispatch({ type: CREATE_EXAM_SET.FAILURE, error }))
      .finally(() => {
        setTimeout(() => {
          dispatch(hideLoading());
        }, 1000);
      });
  } catch (error) {
    dispatch({ type: CREATE_EXAM_SET.FAILURE, error });
  }
};

export const approveQuestion = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: APPROVE_QUESTION.REQUEST });
    return postAPI({
      url: "front/approveExamQuestion",
      data: body,
    })
      .then((data) =>
        dispatch({
          type: APPROVE_QUESTION.SUCCESS,
          payload: data,
          showAlert: true,
          alertMessage: "Approve Success",
        })
      )
      .catch((error) => dispatch({ type: APPROVE_QUESTION.FAILURE, error }))
      .finally(() => {
        setTimeout(() => {
          dispatch(hideLoading());
        }, 1000);
      });
  } catch (error) {
    dispatch({ type: APPROVE_QUESTION.FAILURE, error });
  }
};

export const randomQuestion = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_RANDOM_EXAM.REQUEST });
    return postAPI({
      url: "front/randomExam",
      data: body,
    })
      .then((data) =>
        dispatch({
          type: CREATE_RANDOM_EXAM.SUCCESS,
          payload: data,
          showAlert: true,
          alertMessage: "Create Exam-Set Success",
        })
      )

      .catch((error) => dispatch({ type: CREATE_RANDOM_EXAM.FAILURE, error }))
      .finally(() => {
        setTimeout(() => {
          dispatch(hideLoading());
        }, 1000);
      });
  } catch (error) {
    dispatch({ type: CREATE_RANDOM_EXAM.FAILURE, error });
  }
};

export const getCampaignInviteList = (filter) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_CAMPAIGN_INVITE_LIST.REQUEST });
  return getAPI({
    url: "front/inviteExamer",
    params: {
      skip: filter?.skip,
      limit: 10,
      campaign_id: filter?.id,
    },
  })
    .then((data) =>
      dispatch({ type: GET_CAMPAIGN_INVITE_LIST.SUCCESS, payload: data })
    )
    .catch((error) =>
      dispatch({ type: GET_CAMPAIGN_INVITE_LIST.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateInviteExamer = (id, body) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_INVITE_EXAMER.REQUEST });
  return patchAPI({
    url: `front/inviteExamer/${id}`,

    data: body,
  })
    .then((data) =>
      dispatch({ type: UPDATE_INVITE_EXAMER.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: UPDATE_INVITE_EXAMER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateQuestionInfo = (id, body) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_QUESTION_INFO.REQUEST });
  return patchAPI({
    url: `front/examQuestion/${id}`,
    params: {
      module_name: "exam_info",
    },

    data: body,
  })
    .then((data) =>
      dispatch({
        type: UPDATE_QUESTION_INFO.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Update Question Info Success",
      })
    )
    .catch((error) => dispatch({ type: UPDATE_QUESTION_INFO.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateQuestionStatus = (id, body) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_QUESTION_STATUS.REQUEST });
  return patchAPI({
    url: `front/examQuestion/${id}`,
    params: {
      module_name: "status_info",
    },
    data: body,
  })
    .then((data) =>
      dispatch({
        type: UPDATE_QUESTION_STATUS.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Update Question Status Success",
      })
    )
    .catch((error) => dispatch({ type: UPDATE_QUESTION_STATUS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateQuestionChoice = (id, body) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_QUESTION_CHOICE.REQUEST });
  return patchAPI({
    url: `front/examChoiceDetail/${id}`,

    data: body,
  })
    .then((data) =>
      dispatch({
        type: UPDATE_QUESTION_CHOICE.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Update Question Choice Success",
      })
    )
    .catch((error) => dispatch({ type: UPDATE_QUESTION_CHOICE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const updateCampaign = (body) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_CAMPAIGN.REQUEST });
  return patchAPI({
    url: `front/campaign/${body?.id}`,

    data: body,
  })
    .then((data) => {
      dispatch({
        type: UPDATE_CAMPAIGN.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Update Campaign Success",
      });
      dispatch({ type: CLEAR_FORM_CAMPAIGN });
    })
    .catch((error) => dispatch({ type: UPDATE_CAMPAIGN.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const deleteInviteExamer = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: DELETE_INVITE_EXAMER.REQUEST });
  return deleteAPI({
    url: `front/deleteInviteExam/${id}`,
  })
    .then((data) =>
      dispatch({ type: DELETE_INVITE_EXAMER.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: DELETE_INVITE_EXAMER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const deleteCampaign = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: DELETE_CAMPAIGN.REQUEST });
  return deleteAPI({
    url: `front/campaign/${id}`,
  })
    .then((data) =>
      dispatch({
        type: DELETE_CAMPAIGN.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Delete Success",
      })
    )
    .catch((error) => dispatch({ type: DELETE_CAMPAIGN.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const deleteQuestion = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: DELETE_QUESTION.REQUEST });
  return deleteAPI({
    url: `front/examQuestion/${id}`,
  })
    .then((data) =>
      dispatch({
        type: DELETE_QUESTION.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Delete Success",
      })
    )
    .catch((error) => dispatch({ type: DELETE_QUESTION.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const deleteExamsetById = (id) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: DELETE_EXAMSET_BY_ID.REQUEST });
  return deleteAPI({
    url: `front/examSet/${id}`,
  })
    .then((data) =>
      dispatch({
        type: DELETE_EXAMSET_BY_ID.SUCCESS,
        showAlert: true,
        alertMessage: "Delete Exam-set Success",
      })
    )
    .catch((error) => dispatch({ type: DELETE_EXAMSET_BY_ID.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
