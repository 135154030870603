import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";
import Stack from "components/layout/Stack";

import Typography from "@mui/material/Typography";

import ErrorIcon from "assets/icon/error.png";
import { Container, Link } from "@mui/material";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const Expired = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" align="center" color="initial">
                <img width="100" alt="success" src={ErrorIcon} />
              </Typography>

              <Typography
                variant="h2"
                color="initial"
                align="center"
                gutterBottom
              >
                Session Expired
              </Typography>
              <br />

              <Typography variant="h6" align="center" color="initial">
                Please Contact Admin
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  color="initial"
                >
                  Inbox:
                  <Link
                    variant="h6"
                    underline="hover"
                    href="https://www.facebook.com/fuseonai"
                  >
                    Fuseon
                  </Link>{" "}
                  , Line:
                  <Link
                    href="http://line.me/ti/p/@fuseon"
                    underline="hover"
                    variant="h6"
                  >
                    @fuseon
                  </Link>
                </Typography>
              </Typography>

              <br />
              <Button
                sx={{ width: { xs: "100%", sm: 300 } }}
                onClick={() => history.push("/")}
              >
                Home
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default Expired;
