import { LOGIN } from "redux/actionTypes";
import { showLoading, hideLoading } from "redux/actions/loading";
import { postAPI } from "utils/api";

// export const login =
//   (email = "somchai@gmail.com", password = "@Somchai12345") =>
//   (dispatch) => {
//     dispatch(showLoading());

//     return postAPI({
//       url: "login",
//       data: {
//         email,
//         password,
//         strategy: "local",
//       },
//     })
//       .then(({ accessToken, login }) => {
//         localStorage.setItem("access_token", accessToken);
//         localStorage.setItem("user", JSON.stringify(login));
//         return dispatch({ type: LOGIN.SUCCESS });
//       })
//       .catch((error) => dispatch({ type: LOGIN.FAILURE, error }))
//       .finally(() => {
//         dispatch(hideLoading());
//       });
//   };

export const loginFront =
  (
    body = {
      email: "somchai@gmail.com",
      password: "@Somchai12345",
      strategy: "front-local",
    }
  ) =>
  (dispatch) => {
    dispatch(showLoading());

    return postAPI({
      url: "login_front",
      data: body,
    })
      .then((data) => {
        localStorage.setItem("access_token", data?.accessToken);
        localStorage.setItem("user", JSON.stringify(data));
        dispatch({ type: LOGIN.SUCCESS });
        return data;
      })
      .catch((error) => {
        dispatch({ type: LOGIN.FAILURE, error });
        return error;
      })
      .finally(() => {
        dispatch(hideLoading());
      });
  };

export const logout = () => {
  localStorage.removeItem("access_token");
  localStorage.removeItem("user");
  window.location.replace("/");
};
