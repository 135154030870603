import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AddCircle as AddIcon,
  AccessTime as Clock,
  RemoveRedEye as Eye,
  Search,
  ArrowForward,
  ArrowBack,
} from "@mui/icons-material";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";

import Stepper from "../../components/navigation/Stepper";

import Button from "../../components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import Select from "../../components/form/Select";
import TextField from "../../components/form/TextField";
import Checkbox from "../../components/form/Checkbox";

import ConfirmDialog from "../../components/dialog/ConfirmDialog";

import Table from "../../components/table/Table";

import BoxIcon from "../../containers/BoxIcon";

import More from "../../assets/icon/more.png";
import Essay from "../../assets/icon/essay.png";
import Quiz from "../../assets/icon/quiz.png";
import Upload from "../../assets/icon/upload.png";
import Video from "../../assets/icon/video.png";

import {
  getExamCustomQuestionList,
  saveFormAssessmentCreateExam,
} from "../../redux/actions/assessments";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;

  .background-menu-filter {
    background-color: #f5f7fc;
    border-radius: 8px;
    /* margin: 0 auto; */
  }

  .headline {
    color: #005cc9;
  }
`;

const steps = [
  "Assessment Details",
  "Select Test",
  "Add Question",
  "Review & Finishing",
];

const DataMock = [
  {
    id: 1,
    type: "Video",
    time: "10 Min",
    question: "What do you think will be greater ...",
  },
  {
    id: 2,
    type: "Video",
    time: "10 Min",
    question: "What do you think will be greater ...",
  },
];

const AssessmentAddQuestion = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    customQuestionList,
    formAssessmentCreateExam,
    formAssessmentDetails,
    summaryTest,
  } = useSelector((state) => state.assessments);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentData, setCurrentData] = useState("");

  const [examList, setExamList] = useState(
    formAssessmentCreateExam.map((item, index) =>
      !item.id
        ? {
            id: formAssessmentCreateExam.length + index,
            ...item,
            new_custom: true,
          }
        : { ...item, new_custom: false }
    )
  );
  const [examListDisplay, setExamListDisplay] = useState();

  const [filter, setFilter] = useState({
    title: null,
    is_published: 1,
    question_type: [],
  });

  const [isCheckVideo, setIsCheckVideo] = useState(false);
  const [isCheckEssay, setIsCheckEssay] = useState(false);
  const [isCheckMultipleChoice, setIsCheckMultipleChoice] = useState(false);
  const [isCheckFile, setIsCheckFile] = useState(false);
  const [isCheckCode, setIsCheckCode] = useState(false);

  const { organization_id } = JSON.parse(
    localStorage.getItem("user")
  )?.organization;
  const columns = [
    // {
    //   field: "id",
    //   headerName: "Number ",
    //   flex: 1,
    //   minWidth: 240,
    // },
    {
      field: "question_type",
      headerName: "Type",
      flex: 1,
      minWidth: 240,
    },
    {
      field: "question_time",
      headerName: "Time",
      flex: 1,
      minWidth: 240,
      valueGetter: ({ row }) => `${row?.question_time} Min` || "-",
    },
    {
      field: "question_title",
      headerName: "Question",
      flex: 1,
      minWidth: 300,
      // flex: 1,
    },
  ];

  useEffect(() => {
    dispatch(getExamCustomQuestionList(filter));
  }, []);

  useEffect(() => {
    console.log({ examList });
    if (examList?.length > 0) {
      const newData = examList.map((v, i) => ({
        ...v,
        id: v.id ? v.id : i + 1,
        rawId: v.id,
      }));

      console.log({ newData });
      setExamListDisplay(newData);
    }
  }, [examList]);

  useEffect(() => {
    // console.log("filter", filter);

    setTimeout(() => {
      fetchExamList();
    }, 250);
  }, [filter]);

  const fetchExamList = useCallback(() => {
    dispatch(getExamCustomQuestionList(filter));
  }, [filter]);

  useEffect(() => {
    updateQuestionType(isCheckVideo, "video");
  }, [isCheckVideo]);

  useEffect(() => {
    updateQuestionType(isCheckEssay, "essay");
  }, [isCheckEssay]);

  useEffect(() => {
    updateQuestionType(isCheckMultipleChoice, "multiple-choice");
  }, [isCheckMultipleChoice]);

  useEffect(() => {
    updateQuestionType(isCheckFile, "fileupload");
  }, [isCheckFile]);

  useEffect(() => {
    updateQuestionType(isCheckCode, "code");
  }, [isCheckCode]);

  const updateQuestionType = (value, type) => {
    let newArr = [...filter?.question_type];
    if (value) {
      newArr.push(type);
    } else {
      newArr = newArr.filter((it) => it !== type);
    }

    setFilter((prev) => ({
      ...prev,
      question_type: newArr,
    }));
  };

  const onChangeFilter = (fieldName, newValue) => {
    console.log(fieldName, newValue);
    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  };

  const handleOpenConfirmDialog = (data) => {
    setCurrentData(data);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleAddQuestion = (data) => {
    let newArr = [...examList];
    newArr.push({
      id: data?.id,
      question_title: data?.question_title,
      question_type: data?.question_type,
      question_description: data?.question_description,
      video_url: data?.video_url,
      image_url: data?.image_url,
      is_shuffle_answer: data?.is_shuffle_answer,
      question_time: data?.question_time,
      is_exist: 0,
      choice: [],
    });

    setExamList(newArr);
    dispatch(saveFormAssessmentCreateExam(newArr));
  };

  const handleEditQuestion = (data) => {
    dispatch(saveFormAssessmentCreateExam(examList));
    history.push(`create-custom/${data?.question_type}/${data?.rawId}`);
  };

  const handleDelete = () => {
    console.log({ examList });

    let newArr = [...examList].filter(
      (item) => +item.id !== +currentData?.rawId
    );

    setExamList(newArr);
    setExamListDisplay(newArr);
    dispatch(saveFormAssessmentCreateExam(newArr));
    handleCloseConfirmDialog();
  };

  const goNext = () => {
    dispatch(saveFormAssessmentCreateExam(examList));
    history.push("review");
  };

  return (
    <Div>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
        title="Delete"
        content={currentData?.question_title}
        confirmName={"Delete"}
      />
      <Grid container direction="column" spacing={4}>
        <Grid
          item
          xs
          container
          spacing={2}
          alignItems="center"
          // sx={{ display: { xs: "none", sm: "flex" } }}
        >
          <Grid xs={2} item sx={{ display: { xs: "flex", sm: "none" } }}>
            <ButtonIcon block onClick={() => history.goBack()}>
              <ArrowBack />
            </ButtonIcon>
          </Grid>
          <Grid xs={2} sx={{ display: { xs: "none", sm: "flex" } }} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
          <Grid xs={8} sm={7} item container>
            <Grid xs={12} sm={6} item>
              <Typography
                className="headline"
                variant="h5"
                align="left"
                gutterBottom
              >
                {formAssessmentDetails?.campaign_name}
              </Typography>
              <Typography
                color="initial"
                variant="body"
                align="left"
                gutterBottom
              >
                <i class="las la-pen"></i> {summaryTest.amountTest} Test ;{" "}
                <i class="las la-clock"></i> {summaryTest.amountTime} Mins
              </Typography>
            </Grid>
            <Grid
              xs={0}
              sm={6}
              sx={{ display: { xs: "none", sm: "flex" } }}
              item
            ></Grid>
          </Grid>
          <Grid xs={4} sm={3} item sx={{ display: { xs: "none", sm: "flex" } }}>
            <Button
              block
              onClick={() => goNext()}
              // disabled={examList.length === 0}
            >
              Process to next step
            </Button>
          </Grid>
          <Grid xs={2} item>
            <ButtonIcon
              block
              sx={{ display: { xs: "flex", sm: "none" } }}
              onClick={() => goNext()}
            >
              <ArrowForward />
            </ButtonIcon>
          </Grid>
        </Grid>
        <Grid
          sx={{ display: { xs: "flex", sm: "none" } }}
          item
          container
          xs={12}
          sm={12}
        >
          <Stack spacing={2}>
            <Typography variant="h5" color="initial" align="center">
              Create New Assessment
            </Typography>
            <Typography variant="h6" color="#606266" align="center">
              Add Question
            </Typography>
          </Stack>
        </Grid>
        <Grid item container xs={12} sm={12}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Stepper listStep={steps} activeStep={2} />
          </Grid>
          <Grid item xs={12} sm></Grid>
        </Grid>

        <Grid item xs={12} sm={12}></Grid>

        <Grid item container xs={12} sm={12} spacing={3}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" color="initial">
              You can add up to {10 - +examList?.length} questions.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} spacing={1}>
            <Box sx={{ height: 250, width: "initial", mx: "auto" }}>
              <Table
                columns={columns}
                rows={examListDisplay}
                checkboxSelection={false}
                onEditRow={(row) => {
                  handleEditQuestion(row);
                }}
                onDeleteRow={(row) => handleOpenConfirmDialog(row)}
                headerNameRunningNumber="Number"
                showRunningNumber
              />
            </Box>
          </Grid>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <Typography variant="h5" color="initial">
              Select Your Question Type
            </Typography>
          </Grid>

          <Grid container item xs={12} sm spacing={5}>
            {/* <Grid item xs sm>
              <BoxIcon icon={Video} label="Video" />
            </Grid> */}
            <Grid item xs sm>
              <BoxIcon
                icon={Essay}
                label="Essay"
                onClick={() => history.push("create-custom/essay")}
              />
            </Grid>
            <Grid item xs sm>
              <BoxIcon
                icon={Quiz}
                label="Multiple-choice."
                onClick={() => history.push("create-custom/multiple-choice")}
              />
            </Grid>
            {/* <Grid item xs sm>
              <BoxIcon icon={Upload} label="File Upload" />
            </Grid>
            <Grid item xs={6} sm>
              <BoxIcon icon={More} label="Etc." />
            </Grid> */}
          </Grid>
        </Grid>

        <Grid item xs></Grid>
        <Grid item container spacing={4} alignItems="flex-start">
          <Grid
            item
            xs={12}
            sm={3}
            spacing={4}
            // classname="background-menu-filter"
          >
            <Box sx={{ width: "100%", backgroundColor: "#f5f7fc" }} p={2}>
              <Stack spacing={2} justifyContent="flex-start">
                <Stack spacing={2}>
                  <Typography variant="subtitle1" color="initial">
                    Search by Question
                  </Typography>
                  <TextField
                    type="search"
                    placeholder="Search"
                    value={filter?.title}
                    onChange={(e) => onChangeFilter("title", e.target.value)}
                    innerIconFieldStart={<Search />}
                  />
                  <Select
                    defaultValue="Assessment Name"
                    value={""}
                    items={[]}
                  />{" "}
                </Stack>
                <Stack spacing={2} direction={{ xs: "row", sm: "column" }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Question Pools
                    </Typography>

                    <Checkbox
                      id="check-2bsimple-library"
                      checked={filter?.is_published === 1}
                      label=" Center library"
                      onChange={() => onChangeFilter("is_published", 1)}
                    />
                    <Checkbox
                      id="check-my-library"
                      checked={filter?.is_published === organization_id}
                      label="My library"
                      onChange={() =>
                        onChangeFilter("is_published", organization_id)
                      }
                    />
                  </Stack>

                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Question Type
                    </Typography>

                    <Checkbox
                      id="check-video"
                      label="Video"
                      checked={isCheckVideo}
                      onChange={() => setIsCheckVideo(!isCheckVideo)}
                    />
                    <Checkbox
                      id="check-essay"
                      label="Essay"
                      checked={isCheckEssay}
                      onChange={() => setIsCheckEssay(!isCheckEssay)}
                    />
                    <Checkbox
                      id="check-multiple"
                      label="Multiple-choice"
                      checked={isCheckMultipleChoice}
                      onChange={() =>
                        setIsCheckMultipleChoice(!isCheckMultipleChoice)
                      }
                    />
                    <Checkbox
                      id="check-file"
                      label="File Upload"
                      checked={isCheckFile}
                      onChange={() => setIsCheckFile(!isCheckFile)}
                    />
                    <Checkbox
                      id="check-code"
                      label="Code"
                      checked={isCheckCode}
                      onChange={() => setIsCheckCode(!isCheckCode)}
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12} sm container spacing={4}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Grid item xs>
                <Typography variant="h6" color="grey">
                  Suggestion Question
                </Typography>
              </Grid>
            </Grid>

            <Grid item container direction="column">
              <Stack spacing={4}>
                {customQuestionList?.length > 0 ? (
                  customQuestionList?.map((item, index) => (
                    <QustionItem
                      key={index}
                      data={item}
                      onInsert={() => handleAddQuestion(item)}
                    />
                  ))
                ) : (
                  <Typography
                    p={4}
                    align="center"
                    variant="subtitle1"
                    color="primary"
                  >
                    NO DATA
                  </Typography>
                )}
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default AssessmentAddQuestion;

const QustionItem = ({ data, onInsert }) => {
  const mapWordPublish = (word) => {
    switch (word) {
      case 0:
        return "my library";

      default:
        return "2bsimple library";
    }
  };

  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <CardContent sx={{ p: 2 }}>
        <Grid container xs={12} sm={12}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={2}>
              <Typography variant="h5" color="initial">
                {data?.question_title}
                <Typography variant="body1" paragraph color="initial">
                  {data?.question_type} | {mapWordPublish(data?.is_published)} |{" "}
                  <ButtonIcon color="grey">
                    <Clock />
                  </ButtonIcon>
                  {data?.question_time} Mins
                </Typography>
              </Typography>

              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <div className="empty-block"></div>
                <Grid item container xs={3} sm={3} alignItems="flex-end">
                  <Button block outlined onClick={onInsert}>
                    Add
                  </Button>
                </Grid>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};
