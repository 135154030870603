import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import * as XLSX from "xlsx";

import { useHistory, useParams } from "react-router-dom";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { validateEmail } from "utils/validation";
import { Typography } from "@mui/material";

import Pagination from "../../components/navigation/Pagination";

import Button from "../../components/form/Button";
import Toggle from "../../components/form/Toggle";
import TextField from "../../components/form/TextField";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Table from "../../components/table/Table";

import Box from "components/surfaces/Box";
import Paper from "../../components/surfaces/Paper";
import Taps from "../../components/surfaces/Taps";
import TabPanel from "../../components/surfaces/TabPanel";

import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import SubContentDialog from "../../components/dialog/SubContentDialog";

import {
  createInviteExamer,
  createInviteSendEmail,
  getCampaignById,
  getCampaignInviteList,
  updateInviteExamer,
  deleteInviteExamer,
} from "../../redux/actions/assessments";
import ButtonUploadXLSX from "components/form/ButtonUploadXLSX";

const Div = styled.div`
  padding: 2vw 7vw;
`;

const INITIAL_FORM = {
  email: "",
  first_name: "",
  last_name: "",
};

const AssessmentDetail = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { campaignById, pageCount, limit, total, inviteExamerList } =
    useSelector((state) => state.assessments);

  const [tabActive, setTabActive] = useState(0);
  const [editorData, setEditorData] = useState("");

  const [fileNameUpload, setFileNameUpload] = useState("");
  const [inviteListImport, setInviteListImport] = useState([]);
  const [formInvite, setFormInvite] = useState(INITIAL_FORM);
  const [isOpenModalEmail, setIsOpenModalEmail] = useState(false);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const [openModalEdit, setOpenModalEdit] = useState(false);
  const [isPublishLink, setIsPublishLink] = useState(false);
  const [filter, setFilter] = useState({
    skip: 0,
    id,
  });

  const [inviteExamerListDataDisplay, setInviteExamerListDataDisplay] =
    useState([]);

  const [page, setPage] = useState(1);
  const isShowPagination = inviteExamerList.length > 0 ? true : false;

  const columnsIncludeTest = [
    {
      field: "exam_set_name",
      headerName: "Test",
      minWidth: 300,
    },
    {
      field: "exam_set_time",
      headerName: "Duration",
      minWidth: 300,
      valueGetter: ({ row }) => `${row?.exam_set_time} Minutes` || "-",
    },
  ];

  const columnsQuestion = [
    {
      field: "question_title",
      headerName: "Question",
      minWidth: 300,
    },
    {
      field: "question_type",
      headerName: "Type",
    },
    {
      field: "question_time",
      headerName: "Duration",
      valueGetter: ({ row }) => `${row?.question_time} Minutes` || "-",
    },
  ];

  const columnsEmailInvitation = [
    {
      field: "first_name",
      headerName: "First Name",
      minWidth: 200,
    },
    {
      field: "last_name",
      headerName: "Last Name",
      minWidth: 200,
    },
    {
      field: "email",
      headerName: "Email ",
      minWidth: 200,
    },
    {
      field: "status",
      headerName: "Status ",
      valueGetter: ({ value }) => `${value || "-"}`,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    if (inviteExamerList) {
      const newData = inviteExamerList.map((v, i) => ({
        ...v,
      }));
      setInviteExamerListDataDisplay(newData);
    }
  }, [inviteExamerList]);

  useEffect(() => {
    dispatch(getCampaignById(id));
    dispatch(getCampaignInviteList(filter));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchInviteExamerList();
    }, 250);
  }, [filter]);

  const fetchInviteExamerList = useCallback(() => {
    dispatch(getCampaignInviteList(filter));
  }, [filter]);

  const onChangePage = (event, value) => {
    setPage(value);
    setFilter((prev) => ({
      ...prev,
      skip: limit * (value - 1),
    }));
  };

  const onChangeFormInvite = (event) => {
    const { name, value } = event.target;

    setFormInvite((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleInviteSendEmail = () => {
    dispatch(createInviteSendEmail({ campaigns_id: id }));
  };

  const handleInviteExamer = (event) => {
    event.preventDefault();

    let body = {
      campaigns_id: +id,
      examer: [{ ...formInvite }],
    };
    dispatch(createInviteExamer(body)).then(() => {
      setFormInvite(INITIAL_FORM);
      fetchInviteExamerList();
    });
  };

  const handleEditInvitation = (event) => {
    event.preventDefault();

    let body = {
      email: formInvite?.email,
      first_name: formInvite?.first_name,
      last_name: formInvite?.last_name,
    };

    dispatch(updateInviteExamer(formInvite?.id, body)).then(() => {
      setFormInvite(INITIAL_FORM);
      fetchInviteExamerList();
      handleCloseModalEdit();
    });
  };

  const handleDelete = () => {
    dispatch(deleteInviteExamer(formInvite?.id)).then(() => {
      setFormInvite(INITIAL_FORM);
      fetchInviteExamerList();
      handleCloseConfirmDialog();
    });
  };

  const handleOpenConfirmDialog = (data) => {
    setFormInvite(data);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleOpenModalEdit = (data) => {
    setFormInvite(data);
    setOpenModalEdit(!openModalEdit);
  };

  const handleCloseModalEdit = () => {
    setOpenModalEdit(!openModalEdit);
  };

  const handleCopyLink = async () => {
    await navigator.clipboard.writeText(campaignById?.public_link_url);
    dispatch({
      type: "_SUCCESS",
      showAlert: true,
      alertMessage: "Link copied",
    });
    // alert("Text copied");
  };

  const handleReadFile = (e) => {
    const [file] = e.target.files;
    const reader = new FileReader();

    reader.onload = (evt) => {
      var workbook = XLSX.read(evt.target.result, { type: "array" });
      var jsonExam = XLSX.utils.sheet_to_json(
        workbook.Sheets[workbook.SheetNames[0]],
        {
          header: 1,
        }
      );
      let dataInviteList = [];

      jsonExam.forEach((it, index) => {
        if (it.length == 0) return;
        if (index != 0) {
          dataInviteList.push({
            first_name: it[0],
            last_name: it[1],
            email: it[2],
          });
        }
      });

      let newArr = [...inviteExamerListDataDisplay]
        .concat(dataInviteList)
        .map((item, index) => ({
          email: item?.email,
          first_name: item?.first_name,
          last_name: item?.last_name,
        }));
      // setInviteListImport(dataInviteList);

      // setInviteExamerListDataDisplay([...newArr]);
      setFileNameUpload(file?.name);

      let body = {
        campaigns_id: +id,
        examer: [...newArr],
      };
      dispatch(createInviteExamer(body)).then(() => {
        fetchInviteExamerList();
      });
    };
    reader.readAsArrayBuffer(file);
  };

  const handleClear = (event) => {
    setInviteListImport([]);
  };

  return (
    <Div>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
        title="Delete"
        content={`${formInvite?.first_name} ${formInvite?.last_name}`}
        confirmName={"Delete"}
      />
      <SubContentDialog
        open={openModalEdit}
        title={"Edit Email List"}
        onClose={handleCloseModalEdit}
        maxWidth="md"
      >
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={2}>
              {/* <Typography variant="body1" color="initial" gutterBottom>
                Edit Email List
              </Typography> */}
              <form onSubmit={handleEditInvitation}>
                <Grid container items spacing={2} direction="column">
                  <Grid item xs={12} sm={12}>
                    <TextField
                      placeholder="First Name"
                      name="first_name"
                      required
                      value={formInvite?.first_name}
                      onChange={onChangeFormInvite}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      placeholder="Last Name"
                      name="last_name"
                      value={formInvite?.last_name}
                      required
                      onChange={onChangeFormInvite}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <TextField
                      placeholder="Email"
                      name="email"
                      required
                      value={formInvite?.email}
                      onChange={onChangeFormInvite}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Button block type="submit">
                      Save
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>
      <Grid container spacing={4}>
        <Grid item xs container sx={{ display: { xs: "none", sm: "flex" } }}>
          <Grid xs={2} sm={2} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12}>
          <Stack
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="space-between"
            spacing={2}
          >
            <Typography variant="h2" color="initial">
              {campaignById?.campaign_name}
            </Typography>

            <Box>
              <Stack direction="row" alignItems="center" spacing={2}>
                {/* <Button onClick={() => handleInviteExamer()}>Send Email</Button> */}
                <Button
                  sx={{
                    textTransform: "none",
                  }}
                  onClick={() => handleInviteSendEmail()}
                >
                  Publish
                </Button>
              </Stack>
            </Box>
          </Stack>
        </Grid>

        <Grid xs={12} sm={5} item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography gutterBottom variant="h5" color="initial">
              Invite Candidate
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            By Public link
          </Grid>
          <Grid
            item
            container
            xs={12}
            sm={12}
            spacing={1}
            alignItems="center"
            justifyContent="space-between"
          >
            <Grid item xs={8} sm={8}>
              <Toggle
                value="public-link"
                selected={isPublishLink}
                onChange={(e) => setIsPublishLink(e.target.checked)}
              />
              General public link
            </Grid>
            <Grid item xs={4} sm={4}>
              <Button
                block
                variant="outlined"
                onClick={handleCopyLink}
                disabled={!isPublishLink}
              >
                {" "}
                Copy
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>
        </Grid>

        <Grid xs={12} sm={7} item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography gutterBottom variant="h5" color="initial">
              Included Test
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} spacing={1}>
            <Box sx={{ height: 284, width: "100%" }}>
              <Table
                columns={columnsIncludeTest}
                checkboxSelection={false}
                rows={campaignById?.examSetList}
                hideFooter={true}
              />
            </Box>
          </Grid>
        </Grid>

        <Grid xs={12} sm={5} item container spacing={1}>
          <Grid xs={12} sm={12} item>
            <Paper>
              <Taps
                listTap={["By Email List", "By Name List"]}
                valueCurrentTab={tabActive}
                onChange={(event, newValue) => setTabActive(newValue)}
              />

              <TabPanel value={tabActive} index={0}>
                <form onSubmit={handleInviteExamer}>
                  <Grid container items spacing={2} direction="column">
                    <Grid item xs={12} sm={12}>
                      <TextField
                        placeholder="First Name"
                        name="first_name"
                        required
                        value={formInvite?.first_name}
                        onChange={onChangeFormInvite}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        placeholder="Last Name"
                        name="last_name"
                        value={formInvite?.last_name}
                        required
                        onChange={onChangeFormInvite}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <TextField
                        placeholder="Email"
                        name="email"
                        required
                        value={formInvite?.email}
                        onChange={onChangeFormInvite}
                      />
                    </Grid>
                    <Grid item xs={12} sm={12}>
                      <Button
                        block
                        type="submit"
                        // disabled={!validateEmail(formInvite?.email)}
                      >
                        import e-mail
                      </Button>
                    </Grid>
                  </Grid>
                </form>
              </TabPanel>
              <TabPanel value={tabActive} index={1}>
                <Grid container spacing={2} direction="column">
                  <Grid item xs={12} sm={12}>
                    <Typography variant="body1" color="initial">
                      Import multiple list from file. Download name list{" "}
                      <a
                        href="https://docs.google.com/spreadsheets/d/16HnAAZh5ZRM6bxQk7Ghcd28ojmebw0hQ/edit?usp=sharing&ouid=115016906463370334714&rtpof=true&sd=true"
                        download
                      >
                        template
                      </a>
                      <a href="" do></a>
                    </Typography>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Box sx={{ width: "100%", flexGrow: 1 }}>
                      <ButtonUploadXLSX
                        id="upload-image-or-video"
                        defaultValue={fileNameUpload}
                        outlined
                        onClear={handleClear}
                        onChange={(e) => handleReadFile(e)}
                      />
                    </Box>
                  </Grid>
                </Grid>{" "}
                {/* <DropzoneUploadBox
                  Icon={null}
                  acceptedFiles={[
                    ".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel",
                  ]}
                  dropzoneText={
                    <>
                      <strong className="blue-text">
                        Drop files here to upload
                      </strong>
                      <small>
                        To upload file size is (Max 5 mb) and allowed file type
                        is csv.
                      </small>
                      <Button>Upload file</Button>
                    </>
                  }
                  showPreviews
                  showFileNames
                  maxFileSize={5000000}
                  onChange={(files) => console.log("Files:", files)}
                  onAlert={(message, variant) =>
                    console.log(`${variant}: ${message}`)
                  }
                /> */}
              </TabPanel>
            </Paper>
          </Grid>
        </Grid>

        <Grid xs={12} sm={7} item container spacing={1}>
          <Grid item container xs direction="column">
            <Typography gutterBottom variant="h5" color="initial">
              Custom Question
            </Typography>
            <Box sx={{ height: 300, width: "100%" }}>
              <Table
                columns={columnsQuestion}
                checkboxSelection={false}
                rows={campaignById?.customQuestionList}
                hideFooter={true}
              />
            </Box>
          </Grid>
          <Grid
            xs={12}
            sm={12}
            item
            container
            spacing={2}
            justifyContent="flex-end"
          >
            <Grid item xs={12} sm={12}>
              <Button
                variant="outlined"
                onClick={() => history.push("draft-email")}
              >
                Customize invitation email
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1" color="#005CC9">
              Email invitation
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box sx={{ height: 300, width: "100%" }}>
              <Table
                columns={columnsEmailInvitation}
                checkboxSelection={false}
                rows={inviteExamerListDataDisplay}
                // hideFooter={true}
                onEditRow={(row) => handleOpenModalEdit(row)}
                onDeleteRow={(row) => handleOpenConfirmDialog(row)}
                showRunningNumber
              />
            </Box>
          </Grid>
          {isShowPagination && (
            <Grid xs={12} item>
              <Pagination
                count={pageCount}
                page={page}
                onChange={onChangePage}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Div>
  );
};

export default AssessmentDetail;

const Wrapper = styled.div`
  .MuiDropzoneArea-root {
    display: flex;
    align-items: center;
    padding: 2vw 3vw;
  }

  .MuiDropzoneArea-text {
    display: flex;
    flex-direction: column;
    row-gap: 0.5em;
    color: inherit;
  }

  .MuiDropzoneArea-icon {
    display: none;
  }
`;

const DropzoneUploadBox = ({ ...props }) => {
  return (
    <Wrapper>
      <DropzoneAreaBase {...props} />
    </Wrapper>
  );
};
