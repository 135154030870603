import React from "react";
import { Box } from "@mui/material";

const Tabpanel = ({ children, value, index, ...other }) => {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: { xs: 1, sm: 3 } }}>
          <label>{children}</label>
        </Box>
      )}
    </div>
  );
};

export default Tabpanel;
