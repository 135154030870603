import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { AddCircle as AddIcon, Search } from "@mui/icons-material";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
} from "@mui/material";

import RouterLinkCell from "../../components/table/RouterLinkCell";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";
import Divider from "../../components/surfaces/Divider";

import Pagination from "../../components/navigation/Pagination";
import Stepper from "../../components/navigation/Stepper";

import Toggle from "../../components/form/Toggle";
import Button from "../../components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import Select from "../../components/form/Select";
import TextField from "../../components/form/TextField";
import Checkbox from "../../components/form/Checkbox";
import Chip from "../../components/form/Chip";

import SubContentDialog from "../../components/dialog/SubContentDialog";
import ConfirmDialog from "../../components/dialog/ConfirmDialog";

import BoxIcon from "../../containers/BoxIcon";

import RichTextEditor from "components/form/RichTextEditor";

import { INITIAL_SCENARIOS } from "utils/dataConfig";

import {
  getExamQuestionsList,
  deleteQuestion,
  approveQuestion,
  createExamQuestion,
} from "../../redux/actions/assessments";
import Table from "components/table/Table";
import formatValue from "utils/formatValue";
import UploadImageChoice from "containers/UploadImageChoice";

import {
  RenderHtmlToString,
  DisplyDeltaOrHtml,
} from "components/form/ConvertHtmlToString";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;

  .background-menu-filter {
    background-color: #f5f7fc;
    border-radius: 8px;
    /* margin: 0 auto; */
  }
`;

const steps = ["Input information", "Create question"];

const INITIAL_FORM_STEP_INFO = {
  subject_id: "",
  topic_name: "",
  exam_owner: "",
  level: "",
  amount_answer: "",
  job_position_id: "",
  company_id: 1,
};

const INITIAL_FORM_STEP_CHOICE = {
  is_random_answer: false,
  choices: [],
  // images: [],
};

const INITIAL_CHOICE = {
  choice_no: 1,
  choice_description: "",
  choice_type: "answer",
  is_answer: false,
  answer_point: 1,
};

const INITIAL_FILTER = {
  skip: 0,
  exam_owner: "",
  subject_id: "",
  topic_name: "",
  level: "",
  status: "",
  question: "",
  is_published: 1,
};

const INITIAL_IMAGE = {
  choice_no: 0,
  image_url: "",
  image_order: 1,
};

const Questions = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { examQuestionList, pageCount, limit, total } = useSelector(
    (state) => state.assessments
  );
  const {
    masterDataSubject,
    masterDataLevel,
    masterDataJob,
    masterDataNumberOfChoice,
    masterDataStatus,
  } = useSelector((state) => state.masterData);

  const [page, setPage] = useState(1);
  const [activeStep, setActiveStep] = useState(0);

  const [openCreateQuestion, setOpenCreateQuestion] = useState(false);
  const [openApproveAllDialog, setOpenApproveAllDialog] = useState(false);
  const [openApproveDialog, setOpenApproveDialog] = useState(false);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [openFilterDialog, setOpenFilterDialog] = useState(false);

  const [currentData, setCurrentData] = useState("");
  const [selectedList, setSelectedList] = useState([]);

  const [formInfo, setFormInfo] = useState(INITIAL_FORM_STEP_INFO);
  const [errorsFormInfo, setErrorsFormInfo] = useState({});

  const [formChoice, setFormChoice] = useState(INITIAL_FORM_STEP_CHOICE);
  const [filter, setFilter] = useState(INITIAL_FILTER);

  useEffect(() => {
    dispatch(getExamQuestionsList(filter));
  }, []);

  useEffect(() => {
    setTimeout(() => {
      fetchQuestionList();
    }, 250);
  }, [filter]);

  const fetchQuestionList = useCallback(() => {
    dispatch(getExamQuestionsList(filter));
  }, [filter]);

  useEffect(() => {
    let amount = +formInfo?.amount_answer + 1;
    let newArrChoice = [];

    for (let index = 0; index < amount; index++) {
      if (index === 0) {
        newArrChoice = [
          ...newArrChoice,
          { ...INITIAL_CHOICE, choice_type: "question", choice_no: index },
        ];
      } else
        newArrChoice = [
          ...newArrChoice,
          { ...INITIAL_CHOICE, choice_no: index },
        ];
    }

    // for (let index = 0; index < amount; index++) {
    //   newArrImages.push({ ...INITIAL_IMAGE, choice_no: index });
    // }

    setFormChoice((prev) => ({
      ...prev,
      choices: newArrChoice.map((item) => ({
        ...item,
        image_url: "",
        image_order: 1,
      })),
    }));
  }, [formInfo?.amount_answer]);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "No. ",
    //   minWidth: 50,
    // },
    {
      field: "code",
      minWidth: 250,
      headerName: "ID ",
      renderCell: ({ value, row }) => {
        return (
          <RouterLinkCell to={`questions/${row?.id}`}>{value} </RouterLinkCell>
        );
      },
    },
    {
      field: "question",
      headerName: "Question ",
      minWidth: 250,
      renderCell: RenderHtmlToString,
    },
    {
      field: "subject_id",
      headerName: "Subject ",
    },
    {
      field: "topic_name",
      headerName: "Topic ",
    },
    {
      field: "level",
      headerName: "Level ",
    },
    {
      field: "name_en",
      headerName: "Job Position ",
    },
    {
      field: "exam_owner",
      headerName: "Author ",
    },
    {
      field: "created_at",
      headerName: "วันที่สร้างข้อสอบ ",
      valueGetter: ({ row }) => formatValue("Date", row?.created_at),
    },
    {
      field: "",
      headerName: "Vefify",
      renderCell: ({ row }) => (
        <Button
          block
          disabled={row?.status === "active"}
          onClick={() => {
            handleOpenApproveDialog(row);
          }}
        >
          {" "}
          Approve
        </Button>
      ),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 100,
    },
  ];

  const fetchExamQuestionList = useCallback(() => {
    dispatch(getExamQuestionsList(filter));
  }, [filter]);

  const handleSearch = () => {
    fetchExamQuestionList();
  };

  const onChangePage = (event, value) => {
    console.log({ value, limit });
    setPage(value);
    setFilter((prev) => ({
      ...prev,
      skip: limit * (value - 1),
    }));
  };

  const onChangeFilter = (fieldName, newValue) => {
    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  };

  const onChangeMultiCheck = (fieldName, value, type) => {
    let newArr = [...filter?.[fieldName]];
    if (value) {
      newArr.push(type);
    } else {
      newArr = newArr.filter((it) => it !== type);
    }

    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newArr,
    }));
  };

  const handleOpenDialog = () => {
    setOpenFilterDialog(!openFilterDialog);
  };
  const handleCloseDialog = () => {
    setOpenFilterDialog(!openFilterDialog);
  };

  const handleCheckRowTable = (selectList) => {
    setSelectedList(selectList);
  };

  const handleOpenConfirmDialog = (data) => {
    setCurrentData(data);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleDelete = () => {
    dispatch(deleteQuestion(currentData?.id)).then(() => {
      fetchExamQuestionList();
      handleCloseConfirmDialog();
    });
  };

  const handleOpenApproveDialog = (data) => {
    setCurrentData(data);
    setOpenApproveDialog(!openApproveDialog);
  };

  const handleCloseApproveDialog = () => {
    setOpenApproveDialog(!openApproveDialog);
  };

  const handleOpenApproveAllDialog = (data) => {
    setOpenApproveAllDialog(!openApproveAllDialog);
  };

  const handleCloseApproveAllDialog = () => {
    setOpenApproveAllDialog(!openApproveAllDialog);
  };

  const handleApprove = () => {
    let body = {
      questions: [
        {
          id: currentData?.id,
          status: "active",
        },
      ],
    };

    dispatch(approveQuestion(body)).then(() => {
      handleCloseApproveDialog();
      fetchExamQuestionList();
    });
  };

  const handleApproveAll = () => {
    let mapArr = selectedList?.map((item) => ({
      id: item,
      status: "active",
    }));

    let body = {
      questions: mapArr,
    };

    dispatch(approveQuestion(body)).then(() => {
      handleCloseApproveAllDialog();
      fetchExamQuestionList();
    });
  };

  const handleOpenCreateQuestionDialog = (data) => {
    setOpenCreateQuestion(!openCreateQuestion);
  };

  const handleCloseCreateQuestionDialog = () => {
    setOpenCreateQuestion(!openCreateQuestion);
    setActiveStep(0);
    setFormInfo(INITIAL_FORM_STEP_INFO);
    setFormChoice(INITIAL_FORM_STEP_CHOICE);
    fetchExamQuestionList();
  };

  const handleChangeFormInfo = (event) => {
    const { name, value } = event.target;

    setFormInfo((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateFormInfo({ [name]: value });
  };

  const handleChangeRandomChoice = (event) => {
    const { name, checked } = event.target;

    setFormChoice((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleChangeAuswerCheckbox = (event, index) => {
    const { checked } = event.target;

    let newArr = [...formChoice?.choices];
    newArr[index] = {
      ...newArr[index],
      is_answer: checked,
    };
    setFormChoice((prev) => ({
      ...prev,
      choices: newArr,
    }));
  };

  const handleSetDelta = (value = "", index) => {
    let newArr = [...formChoice?.choices];
    newArr[index] = {
      ...newArr[index],
      choice_description: JSON.stringify(value),
    };

    setFormChoice((prev) => ({
      ...prev,
      choices: newArr,
    }));
  };

  const handleUploadImage = (order, path) => {
    let newArr = [...formChoice?.choices];
    newArr[order] = { ...newArr[order], image_url: path };
    setFormChoice((prev) => ({
      ...prev,
      choices: newArr,
    }));
  };

  const validateFormInfo = (
    fieldValues = {
      ...formInfo,
      company_id: "not-required",
    }
  ) => {
    let temp = { ...errorsFormInfo };
    if ("subject_id" in fieldValues)
      temp.subject_id = fieldValues.subject_id !== "" ? "" : "กรุณาเลือก";
    if ("topic_name" in fieldValues)
      temp.topic_name = fieldValues.topic_name !== "" ? "" : "กรุณากรอก";
    if ("exam_owner" in fieldValues)
      temp.exam_owner = fieldValues.exam_owner !== "" ? "" : "กรุณากรอก";
    if ("level" in fieldValues)
      temp.level = fieldValues.level !== "" ? "" : "กรุณาเลือก";
    if ("amount_answer" in fieldValues)
      temp.amount_answer = fieldValues.amount_answer !== "" ? "" : "กรุณาเลือก";
    if ("job_position_id" in fieldValues)
      temp.job_position_id =
        fieldValues.job_position_id !== "" ? "" : "กรุณาเลือก";

    setErrorsFormInfo({ ...temp });

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const handleSubmitStep1 = (event) => {
    event.preventDefault();

    const isValid = validateFormInfo();

    if (isValid) {
      setActiveStep(1);
    }
  };

  const handleSubmitStep2 = (event) => {
    event.preventDefault();

    let body = {
      ...formInfo,
      ...formChoice,
      images: formChoice?.choices?.map((item) => ({
        choice_no: item?.choice_no,
        image_url: item?.image_url,
        image_order: item?.image_order,
      })),
    };
    dispatch(createExamQuestion(body)).then(() => {
      handleCloseCreateQuestionDialog();
    });
  };

  return (
    <Div>
      <SubContentDialog
        open={openCreateQuestion}
        title={"Question Information"}
        onClose={handleCloseCreateQuestionDialog}
        alignTitle="center"
        maxWidth="lg"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Stepper listStep={steps} activeStep={activeStep} />
          </Grid>
          <Grid item xs={12} sm={12}>
            {activeStep === 0 ? (
              <form onSubmit={handleSubmitStep1}>
                <Grid item container spacing={2} xs={12} sm={12}>
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Select
                        defaultValue="Subject"
                        items={masterDataSubject}
                        value={formInfo?.subject_id}
                        error={errorsFormInfo?.subject_id}
                        onChange={handleChangeFormInfo}
                        name="subject_id"
                      />

                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Stack direction="row">
                          <Checkbox label="Other subject" disabled />
                        </Stack>
                        <TextField
                          disabled
                          placeholder="for subject, please specify here"
                        />
                      </Stack>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <TextField
                        placeholder="Topic"
                        value={formInfo?.topic_name}
                        error={errorsFormInfo?.topic_name}
                        onChange={handleChangeFormInfo}
                        name="topic_name"
                      />
                      <TextField
                        placeholder="Author"
                        value={formInfo?.exam_owner}
                        error={errorsFormInfo?.exam_owner}
                        onChange={handleChangeFormInfo}
                        name="exam_owner"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Select
                        defaultValue="Please specify level of difficuly"
                        items={masterDataLevel}
                        value={formInfo?.level}
                        error={errorsFormInfo?.level}
                        onChange={handleChangeFormInfo}
                        name="level"
                      />
                      <Select
                        defaultValue="Select job position"
                        items={masterDataJob}
                        error={errorsFormInfo?.job_position_id}
                        value={formInfo?.job_position_id}
                        onChange={handleChangeFormInfo}
                        name="job_position_id"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Select
                        defaultValue="Select type of question"
                        value={""}
                        items={[]}
                        disabled
                      />
                      <Select
                        defaultValue="Please specify number of choices"
                        items={masterDataNumberOfChoice}
                        value={formInfo?.amount_answer}
                        error={errorsFormInfo?.amount_answer}
                        onChange={handleChangeFormInfo}
                        name="amount_answer"
                      />
                    </Stack>
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                      spacing={2}
                    >
                      <Button
                        outlined
                        onClick={() => handleCloseCreateQuestionDialog()}
                      >
                        CANCEL
                      </Button>
                      <Button type="submit">NEXT</Button>
                    </Stack>
                  </Grid>
                </Grid>
              </form>
            ) : (
              <form onSubmit={handleSubmitStep2}>
                <Grid item container spacing={2} xs={12} sm={12}>
                  <Grid item container xs={12} sm={12} spacing={2}>
                    {formChoice?.choices?.map((item, index) => (
                      <>
                        <Grid item xs={12} sm={12}>
                          <Stack
                            direction="row"
                            alignItems="center"
                            spacing={2}
                          >
                            <Typography variant="body1" color="initial">
                              {index === 0 ? "Question" : `Choice ${index}`}
                            </Typography>
                            {index !== 0 && (
                              <Checkbox
                                label="Select as correct answer"
                                checked={item?.is_answer}
                                onChange={(e) =>
                                  handleChangeAuswerCheckbox(e, index)
                                }
                              />
                            )}
                          </Stack>
                        </Grid>
                        <Grid item container xs={12} sm={12} spacing={2}>
                          <Grid item xs={12} sm={8}>
                            <RichTextEditor
                              onRichTextBlur={(e) => handleSetDelta(e, index)}
                            />
                          </Grid>
                          <Grid item xs={12} sm={4}>
                            <UploadImageChoice
                              inputID={`image-question:${index}`}
                              index={index}
                              defaultImage={item?.image_url}
                              setImage={handleUploadImage}
                            />
                          </Grid>
                        </Grid>
                      </>
                    ))}
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <Typography variant="subtitle1" color="initial">
                          Remark :
                        </Typography>
                        <Typography variant="body1" color="initial">
                          You need to approve question before using in test set.
                        </Typography>
                      </Stack>

                      <Box sx={{ width: "100%" }}>
                        <Typography
                          variant="subtitle1"
                          color="initial"
                          align="right"
                        >
                          Do you want to random choices?
                          <Toggle
                            // value="public-link"
                            name="is_random_answer"
                            checked={formChoice?.is_random_answer}
                            onChange={handleChangeRandomChoice}
                          />
                        </Typography>
                      </Box>
                    </Stack>
                  </Grid>
                  <Grid item container xs={12} sm={12}>
                    <Grid item xs={12} sm={2}></Grid>
                    <Grid item xs={12} sm={8}>
                      <Stack direction="row" alignItems="center" spacing={2}>
                        <Button
                          block
                          outlined
                          onClick={() => {
                            setActiveStep(0);
                          }}
                        >
                          Cancel
                        </Button>
                        <Button block type="submit">
                          Create
                        </Button>
                      </Stack>
                    </Grid>
                    <Grid item xs={2} sm={2}></Grid>
                  </Grid>
                </Grid>
              </form>
            )}
          </Grid>
        </Grid>
      </SubContentDialog>

      <SubContentDialog
        fullScreen
        title="Filter"
        open={openFilterDialog}
        onClose={handleCloseDialog}
      >
        <Grid container>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Select Library{" "}
              </Typography>
              <Checkbox
                id="check-center-library"
                checked={filter?.is_published === 1}
                label=" Center library"
                onChange={() => onChangeFilter("is_published", 1)}
              />
              <Checkbox
                id="check-my-library"
                checked={filter?.is_published === 0}
                label="My library"
                onChange={() => onChangeFilter("is_published", 0)}
              />
            </Stack>

            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Date Posted
              </Typography>
              <Checkbox variant="" label="Last Hour" />
              <Checkbox label="Last 24 Hour" />
              <Checkbox label="Last 7 Days" />
              <Checkbox label="Last 14 Days" />
              <Checkbox label="Last 30 Days" />
              <Checkbox label="All" />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Test Level
              </Typography>
              <Checkbox
                id="check-level-1"
                label="1 Level"
                checked={filter?.level?.includes("1")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "1")
                }
              />
              <Checkbox
                id="check-level-2"
                label="2 Level"
                checked={filter?.level?.includes("2")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "2")
                }
              />
              <Checkbox
                id="check-level-3"
                label="3 Level"
                checked={filter?.level?.includes("3")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "3")
                }
              />
              <Checkbox
                id="check-level-4"
                label="4 Level"
                checked={filter?.level?.includes("4")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "4")
                }
              />
              <Checkbox
                id="check-level-5"
                label="5 Level"
                checked={filter?.level?.includes("5")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "5")
                }
              />
              <Checkbox
                id="check-level-6"
                label="6 Level"
                checked={filter?.level?.includes("6")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "6")
                }
              />
              <Checkbox
                id="check-level-7"
                label="7 Level"
                checked={filter?.level?.includes("7")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "7")
                }
              />
              <Checkbox
                id="check-level-8"
                label="8 Level"
                checked={filter?.level?.includes("8")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "8")
                }
              />
              <Checkbox
                id="check-level-9"
                label="9 Level"
                checked={filter?.level?.includes("9")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "9")
                }
              />
              <Checkbox
                id="check-level-10"
                label="10 Level"
                checked={filter?.level?.includes("10")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "10")
                }
              />
              <Checkbox
                id="check-level-11"
                label="11 Level"
                checked={filter?.level?.includes("11")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "11")
                }
              />
              <Box px={1}>
                <Button variant="text" startIcon={<AddIcon />}>
                  View more
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </SubContentDialog>

      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
        title="Delete Item"
        // content={currentData?.question}
        content={DisplyDeltaOrHtml(currentData?.question)}
      />
      <ConfirmDialog
        open={openApproveDialog}
        onClose={handleCloseApproveDialog}
        onConfirm={handleApprove}
        title={"Approve Question"}
        content={`${selectedList?.length} will be approved`}
      />
      <ConfirmDialog
        open={openApproveAllDialog}
        onClose={handleCloseApproveAllDialog}
        onConfirm={handleApproveAll}
        title={"Approve Question"}
        content={`${selectedList?.length} will be approved`}
      />
      <Grid container direction="column" spacing={4}>
        <Grid
          item
          xs
          container
          sx={{ display: { xs: "none", sm: "flex" } }}
          spacing={2}
        >
          <Grid xs={12} sm={2} item>
            <Button
              block
              outlined
              onClick={() => history.push("/test/testset")}
            >
              TestSet
            </Button>
          </Grid>
          <Grid xs={12} sm={2} item>
            <Button block>Question</Button>
          </Grid>
        </Grid>

        <Grid item container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}></Grid>
          <Grid item xs={12} sm={8} md={9} container spacing={4}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" color="initial">
                    Your Questions
                  </Typography>

                  <Button variant="text" onClick={() => handleOpenDialog()}>
                    <Typography
                      sx={{
                        display: { xs: "flex", sm: "none" },
                        textTransform: "initial",
                      }}
                      variant="h4"
                      color="#696969"
                    >
                      Filter
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button block onClick={() => handleOpenCreateQuestionDialog()}>
                  Create Question
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: { xs: "transparency", sm: "#f5f7fc" },
                // height: 400,
                // overflow: "auto",
              }}
              p={{ xs: 0, sm: 2 }}
            >
              <Stack spacing={2} justifyContent="flex-start">
                <Stack spacing={2}>
                  <Typography variant="subtitle1" color="initial">
                    Search by Keywords
                  </Typography>

                  <TextField
                    type="search"
                    placeholder="Search"
                    innerIconFieldStart={<Search />}
                  />
                </Stack>

                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Select Library{" "}
                    </Typography>
                    <Checkbox
                      id="check-center-library"
                      checked={filter?.is_published === 1}
                      label=" Center library"
                      onChange={() => onChangeFilter("is_published", 1)}
                    />
                    <Checkbox
                      id="check-my-library"
                      checked={filter?.is_published === 0}
                      label="My library"
                      onChange={() => onChangeFilter("is_published", 0)}
                    />
                  </Stack>
                </Box>

                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Test Level
                    </Typography>
                    <Checkbox
                      id="check-level-1"
                      label="1 Level"
                      checked={filter?.level?.includes("1")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "1")
                      }
                    />
                    <Checkbox
                      id="check-level-2"
                      label="2 Level"
                      checked={filter?.level?.includes("2")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "2")
                      }
                    />
                    <Checkbox
                      id="check-level-3"
                      label="3 Level"
                      checked={filter?.level?.includes("3")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "3")
                      }
                    />
                    <Checkbox
                      id="check-level-4"
                      label="4 Level"
                      checked={filter?.level?.includes("4")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "4")
                      }
                    />
                    <Checkbox
                      id="check-level-5"
                      label="5 Level"
                      checked={filter?.level?.includes("5")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "5")
                      }
                    />
                    <Checkbox
                      id="check-level-6"
                      label="6 Level"
                      checked={filter?.level?.includes("6")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "6")
                      }
                    />
                    <Checkbox
                      id="check-level-7"
                      label="7 Level"
                      checked={filter?.level?.includes("7")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "7")
                      }
                    />
                    <Checkbox
                      id="check-level-8"
                      label="8 Level"
                      checked={filter?.level?.includes("8")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "8")
                      }
                    />
                    <Checkbox
                      id="check-level-9"
                      label="9 Level"
                      checked={filter?.level?.includes("9")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "9")
                      }
                    />
                    <Checkbox
                      id="check-level-10"
                      label="10 Level"
                      checked={filter?.level?.includes("10")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "10")
                      }
                    />
                    <Checkbox
                      id="check-level-11"
                      label="11 Level"
                      checked={filter?.level?.includes("11")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "11")
                      }
                    />

                    <Box px={1}>
                      <Button variant="text" startIcon={<AddIcon />}>
                        View more
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9} container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction="row"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={3}>
                <Select
                  defaultValue="Subject"
                  value={filter?.subject_id}
                  items={masterDataSubject}
                  onChange={(e) => onChangeFilter("subject_id", e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  placeholder="Topic"
                  type="search"
                  value={filter?.topic_name}
                  onChange={(e) => onChangeFilter("topic_name", e.target.value)}
                />{" "}
              </Grid>
              {/* <Grid item xs={12} sm={3}>
                <Select defaultValue="Level" value={""} items={[]} />{" "}
              </Grid> */}
              <Grid item xs={12} sm={3}>
                <TextField
                  type="search"
                  placeholder="Author"
                  value={filter?.exam_owner}
                  onChange={(e) => onChangeFilter("exam_owner", e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select
                  defaultValue="Status"
                  value={filter?.status}
                  items={masterDataStatus}
                  onChange={(e) => onChangeFilter("status", e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  placeholder="Code"
                  type="search"
                  value={filter?.code}
                  onChange={(e) => onChangeFilter("code", e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  type="search"
                  placeholder="Question"
                  value={filter?.question}
                  onChange={(e) => onChangeFilter("question", e.target.value)}
                />{" "}
              </Grid>
              <Grid item xs={12} sm={3}></Grid>
              {/* <Grid item xs={12} sm={6}>
                <TextField
                  type="search"
                  placeholder="Search ID / Question"
                  innerIconFieldStart={<Search />}
                />
              </Grid> */}
              <Grid item xs={12} sm={3}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button block onClick={() => handleSearch()}>
                    search
                  </Button>
                  <Button
                    block
                    outlined
                    onClick={() => setFilter(INITIAL_FILTER)}
                  >
                    Clear
                  </Button>
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Divider />
            </Grid>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm={9}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="initial">
                    Showing {examQuestionList?.length} of {total} Test
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    block
                    disabled={selectedList.length === 0}
                    onClick={() => {
                      handleOpenApproveAllDialog();
                    }}
                  >
                    Approve All
                  </Button>
                  {/* <Button block outlined disabled onClick={() => {}}>
                    Delete
                  </Button> */}
                </Stack>
              </Grid>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Box sx={{ width: "100%", height: 800 }}>
                {filter?.is_published === 1 ? (
                  <Table
                    columns={columns}
                    rows={examQuestionList}
                    keepNonExistentRowsSelected
                    checkboxSelection
                    onSelectionChange={(e) => handleCheckRowTable(e)}
                    showRunningNumber // disablecBorderContent
                  />
                ) : (
                  <Table
                    columns={columns}
                    rows={examQuestionList}
                    keepNonExistentRowsSelected
                    checkboxSelection
                    onSelectionChange={(e) => handleCheckRowTable(e)}
                    onDeleteRow={(row) => handleOpenConfirmDialog(row)}
                    showRunningNumber // disablecBorderContent
                  />
                )}
              </Box>
            </Grid>

            {examQuestionList?.length > 0 && (
              <Grid xs={12} item>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={onChangePage}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default Questions;
