import React, { useEffect } from "react";
import styled from "styled-components";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import katex from "katex";
import "katex/dist/katex.min.css";

import Box from "../surfaces/Box";

const StyledBox = styled(Box)`
  .ql-editor {
    min-height: 50vh;
  }
`;

const theme = "snow";
const modules = {
  toolbar: [
    [{ header: "1" }, { header: "2" }, { font: [] }],
    [{ size: [] }],
    ["bold", "italic", "underline", "strike", "blockquote", "formula"],
    [
      { list: "ordered" },
      { list: "bullet" },
      { indent: "-1" },
      { indent: "+1" },
    ],
    ["link", "image", "video"],
    [{ script: "super" }, { script: "sub" }],
    ["clean"],
  ],
};
const placeholder = "Hello My Editor";
const formats = [
  "header",
  "font",
  "size",
  "bold",
  "italic",
  "underline",
  "strike",
  "blockquote",
  "list",
  "bullet",
  "indent",
  "link",
  "image",
  "video",
  "formula",
  "script",
];

const TextEditor = ({ onChange, editValue = "" }) => {
  window.katex = katex;
  const { quill, quillRef } = useQuill({
    theme,
    modules,
    formats,
    placeholder,
  });

  useEffect(() => {
    if (quill) {
      quill.on("text-change", function (delta, source) {
        onChange(quill?.root.innerHTML);
      });
    }
  }, [quill]);

  return (
    <StyledBox>
      <div
        ref={quillRef}
        contentEditable={true}
        suppressContentEditableWarning={true}
      >
        <div dangerouslySetInnerHTML={{ __html: editValue }}></div>
      </div>
    </StyledBox>
  );
};

export default TextEditor;
