import React from "react";
import styled from "styled-components";
import {
  FormControl,
  NativeSelect,
  InputBase,
  FormHelperText,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const StyledSelect = styled(InputBase)(({ theme }) => ({
  "& .MuiInputBase-input": {
    borderRadius: theme.spacing(1),
    position: "relative",
    border: "1px solid #ECEDF2",
    padding: theme.spacing(1),
  },
}));

const Select = ({ items = [], defaultValue, error = null, label, ...rest }) => {
  return (
    <FormControl fullWidth error={error} size="small">
      {Boolean(label) && (
        <label style={{ fontSize: "16px", marginBottom: " 13px" }}>
          {label}
        </label>
      )}
      <NativeSelect
        input={
          <StyledSelect
            style={
              error ? { border: "1px solid red", borderRadius: "4px" } : {}
            }
            {...rest}
          />
        }
        IconComponent={KeyboardArrowDownIcon}
      >
        <option value="" disabled>
          {defaultValue}
        </option>
        {items.map(({ value, text }) => (
          <option key={value} value={value}>
            {text}
          </option>
        ))}
      </NativeSelect>
      {error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
};

export default Select;
