import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";

import Select from "components/form/Select";
import TextField from "components/form/TextField";

import Stack from "components/layout/Stack";

import Typography from "@mui/material/Typography";
import { saveFormIndividual } from "redux/actions/register";
import { INITIAL_ADDRESS } from "utils/dataConfig";
import {
  validateEmail,
  validatePasswords,
  validatePhone,
} from "utils/validation";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const Individual = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formIndividual } = useSelector((state) => state.register);
  const { masterDataSubDistrict, masterDataDistrict, masterDataProvince } =
    useSelector((state) => state.masterData);

  const [dataProvince, setDataProvince] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);

  const [form, setForm] = useState({ ...formIndividual });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  useEffect(() => {
    setDataProvince(masterDataProvince);
  }, [masterDataProvince]);

  const onChangeForm = (event) => {
    const { name, value } = event.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateForm({ [name]: value });
  };

  const onChangeAddress = (name, value) => {
    let newObj = form?.address?.[0] || INITIAL_ADDRESS;

    switch (name) {
      case "province_id":
        newObj = {
          ...newObj,
          [name]: value,
          district_id: "",
          sub_district_id: "",
          zip_code: "",
        };
        break;

      case "district_id":
        newObj = {
          ...newObj,
          [name]: value,
          sub_district_id: "",
          zip_code: "",
        };
        break;

      case "sub_district_id":
        const postcode = masterDataSubDistrict
          .filter((s) => s.value === +value)
          .map((z) => z.postcode)[0];
        console.log({ postcode });
        newObj = {
          ...newObj,
          [name]: value,
          zip_code: postcode,
        };
        break;

      default:
        newObj = {
          ...newObj,
          [name]: value,
        };
        break;
    }

    setForm((prev) => ({
      ...prev,
      address: [newObj],
    }));
  };

  const handleProvinceChange = (value) => {
    const districts = masterDataDistrict.filter(
      (d) => d.province_id === +value
    );
    console.log({ districts });
    setDataDistrict(districts);

    onChangeAddress("province_id", +value);
    // validate({ province: value });
  };
  const handleDistrictChange = (value) => {
    const subDistricts = masterDataSubDistrict.filter(
      (s) => s.district_id === +value
    );
    console.log("subDistricts", subDistricts);
    setDataSubDistrict(subDistricts);
    onChangeAddress("district_id", +value);
    // validate({ district: value });
  };

  const handleSubDistrictChange = (value) => {
    onChangeAddress("sub_district_id", +value);
    // validate({ subdistrict: value });
  };

  const validateForm = (fieldValues = { ...form, address: "not-required" }) => {
    let temp = { ...errors };
    if ("first_name" in fieldValues)
      temp.first_name = fieldValues.first_name !== "" ? "" : "กรุณากรอก";

    if ("last_name" in fieldValues)
      temp.last_name = fieldValues.last_name !== "" ? "" : "กรุณากรอก";

    if ("phone" in fieldValues)
      temp.phone =
        fieldValues.phone !== ""
          ? validatePhone(fieldValues.phone)
            ? ""
            : "กรุณากรอกรูปแบบให้ถูกต้อง"
          : "กรุณากรอก";

    if ("email" in fieldValues)
      temp.email =
        fieldValues.email !== ""
          ? (temp.email = validateEmail(fieldValues.email)
              ? ""
              : "กรุณากรอกรูปแบบให้ถูกต้อง")
          : "กรุณากรอก";
    if ("password" in fieldValues)
      temp.password =
        fieldValues.password !== ""
          ? validatePasswords(fieldValues.password)
            ? ""
            : "password must be greater than 8 characters, 1 uppercase, 1 special character, 1 number"
          : "กรุณากรอก";

    if ("confirmpassword" in fieldValues)
      temp.confirmpassword =
        fieldValues.confirmpassword !== ""
          ? fieldValues.confirmpassword.localeCompare(form?.password) === 0
            ? ""
            : "กรุณากรอกให้ถูกต้อง"
          : "กรุณากรอก";

    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();
    console.log("isValid", isValid);

    if (isValid) {
      dispatch(saveFormIndividual(form));
      history.push("conditions");
    }

    // if (isSubmit && isValid) {
    //   dispatch(saveFormAssessmentDetail(form));
    //   history.push("select-test");
    // }
    // setIsSubmit(true);
  };

  return (
    <Div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Stack spacing={2}>
                <Typography variant="h2" align="center" color="initial">
                  Individual Register
                </Typography>

                <Typography variant="h5" color="initial">
                  Your Information
                </Typography>
                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <TextField
                    label="Name"
                    placeholder={"Name "}
                    name="first_name"
                    value={form?.first_name}
                    error={errors?.first_name}
                    onChange={onChangeForm}
                    // required
                  />
                  <TextField
                    label="Last Name"
                    placeholder={"Last Name "}
                    name="last_name"
                    value={form?.last_name}
                    error={errors?.last_name}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <TextField
                    label="Phone"
                    type="phone"
                    placeholder={"Mobile Number "}
                    name="phone"
                    value={form?.phone}
                    error={errors?.phone}
                    onChange={onChangeForm}
                    // required
                  />
                  <TextField
                    label="Email"
                    type="email"
                    placeholder={"Email  "}
                    name="email"
                    value={form?.email}
                    error={errors?.email}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <TextField
                    label="Password"
                    type="password"
                    placeholder={"Password"}
                    name="password"
                    value={form?.password}
                    error={errors?.password}
                    onChange={onChangeForm}
                    // required
                  />
                  <TextField
                    label="Confirm Password"
                    type="password"
                    placeholder={"Confirm Password"}
                    name="confirmpassword"
                    value={form?.confirmpassword}
                    error={errors?.confirmpassword}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <Typography variant="h5" color="initial">
                  Address
                </Typography>

                {form?.address?.map((item, index) => (
                  <Fragment key={index}>
                    <Stack
                      spacing={4}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                    >
                      <TextField
                        label="Address"
                        placeholder={"Address"}
                        name="address"
                        value={item?.address}
                        // error={errors?.address}
                        onChange={(e) =>
                          onChangeAddress("address", e.target.value)
                        }
                      />
                      <TextField
                        label="Road"
                        placeholder={"Road"}
                        name="road"
                        value={item?.road}
                        // error={errors?.road}
                        onChange={(e) =>
                          onChangeAddress("road", e.target.value)
                        }
                      />
                    </Stack>

                    <Stack
                      spacing={4}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                    >
                      <Select
                        label="Province"
                        defaultValue={"Province"}
                        items={dataProvince}
                        value={item?.province_id}
                        // error={errors?.province_id}
                        onChange={(e) => handleProvinceChange(e.target.value)}
                      />
                      <Select
                        label="District"
                        defaultValue={"District"}
                        items={dataDistrict}
                        disabled={dataDistrict.length === 0}
                        value={item?.district_id}
                        // error={errors?.district_id}
                        onChange={(e) => handleDistrictChange(e.target.value)}
                      />
                    </Stack>
                    <Stack
                      spacing={4}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                    >
                      <Select
                        label="Sub District "
                        defaultValue={"Sub-District"}
                        items={dataSubDistrict}
                        value={item?.sub_district_id}
                        disabled={dataSubDistrict.length === 0}
                        // error={errors?.sub_district_id}
                        onChange={(e) =>
                          handleSubDistrictChange(e.target.value)
                        }
                      />
                      <TextField
                        label="Postal Code"
                        placeholder={"Postal Code"}
                        value={item?.zip_code}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>
                  </Fragment>
                ))}
              </Stack>

              <Button sx={{ width: { xs: "100%", sm: 600 } }} type="submit">
                Next
              </Button>
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </Div>
  );
};

export default Individual;
