import DeltaToHtml from "components/form/DeltaToHtml";
import ReactHtmlParser from "html-react-parser";

import styled from "styled-components";

const Div = styled.div`
  .truncate {
    h1,
    h2,
    h3,
    h4,
    p {
      white-space: nowrap;
      width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

const RenderHtmlToString = ({ value }) => {
  if (value === "") {
    return "-";
  }
  let isDeltaString = value?.startsWith("[");
  if (isDeltaString)
    return (
      <Div>
        <div className="truncate">{<DeltaToHtml contents={value} />}</div>
      </Div>
    );
  return (
    <Div>
      <div className="truncate">{ReactHtmlParser(value)}</div>
    </Div>
  );
};

const DisplyDeltaOrHtml = (value = "") => {
  const isDelta = value?.startsWith("[");
  if (isDelta) return <DeltaToHtml contents={value} />;
  // quill?.getContents()
  return ReactHtmlParser(value);
};

export { DisplyDeltaOrHtml, RenderHtmlToString };
