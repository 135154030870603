export const INITIAL_SCENARIOS = [
  {
    label: "1st Test",
  },
  {
    label: "2nd Test",
  },
  {
    label: "3rd Test",
  },
  {
    label: "4th Test",
  },
  {
    label: "5th Test",
  },
];

export const INITIAL_CHOICE_LIST = [
  {
    choice_no: 1,
    choice_description: "",
    choice_type: "answer",
    is_answer: false,
    answer_point: 1,
    image_url: "",
    vdo_url: "",
  },
  {
    choice_no: 2,
    choice_description: "",
    choice_type: "answer",
    is_answer: false,
    answer_point: 1,
    image_url: "",
    vdo_url: "",
  },
  {
    choice_no: 3,
    choice_description: "",
    choice_type: "answer",
    is_answer: false,
    answer_point: 1,
    image_url: "",
    vdo_url: "",
  },
  {
    choice_no: 4,
    choice_description: "",
    choice_type: "answer",
    is_answer: false,
    answer_point: 1,
    image_url: "",
    vdo_url: "",
  },
];

export const INITIAL_ADDRESS = {
  address_type: 1,
  address: "",
  road: "",
  sub_district_id: "",
  district_id: "",
  province_id: "",
  zip_code: "",
};

export const JOB_ROLE = [
  {
    label: ". NET Developer",
    id: 1,
  },
  {
    label: "อนิเมเตอร์ 3D",
    id: 2,
  },
  {
    label: "3D modeller",
    id: 3,
  },
  {
    label: "ช่างพิมพ์ 3 มิติ",
    id: 4,
  },
  {
    label: "วิศวกรซอฟต์แวร์ 3D",
    id: 5,
  },
  {
    label: "ผู้ประกอบการระเบิด",
    id: 6,
  },
  {
    label: "ผู้ประกอบการเครื่องดูดซับ",
    id: 7,
  },
  {
    label: "ที่ปรึกษาด้านวิชาการ",
    id: 8,
  },
  {
    label: "บรรณาธิการวิชาการ",
    id: 9,
  },
  {
    label: "เจ้าหน้าที่สนับสนุนด้านวิชาการ",
    id: 10,
  },
  {
    label: "ผู้จัดการที่พัก",
    id: 11,
  },
  {
    label: "ผู้อำนวยการบัญชี",
    id: 12,
  },
  {
    label: "ผู้บริหารฝ่ายบัญชี",
    id: 13,
  },
  {
    label: "ผู้จัดการบัญชี",
    id: 14,
  },
  {
    label: "นักบัญชี",
    id: 15,
  },
  {
    label: "นักวิเคราะห์บัญชี",
    id: 16,
  },
  {
    label: "ผู้ช่วยบัญชี",
    id: 17,
  },
  {
    label: "เสมียนบัญชี",
    id: 18,
  },
  {
    label: "ผู้จัดการบัญชี",
    id: 19,
  },
  {
    label: "พนักงานเจ้าหนี้",
    id: 20,
  },
  {
    label: "พนักงานบัญชีลูกหนี้",
    id: 21,
  },
  {
    label: "วิศวกรอะคูสติก",
    id: 22,
  },
  {
    label: "เจ้าหน้าที่เคลื่อนไหว",
    id: 23,
  },
  {
    label: "ผู้นำกิจกรรม",
    id: 24,
  },
  {
    label: "นักแสดงชายนักแสดงหญิง",
    id: 25,
  },
  {
    label: "ผู้ช่วยคณิตศาสตร์ประกันภัย",
    id: 26,
  },
  {
    label: "ที่ปรึกษาด้านคณิตศาสตร์ประกันภัย",
    id: 27,
  },
  {
    label: "ผู้อำนวยการคณิตศาสตร์ประกันภัย",
    id: 28,
  },
  {
    label: "นักคณิตศาสตร์ประกันภัย",
    id: 29,
  },
  {
    label: "นักฝังเข็ม",
    id: 30,
  },
  {
    label: "ผู้จัดการฝ่ายบริหาร",
    id: 31,
  },
  {
    label: "ผู้ช่วยผู้บริหาร",
    id: 32,
  },
  {
    label: "ผู้ประสานงานการรับสมัคร",
    id: 33,
  },
  {
    label: "ผู้เชี่ยวชาญโฆษณา",
    id: 34,
  },
  {
    label: "พนักงานดูแลชุมชนผู้ใหญ่",
    id: 35,
  },
  {
    label: "ครูการรู้หนังสือสำหรับผู้ใหญ่",
    id: 36,
  },
  {
    label: "วิศวกรวิเคราะห์ขั้นสูง",
    id: 37,
  },
  {
    label: "ผู้ประกอบการพยาบาลขั้นสูง",
    id: 38,
  },
  {
    label: "นักกายภาพบำบัดขั้นสูง",
    id: 39,
  },
  {
    label: "ผู้จัดการโฆษณาและโปรโมชั่น",
    id: 40,
  },
  {
    label: "ผู้ช่วยโฆษณา",
    id: 41,
  },
  {
    label: "นักเขียนคำโฆษณาโฆษณา",
    id: 42,
  },
  {
    label: "ผู้ติดตั้งโฆษณา",
    id: 43,
  },
  {
    label: "ผู้จัดการฝ่ายโฆษณา",
    id: 44,
  },
  {
    label: "ผู้ซื้อสื่อโฆษณา",
    id: 45,
  },
  {
    label: "โฆษณาสื่อวางแผน",
    id: 46,
  },
  {
    label: "ตัวแทนขายโฆษณา",
    id: 47,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการโฆษณา",
    id: 48,
  },
  {
    label: "วิศวกรอากาศพลศาสตร์",
    id: 49,
  },
  {
    label: "เจ้าหน้าที่บริการข้อมูลการบิน",
    id: 50,
  },
  {
    label: "ผู้เชี่ยวชาญด้านข้อมูลการบิน",
    id: 51,
  },
  {
    label: "วิศวกรการบินและอวกาศ",
    id: 52,
  },
  {
    label: "Drafter วิศวกรรมการบินและอวกาศ",
    id: 53,
  },
  {
    label: "ช่างเทคนิควิศวกรรมการบินและอวกาศ",
    id: 54,
  },
  {
    label: "ความงาม",
    id: 55,
  },
  {
    label: "ช่างเทคนิคบริการหลังการขาย",
    id: 56,
  },
  {
    label: "สถาปนิกว่องไว",
    id: 57,
  },
  {
    label: "วิศวกรเกษตรกรรม",
    id: 58,
  },
  {
    label: "วิศวกรออกแบบอุปกรณ์เกษตรกรรม",
    id: 59,
  },
  {
    label: "สารวัตรการเกษตร",
    id: 60,
  },
  {
    label: "เครื่องจักรกลการเกษตรและอุปกรณ์จัดจำหน่ายอุปกรณ์",
    id: 61,
  },
  {
    label: "ช่างเทคนิคการเกษตร",
    id: 62,
  },
  {
    label: "เจ้าหน้าที่นโยบายการเกษตร",
    id: 63,
  },
  {
    label: "วัตถุดิบเกษตรกรรมเมล็ดพันธุ์และผู้จัดการการจัดจำหน่ายสัตว์",
    id: 64,
  },
  {
    label: "นักวิทยาศาสตร์เกษตรกรรม",
    id: 65,
  },
  {
    label: "ช่างเทคนิคการเกษตร",
    id: 66,
  },
  {
    label: "การเกษตรป่าไม้และครูอาชีวศึกษา",
    id: 67,
  },
  {
    label: "หัวหน้าทีมผลิตพืชไร่นา",
    id: 68,
  },
  {
    label: "นักปฐพีวิทยา",
    id: 69,
  },
  {
    label: "วิศวกร AI",
    id: 70,
  },
  {
    label: "กองทัพอากาศ",
    id: 71,
  },
  {
    label: "นักบินกองทัพอากาศ",
    id: 72,
  },
  {
    label: "นักวิเคราะห์มลพิษทางอากาศ",
    id: 73,
  },
  {
    label: "ผู้ประกอบการโรงงานแยกอากาศ",
    id: 74,
  },
  {
    label: "ผู้ควบคุมการจราจรทางอากาศ",
    id: 75,
  },
  {
    label: "ผู้สอนการจราจรทางอากาศ",
    id: 76,
  },
  {
    label: "ผู้จัดการการจราจรทางอากาศ",
    id: 77,
  },
  {
    label: "ช่างเทคนิคความปลอดภัยการจราจรทางอากาศ",
    id: 78,
  },
  {
    label: "ช่างประกอบเครื่องบิน",
    id: 79,
  },
  {
    label: "ผู้ตรวจการประกอบเครื่องบิน",
    id: 80,
  },
  {
    label: "หัวหน้างานประกอบเครื่องบิน",
    id: 81,
  },
  {
    label: "ผู้ประสานงานการดำเนินงานของเครื่องบิน",
    id: 82,
  },
  {
    label: "ตัวติดตั้งเครื่องติดตั้งเครื่องบิน",
    id: 83,
  },
  {
    label: "เครื่องส่งเครื่องบิน",
    id: 84,
  },
  {
    label: "เครื่องยนต์เครื่องยนต์อากาศยาน",
    id: 85,
  },
  {
    label: "ผู้ตรวจการเครื่องยนต์อากาศยาน",
    id: 86,
  },
  {
    label: "ผู้เชี่ยวชาญด้านเครื่องยนต์อากาศยาน",
    id: 87,
  },
  {
    label: "เครื่องทดสอบเครื่องยนต์อากาศยาน",
    id: 88,
  },
  {
    label: "ผู้ให้บริการระบบเชื้อเพลิงอากาศยาน",
    id: 89,
  },
  {
    label: "เครื่องยนต์เครื่องยนต์กังหันก๊าซเครื่องยนต์",
    id: 90,
  },
  {
    label: "เครื่องแต่งกายอากาศยาน",
    id: 91,
  },
  {
    label: "ช่างเทคนิคการตกแต่งภายในเครื่องบิน",
    id: 92,
  },
  {
    label: "ผู้ประสานงานการบำรุงรักษาเครื่องบิน",
    id: 93,
  },
  {
    label: "วิศวกรการบำรุงรักษาเครื่องบิน",
    id: 94,
  },
  {
    label: "ช่างซ่อมบำรุงเครื่องบิน",
    id: 95,
  },
  {
    label: "เครื่องบินมาร์แชล",
    id: 96,
  },
  {
    label: "นักบินเครื่องบิน",
    id: 97,
  },
  {
    label: "ผู้ปฏิบัติงานบริการอาหารสายการบิน",
    id: 98,
  },
  {
    label: "นักบินขนส่งสายการบิน",
    id: 99,
  },
  {
    label: "ตัวจัดการสัมภาระสนามบิน",
    id: 100,
  },
  {
    label: "หัวหน้าผู้บริหารสนามบิน",
    id: 101,
  },
  {
    label: "ผู้อำนวยการสนามบิน",
    id: 102,
  },
  {
    label: "เจ้าหน้าที่สิ่งแวดล้อมสนามบิน",
    id: 103,
  },
  {
    label: "ช่างซ่อมสนามบิน",
    id: 104,
  },
  {
    label: "เจ้าหน้าที่ปฏิบัติการสนามบิน",
    id: 105,
  },
  {
    label: "วิศวกรวางแผนสนามบิน",
    id: 106,
  },
  {
    label: "เจ้าหน้าที่รักษาความปลอดภัยสนามบิน",
    id: 107,
  },
  {
    label: "ผู้จัดการความปลอดภัย Airside",
    id: 108,
  },
  {
    label: "ผู้จัดการน่านฟ้า",
    id: 109,
  },
  {
    label: "นักบำบัดสัตว์ทางเลือก",
    id: 110,
  },
  {
    label: "เอกอัครราชทูต",
    id: 111,
  },
  {
    label: "แอสเซมเบลอร์กระสุนปืน",
    id: 112,
  },
  {
    label: "ผู้จัดการร้านขายกระสุน",
    id: 113,
  },
  {
    label: "กระสุนผู้ขายพิเศษ",
    id: 114,
  },
  {
    label: "ผู้ดูแลความบันเทิงและนันทนาการ",
    id: 115,
  },
  {
    label: "เครื่องทำความสะอาดสวนสนุก",
    id: 116,
  },
  {
    label: "ช่างเทคนิคยาชา",
    id: 117,
  },
  {
    label: "โปรแกรมเมอร์นักวิเคราะห์",
    id: 118,
  },
  {
    label: "นักเคมีวิเคราะห์",
    id: 119,
  },
  {
    label: "ช่างเทคนิคทางกายวิภาคศาสตร์",
    id: 120,
  },
  {
    label: "นักพัฒนา Android",
    id: 121,
  },
  {
    label: "นักพัฒนาเชิงมุม",
    id: 122,
  },
  {
    label: "ช่างเทคนิคการผสมเทียมสัตว์",
    id: 123,
  },
  {
    label: "นักบำบัดโรคสัตว์",
    id: 124,
  },
  {
    label: "พฤติกรรมสัตว์",
    id: 125,
  },
  {
    label: "ผู้ดูแลสัตว์",
    id: 126,
  },
  {
    label: "หมอนวดสัตว์",
    id: 127,
  },
  {
    label: "ช่างถ่ายโอนสัตว์",
    id: 128,
  },
  {
    label: "ผู้จัดการสิ่งอำนวยความสะดวกสัตว์",
    id: 129,
  },
  {
    label: "นักโภชนาการสัตว์",
    id: 130,
  },
  {
    label: "ผู้ประกอบการอาหารสัตว์",
    id: 131,
  },
  {
    label: "หัวหน้างานเลี้ยงสัตว์",
    id: 132,
  },
  {
    label: "สัตว์เลี้ยงสัตว์",
    id: 133,
  },
  {
    label: "ผู้ดูแลสัตว์",
    id: 134,
  },
  {
    label: "นักอุทกศาสตร์สัตว์",
    id: 135,
  },
  {
    label: "นักบำบัดการนวดสัตว์",
    id: 136,
  },
  {
    label: "กระดูกสัตว์",
    id: 137,
  },
  {
    label: "นักกายภาพบำบัดสัตว์",
    id: 138,
  },
  {
    label: "คนงานที่พักพิงสัตว์",
    id: 139,
  },
  {
    label: "นักบำบัดสัตว์",
    id: 140,
  },
  {
    label: "เทรนเนอร์สัตว์",
    id: 141,
  },
  {
    label: "ผู้ตรวจสอบสวัสดิภาพสัตว์",
    id: 142,
  },
  {
    label: "ผู้กำกับแอนิเมชัน",
    id: 143,
  },
  {
    label: "ศิลปินเลย์เอาต์แอนิเมชั่น",
    id: 144,
  },
  {
    label: "อนิเมชั่น",
    id: 145,
  },
  {
    label: "ผู้ให้บริการเครื่องขั้วบวก",
    id: 146,
  },
  {
    label: "นักมานุษยวิทยา",
    id: 147,
  },
  {
    label: "อาจารย์มานุษยวิทยา",
    id: 148,
  },
  {
    label: "ผู้ผลิตเฟอร์นิเจอร์โบราณ",
    id: 149,
  },
  {
    label: "ผู้จัดการร้านค้าโบราณ",
    id: 150,
  },
  {
    label: "นักพัฒนาแอป",
    id: 151,
  },
  {
    label: "ผู้พัฒนาแอปพลิเคชัน",
    id: 152,
  },
  {
    label: "วิศวกรแอพพลิเคชั่น",
    id: 153,
  },
  {
    label: "วิศวกรความปลอดภัยของแอปพลิเคชัน",
    id: 154,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสนับสนุนแอปพลิเคชัน",
    id: 155,
  },
  {
    label: "ตัวแทนการตั้งค่าการนัดหมาย",
    id: 156,
  },
  {
    label: "นักชีววิทยาการเพาะเลี้ยงสัตว์น้ำ",
    id: 157,
  },
  {
    label: "กรงสัตว์น้ำ",
    id: 158,
  },
  {
    label: "ช่างเทคนิคกรงสัตว์น้ำ",
    id: 159,
  },
  {
    label: "นักวิเคราะห์ด้านสิ่งแวดล้อมการเพาะเลี้ยงสัตว์น้ำ",
    id: 160,
  },
  {
    label: "ผู้จัดการการเก็บเกี่ยวการเพาะเลี้ยงสัตว์น้ำ",
    id: 161,
  },
  {
    label: "ช่างเทคนิคการเก็บเกี่ยวการเพาะเลี้ยงสัตว์น้ำ",
    id: 162,
  },
  {
    label: "คนงานเก็บเกี่ยวการเพาะเลี้ยงสัตว์น้ำ",
    id: 163,
  },
  {
    label: "ผู้จัดการโรงเพาะเลี้ยงสัตว์น้ำ",
    id: 164,
  },
  {
    label: "ช่างเพาะเลี้ยงสัตว์น้ำ",
    id: 165,
  },
  {
    label: "คนงานโรงเพาะเลี้ยงสัตว์น้ำ",
    id: 166,
  },
  {
    label: "ผู้จัดการการเลี้ยงสัตว์น้ำ",
    id: 167,
  },
  {
    label: "ช่างเลี้ยงสัตว์น้ำ",
    id: 168,
  },
  {
    label: "คนงานเลี้ยงสัตว์น้ำ",
    id: 169,
  },
  {
    label: "ผู้จัดการการจอดรถ",
    id: 170,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตสัตว์น้ำ",
    id: 171,
  },
  {
    label: "หัวหน้างานคุณภาพการเพาะเลี้ยงสัตว์น้ำ",
    id: 172,
  },
  {
    label: "ช่างการเลี้ยงสัตว์น้ำ",
    id: 173,
  },
  {
    label: "ผู้จัดการการหมุนเวียนการเพาะเลี้ยงสัตว์น้ำ",
    id: 174,
  },
  {
    label: "ช่างเทคนิคการหมุนเวียนสัตว์น้ำ",
    id: 175,
  },
  {
    label: "หัวหน้างานไซต์การเพาะเลี้ยงสัตว์น้ำ",
    id: 176,
  },
  {
    label: "ผู้เชี่ยวชาญด้านสุขภาพสัตว์น้ำ",
    id: 177,
  },
  {
    label: "นักปรบมือ",
    id: 178,
  },
  {
    label: "นักโบราณคดี",
    id: 179,
  },
  {
    label: "อาจารย์โบราณคดี",
    id: 180,
  },
  {
    label: "สถาปนิก",
    id: 181,
  },
  {
    label: "ผู้จัดการสถาปัตยกรรมและวิศวกรรม",
    id: 182,
  },
  {
    label: "Drafter สถาปัตยกรรม",
    id: 183,
  },
  {
    label: "อาจารย์สอนสถาปัตยกรรม",
    id: 184,
  },
  {
    label: "ผู้จัดการจดหมายเหตุ",
    id: 185,
  },
  {
    label: "ผู้เก็บเอกสาร",
    id: 186,
  },
  {
    label: "ผู้จัดการพื้นที่",
    id: 187,
  },
  {
    label: "เจ้าหน้าที่กองกำลัง",
    id: 188,
  },
  {
    label: "เจ้าหน้าที่ฝึกอบรมและการศึกษากองกำลังติดอาวุธ",
    id: 189,
  },
  {
    label: "คนขับรถหุ้มเกราะ",
    id: 190,
  },
  {
    label: "ทหารเกราะหุ้มเกราะ",
    id: 191,
  },
  {
    label: "กองทัพบก กัปตัน",
    id: 192,
  },
  {
    label: "กองทัพบก พล.ต.ท.",
    id: 193,
  },
  {
    label: "กองทัพบก พล.อ",
    id: 194,
  },
  {
    label: "กองทัพบก พันตรี",
    id: 195,
  },
  {
    label: "นักอโรเมเธอร์คน",
    id: 196,
  },
  {
    label: "ผู้กำกับศิลป์",
    id: 197,
  },
  {
    label: "ผู้ดูแลศิลปะ",
    id: 198,
  },
  {
    label: "รูปแบบศิลปะ",
    id: 199,
  },
  {
    label: "ผู้ฟื้นฟูศิลปะ",
    id: 200,
  },
  {
    label: "อาจารย์สอนศิลปะ",
    id: 201,
  },
  {
    label: "ครูสอนศิลปะโรงเรียนมัธยม",
    id: 202,
  },
  {
    label: "นักบำบัดศิลปะ",
    id: 203,
  },
  {
    label: "ทหารปืนใหญ่",
    id: 204,
  },
  {
    label: "ช่างฝีมือ",
    id: 205,
  },
  {
    label: "โค้ชศิลปะ",
    id: 206,
  },
  {
    label: "ผู้กำกับศิลป์",
    id: 207,
  },
  {
    label: "จิตรกรศิลปะ",
    id: 208,
  },
  {
    label: "เจ้าหน้าที่ศิลปะการศึกษาศิลปะ",
    id: 209,
  },
  {
    label: "คนงานที่ลดแร่ใยหิน",
    id: 210,
  },
  {
    label: "ผู้พัฒนา ASP.NET",
    id: 211,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการยางมะตอย",
    id: 212,
  },
  {
    label: "ผู้ประกอบการโรงงานยางมะตอย",
    id: 213,
  },
  {
    label: "ผู้ทดสอบ",
    id: 214,
  },
  {
    label: "ผู้จัดการสินทรัพย์",
    id: 215,
  },
  {
    label: "ผู้ช่วยนักจิตวิทยาคลินิก",
    id: 216,
  },
  {
    label: "ผู้ช่วยผู้กำกับ",
    id: 217,
  },
  {
    label: "ผู้ช่วยบรรณาธิการ",
    id: 218,
  },
  {
    label: "ผู้ช่วยอาจารย์",
    id: 219,
  },
  {
    label: "ผู้ช่วยผุ้จัดการ",
    id: 220,
  },
  {
    label: "ผู้ช่วยอนิเมเตอร์กลางแจ้ง",
    id: 221,
  },
  {
    label: "ผู้ช่วยผู้อำนวยการเวที",
    id: 222,
  },
  {
    label: "ผู้ช่วยผู้อำนวยการวิดีโอและภาพยนตร์",
    id: 223,
  },
  {
    label: "บรรณาธิการร่วม",
    id: 224,
  },
  {
    label: "โหราจารย์",
    id: 225,
  },
  {
    label: "นักบินอวกาศ",
    id: 226,
  },
  {
    label: "นักดาราศาสตร์",
    id: 227,
  },
  {
    label: "ช่างซ่อมตู้เอทีเอ็ม",
    id: 228,
  },
  {
    label: "อัยการ",
    id: 229,
  },
  {
    label: "ผู้ให้บริการสถานที่ท่องเที่ยว",
    id: 230,
  },
  {
    label: "คู่ au",
    id: 231,
  },
  {
    label: "ผู้จัดการบ้านประมูล",
    id: 232,
  },
  {
    label: "ผู้ประมูล",
    id: 233,
  },
  {
    label: "ผู้จัดการร้านขายเครื่องเสียงและวิดีโอ",
    id: 234,
  },
  {
    label: "ผู้ขายเฉพาะด้านเสียงและวิดีโอ",
    id: 235,
  },
  {
    label: "เสียง describer",
    id: 236,
  },
  {
    label: "วิศวกรเสียง",
    id: 237,
  },
  {
    label: "ช่างเทคนิคการผลิตเสียง",
    id: 238,
  },
  {
    label: "ช่างเทคนิคด้านเสียง",
    id: 239,
  },
  {
    label: "นักโสตสัมผัสวิทยา",
    id: 240,
  },
  {
    label: "ผู้จัดการร้านขายอุปกรณ์โสตวิทยา",
    id: 241,
  },
  {
    label: "อุปกรณ์โสตวิทยาผู้ขายพิเศษ",
    id: 242,
  },
  {
    label: "ช่างเทคนิคโสตวิทยา",
    id: 243,
  },
  {
    label: "หัวหน้างานตรวจสอบบัญชี",
    id: 244,
  },
  {
    label: "พนักงานตรวจสอบบัญชี",
    id: 245,
  },
  {
    label: "ผู้ตรวจสอบบัญชี",
    id: 246,
  },
  {
    label: "ผู้ดำเนินการกด Auger",
    id: 247,
  },
  {
    label: "ช่างซ่อมรถยนต์",
    id: 248,
  },
  {
    label: "ผู้ให้บริการสายงานอัตโนมัติ",
    id: 249,
  },
  {
    label: "คอนโทรลเลอร์เคเบิลรถยนต์อัตโนมัติ",
    id: 250,
  },
  {
    label: "ผู้ให้บริการเครื่องตัดอัตโนมัติ",
    id: 251,
  },
  {
    label: "ผู้ให้บริการ Fly Bar อัตโนมัติ",
    id: 252,
  },
  {
    label: "ผู้ให้บริการตรวจสอบแสงอัตโนมัติ",
    id: 253,
  },
  {
    label: "วิศวกรระบบอัตโนมัติ",
    id: 254,
  },
  {
    label: "ช่างเทคนิควิศวกรรมอัตโนมัติ",
    id: 255,
  },
  {
    label: "ช่างเทคนิคแบตเตอรี่ยานยนต์",
    id: 256,
  },
  {
    label: "ช่างเทคนิคเบรกยานยนต์",
    id: 257,
  },
  {
    label: "นักออกแบบยานยนต์",
    id: 258,
  },
  {
    label: "ช่างไฟฟ้ายานยนต์",
    id: 259,
  },
  {
    label: "วิศวกรยานยนต์",
    id: 260,
  },
  {
    label: "วิศวกรรมยานยนต์ Drafter",
    id: 261,
  },
  {
    label: "ช่างเทคนิควิศวกรรมยานยนต์",
    id: 262,
  },
  {
    label: "ไดรเวอร์ทดสอบยานยนต์",
    id: 263,
  },
  {
    label: "ครูสอนวิชาชีพการพยาบาลและการผดุงครรภ์",
    id: 264,
  },
  {
    label: "ผู้จัดการการสื่อสารการบินและการประสานงานความถี่",
    id: 265,
  },
  {
    label: "ผู้จัดการฝ่ายสื่อสารข้อมูลการบิน",
    id: 266,
  },
  {
    label: "วิศวกรระบบการบินภาคพื้นดิน",
    id: 267,
  },
  {
    label: "ผู้ตรวจการการบิน",
    id: 268,
  },
  {
    label: "นักอุตุนิยมวิทยาการบิน",
    id: 269,
  },
  {
    label: "เจ้าหน้าที่ความปลอดภัยการบิน",
    id: 270,
  },
  {
    label: "ผู้จัดการการเฝ้าระวังการบินและรหัสการประสานงานรหัส",
    id: 271,
  },
  {
    label: "ผู้ตรวจการ avionics",
    id: 272,
  },
  {
    label: "ช่างเทคนิค Avionics",
    id: 273,
  },
  {
    label: "นักเขียนคำโฆษณา B2B",
    id: 274,
  },
  {
    label: "B2B ภายในการขาย",
    id: 275,
  },
  {
    label: "คนเลี้ยง",
    id: 276,
  },
  {
    label: "ผู้เชี่ยวชาญด้านหลังสำนักงาน",
    id: 277,
  },
  {
    label: "นักพัฒนากระดูกสันหลัง",
    id: 278,
  },
  {
    label: "นักพัฒนาแบ็กเอนด์",
    id: 279,
  },
  {
    label: "Backend Web Developer",
    id: 280,
  },
  {
    label: "ช่างเทคนิคแบคทีเรียวิทยา",
    id: 281,
  },
  {
    label: "ผู้ดูแลการไหลของสัมภาระ",
    id: 282,
  },
  {
    label: "พนักงานยกกระเป๋าสัมภาระ",
    id: 283,
  },
  {
    label: "คนทำขนมปัง",
    id: 284,
  },
  {
    label: "ผู้จัดการร้านเบเกอรี่",
    id: 285,
  },
  {
    label: "ผู้ขายเฉพาะเบเกอรี่",
    id: 286,
  },
  {
    label: "ผู้ประกอบการอบ",
    id: 287,
  },
  {
    label: "ผู้ประกอบการ Saw Band",
    id: 288,
  },
  {
    label: "ผู้จัดการบัญชีธนาคาร",
    id: 289,
  },
  {
    label: "ผู้จัดการธนาคาร",
    id: 290,
  },
  {
    label: "พนักงานธนาคาร",
    id: 291,
  },
  {
    label: "เหรัญญิกธนาคาร",
    id: 292,
  },
  {
    label: "ผู้จัดการผลิตภัณฑ์ธนาคาร",
    id: 293,
  },
  {
    label: "ผู้ดูแลทรัพย์สินล้มละลาย",
    id: 294,
  },
  {
    label: "ช่างตัดผม",
    id: 295,
  },
  {
    label: "นักชงกาแฟ",
    id: 296,
  },
  {
    label: "บาร์เทนเดอร์",
    id: 297,
  },
  {
    label: "ผู้ผลิตตะกร้า",
    id: 298,
  },
  {
    label: "ช่างฟิตห้องน้ำ",
    id: 299,
  },
  {
    label: "เครื่องประกอบแบตเตอรี่",
    id: 300,
  },
  {
    label: "ช่างเทคนิคการทดสอบแบตเตอรี่",
    id: 301,
  },
  {
    label: "ผู้ดูแลร้านเสริมสวย",
    id: 302,
  },
  {
    label: "ผู้จัดการร้านเสริมสวย",
    id: 303,
  },
  {
    label: "ครูสอนวิชาความงาม",
    id: 304,
  },
  {
    label: "ผู้ให้บริการที่พักพร้อมอาหารเช้า",
    id: 305,
  },
  {
    label: "ผู้เพาะพันธุ์ผึ้ง",
    id: 306,
  },
  {
    label: "เบียร์ซอมเมลิเย่ร์",
    id: 307,
  },
  {
    label: "นักวิทยาศาสตร์เชิงพฤติกรรม",
    id: 308,
  },
  {
    label: "เบลล์ฮอป",
    id: 309,
  },
  {
    label: "ตัวสร้างเข็มขัด",
    id: 310,
  },
  {
    label: "ผู้ดูแลผลประโยชน์",
    id: 311,
  },
  {
    label: "ผู้ให้คำแนะนำที่ได้รับประโยชน์",
    id: 312,
  },
  {
    label: "ที่ปรึกษาปลิดชีพ",
    id: 313,
  },
  {
    label: "ช่างเทคนิครองเท้า bespoke",
    id: 314,
  },
  {
    label: "ผู้จัดการเดิมพัน",
    id: 315,
  },
  {
    label: "ช่างเทคนิคการกรองเครื่องดื่ม",
    id: 316,
  },
  {
    label: "ผู้จัดการฝ่ายจำหน่ายเครื่องดื่ม",
    id: 317,
  },
  {
    label: "ผู้จัดการร้านเครื่องดื่ม",
    id: 318,
  },
  {
    label: "เครื่องดื่มผู้ขายพิเศษ",
    id: 319,
  },
  {
    label: "ช่างประกอบจักรยาน",
    id: 320,
  },
  {
    label: "ผู้จัดส่งจักรยาน",
    id: 321,
  },
  {
    label: "ช่างจักรยาน",
    id: 322,
  },
  {
    label: "บรรณารักษ์เก็บข้อมูลขนาดใหญ่",
    id: 323,
  },
  {
    label: "วิศวกรข้อมูลขนาดใหญ่",
    id: 324,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเรียกเก็บเงินและการโพสต์",
    id: 325,
  },
  {
    label: "เสมียนการเรียกเก็บเงิน",
    id: 326,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเรียกเก็บเงิน",
    id: 327,
  },
  {
    label: "ตัวดำเนินการ",
    id: 328,
  },
  {
    label: "ผู้โทรหาบิงโก",
    id: 329,
  },
  {
    label: "วิศวกรชีวเคมี",
    id: 330,
  },
  {
    label: "นักชีวเคมี",
    id: 331,
  },
  {
    label: "ช่างเทคนิคชีวเคมี",
    id: 332,
  },
  {
    label: "เครื่องยนต์ชีวภาพ",
    id: 333,
  },
  {
    label: "ช่างเทคนิคชีวภาพ",
    id: 334,
  },
  {
    label: "นักชีวภาพ",
    id: 335,
  },
  {
    label: "นักวิเคราะห์ชีวสารสนเทศศาสตร์",
    id: 336,
  },
  {
    label: "นักวิทยาศาสตร์ชีวสารสนเทศศาสตร์",
    id: 337,
  },
  {
    label: "นักชีววิทยา",
    id: 338,
  },
  {
    label: "วิทยากรชีววิทยา",
    id: 339,
  },
  {
    label: "ครูสอนวิชาชีววิทยาโรงเรียนมัธยม",
    id: 340,
  },
  {
    label: "ช่างเทคนิคชีววิทยา",
    id: 341,
  },
  {
    label: "วิศวกรชีวการแพทย์",
    id: 342,
  },
  {
    label: "นักวิทยาศาสตร์ชีวการแพทย์",
    id: 343,
  },
  {
    label: "นักวิทยาศาสตร์ชีวการแพทย์ขั้นสูง",
    id: 344,
  },
  {
    label: "นักชีวภาพ",
    id: 345,
  },
  {
    label: "นักชีวฟิสิกส์",
    id: 346,
  },
  {
    label: "ช่างเทคนิคเทคโนโลยีชีวภาพ",
    id: 347,
  },
  {
    label: "ช่างตีเหล็ก",
    id: 348,
  },
  {
    label: "ผู้ให้บริการลวก",
    id: 349,
  },
  {
    label: "ผู้ประกอบการอัฒจันทร์",
    id: 350,
  },
  {
    label: "ผู้ประกอบการเครื่องปั่น",
    id: 351,
  },
  {
    label: "ผู้ประกอบการโรงงานผสม",
    id: 352,
  },
  {
    label: "บล็อกผู้ปฏิบัติงานเครื่องจักร",
    id: 353,
  },
  {
    label: "นักพัฒนาบล็อกเชน",
    id: 354,
  },
  {
    label: "บรรณาธิการบล็อก",
    id: 355,
  },
  {
    label: "ผู้จัดการบล็อก",
    id: 356,
  },
  {
    label: "ผู้เขียนบล็อก",
    id: 357,
  },
  {
    label: "ผู้ประกอบการเครื่องเป่าแม่พิมพ์",
    id: 358,
  },
  {
    label: "เรือแข็ง",
    id: 359,
  },
  {
    label: "เรือ",
    id: 360,
  },
  {
    label: "ศิลปินร่างกาย",
    id: 361,
  },
  {
    label: "ผู้คุ้มกัน",
    id: 362,
  },
  {
    label: "ผู้ให้บริการหม้อไอน้ำ",
    id: 363,
  },
  {
    label: "เครื่องทำหม้อต้มน้ำ",
    id: 364,
  },
  {
    label: "ช่างทิ้งระเบิด",
    id: 365,
  },
  {
    label: "บรรณาธิการหนังสือ",
    id: 366,
  },
  {
    label: "สำนักพิมพ์หนังสือ",
    id: 367,
  },
  {
    label: "ผู้บูรณะหนังสือ",
    id: 368,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรระบบเย็บหนังสือ",
    id: 369,
  },
  {
    label: "ผู้ทำบัญชี",
    id: 370,
  },
  {
    label: "ผู้ตรวจสอบ",
    id: 371,
  },
  {
    label: "ผู้จัดการร้านหนังสือ",
    id: 372,
  },
  {
    label: "ร้านขายหนังสือเฉพาะทาง",
    id: 373,
  },
  {
    label: "ผู้ให้บริการบูม",
    id: 374,
  },
  {
    label: "นักพัฒนา bootstrap",
    id: 375,
  },
  {
    label: "ผู้ให้บริการเครื่องที่น่าเบื่อ",
    id: 376,
  },
  {
    label: "ช่างเทคนิคพฤกษศาสตร์",
    id: 377,
  },
  {
    label: "ผู้เชี่ยวชาญด้านพฤกษศาสตร์",
    id: 378,
  },
  {
    label: "นักพฤกษศาสตร์",
    id: 379,
  },
  {
    label: "ผู้สอนมวย",
    id: 380,
  },
  {
    label: "ผู้ให้บริการเครื่องถักเปีย",
    id: 381,
  },
  {
    label: "การถักเปียช่างเทคนิคสิ่งทอ",
    id: 382,
  },
  {
    label: "ผู้จัดการสาขา",
    id: 383,
  },
  {
    label: "นักออกแบบแบรนด์",
    id: 384,
  },
  {
    label: "ผู้จัดการแบรนด์",
    id: 385,
  },
  {
    label: "เครื่องราง",
    id: 386,
  },
  {
    label: "ผู้ให้บริการบ้านเบียร์",
    id: 387,
  },
  {
    label: "ผู้ผลิตเบียร์",
    id: 388,
  },
  {
    label: "ก้อนอิฐและกระเบื้อง",
    id: 389,
  },
  {
    label: "ช่างก่ออิฐ",
    id: 390,
  },
  {
    label: "หัวหน้างานก่ออิฐ",
    id: 391,
  },
  {
    label: "หัวหน้างานก่อสร้างสะพาน",
    id: 392,
  },
  {
    label: "ผู้ตรวจการสะพาน",
    id: 393,
  },
  {
    label: "นายพลจัตวา",
    id: 394,
  },
  {
    label: "ผู้ประกอบการเครื่อง briquetting",
    id: 395,
  },
  {
    label: "บรรณาธิการข่าวออกอากาศ",
    id: 396,
  },
  {
    label: "ช่างเทคนิคการออกอากาศ",
    id: 397,
  },
  {
    label: "ผู้อำนวยการโครงการกระจายเสียง",
    id: 398,
  },
  {
    label: "ตัวแทนนายหน้า",
    id: 399,
  },
  {
    label: "ผู้อำนวยการ บริษัท นายหน้า",
    id: 400,
  },
  {
    label: "ผู้ผลิตแปรง",
    id: 401,
  },
  {
    label: "นักวิเคราะห์งบประมาณ",
    id: 402,
  },
  {
    label: "ผู้จัดการงบประมาณ",
    id: 403,
  },
  {
    label: "ผู้ดูแลอาคาร",
    id: 404,
  },
  {
    label: "เครื่องทำความสะอาดอาคาร",
    id: 405,
  },
  {
    label: "คนงานก่อสร้างอาคาร",
    id: 406,
  },
  {
    label: "อาคารช่างไฟฟ้า",
    id: 407,
  },
  {
    label: "อาคารทำความสะอาดภายนอก",
    id: 408,
  },
  {
    label: "ผู้ตรวจการอาคาร",
    id: 409,
  },
  {
    label: "ผู้จัดการร้านขายวัสดุก่อสร้าง",
    id: 410,
  },
  {
    label: "วัสดุก่อสร้างผู้ขายพิเศษ",
    id: 411,
  },
  {
    label: "ฟิลเลอร์จำนวนมาก",
    id: 412,
  },
  {
    label: "ผู้ให้บริการรถปราบดิน",
    id: 413,
  },
  {
    label: "คนขับรถบัส",
    id: 414,
  },
  {
    label: "ผู้สอนการขับรถบัส",
    id: 415,
  },
  {
    label: "หัวหน้าเส้นทางรถบัส",
    id: 416,
  },
  {
    label: "ครูสอนวิชาชีพบริหารธุรกิจ",
    id: 417,
  },
  {
    label: "นักวิเคราะห์ธุรกิจ",
    id: 418,
  },
  {
    label: "ครูสอนวิชาธุรกิจและการตลาด",
    id: 419,
  },
  {
    label: "โค้ชธุรกิจ",
    id: 420,
  },
  {
    label: "ที่ปรึกษาทางธุรกิจ",
    id: 421,
  },
  {
    label: "นักพัฒนาธุรกิจ",
    id: 422,
  },
  {
    label: "บริษัท พัฒนาธุรกิจ",
    id: 423,
  },
  {
    label: "ที่ปรึกษาด้านการพัฒนาธุรกิจ",
    id: 424,
  },
  {
    label: "ผู้จัดการฝ่ายพัฒนาธุรกิจ",
    id: 425,
  },
  {
    label: "นักวิจัยเศรษฐศาสตร์ธุรกิจ",
    id: 426,
  },
  {
    label: "นักวิเคราะห์ระบบธุรกิจอัจฉริยะ",
    id: 427,
  },
  {
    label: "ผู้จัดการระบบธุรกิจอัจฉริยะ",
    id: 428,
  },
  {
    label: "ผู้เชี่ยวชาญด้านข่าวกรองธุรกิจ",
    id: 429,
  },
  {
    label: "นักข่าวธุรกิจ",
    id: 430,
  },
  {
    label: "อาจารย์ธุรกิจ",
    id: 431,
  },
  {
    label: "ผู้จัดการธุรกิจ",
    id: 432,
  },
  {
    label: "ผู้จัดการฝ่ายบริการธุรกิจ",
    id: 433,
  },
  {
    label: "การศึกษาธุรกิจและวิชาเศรษฐศาสตร์โรงเรียนมัธยมศึกษา",
    id: 434,
  },
  {
    label: "ครูสอนธุรกิจ",
    id: 435,
  },
  {
    label: "ผู้ประเมินราคาธุรกิจ",
    id: 436,
  },
  {
    label: "คนขายเนื้อ",
    id: 437,
  },
  {
    label: "ผู้ซื้อ",
    id: 438,
  },
  {
    label: "C นักพัฒนา",
    id: 439,
  },
  {
    label: "C# นักพัฒนา",
    id: 440,
  },
  {
    label: "นักพัฒนา C ++",
    id: 441,
  },
  {
    label: "ลูกเรือ",
    id: 442,
  },
  {
    label: "อาจารย์สอนลูกเรือ",
    id: 443,
  },
  {
    label: "ผู้จัดการทีมงาน",
    id: 444,
  },
  {
    label: "ผู้ผลิตตู้",
    id: 445,
  },
  {
    label: "สายเคเบิล",
    id: 446,
  },
  {
    label: "Cacao Bean Roaster",
    id: 447,
  },
  {
    label: "ทำความสะอาดถั่วโกโก้",
    id: 448,
  },
  {
    label: "ช่างเทคนิคที่ดิน",
    id: 449,
  },
  {
    label: "ผู้ดำเนินการกดเค้ก",
    id: 450,
  },
  {
    label: "นักพัฒนา CakePHP",
    id: 451,
  },
  {
    label: "วิศวกรการคำนวณ",
    id: 452,
  },
  {
    label: "ช่างเทคนิคการสอบเทียบ",
    id: 453,
  },
  {
    label: "ผู้จัดการศูนย์บริการ",
    id: 454,
  },
  {
    label: "ตัวแทนศูนย์บริการ",
    id: 455,
  },
  {
    label: "หัวหน้าศูนย์บริการ",
    id: 456,
  },
  {
    label: "ตัวแทนศูนย์บริการ",
    id: 457,
  },
  {
    label: "นักวิเคราะห์คอลเซ็นเตอร์",
    id: 458,
  },
  {
    label: "ผู้จัดการศูนย์บริการ",
    id: 459,
  },
  {
    label: "ผู้สอบบัญชีคุณภาพ",
    id: 460,
  },
  {
    label: "หัวหน้าศูนย์บริการ",
    id: 461,
  },
  {
    label: "ผู้ให้บริการกล้อง",
    id: 462,
  },
  {
    label: "แคมเปญ canvasser",
    id: 463,
  },
  {
    label: "ผู้อำนวยการรณรงค์",
    id: 464,
  },
  {
    label: "ผู้จัดการภาคพื้นดินแคมป์ปิ้ง",
    id: 465,
  },
  {
    label: "การตั้งแคมป์",
    id: 466,
  },
  {
    label: "ช่างทำเทียน",
    id: 467,
  },
  {
    label: "ผู้ประกอบการเครื่องขนม",
    id: 468,
  },
  {
    label: "ผู้ให้บริการสายกระป๋องและขวดบรรจุขวด",
    id: 469,
  },
  {
    label: "เครื่องรวบรวมสินค้าผ้าใบ",
    id: 470,
  },
  {
    label: "ผู้ประกอบการเครื่องเติมแคปซูล",
    id: 471,
  },
  {
    label: "อาจารย์สอนขับรถ",
    id: 472,
  },
  {
    label: "ตัวแทนเช่าซื้อรถยนต์",
    id: 473,
  },
  {
    label: "ผู้ให้บริการคาร์บอเนต",
    id: 474,
  },
  {
    label: "ดูแลที่บ้านคนงาน",
    id: 475,
  },
  {
    label: "โค้ชอาชีพ",
    id: 476,
  },
  {
    label: "ที่ปรึกษาด้านอาชีพ",
    id: 477,
  },
  {
    label: "ที่ปรึกษาคำแนะนำด้านอาชีพ",
    id: 478,
  },
  {
    label: "คนขับรถบรรทุกสินค้า",
    id: 480,
  },
  {
    label: "ช่างไม้",
    id: 481,
  },
  {
    label: "หัวหน้างานช่างไม้",
    id: 482,
  },
  {
    label: "พรม",
    id: 483,
  },
  {
    label: "คนงานหัตถกรรม",
    id: 484,
  },
  {
    label: "ช่างทอพรม",
    id: 485,
  },
  {
    label: "คนขับรถม้า",
    id: 486,
  },
  {
    label: "ผู้ทำแผนที่",
    id: 487,
  },
  {
    label: "นักเขียนการ์ตูน",
    id: 488,
  },
  {
    label: "ผู้ดูแลเคส",
    id: 489,
  },
  {
    label: "ผู้จัดการคดี",
    id: 490,
  },
  {
    label: "แคชเชียร์",
    id: 491,
  },
  {
    label: "แคชเชียร์คาสิโน",
    id: 492,
  },
  {
    label: "ผู้จัดการเกมคาสิโน",
    id: 493,
  },
  {
    label: "บอสพิทคาสิโน",
    id: 494,
  },
  {
    label: "ผู้กำกับการคัดเลือกนักแสดง",
    id: 495,
  },
  {
    label: "ผู้ให้บริการเครื่องหล่อ",
    id: 496,
  },
  {
    label: "ผู้สร้างแม่พิมพ์หล่อ",
    id: 497,
  },
  {
    label: "ผู้จับ",
    id: 498,
  },
  {
    label: "ผู้จัดการหมวดหมู่",
    id: 499,
  },
  {
    label: "พ่อแม่พันธุ์วัว",
    id: 500,
  },
  {
    label: "การทำเล็บเท้าวัว",
    id: 501,
  },
  {
    label: "ตัวติดตั้งเพดาน",
    id: 502,
  },
  {
    label: "ผู้ให้บริการห้องใต้ดิน",
    id: 503,
  },
  {
    label: "ผู้ดูแลสุสาน",
    id: 504,
  },
  {
    label: "ผู้ว่าราชการธนาคารกลาง",
    id: 505,
  },
  {
    label: "ผู้ให้บริการเครื่องหมุนเหวี่ยง",
    id: 506,
  },
  {
    label: "จิตรกรเซรามิก",
    id: 507,
  },
  {
    label: "นักเซรามิก",
    id: 508,
  },
  {
    label: "ผู้ช่วยพยาบาลที่ผ่านการรับรอง",
    id: 509,
  },
  {
    label: "ผู้ประกอบการเครื่องทำโซ่",
    id: 510,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดช่อง",
    id: 511,
  },
  {
    label: "โรงพยาบาล",
    id: 512,
  },
  {
    label: "นักวิเคราะห์การชำระเงินคืน",
    id: 513,
  },
  {
    label: "ตัวแทนสนับสนุนการแชทและอีเมล",
    id: 514,
  },
  {
    label: "ผู้ควบคุมการชำระเงิน",
    id: 515,
  },
  {
    label: "พ่อครัว",
    id: 516,
  },
  {
    label: "เชฟเดอพาร์ที่",
    id: 517,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการประยุกต์ใช้สารเคมี",
    id: 518,
  },
  {
    label: "วิศวกรเคมี",
    id: 519,
  },
  {
    label: "ช่างเทคนิควิศวกรรมเคมี",
    id: 520,
  },
  {
    label: "ช่างคุณภาพการผลิตสารเคมี",
    id: 521,
  },
  {
    label: "นักโลหะวิทยาเคมี",
    id: 522,
  },
  {
    label: "เครื่องผสมเคมี",
    id: 523,
  },
  {
    label: "ผู้ประกอบการห้องควบคุมโรงงานเคมี",
    id: 524,
  },
  {
    label: "ผู้จัดการโรงงานเคมี",
    id: 525,
  },
  {
    label: "ตัวควบคุมโรงงานแปรรูปเคมี",
    id: 526,
  },
  {
    label: "หัวหน้างานแปรรูปเคมี",
    id: 527,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตสารเคมี",
    id: 528,
  },
  {
    label: "ผู้จัดการฝ่ายจำหน่ายผลิตภัณฑ์เคมี",
    id: 529,
  },
  {
    label: "ช่างเทคนิคเคมี",
    id: 530,
  },
  {
    label: "เครื่องทดสอบเคมี",
    id: 531,
  },
  {
    label: "นักเคมี",
    id: 532,
  },
  {
    label: "วิทยากรเคมี",
    id: 533,
  },
  {
    label: "ครูสอนเคมีโรงเรียนมัธยม",
    id: 534,
  },
  {
    label: "ช่างเทคนิคเคมี",
    id: 535,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่บริหาร",
    id: 536,
  },
  {
    label: "หัวหน้า",
    id: 537,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่ข้อมูล",
    id: 538,
  },
  {
    label: "ประธานเจ้าหน้าที่บริหาร",
    id: 539,
  },
  {
    label: "ประธานเจ้าหน้าที่ฝ่ายการเงิน",
    id: 540,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่ดับเพลิง",
    id: 541,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่รักษาความปลอดภัย ICT",
    id: 542,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่สารสนเทศ",
    id: 543,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่รักษาความปลอดภัยข้อมูล",
    id: 544,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่การตลาด",
    id: 545,
  },
  {
    label: "ประธานฝ่ายปฏิบัติการ",
    id: 546,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่เทคโนโลยี",
    id: 547,
  },
  {
    label: "ผู้ประสานงานการดูแลเด็ก",
    id: 548,
  },
  {
    label: "นักสังคมสงเคราะห์ดูแลเด็ก",
    id: 549,
  },
  {
    label: "ครูดูแลเด็ก",
    id: 550,
  },
  {
    label: "ผู้ดูแลเด็ก",
    id: 551,
  },
  {
    label: "ผู้จัดการศูนย์ดูแลเด็ก",
    id: 552,
  },
  {
    label: "พนักงานดูแลเด็ก",
    id: 553,
  },
  {
    label: "พนักงานสวัสดิการเด็ก",
    id: 554,
  },
  {
    label: "ผู้ดำเนินการแช่เย็น",
    id: 555,
  },
  {
    label: "ปล่องไฟกวาด",
    id: 556,
  },
  {
    label: "หัวหน้าหัวหน้า",
    id: 557,
  },
  {
    label: "ผู้จัดการฝ่ายจัดจำหน่ายจีนและเครื่องแก้ว",
    id: 558,
  },
  {
    label: "ผู้ดำเนินการ chipper",
    id: 559,
  },
  {
    label: "ผู้ช่วยไคโรแพรคติก",
    id: 560,
  },
  {
    label: "หมอนวด",
    id: 561,
  },
  {
    label: "ผู้ประกอบการปั้นช็อคโกแลต",
    id: 562,
  },
  {
    label: "ช็อคโกแลต",
    id: 563,
  },
  {
    label: "Choirmaster/Choirmistress",
    id: 564,
  },
  {
    label: "นักออกแบบท่าเต้น",
    id: 565,
  },
  {
    label: "นักเต้น",
    id: 566,
  },
  {
    label: "นักโครมาโตกราฟี",
    id: 567,
  },
  {
    label: "ผู้ประกอบการหมักไซเดอร์",
    id: 568,
  },
  {
    label: "นายไซเดอร์",
    id: 569,
  },
  {
    label: "ซิการ์แบรนเดอร์",
    id: 570,
  },
  {
    label: "ผู้ตรวจการซิการ์",
    id: 571,
  },
  {
    label: "ผู้ประกอบการเครื่องสูบบุหรี่",
    id: 572,
  },
  {
    label: "ศิลปินละครสัตว์",
    id: 573,
  },
  {
    label: "ครูสอนศิลปะละครสัตว์",
    id: 574,
  },
  {
    label: "พลเรือน",
    id: 575,
  },
  {
    label: "เจ้าหน้าที่บังคับใช้พลเรือน",
    id: 576,
  },
  {
    label: "วิศวกรโยธา",
    id: 577,
  },
  {
    label: "ช่างเทคนิควิศวกรรมโยธา",
    id: 578,
  },
  {
    label: "คนงานวิศวกรรมโยธา",
    id: 579,
  },
  {
    label: "นายทะเบียนพลเรือน",
    id: 580,
  },
  {
    label: "ข้าราชการพลเรือน",
    id: 581,
  },
  {
    label: "การเรียกร้องตัวปรับ",
    id: 582,
  },
  {
    label: "อ้างสิทธิ์ผู้ประเมินราคา",
    id: 583,
  },
  {
    label: "การเรียกร้องผู้ตรวจสอบ",
    id: 584,
  },
  {
    label: "ผู้ตรวจสอบการเรียกร้อง",
    id: 585,
  },
  {
    label: "ตัวชี้วัด",
    id: 586,
  },
  {
    label: "อาจารย์สอนภาษาคลาสสิก",
    id: 587,
  },
  {
    label: "โรงเรียนสอนภาษาคลาสสิกโรงเรียนมัธยมศึกษา",
    id: 588,
  },
  {
    label: "เตาเผาดินเผา",
    id: 589,
  },
  {
    label: "ผลิตภัณฑ์ดินแห้งผู้ประกอบการเตาเผาแห้ง",
    id: 590,
  },
  {
    label: "ผู้จัดการลูกค้าสัมพันธ์",
    id: 591,
  },
  {
    label: "ตัวแทนบริการลูกค้า",
    id: 592,
  },
  {
    label: "ผู้จัดการความสำเร็จของลูกค้า",
    id: 593,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสนับสนุนลูกค้า",
    id: 594,
  },
  {
    label: "นักอุตุนิยมวิทยา",
    id: 595,
  },
  {
    label: "ผู้ส่งสัญญาณคลินิก",
    id: 596,
  },
  {
    label: "ผู้ช่วยทางคลินิก",
    id: 597,
  },
  {
    label: "ผู้อำนวยการคลินิก",
    id: 598,
  },
  {
    label: "ผู้เชี่ยวชาญด้านเอกสารทางคลินิก",
    id: 599,
  },
  {
    label: "ผู้จัดการสารสนเทศทางคลินิก",
    id: 600,
  },
  {
    label: "ผู้สอนคลินิก",
    id: 601,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการทางคลินิก",
    id: 602,
  },
  {
    label: "นักวิทยาศาสตร์การกระจายคลินิก",
    id: 603,
  },
  {
    label: "เภสัชกรคลินิก",
    id: 604,
  },
  {
    label: "นักจิตวิทยาคลีนิค",
    id: 605,
  },
  {
    label: "นักสังคมสงเคราะห์คลินิก",
    id: 606,
  },
  {
    label: "ผู้ตรวจสอบการตรวจสอบทางคลินิก",
    id: 607,
  },
  {
    label: "ผู้ดูแลห้อง Cloak",
    id: 608,
  },
  {
    label: "นาฬิกาและช่างซ่อมนาฬิกา",
    id: 609,
  },
  {
    label: "ช่างเครื่องดัดแปลงเสื้อผ้า",
    id: 610,
  },
  {
    label: "ผู้จัดการฝ่ายจำหน่ายเสื้อผ้าและรองเท้า",
    id: 611,
  },
  {
    label: "นักออกแบบเสื้อผ้า CAD",
    id: 612,
  },
  {
    label: "ช่างเสื้อผ้าช่างเสื้อผ้า",
    id: 613,
  },
  {
    label: "เครื่องตัดเสื้อผ้า",
    id: 614,
  },
  {
    label: "ผู้จัดการฝ่ายพัฒนาเสื้อผ้า",
    id: 615,
  },
  {
    label: "นักออกแบบแฟชั่นเสื้อผ้า",
    id: 616,
  },
  {
    label: "หมัดเด็ดเสื้อผ้า",
    id: 617,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการเสื้อผ้า",
    id: 618,
  },
  {
    label: "ช่างเทคนิคการควบคุมกระบวนการเสื้อผ้า",
    id: 619,
  },
  {
    label: "เครื่องแต่งกายผลิตภัณฑ์",
    id: 620,
  },
  {
    label: "ผู้ตรวจสอบคุณภาพเสื้อผ้า",
    id: 621,
  },
  {
    label: "ช่างเครื่องตัวอย่างเสื้อผ้า",
    id: 622,
  },
  {
    label: "ผู้จัดการร้านขายเสื้อผ้า",
    id: 623,
  },
  {
    label: "ผู้ขายเสื้อผ้าพิเศษ",
    id: 624,
  },
  {
    label: "นักเทคโนโลยีเสื้อผ้า",
    id: 625,
  },
  {
    label: "สถาปนิกคลาวด์",
    id: 626,
  },
  {
    label: "ที่ปรึกษาคลาวด์",
    id: 627,
  },
  {
    label: "วิศวกรคลาวด์",
    id: 628,
  },
  {
    label: "วิศวกรเมบิวต์คลาวด์",
    id: 629,
  },
  {
    label: "วิศวกรปฏิบัติการคลาวด์",
    id: 630,
  },
  {
    label: "ผู้เชี่ยวชาญด้านคลาวด์",
    id: 631,
  },
  {
    label: "สถาปนิกความปลอดภัยคลาวด์",
    id: 632,
  },
  {
    label: "สโมสรโฮสต์/คลับพนักงานต้อนรับ",
    id: 633,
  },
  {
    label: "นักบินร่วม",
    id: 634,
  },
  {
    label: "ผู้ฝึกสอน",
    id: 635,
  },
  {
    label: "ผู้ประกอบการแข็งตัว",
    id: 636,
  },
  {
    label: "เจ้าหน้าที่เฝ้าดูชายฝั่ง",
    id: 637,
  },
  {
    label: "ผู้ให้บริการเครื่องเคลือบเครื่อง",
    id: 638,
  },
  {
    label: "บาร์เทนเดอร์ค็อกเทล",
    id: 639,
  },
  {
    label: "ผู้ให้บริการ Cocoa Mill",
    id: 640,
  },
  {
    label: "ผู้ดำเนินการ Cocoa Press",
    id: 641,
  },
  {
    label: "ผู้ตรวจสอบรหัส",
    id: 642,
  },
  {
    label: "ผู้สอบบัญชี",
    id: 643,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเข้ารหัส",
    id: 644,
  },
  {
    label: "เครื่องบดกาแฟ",
    id: 645,
  },
  {
    label: "คั่วกาแฟ",
    id: 646,
  },
  {
    label: "Coffee Coffee",
    id: 647,
  },
  {
    label: "ผู้จัดการกาแฟชาโกโก้และเครื่องเทศ",
    id: 648,
  },
  {
    label: "นักพัฒนา CoffeeScript",
    id: 649,
  },
  {
    label: "ผู้ให้บริการเตาเผา",
    id: 650,
  },
  {
    label: "ผู้จัดการคอลเลกชัน",
    id: 651,
  },
  {
    label: "ผู้เชี่ยวชาญด้านคอลเลกชัน",
    id: 652,
  },
  {
    label: "พันเอก",
    id: 653,
  },
  {
    label: "ตัวดำเนินการสุ่มตัวอย่างสี",
    id: 654,
  },
  {
    label: "ช่างเทคนิคการสุ่มตัวอย่างสี",
    id: 655,
  },
  {
    label: "คอลัมนิสต์",
    id: 656,
  },
  {
    label: "ผู้จัดการหอศิลป์เชิงพาณิชย์",
    id: 657,
  },
  {
    label: "ผู้อำนวยการฝ่ายการค้า",
    id: 658,
  },
  {
    label: "นักบินพาณิชย์",
    id: 659,
  },
  {
    label: "ตัวแทนขายเชิงพาณิชย์",
    id: 660,
  },
  {
    label: "วิศวกรการว่าจ้าง",
    id: 661,
  },
  {
    label: "ช่างเทคนิคการว่าจ้าง",
    id: 662,
  },
  {
    label: "นายหน้าซื้อขายสินค้า",
    id: 663,
  },
  {
    label: "ผู้ค้าสินค้าโภคภัณฑ์",
    id: 664,
  },
  {
    label: "ผู้ดูแลโครงสร้างพื้นฐานการสื่อสาร",
    id: 665,
  },
  {
    label: "ผู้จัดการฝ่ายสื่อสาร",
    id: 666,
  },
  {
    label: "นักวิทยาศาสตร์การสื่อสาร",
    id: 667,
  },
  {
    label: "วิทยากรสื่อสาร",
    id: 668,
  },
  {
    label: "ผู้จัดการฝ่ายสื่อสาร",
    id: 669,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสื่อสาร",
    id: 670,
  },
  {
    label: "ศิลปินชุมชน",
    id: 671,
  },
  {
    label: "ผู้ปฏิบัติงานกรณีการดูแลชุมชน",
    id: 672,
  },
  {
    label: "เจ้าหน้าที่พัฒนาชุมชน",
    id: 673,
  },
  {
    label: "นักสังคมสงเคราะห์การพัฒนาชุมชน",
    id: 674,
  },
  {
    label: "วิศวกรสุขภาพชุมชน",
    id: 675,
  },
  {
    label: "เจ้าหน้าที่สาธารณสุขชุมชน",
    id: 676,
  },
  {
    label: "ผู้จัดการชุมชน",
    id: 677,
  },
  {
    label: "นักสังคมสงเคราะห์ชุมชน",
    id: 678,
  },
  {
    label: "ผู้จัดการฝ่ายสนับสนุนชุมชน",
    id: 679,
  },
  {
    label: "เพื่อนร่วมงาน",
    id: 680,
  },
  {
    label: "ผู้จัดการค่าตอบแทนและผลประโยชน์",
    id: 681,
  },
  {
    label: "ผู้เชี่ยวชาญด้านค่าตอบแทนและผลประโยชน์",
    id: 682,
  },
  {
    label: "นักวิเคราะห์ค่าตอบแทน",
    id: 683,
  },
  {
    label: "เจ้าหน้าที่นโยบายการแข่งขัน",
    id: 684,
  },
  {
    label: "นักบำบัดเสริม",
    id: 685,
  },
  {
    label: "วิศวกรการปฏิบัติตามกฎระเบียบ",
    id: 686,
  },
  {
    label: "เจ้าหน้าที่ปฏิบัติตามกฎระเบียบ",
    id: 687,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการปฏิบัติตามกฎระเบียบ",
    id: 688,
  },
  {
    label: "วิศวกรส่วนประกอบ",
    id: 689,
  },
  {
    label: "นักแต่งเพลง",
    id: 690,
  },
  {
    label: "ผู้ประกอบการเครื่องอัดขึ้นรูป",
    id: 691,
  },
  {
    label: "นักวิเคราะห์คอมพิวเตอร์และข้อมูล",
    id: 692,
  },
  {
    label: "นักวิทยาศาสตร์การวิจัยคอมพิวเตอร์และข้อมูล",
    id: 693,
  },
  {
    label: "ผู้จัดการระบบคอมพิวเตอร์และสารสนเทศ",
    id: 694,
  },
  {
    label: "คอมพิวเตอร์และอุปกรณ์เสริมผู้ขายพิเศษ",
    id: 695,
  },
  {
    label: "เกมคอมพิวเตอร์มัลติมีเดียและซอฟต์แวร์ผู้ขายพิเศษ",
    id: 696,
  },
  {
    label: "วิศวกรฮาร์ดแวร์คอมพิวเตอร์",
    id: 697,
  },
  {
    label: "ช่างเทคนิควิศวกรรมฮาร์ดแวร์คอมพิวเตอร์",
    id: 698,
  },
  {
    label: "ช่างซ่อมฮาร์ดแวร์คอมพิวเตอร์",
    id: 699,
  },
  {
    label: "ช่างเทคนิคทดสอบฮาร์ดแวร์คอมพิวเตอร์",
    id: 700,
  },
  {
    label: "ผู้ให้บริการเครื่องวัดตัวเลขคอมพิวเตอร์",
    id: 701,
  },
  {
    label: "วิทยากรวิทยากรคอมพิวเตอร์",
    id: 702,
  },
  {
    label: "นักวิทยาศาสตร์คอมพิวเตอร์",
    id: 703,
  },
  {
    label: "ผู้จัดการร้านคอมพิวเตอร์",
    id: 704,
  },
  {
    label: "ซอฟต์แวร์คอมพิวเตอร์และผู้จัดการร้านมัลติมีเดีย",
    id: 705,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสนับสนุนคอมพิวเตอร์",
    id: 706,
  },
  {
    label: "ช่างเทคนิคคอมพิวเตอร์",
    id: 707,
  },
  {
    label: "วิศวกรวิสัยทัศน์คอมพิวเตอร์",
    id: 708,
  },
  {
    label: "ผู้ให้บริการออกแบบคอมพิวเตอร์ช่วย",
    id: 709,
  },
  {
    label:
      "คอมพิวเตอร์อุปกรณ์อุปกรณ์ต่อพ่วงคอมพิวเตอร์และตัวจัดการการกระจายซอฟต์แวร์",
    id: 710,
  },
  {
    label: "ศิลปินแนวความคิด",
    id: 711,
  },
  {
    label: "เจ้าหน้าที่อำนวยความสะดวก",
    id: 712,
  },
  {
    label: "หมัดเด็ดคอนกรีต",
    id: 713,
  },
  {
    label: "หัวหน้างานหมัดเด็ดคอนกรีต",
    id: 714,
  },
  {
    label: "ผู้ประกอบการเครื่องผลิตภัณฑ์คอนกรีต",
    id: 715,
  },
  {
    label: "ผู้ให้บริการปั๊มคอนกรีต",
    id: 716,
  },
  {
    label: "ผู้ผลิตลูกกวาด",
    id: 717,
  },
  {
    label: "ผู้จัดการร้านค้าขนมหวาน",
    id: 718,
  },
  {
    label: "ผู้ขายเฉพาะขนมหวาน",
    id: 719,
  },
  {
    label: "นักวิทยาศาสตร์อนุรักษ์",
    id: 720,
  },
  {
    label: "ผู้พิทักษ์",
    id: 721,
  },
  {
    label: "นักดำน้ำเชิงพาณิชย์ก่อสร้าง",
    id: 722,
  },
  {
    label: "วิศวกรก่อสร้าง",
    id: 723,
  },
  {
    label: "ช่างเทคนิคอุปกรณ์ก่อสร้าง",
    id: 724,
  },
  {
    label: "ตัวประมาณค่าการก่อสร้าง",
    id: 725,
  },
  {
    label: "โฟร์แมนก่อสร้าง",
    id: 726,
  },
  {
    label: "ผู้รับเหมาก่อสร้าง",
    id: 727,
  },
  {
    label: "หัวหน้างานก่อสร้างทั่วไป",
    id: 728,
  },
  {
    label: "ผู้จัดการฝ่ายก่อสร้าง",
    id: 729,
  },
  {
    label: "จิตรกรก่อสร้าง",
    id: 730,
  },
  {
    label: "หัวหน้างานจิตรกรรมก่อสร้าง",
    id: 731,
  },
  {
    label: "ผู้จัดการโครงการก่อสร้าง",
    id: 732,
  },
  {
    label: "ผู้ตรวจสอบคุณภาพการก่อสร้าง",
    id: 733,
  },
  {
    label: "ผู้จัดการคุณภาพการก่อสร้าง",
    id: 734,
  },
  {
    label: "ผู้ตรวจสอบความปลอดภัยการก่อสร้าง",
    id: 735,
  },
  {
    label: "ผู้จัดการความปลอดภัยการก่อสร้าง",
    id: 736,
  },
  {
    label: "นั่งร้านก่อสร้าง",
    id: 737,
  },
  {
    label: "หัวหน้างานการก่อสร้างนั่งร้าน",
    id: 738,
  },
  {
    label: "คนงานก่อสร้าง",
    id: 739,
  },
  {
    label: "กงสุล",
    id: 740,
  },
  {
    label: "ที่ปรึกษา",
    id: 741,
  },
  {
    label: "นักสังคมสงเคราะห์ที่ปรึกษา",
    id: 742,
  },
  {
    label: "ช่างซ่อมอุปกรณ์อิเล็กทรอนิกส์สำหรับผู้บริโภค",
    id: 743,
  },
  {
    label: "ผู้ตรวจสอบสินค้าอุปโภคบริโภค",
    id: 744,
  },
  {
    label: "ที่ปรึกษาสิทธิผู้บริโภค",
    id: 745,
  },
  {
    label: "ผู้จัดการศูนย์ติดต่อ",
    id: 746,
  },
  {
    label: "ผู้ดูแลศูนย์ติดต่อ",
    id: 747,
  },
  {
    label: "ตัวดำเนินการคอนเทนเนอร์เครน",
    id: 748,
  },
  {
    label: "เครื่องประกอบอุปกรณ์ตู้คอนเทนเนอร์",
    id: 749,
  },
  {
    label: "หัวหน้างานประกอบตู้คอนเทนเนอร์",
    id: 750,
  },
  {
    label: "วิศวกรออกแบบอุปกรณ์ตู้คอนเทนเนอร์",
    id: 751,
  },
  {
    label: "ผู้ร่วมงานด้านเนื้อหาและดิจิทัล",
    id: 752,
  },
  {
    label: "ผู้จัดการเนื้อหา",
    id: 753,
  },
  {
    label: "นักการตลาดเนื้อหา",
    id: 754,
  },
  {
    label: "นักเขียนคำโฆษณาการตลาดเนื้อหา",
    id: 755,
  },
  {
    label: "บรรณาธิการการตลาดเนื้อหา",
    id: 756,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดเนื้อหา",
    id: 757,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดเนื้อหา",
    id: 758,
  },
  {
    label: "นักยุทธศาสตร์การตลาดเนื้อหา",
    id: 759,
  },
  {
    label: "นักเขียนเนื้อหา",
    id: 760,
  },
  {
    label: "วิศวกรสัญญา",
    id: 761,
  },
  {
    label: "ที่ปรึกษากฎหมายสัญญา",
    id: 762,
  },
  {
    label: "ผู้จัดการสัญญา",
    id: 763,
  },
  {
    label: "นักเขียนที่มีส่วนร่วม",
    id: 764,
  },
  {
    label: "แอสเซมเบลอร์ควบคุม",
    id: 765,
  },
  {
    label: "เครื่องทดสอบแผงควบคุม",
    id: 766,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเพิ่มประสิทธิภาพอัตราการแปลง",
    id: 767,
  },
  {
    label: "เสมียนยานพาหนะ",
    id: 768,
  },
  {
    label: "ทำอาหาร",
    id: 769,
  },
  {
    label: "คูเปอร์",
    id: 770,
  },
  {
    label: "coppersmith",
    id: 771,
  },
  {
    label: "Copy Editor",
    id: 772,
  },
  {
    label: "สำเนาเครื่องหมาย",
    id: 773,
  },
  {
    label: "นักเขียนคำโฆษณา - ทั่วไป",
    id: 774,
  },
  {
    label: "ผู้เขียนคำโฆษณาและบรรณาธิการ",
    id: 775,
  },
  {
    label: "นักเขียนคำโฆษณาสำหรับแคมเปญการตลาด",
    id: 776,
  },
  {
    label: "คนงานหล่อร่วม",
    id: 777,
  },
  {
    label: "นักพัฒนา Corona SDK",
    id: 778,
  },
  {
    label: "เจ้าหน้าที่ชันสูตรศพ",
    id: 779,
  },
  {
    label: "อัยการสูงสุด",
    id: 780,
  },
  {
    label: "ผู้จัดการธนาคารของ บริษัท",
    id: 781,
  },
  {
    label: "ที่ปรึกษาของ บริษัท",
    id: 782,
  },
  {
    label: "นายธนาคารเพื่อการลงทุนขององค์กร",
    id: 783,
  },
  {
    label: "ที่ปรึกษากฎหมายของ บริษัท",
    id: 784,
  },
  {
    label: "ทนายความของ บริษัท",
    id: 785,
  },
  {
    label: "ผู้จัดการความรับผิดชอบต่อสังคมขององค์กร",
    id: 786,
  },
  {
    label: "ผู้ฝึกสอนองค์กร",
    id: 787,
  },
  {
    label: "ผู้จัดการฝึกอบรมองค์กร",
    id: 788,
  },
  {
    label: "ผู้จัดการการท่องเที่ยวขององค์กร",
    id: 789,
  },
  {
    label: "เหรัญญิกของ บริษัท",
    id: 790,
  },
  {
    label: "ผู้จัดการบริการราชทัณฑ์",
    id: 791,
  },
  {
    label: "ตัวแทนติดต่อ",
    id: 792,
  },
  {
    label: "ช่างเทคนิคการกัดกร่อน",
    id: 793,
  },
  {
    label: "ตัวดำเนินการ Corrugator",
    id: 794,
  },
  {
    label: "นักเคมีเครื่องสำอาง",
    id: 795,
  },
  {
    label: "เครื่องสำอางและผู้จัดการร้านค้าน้ำหอม",
    id: 796,
  },
  {
    label: "เครื่องสำอางและผู้ขายเฉพาะทางน้ำหอม",
    id: 797,
  },
  {
    label: "ผู้ประกอบการเครื่องสำอางเครื่องสำอาง",
    id: 798,
  },
  {
    label: "ผู้ดูแลเครื่องสำอางค์",
    id: 799,
  },
  {
    label: "นักจักรวาลวิทยา",
    id: 800,
  },
  {
    label: "นักวิเคราะห์ต้นทุน",
    id: 801,
  },
  {
    label: "ตัวประมาณราคา",
    id: 802,
  },
  {
    label: "ผู้ดูแลเครื่องแต่งกาย",
    id: 803,
  },
  {
    label: "ผู้ซื้อเครื่องแต่งกาย",
    id: 804,
  },
  {
    label: "นักออกแบบเครื่องแต่งกาย",
    id: 805,
  },
  {
    label: "เครื่องแต่งกาย",
    id: 806,
  },
  {
    label: "ผู้ให้บริการผ้าฝ้ายจิน",
    id: 807,
  },
  {
    label: "เจ้าหน้าที่ชนบท",
    id: 808,
  },
  {
    label: "ผู้ให้บริการจัดส่ง",
    id: 809,
  },
  {
    label: "เจ้าหน้าที่บริหารศาล",
    id: 810,
  },
  {
    label: "ผู้บริหารศาล",
    id: 811,
  },
  {
    label: "นายอำเภอศาล",
    id: 812,
  },
  {
    label: "เสมียนศาล",
    id: 813,
  },
  {
    label: "เจ้าหน้าที่บังคับใช้ศาล",
    id: 814,
  },
  {
    label: "ผู้ประสานงานศาล",
    id: 815,
  },
  {
    label: "นักข่าวศาล",
    id: 816,
  },
  {
    label: "ศาลเทศบาลหรือเสมียนใบอนุญาต",
    id: 817,
  },
  {
    label: "ผู้จัดการร้านค้างานฝีมือ",
    id: 818,
  },
  {
    label: "หัวหน้าลูกเรือเครน",
    id: 819,
  },
  {
    label: "ช่างเทคนิคเครน",
    id: 820,
  },
  {
    label: "ผู้อำนวยการสร้างสรรค์",
    id: 821,
  },
  {
    label: "ผู้อำนวยการฝ่ายสร้างสรรค์หรือผู้กำกับศิลป์",
    id: 822,
  },
  {
    label: "Creatives Community Manager",
    id: 823,
  },
  {
    label: "ที่ปรึกษาสินเชื่อ",
    id: 824,
  },
  {
    label: "นักวิเคราะห์เครดิต",
    id: 825,
  },
  {
    label: "ผู้มีอำนาจ",
    id: 826,
  },
  {
    label: "ที่ปรึกษาด้านเครดิต",
    id: 827,
  },
  {
    label: "ผู้จัดการเครดิต",
    id: 828,
  },
  {
    label: "นักวิเคราะห์ความเสี่ยงด้านเครดิต",
    id: 829,
  },
  {
    label: "ผู้จัดการเครดิตยูเนี่ยน",
    id: 830,
  },
  {
    label: "นักข่าวอาชญากรรม",
    id: 831,
  },
  {
    label: "ผู้ตรวจสอบอาชญากร",
    id: 832,
  },
  {
    label: "นักสังคมสงเคราะห์ความยุติธรรมทางอาญา",
    id: 833,
  },
  {
    label: "นักอาชญาวิทยา",
    id: 834,
  },
  {
    label: "ผู้ให้บริการสายด่วนวิกฤต",
    id: 835,
  },
  {
    label: "นักสังคมสงเคราะห์สถานการณ์วิกฤตการณ์",
    id: 836,
  },
  {
    label: "นักวิจารณ์",
    id: 837,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตพืชผล",
    id: 838,
  },
  {
    label: "คนงานผลิตพืชผล",
    id: 839,
  },
  {
    label: "Crosscut Saw Operator",
    id: 840,
  },
  {
    label: "ผู้พิทักษ์ข้าม",
    id: 841,
  },
  {
    label: "ผู้ควบคุมฝูงชน",
    id: 842,
  },
  {
    label: "ผู้อำนวยการศูนย์วัฒนธรรม",
    id: 843,
  },
  {
    label: "ผู้จัดการสิ่งอำนวยความสะดวกทางวัฒนธรรม",
    id: 844,
  },
  {
    label: "เจ้าหน้าที่นโยบายวัฒนธรรม",
    id: 845,
  },
  {
    label: "ภัณฑารักษ์",
    id: 846,
  },
  {
    label: "ภัณฑารักษ์ของพืชสวน",
    id: 847,
  },
  {
    label: "บ่มพนักงานห้องพัก",
    id: 848,
  },
  {
    label: "ผู้ดูแลหลักสูตร",
    id: 849,
  },
  {
    label: "ผู้ดูแล",
    id: 850,
  },
  {
    label: "ผู้สนับสนุนลูกค้า",
    id: 851,
  },
  {
    label: "ตัวแทนดูแลลูกค้า",
    id: 852,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการดูแลลูกค้า",
    id: 853,
  },
  {
    label: "เสมียนข้อมูลศูนย์ติดต่อลูกค้า",
    id: 854,
  },
  {
    label: "ผู้จัดการประสบการณ์ลูกค้า",
    id: 855,
  },
  {
    label: "ผู้เชี่ยวชาญประสบการณ์ลูกค้า",
    id: 856,
  },
  {
    label: "เจ้าหน้าที่ความสุขของลูกค้า",
    id: 857,
  },
  {
    label: "ตัวแทนความสุขของลูกค้า",
    id: 858,
  },
  {
    label: "ผู้จัดการข่าวกรองลูกค้า",
    id: 859,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดลูกค้า",
    id: 860,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการขึ้นเครื่อง",
    id: 861,
  },
  {
    label: "การดำเนินงานของลูกค้า",
    id: 862,
  },
  {
    label: "การดำเนินงานของลูกค้าเป็นผู้นำ",
    id: 863,
  },
  {
    label: "ผู้จัดการฝ่ายลูกค้าสัมพันธ์และฝ่ายปฏิบัติการ",
    id: 864,
  },
  {
    label: "ผู้เชี่ยวชาญด้านลูกค้าสัมพันธ์",
    id: 865,
  },
  {
    label: "ผู้จัดการฝ่ายบริการลูกค้า",
    id: 866,
  },
  {
    label: "ตัวแทนฝ่ายบริการลูกค้า",
    id: 867,
  },
  {
    label: "ทีมบริการลูกค้า",
    id: 868,
  },
  {
    label: "ที่ปรึกษาความสำเร็จของลูกค้า",
    id: 869,
  },
  {
    label: "วิศวกรความสำเร็จของลูกค้า",
    id: 870,
  },
  {
    label: "ผู้จัดการความสำเร็จของลูกค้า",
    id: 871,
  },
  {
    label: "ตัวแทนความสำเร็จของลูกค้า",
    id: 872,
  },
  {
    label: "ตัวแทนฝ่ายสนับสนุนลูกค้า",
    id: 873,
  },
  {
    label: "วิศวกรฝ่ายสนับสนุนลูกค้า",
    id: 874,
  },
  {
    label: "ผู้จัดการฝ่ายสนับสนุนลูกค้า",
    id: 875,
  },
  {
    label: "ตัวแทนฝ่ายสนับสนุนลูกค้า",
    id: 876,
  },
  {
    label: "เจ้าหน้าที่ศุลกากรและภาษีสรรพสามิต",
    id: 877,
  },
  {
    label: "เจ้าหน้าที่ศุลกากร",
    id: 878,
  },
  {
    label: "ผู้ดำเนินการเครื่องตัด",
    id: 879,
  },
  {
    label: "นักวิเคราะห์ความปลอดภัยไซเบอร์",
    id: 880,
  },
  {
    label: "ฟิลเลอร์กระบอกสูบ",
    id: 881,
  },
  {
    label: "ตัวดำเนินการเครื่องบดทรงกระบอก",
    id: 882,
  },
  {
    label: "ตัวคัดกรองเซลล์วิทยา",
    id: 883,
  },
  {
    label: "ผู้ประกอบการประมวลผลผลิตภัณฑ์นม",
    id: 884,
  },
  {
    label: "ช่างเทคนิคการแปรรูปนม",
    id: 885,
  },
  {
    label: "ผลิตภัณฑ์นมและผู้จัดการการจัดจำหน่ายน้ำมันที่กินได้",
    id: 886,
  },
  {
    label: "ผู้ผลิตผลิตภัณฑ์นม",
    id: 887,
  },
  {
    label: "คนงานผลิตผลิตภัณฑ์นม",
    id: 888,
  },
  {
    label: "เต้นรำrépétiteur",
    id: 889,
  },
  {
    label: "ครูสอนเต้นรำ",
    id: 890,
  },
  {
    label: "นักบำบัดการเต้นรำ",
    id: 891,
  },
  {
    label: "นักเต้น",
    id: 892,
  },
  {
    label: "คนขับสินค้าอันตราย",
    id: 893,
  },
  {
    label: "ที่ปรึกษาด้านความปลอดภัยของสินค้าอันตราย",
    id: 894,
  },
  {
    label: "ผู้อำนวยการ Data & Analytics",
    id: 895,
  },
  {
    label: "นักวิเคราะห์ข้อมูล",
    id: 896,
  },
  {
    label: "ผู้ประกอบการศูนย์ข้อมูล",
    id: 897,
  },
  {
    label: "ผู้ประสานงานข้อมูล",
    id: 898,
  },
  {
    label: "วิศวกรข้อมูล",
    id: 899,
  },
  {
    label: "เสมียนป้อนข้อมูล",
    id: 900,
  },
  {
    label: "ตัวดำเนินการป้อนข้อมูล",
    id: 901,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการป้อนข้อมูล",
    id: 902,
  },
  {
    label: "หัวหน้างานป้อนข้อมูล",
    id: 903,
  },
  {
    label: "วิศวกรโครงสร้างพื้นฐานข้อมูล",
    id: 904,
  },
  {
    label: "ตัวจัดการข้อมูล",
    id: 905,
  },
  {
    label: "เจ้าหน้าที่คุ้มครองข้อมูล",
    id: 906,
  },
  {
    label: "นักวิเคราะห์การประกันคุณภาพข้อมูล",
    id: 907,
  },
  {
    label: "ผู้เชี่ยวชาญด้านคุณภาพข้อมูล",
    id: 908,
  },
  {
    label: "นักวิทยาศาสตร์ด้านข้อมูล",
    id: 909,
  },
  {
    label: "นักออกแบบคลังข้อมูล",
    id: 910,
  },
  {
    label: "ผู้ดูแลระบบฐานข้อมูลและเครือข่าย",
    id: 911,
  },
  {
    label: "ฐานข้อมูลและสถาปนิกเครือข่าย",
    id: 912,
  },
  {
    label: "ผู้ดูแลระบบฐานข้อมูล",
    id: 913,
  },
  {
    label: "นักออกแบบฐานข้อมูล",
    id: 914,
  },
  {
    label: "ผู้พัฒนาฐานข้อมูล",
    id: 915,
  },
  {
    label: "ผู้รวมฐานข้อมูล",
    id: 916,
  },
  {
    label: "ผู้จัดการการตลาดฐานข้อมูล",
    id: 917,
  },
  {
    label: "ที่ปรึกษาบริการหาคู่",
    id: 918,
  },
  {
    label: "คณบดีคณะ",
    id: 919,
  },
  {
    label: "ผู้ดำเนินการ Debarker",
    id: 920,
  },
  {
    label: "นักสะสมหนี้",
    id: 921,
  },
  {
    label: "ผู้ให้บริการเครื่องจักร Deburning",
    id: 922,
  },
  {
    label: "เจ้าหน้าที่ดาดฟ้า",
    id: 923,
  },
  {
    label: "คนขาย",
    id: 924,
  },
  {
    label: "คนงานที่มีการปนเปื้อน",
    id: 925,
  },
  {
    label: "จิตรกรตกแต่ง",
    id: 926,
  },
  {
    label: "คนงานประมงทะเลลึก",
    id: 927,
  },
  {
    label: "เจ้าหน้าที่บริหารงานกลาโหม",
    id: 928,
  },
  {
    label: "ผู้จัดการร้านค้า Delicatessen",
    id: 929,
  },
  {
    label: "ผู้ขายเฉพาะ Delicatessen",
    id: 930,
  },
  {
    label: "คนขับรถส่งของ",
    id: 931,
  },
  {
    label: "ผู้จัดการการสร้างอุปสงค์",
    id: 932,
  },
  {
    label: "นักประชากรศาสตร์",
    id: 933,
  },
  {
    label: "หัวหน้างานรื้อถอน",
    id: 934,
  },
  {
    label: "คนงานรื้อถอน",
    id: 935,
  },
  {
    label: "ผู้ช่วยทันตแพทย์",
    id: 936,
  },
  {
    label: "ผู้ช่วยเก้าอี้ทันตกรรม",
    id: 937,
  },
  {
    label: "ทันตกรรมสุขอนามัย",
    id: 938,
  },
  {
    label: "เครื่องประกอบเครื่องมือทันตกรรม",
    id: 939,
  },
  {
    label: "ผู้ประกอบการทันตกรรม",
    id: 940,
  },
  {
    label: "ช่างทันตกรรม",
    id: 941,
  },
  {
    label: "อาจารย์ทันตกรรม",
    id: 942,
  },
  {
    label: "ผู้จัดการแผนก",
    id: 943,
  },
  {
    label: "ผู้จัดการห้างสรรพสินค้า",
    id: 944,
  },
  {
    label: "รองครูใหญ่",
    id: 945,
  },
  {
    label: "ปั้นจั่น",
    id: 946,
  },
  {
    label: "ช่างเทคนิคการกลั่นน้ำทะเล",
    id: 947,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการออกแบบและสร้างสรรค์",
    id: 948,
  },
  {
    label: "ครูสอนวิชาชีพศิลปะการออกแบบและประยุกต์",
    id: 949,
  },
  {
    label: "ผู้อำนวยการฝ่ายออกแบบ",
    id: 950,
  },
  {
    label: "วิศวกรออกแบบ",
    id: 951,
  },
  {
    label: "ผู้จัดการโปรแกรมออกแบบ",
    id: 952,
  },
  {
    label: "นักออกแบบ",
    id: 953,
  },
  {
    label: "นักพัฒนาเดสก์ท็อป",
    id: 954,
  },
  {
    label: "สำนักพิมพ์เดสก์ท็อป",
    id: 955,
  },
  {
    label: "ผู้จัดการปลายทาง",
    id: 956,
  },
  {
    label: "ที่ปรึกษา DevOps",
    id: 957,
  },
  {
    label: "ผู้อำนวยการ DevOps",
    id: 958,
  },
  {
    label: "วิศวกร Devops",
    id: 959,
  },
  {
    label: "ช่างเทคนิคการแยกน้ำ",
    id: 960,
  },
  {
    label: "นักถ่ายภาพรังสีวินิจฉัย",
    id: 961,
  },
  {
    label: "ช่างเครื่องยนต์ดีเซล",
    id: 962,
  },
  {
    label: "อาหารปรุงอาหาร",
    id: 963,
  },
  {
    label: "ผู้ช่วยด้านอาหาร",
    id: 964,
  },
  {
    label: "ช่างเทคนิคการควบคุมอาหาร",
    id: 965,
  },
  {
    label: "เกี่ยวกับนักโภชนาการ",
    id: 966,
  },
  {
    label: "ผู้ให้บริการบ่อหมัก",
    id: 967,
  },
  {
    label: "ผู้จัดการบัญชีดิจิทัล",
    id: 968,
  },
  {
    label: "ผู้ซื้อโฆษณาดิจิทัล",
    id: 969,
  },
  {
    label: "ศิลปินดิจิตอล",
    id: 970,
  },
  {
    label: "ผู้ผลิตเนื้อหาดิจิทัล",
    id: 971,
  },
  {
    label: "ผู้เชี่ยวชาญด้านเนื้อหาดิจิทัล",
    id: 972,
  },
  {
    label: "บรรณาธิการดิจิตอล",
    id: 973,
  },
  {
    label: "ผู้เชี่ยวชาญด้านนิติวิทยาศาสตร์ดิจิทัล",
    id: 974,
  },
  {
    label: "นักออกแบบเกมดิจิทัล",
    id: 975,
  },
  {
    label: "นักพัฒนาเกมดิจิทัล",
    id: 976,
  },
  {
    label: "ผู้ทดสอบเกมดิจิทัล",
    id: 977,
  },
  {
    label: "ครูสอนการรู้หนังสือดิจิทัล",
    id: 978,
  },
  {
    label: "นักวิเคราะห์การตลาดดิจิทัล",
    id: 979,
  },
  {
    label: "ผู้อำนวยการฝ่ายการตลาดดิจิทัล",
    id: 980,
  },
  {
    label: "ผู้บริหารการตลาดดิจิทัล",
    id: 981,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดดิจิทัล",
    id: 982,
  },
  {
    label: "นักยุทธศาสตร์การตลาดดิจิทัล",
    id: 983,
  },
  {
    label: "นักออกแบบสื่อดิจิทัล",
    id: 984,
  },
  {
    label: "ผู้เชี่ยวชาญด้านสื่อดิจิทัล",
    id: 985,
  },
  {
    label: "เครื่องพิมพ์ดิจิตอล",
    id: 986,
  },
  {
    label: "ผู้จัดการโครงการดิจิตอล",
    id: 987,
  },
  {
    label: "Digital Prototyper",
    id: 988,
  },
  {
    label: "ผู้ประกอบการถังจุ่ม",
    id: 989,
  },
  {
    label: "ทูต",
    id: 990,
  },
  {
    label: "นักเขียนคำโฆษณาตอบกลับโดยตรง",
    id: 991,
  },
  {
    label: "ผู้อำนวยการฝ่ายปฏิบัติตามกฎระเบียบและความปลอดภัยของข้อมูลในการพนัน",
    id: 992,
  },
  {
    label: "ผู้อำนวยการฝ่ายการตลาดเนื้อหา",
    id: 993,
  },
  {
    label: "ผู้อำนวยการฝ่ายการเงิน",
    id: 994,
  },
  {
    label: "ผู้อำนวยการฝ่ายวิเคราะห์การตลาด",
    id: 995,
  },
  {
    label: "ผู้อำนวยการพยาบาล",
    id: 996,
  },
  {
    label: "ผู้อำนวยการฝ่ายปฏิบัติการ",
    id: 997,
  },
  {
    label: "ผู้อำนวยการด้านการถ่ายภาพ",
    id: 998,
  },
  {
    label: "ผู้ปฏิบัติงานสนับสนุนคนพิการ",
    id: 999,
  },
  {
    label: "ดีเจ",
    id: 1000,
  },
  {
    label: "วิศวกรรื้อถอน",
    id: 1001,
  },
  {
    label: "ผู้ควบคุมการรื้อถอน",
    id: 1002,
  },
  {
    label: "คนงานรื้อถอน",
    id: 1003,
  },
  {
    label: "ผู้ส่ง",
    id: 1004,
  },
  {
    label: "ผู้ดำเนินการกลั่น",
    id: 1005,
  },
  {
    label: "โรงกลั่นมิลเลอร์",
    id: 1006,
  },
  {
    label: "หัวหน้างานกลั่น",
    id: 1007,
  },
  {
    label: "คนงานโรงกลั่น",
    id: 1008,
  },
  {
    label: "วิศวกรระบบกระจาย",
    id: 1009,
  },
  {
    label: "Dispatcher ศูนย์กระจายสินค้า",
    id: 1010,
  },
  {
    label: "ผู้จัดการการกระจายสินค้า",
    id: 1011,
  },
  {
    label: "ผู้จัดการเขต",
    id: 1012,
  },
  {
    label: "นักวิเคราะห์เงินปันผล",
    id: 1013,
  },
  {
    label: "นักพัฒนา Django",
    id: 1014,
  },
  {
    label: "ผู้ช่วยผ่าตัดศัลยกรรม",
    id: 1015,
  },
  {
    label: "พ่อแม่พันธุ์สุนัข",
    id: 1016,
  },
  {
    label: "ผู้ฝึกสอนสุนัข",
    id: 1017,
  },
  {
    label: "ผู้ผลิตตุ๊กตา",
    id: 1018,
  },
  {
    label: "ผู้จัดการร้านเครื่องใช้ไฟฟ้าในประเทศ",
    id: 1019,
  },
  {
    label: "เครื่องใช้ไฟฟ้าในประเทศผู้ขายพิเศษ",
    id: 1020,
  },
  {
    label: "บัตเลอร์ในประเทศ",
    id: 1021,
  },
  {
    label: "น้ำยาทำความสะอาดในประเทศ",
    id: 1022,
  },
  {
    label: "ช่างไฟฟ้าในประเทศ",
    id: 1023,
  },
  {
    label: "ผู้ประเมินพลังงานในประเทศ",
    id: 1024,
  },
  {
    label: "แม่บ้านในประเทศ",
    id: 1025,
  },
  {
    label: "ตัวติดตั้งประตู",
    id: 1026,
  },
  {
    label: "ผู้ดูแลประตู",
    id: 1027,
  },
  {
    label: "ผู้ขายประตูบ้าน",
    id: 1028,
  },
  {
    label: "คนเฝ้าประตู/ประตูบ้าน",
    id: 1029,
  },
  {
    label: "เครื่องราง",
    id: 1030,
  },
  {
    label: "ช่างเทคนิคการระบายน้ำ",
    id: 1031,
  },
  {
    label: "วิศวกรระบายน้ำ",
    id: 1032,
  },
  {
    label: "คนงานระบายน้ำ",
    id: 1033,
  },
  {
    label: "ครูสอนละคร",
    id: 1034,
  },
  {
    label: "ครูสอนละครโรงเรียนมัธยม",
    id: 1035,
  },
  {
    label: "การแสดงละคร",
    id: 1036,
  },
  {
    label: "ผ้าม่านและพรมทำความสะอาด",
    id: 1037,
  },
  {
    label: "ศิลปินวาดภาพ",
    id: 1038,
  },
  {
    label: "วาดภาพผู้ประกอบการเตาเผา",
    id: 1039,
  },
  {
    label: "ผู้ให้บริการขุด",
    id: 1040,
  },
  {
    label: "หัวหน้างานขุดลอก",
    id: 1041,
  },
  {
    label: "คนแต่งตัว",
    id: 1042,
  },
  {
    label: "ช่างตัดเสื้อ",
    id: 1043,
  },
  {
    label: "ผู้ประกอบการฝึกซ้อม",
    id: 1044,
  },
  {
    label: "ผู้ดำเนินการกดเจาะ",
    id: 1045,
  },
  {
    label: "เครื่องเจาะ",
    id: 1046,
  },
  {
    label: "วิศวกรขุดเจาะ",
    id: 1047,
  },
  {
    label: "ผู้ประกอบการเครื่องขุดเจาะ",
    id: 1048,
  },
  {
    label: "อาจารย์สอนขับรถ",
    id: 1049,
  },
  {
    label: "หล่นคนงานค้อนปลอม",
    id: 1050,
  },
  {
    label: "ที่ปรึกษาการติดยาเสพติดและแอลกอฮอล์",
    id: 1051,
  },
  {
    label: "ผู้จัดการร้านขายยา",
    id: 1052,
  },
  {
    label: "นักพัฒนา Drupal",
    id: 1053,
  },
  {
    label: "ผู้ดูแลบ้านแห้ง",
    id: 1054,
  },
  {
    label: "ผู้ดำเนินการกดแห้ง",
    id: 1055,
  },
  {
    label: "ผู้ดูแลเครื่องเป่า",
    id: 1056,
  },
  {
    label: "สถาปนิกอีเลิร์นนิง",
    id: 1057,
  },
  {
    label: "นักพัฒนาอิเล็กทรอนิกส์",
    id: 1058,
  },
  {
    label: "ครูผู้สอนความต้องการการศึกษาพิเศษในช่วงปีแรก ๆ",
    id: 1059,
  },
  {
    label: "ครูปีแรก ๆ",
    id: 1060,
  },
  {
    label: "ผู้ช่วยสอนในช่วงปีแรก ๆ",
    id: 1061,
  },
  {
    label: "วิทยากรวิทยาศาสตร์โลก",
    id: 1062,
  },
  {
    label: "ผู้จัดการธุรกิจ",
    id: 1063,
  },
  {
    label: "นักนิเวศวิทยา",
    id: 1064,
  },
  {
    label: "ผู้จัดการฝ่ายขายสินค้าอีคอมเมิร์ซ",
    id: 1065,
  },
  {
    label: "ที่ปรึกษาทางเศรษฐกิจ",
    id: 1066,
  },
  {
    label: "ผู้ประสานงานการพัฒนาเศรษฐกิจ",
    id: 1067,
  },
  {
    label: "เจ้าหน้าที่นโยบายเศรษฐกิจ",
    id: 1068,
  },
  {
    label: "อาจารย์เศรษฐศาสตร์",
    id: 1069,
  },
  {
    label: "นักเศรษฐศาสตร์",
    id: 1070,
  },
  {
    label: "ผู้ให้บริการ Edge Bander",
    id: 1071,
  },
  {
    label: "บรรณาธิการ",
    id: 1072,
  },
  {
    label: "บรรณาธิการ",
    id: 1073,
  },
  {
    label: "ผู้ช่วยกองบรรณาธิการ",
    id: 1074,
  },
  {
    label: "ผู้บริหารการศึกษา",
    id: 1075,
  },
  {
    label: "ผู้อำนวยการด้านการศึกษา",
    id: 1076,
  },
  {
    label: "ผู้ตรวจการศึกษา",
    id: 1077,
  },
  {
    label: "เจ้าหน้าที่นโยบายการศึกษา",
    id: 1078,
  },
  {
    label: "ผู้ประสานงานโปรแกรมการศึกษา",
    id: 1079,
  },
  {
    label: "อาจารย์สอนการศึกษา",
    id: 1080,
  },
  {
    label: "เจ้าหน้าที่สวัสดิการการศึกษา",
    id: 1081,
  },
  {
    label: "ที่ปรึกษาด้านการศึกษา",
    id: 1082,
  },
  {
    label: "นักจิตวิทยาการศึกษา",
    id: 1083,
  },
  {
    label: "นักวิจัยการศึกษา",
    id: 1084,
  },
  {
    label: "ผู้จัดการบ้านผู้สูงอายุ",
    id: 1085,
  },
  {
    label: "Elearning Copy Editor",
    id: 1086,
  },
  {
    label: "ตัวแทนการเลือกตั้ง",
    id: 1087,
  },
  {
    label: "ผู้สังเกตการณ์การเลือกตั้ง",
    id: 1088,
  },
  {
    label: "ช่างเทคนิคเครื่องวัดไฟฟ้า",
    id: 1089,
  },
  {
    label: "วิศวกรผลิตพลังงานไฟฟ้า",
    id: 1090,
  },
  {
    label: "สายเคเบิลไฟฟ้า",
    id: 1091,
  },
  {
    label: "เครื่องไฟฟ้า",
    id: 1092,
  },
  {
    label: "วิศวกรไฟฟ้า",
    id: 1093,
  },
  {
    label: "ช่างเทคนิควิศวกรรมไฟฟ้า",
    id: 1094,
  },
  {
    label: "เครื่องประกอบอุปกรณ์ไฟฟ้า",
    id: 1095,
  },
  {
    label: "ผู้ตรวจการอุปกรณ์ไฟฟ้า",
    id: 1096,
  },
  {
    label: "หัวหน้างานผลิตอุปกรณ์ไฟฟ้า",
    id: 1097,
  },
  {
    label: "ผู้จัดการฝ่ายจำหน่ายเครื่องใช้ไฟฟ้า",
    id: 1098,
  },
  {
    label: "ช่างไฟฟ้า Mechanic",
    id: 1099,
  },
  {
    label: "ผู้จัดจำหน่ายพลังงานไฟฟ้า",
    id: 1100,
  },
  {
    label: "หัวหน้างานไฟฟ้า",
    id: 1101,
  },
  {
    label: "ผู้ประกอบการระบบส่งกำลังไฟฟ้า",
    id: 1102,
  },
  {
    label: "ช่างไฟฟ้า",
    id: 1103,
  },
  {
    label: "ครูสอนวิชาไฟฟ้าและพลังงาน",
    id: 1104,
  },
  {
    label: "พนักงานจำหน่ายไฟฟ้า",
    id: 1105,
  },
  {
    label: "ตัวแทนขายไฟฟ้า",
    id: 1106,
  },
  {
    label: "ผู้ผลิตเซลล์อิเล็กโทรไลติก",
    id: 1107,
  },
  {
    label: "วิศวกรแม่เหล็กไฟฟ้า",
    id: 1108,
  },
  {
    label: "เครื่องวัดไฟฟ้า",
    id: 1109,
  },
  {
    label: "วิศวกรเครื่องกลไฟฟ้า",
    id: 1110,
  },
  {
    label: "ช่างเทคนิควิศวกรรมไฟฟ้า",
    id: 1111,
  },
  {
    label: "เครื่องประกอบอุปกรณ์ไฟฟ้า",
    id: 1112,
  },
  {
    label: "ช่างเชื่อมลำแสงอิเล็กตรอน",
    id: 1113,
  },
  {
    label: "อุปกรณ์อิเล็กทรอนิกส์และโทรคมนาคมและผู้จัดการการจัดจำหน่ายชิ้นส่วน",
    id: 1114,
  },
  {
    label: "อุปกรณ์ประกอบอุปกรณ์อิเล็กทรอนิกส์",
    id: 1115,
  },
  {
    label: "ผู้ตรวจการอุปกรณ์อิเล็กทรอนิกส์",
    id: 1116,
  },
  {
    label: "เครื่องดนตรีอิเล็กทรอนิกส์",
    id: 1117,
  },
  {
    label: "ครูสอนวิชาอิเล็กทรอนิกส์และระบบอัตโนมัติ",
    id: 1118,
  },
  {
    label: "อุปกรณ์อิเล็กทรอนิกส์",
    id: 1119,
  },
  {
    label: "วิศวกรอิเล็กทรอนิกส์",
    id: 1120,
  },
  {
    label: "ช่างเทคนิควิศวกรรมอิเล็กทรอนิกส์",
    id: 1121,
  },
  {
    label: "หัวหน้างานการผลิตอิเล็กทรอนิกส์",
    id: 1122,
  },
  {
    label: "ผู้ประกอบการเครื่องไฟฟ้า",
    id: 1123,
  },
  {
    label: "ครูประถมศึกษา",
    id: 1124,
  },
  {
    label: "นักพัฒนาน้ำอมฤต",
    id: 1125,
  },
  {
    label: "นักการตลาดอีเมลและนักเขียนคำโฆษณา",
    id: 1126,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดอีเมล",
    id: 1127,
  },
  {
    label: "ผู้ร่วมผลิตอีเมล",
    id: 1128,
  },
  {
    label: "ผู้ดอง",
    id: 1129,
  },
  {
    label: "ที่ปรึกษาสถานทูต",
    id: 1130,
  },
  {
    label: "วิศวกรซอฟต์แวร์ฝังตัว",
    id: 1131,
  },
  {
    label: "นักออกแบบระบบฝังตัว",
    id: 1132,
  },
  {
    label: "นักพัฒนาซอฟต์แวร์ระบบฝังตัว",
    id: 1133,
  },
  {
    label: "นักพัฒนา Ember",
    id: 1134,
  },
  {
    label: "ember.js นักพัฒนา",
    id: 1135,
  },
  {
    label: "คนเย็บปักถักร้อย",
    id: 1136,
  },
  {
    label: "ผู้ให้บริการเครื่องปัก",
    id: 1137,
  },
  {
    label: "คนขับรถพยาบาลฉุกเฉิน",
    id: 1138,
  },
  {
    label: "dispatcher ทางการแพทย์ฉุกเฉิน",
    id: 1139,
  },
  {
    label: "ผู้ประสานงานการตอบสนองฉุกเฉิน",
    id: 1140,
  },
  {
    label: "เจ้าหน้าที่ตอบรับฉุกเฉิน",
    id: 1141,
  },
  {
    label: "ตัวแทนการจ้างงาน",
    id: 1142,
  },
  {
    label: "ที่ปรึกษาการจ้างงานและการรวมอาชีพ",
    id: 1143,
  },
  {
    label: "ผู้ประสานงานโปรแกรมการจ้างงาน",
    id: 1144,
  },
  {
    label: "พนักงานสนับสนุนการจ้างงาน",
    id: 1145,
  },
  {
    label: "เครื่องเคลือบ",
    id: 1146,
  },
  {
    label: "นักวิเคราะห์พลังงาน",
    id: 1147,
  },
  {
    label: "ผู้ประเมินพลังงาน",
    id: 1148,
  },
  {
    label: "เจ้าหน้าที่อนุรักษ์พลังงาน",
    id: 1149,
  },
  {
    label: "ที่ปรึกษาด้านพลังงาน",
    id: 1150,
  },
  {
    label: "วิศวกรพลังงาน",
    id: 1151,
  },
  {
    label: "ผู้จัดการพลังงาน",
    id: 1152,
  },
  {
    label: "วิศวกรระบบพลังงาน",
    id: 1153,
  },
  {
    label: "ผู้ค้าพลังงาน",
    id: 1154,
  },
  {
    label: "ผู้จัดการหมั้น",
    id: 1155,
  },
  {
    label: "นักออกแบบเครื่องยนต์",
    id: 1156,
  },
  {
    label: "ผู้ดูแลเครื่องยนต์",
    id: 1157,
  },
  {
    label: "บอร์ดบอร์ดวิศวกรรมบอร์ด",
    id: 1158,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรบอร์ดไม้วิศวกรรม",
    id: 1159,
  },
  {
    label: "ผู้ช่วยวิศวกรรม",
    id: 1160,
  },
  {
    label: "อาจารย์วิศวกรรม",
    id: 1161,
  },
  {
    label: "ภาษาอังกฤษเป็นอาจารย์สอนภาษาที่สอง",
    id: 1162,
  },
  {
    label: "ผู้ประกอบการเครื่องแกะสลัก",
    id: 1163,
  },
  {
    label: "ผู้บริหารบัญชีองค์กร",
    id: 1164,
  },
  {
    label: "สถาปนิกองค์กร",
    id: 1165,
  },
  {
    label: "ผู้จัดการความสำเร็จของลูกค้าองค์กร",
    id: 1166,
  },
  {
    label: "พนักงานพัฒนาองค์กร",
    id: 1167,
  },
  {
    label: "นักข่าวบันเทิง",
    id: 1168,
  },
  {
    label: "ผู้ผลิตซองจดหมาย",
    id: 1169,
  },
  {
    label: "เจ้าหน้าที่การศึกษาด้านสิ่งแวดล้อม",
    id: 1170,
  },
  {
    label: "วิศวกรสิ่งแวดล้อม",
    id: 1171,
  },
  {
    label: "ผู้เชี่ยวชาญด้านสิ่งแวดล้อม",
    id: 1172,
  },
  {
    label: "นักธรณีวิทยาสิ่งแวดล้อม",
    id: 1173,
  },
  {
    label: "ผู้ประสานงานด้านสุขภาพและความปลอดภัยด้านสิ่งแวดล้อม",
    id: 1174,
  },
  {
    label: "วิศวกรสุขภาพและความปลอดภัยด้านสิ่งแวดล้อม",
    id: 1175,
  },
  {
    label: "ผู้ตรวจสอบสุขภาพสิ่งแวดล้อม",
    id: 1176,
  },
  {
    label: "วิศวกรเหมืองแร่สิ่งแวดล้อม",
    id: 1177,
  },
  {
    label: "เจ้าหน้าที่นโยบายสิ่งแวดล้อม",
    id: 1178,
  },
  {
    label: "ผู้ประสานงานโครงการด้านสิ่งแวดล้อม",
    id: 1179,
  },
  {
    label: "ผู้จัดการฝ่ายคุ้มครองสิ่งแวดล้อม",
    id: 1180,
  },
  {
    label: "นักวิทยาศาสตร์สิ่งแวดล้อม",
    id: 1181,
  },
  {
    label: "ช่างเทคนิคด้านสิ่งแวดล้อม",
    id: 1182,
  },
  {
    label: "นักระบาดวิทยา",
    id: 1183,
  },
  {
    label: "ผู้จัดการความเสมอภาคและการรวม",
    id: 1184,
  },
  {
    label: "ช่างทันตกรรมม้า",
    id: 1185,
  },
  {
    label: "คนงานม้า",
    id: 1186,
  },
  {
    label: "ผู้จัดการสนามม้า",
    id: 1187,
  },
  {
    label: "วิศวกรอุปกรณ์",
    id: 1188,
  },
  {
    label: "นักยศาสตร์",
    id: 1189,
  },
  {
    label: "คุ้มกัน",
    id: 1190,
  },
  {
    label: "เครื่องประมาณ",
    id: 1191,
  },
  {
    label: "แฮ็กเกอร์จริยธรรม",
    id: 1192,
  },
  {
    label: "ผู้ช่วยงาน",
    id: 1193,
  },
  {
    label: "ผู้ประสานงานกิจกรรม",
    id: 1194,
  },
  {
    label: "ช่างไฟฟ้าเหตุการณ์",
    id: 1195,
  },
  {
    label: "ผู้จัดการงานอีเว้นท์",
    id: 1196,
  },
  {
    label: "นักวางแผนงาน",
    id: 1197,
  },
  {
    label: "นั่งร้านเหตุการณ์",
    id: 1198,
  },
  {
    label: "ผู้ให้บริการรถขุด",
    id: 1199,
  },
  {
    label: "ผู้ช่วยผู้บริหาร",
    id: 1200,
  },
  {
    label: "ผู้อำนวยการบริหาร",
    id: 1201,
  },
  {
    label: "ผู้สรรหาผู้บริหาร",
    id: 1202,
  },
  {
    label: "ที่ปรึกษาการค้นหาผู้บริหาร",
    id: 1203,
  },
  {
    label: "เลขาผู้บริหาร",
    id: 1204,
  },
  {
    label: "ภัณฑารักษ์นิทรรศการ",
    id: 1205,
  },
  {
    label: "นายทะเบียนนิทรรศการ",
    id: 1206,
  },
  {
    label: "นักธรณีวิทยาสำรวจ",
    id: 1207,
  },
  {
    label: "วิศวกรระเบิด",
    id: 1208,
  },
  {
    label: "นักพัฒนาด่วน",
    id: 1209,
  },
  {
    label: "พิเศษ",
    id: 1210,
  },
  {
    label: "สกัดเครื่องทดสอบมิกเซอร์",
    id: 1211,
  },
  {
    label: "ผู้ให้บริการเครื่องอัดรีด",
    id: 1212,
  },
  {
    label: "ผู้จัดการร้านขายอุปกรณ์และออพติคอล",
    id: 1213,
  },
  {
    label: "ผู้ขายเฉพาะด้านแว่นตาและออพติคอล",
    id: 1214,
  },
  {
    label: "ผู้จัดการโฆษณา Facebook",
    id: 1215,
  },
  {
    label: "ผู้จัดการสิ่งอำนวยความสะดวก",
    id: 1216,
  },
  {
    label: "ผู้จัดการสิ่งอำนวยความสะดวก",
    id: 1217,
  },
  {
    label: "ตัวตรวจสอบข้อเท็จจริง",
    id: 1218,
  },
  {
    label: "มือโรงงาน",
    id: 1219,
  },
  {
    label: "ที่ปรึกษาการวางแผนครอบครัว",
    id: 1220,
  },
  {
    label: "นักสังคมสงเคราะห์ครอบครัว",
    id: 1221,
  },
  {
    label: "พนักงานช่วยเหลือครอบครัว",
    id: 1222,
  },
  {
    label: "ผู้จัดการฟาร์ม",
    id: 1223,
  },
  {
    label: "ตัวควบคุมนมฟาร์ม",
    id: 1224,
  },
  {
    label: "คนที่มีความสุข",
    id: 1225,
  },
  {
    label: "นักออกแบบด้านแฟชั่น",
    id: 1226,
  },
  {
    label: "บรรณาธิการแฟชั่น",
    id: 1227,
  },
  {
    label: "นางแบบแฟชั่น",
    id: 1228,
  },
  {
    label: "คนงานอาหารจานด่วนและเคาน์เตอร์",
    id: 1229,
  },
  {
    label: "ผู้ปฏิบัติงานไขมัน",
    id: 1230,
  },
  {
    label: "ผู้ให้บริการหมัก",
    id: 1231,
  },
  {
    label: "เครื่องผสมปุ๋ย",
    id: 1232,
  },
  {
    label: "ไฟเบอร์กลาส",
    id: 1233,
  },
  {
    label: "เครื่องจักรเส้นใยนุ่ม",
    id: 1234,
  },
  {
    label: "ผู้ให้บริการเครื่องไฟเบอร์กลาส",
    id: 1235,
  },
  {
    label: "พนักงานขายภาคสนาม",
    id: 1236,
  },
  {
    label: "ตัวแทนขายภาคสนาม",
    id: 1237,
  },
  {
    label: "ตัวแทนบริการภาคสนาม",
    id: 1238,
  },
  {
    label: "ช่างเทคนิคบริการภาคสนาม",
    id: 1239,
  },
  {
    label: "ผู้จัดการการสำรวจภาคสนาม",
    id: 1240,
  },
  {
    label: "ผู้กำกับการต่อสู้",
    id: 1241,
  },
  {
    label: "ผู้ให้บริการที่คดเคี้ยวของเส้นใย",
    id: 1242,
  },
  {
    label: "เสมียนไฟล์",
    id: 1243,
  },
  {
    label: "ผู้ผลิตลวดลาย",
    id: 1244,
  },
  {
    label: "ตัวแทนการยื่นเอกสาร",
    id: 1245,
  },
  {
    label: "ผู้ให้บริการเครื่องเอกสาร",
    id: 1246,
  },
  {
    label: "ผู้จัดการด้านการเงินและการบัญชี",
    id: 1247,
  },
  {
    label: "บริษัท การเงิน",
    id: 1248,
  },
  {
    label: "ผู้อำนวยการฝ่ายการเงิน",
    id: 1249,
  },
  {
    label: "ที่ปรึกษาทางการเงิน",
    id: 1250,
  },
  {
    label: "นักวิเคราะห์การเงิน",
    id: 1251,
  },
  {
    label: "ผู้สอบบัญชีทางการเงิน",
    id: 1252,
  },
  {
    label: "นายหน้าการเงิน",
    id: 1253,
  },
  {
    label: "ผู้ควบคุมการเงิน",
    id: 1254,
  },
  {
    label: "ผู้ตรวจสอบการเงิน",
    id: 1255,
  },
  {
    label: "ผู้ตรวจสอบการฉ้อโกงทางการเงิน",
    id: 1256,
  },
  {
    label: "ผู้จัดการการเงิน",
    id: 1257,
  },
  {
    label: "ตลาดการเงินผู้ดูแลระบบแบ็คออฟฟิศ",
    id: 1258,
  },
  {
    label: "นักวางแผนทางการเงิน",
    id: 1259,
  },
  {
    label: "ผู้จัดการวางแผนการเงิน",
    id: 1260,
  },
  {
    label: "นักวิเคราะห์ความเสี่ยงทางการเงิน",
    id: 1261,
  },
  {
    label: "ผู้ค้าการเงิน",
    id: 1262,
  },
  {
    label: "อาจารย์สอนศิลปะ",
    id: 1263,
  },
  {
    label: "ผู้จัดการคลังหนังเสร็จแล้ว",
    id: 1264,
  },
  {
    label: "การตกแต่งช่างสิ่งทอ",
    id: 1265,
  },
  {
    label: "คณะกรรมาธิการดับเพลิง",
    id: 1266,
  },
  {
    label: "ผู้ตรวจการดับเพลิง",
    id: 1267,
  },
  {
    label: "วิศวกรป้องกันและป้องกันอัคคีภัย",
    id: 1268,
  },
  {
    label: "ช่างเทคนิคป้องกันอัคคีภัย",
    id: 1269,
  },
  {
    label: "เครื่องทดสอบความปลอดภัยจากอัคคีภัย",
    id: 1270,
  },
  {
    label: "ผู้ให้บริการรถดับเพลิง",
    id: 1271,
  },
  {
    label: "นักดับเพลิง",
    id: 1272,
  },
  {
    label: "ผู้สอนดับเพลิง",
    id: 1273,
  },
  {
    label: "ช่างประกอบพลุ",
    id: 1274,
  },
  {
    label: "ผู้สอนการปฐมพยาบาล",
    id: 1275,
  },
  {
    label: "เจ้าหน้าที่นโยบายการคลัง",
    id: 1276,
  },
  {
    label: "ผู้จัดการร้านขายปลาและอาหารทะเล",
    id: 1277,
  },
  {
    label: "ผู้ขายเฉพาะปลาและอาหารทะเล",
    id: 1278,
  },
  {
    label: "ผู้ประกอบการปลากระป๋องปลา",
    id: 1279,
  },
  {
    label: "ทำอาหารปลา",
    id: 1280,
  },
  {
    label: "ผู้ประกอบการเตรียมปลา",
    id: 1281,
  },
  {
    label: "ผู้ดำเนินการผลิตปลา",
    id: 1282,
  },
  {
    label: "เครื่องตัดแต่งปลา",
    id: 1283,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายปลาครัสเตเชียนและมอลลัคส์",
    id: 1284,
  },
  {
    label: "ที่ปรึกษาการประมง",
    id: 1285,
  },
  {
    label: "วิศวกรผู้ช่วยประมง",
    id: 1286,
  },
  {
    label: "ชาวประมง",
    id: 1287,
  },
  {
    label: "การประมง Boatmaster",
    id: 1288,
  },
  {
    label: "Deckhand ประมง",
    id: 1289,
  },
  {
    label: "นายประมง",
    id: 1290,
  },
  {
    label: "ผู้สังเกตการณ์การประมง",
    id: 1291,
  },
  {
    label: "วิศวกรเครื่องทำความเย็นประมง",
    id: 1292,
  },
  {
    label: "ผู้ผลิตตาข่ายตกปลา",
    id: 1293,
  },
  {
    label: "ผู้สอนการออกกำลังกาย",
    id: 1294,
  },
  {
    label: "ช่างฟิตและเทอร์เนอร์",
    id: 1295,
  },
  {
    label: "นักพัฒนาขวด",
    id: 1296,
  },
  {
    label: "ผู้บัญชาการกองทัพเรือ",
    id: 1297,
  },
  {
    label: "ผู้ดำเนินการกดแบบ Flexographic",
    id: 1298,
  },
  {
    label: "พนักงานต้อนรับบนเครื่องบิน",
    id: 1299,
  },
  {
    label: "ผู้สอนการบิน",
    id: 1300,
  },
  {
    label: "เจ้าหน้าที่ปฏิบัติการเที่ยวบิน",
    id: 1301,
  },
  {
    label: "วิศวกรทดสอบการบิน",
    id: 1302,
  },
  {
    label: "ผู้จัดการร้านค้าพื้นและผนัง",
    id: 1303,
  },
  {
    label: "ผู้ขายแบบพื้นและผนัง",
    id: 1304,
  },
  {
    label: "ผู้ให้บริการเครื่องฟอกแป้ง",
    id: 1305,
  },
  {
    label: "ผู้จัดการร้านดอกไม้และสวน",
    id: 1306,
  },
  {
    label: "ผู้ขายดอกไม้และสวน",
    id: 1307,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายดอกไม้และพืช",
    id: 1308,
  },
  {
    label: "วิศวกรพลังงานของเหลว",
    id: 1309,
  },
  {
    label: "ช่างเทคนิคพลังงานของเหลว",
    id: 1310,
  },
  {
    label: "นักพัฒนา",
    id: 1311,
  },
  {
    label: "ผู้อำนวยการบิน",
    id: 1312,
  },
  {
    label: "เครื่องผสมยางโฟม",
    id: 1313,
  },
  {
    label: "ผู้ให้บริการติดตาม",
    id: 1314,
  },
  {
    label: "นักวิเคราะห์อาหาร",
    id: 1315,
  },
  {
    label: "ผู้จัดการด้านอาหารและเครื่องดื่ม",
    id: 1316,
  },
  {
    label: "นักเทคโนโลยีบรรจุภัณฑ์อาหารและเครื่องดื่ม",
    id: 1317,
  },
  {
    label: "เทคโนโลยีชีวภาพด้านอาหาร",
    id: 1318,
  },
  {
    label: "ผู้เดินทางอาหาร",
    id: 1319,
  },
  {
    label: "อาหารชั้นประถมอาหาร",
    id: 1320,
  },
  {
    label: "วิศวกรการผลิตอาหาร",
    id: 1321,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตอาหาร",
    id: 1322,
  },
  {
    label: "ผู้ดำเนินการผลิตอาหาร",
    id: 1323,
  },
  {
    label: "วางแผนการผลิตอาหาร",
    id: 1324,
  },
  {
    label: "ที่ปรึกษาด้านอาหาร",
    id: 1325,
  },
  {
    label: "ผู้ตรวจสอบความปลอดภัยด้านอาหาร",
    id: 1326,
  },
  {
    label: "ผู้เชี่ยวชาญด้านความปลอดภัยด้านอาหาร",
    id: 1327,
  },
  {
    label: "วิทยากรวิทยาศาสตร์การอาหาร",
    id: 1328,
  },
  {
    label: "ผู้จัดการฝ่ายบริการอาหาร",
    id: 1329,
  },
  {
    label: "ครูสอนวิชาชีพอาหาร",
    id: 1330,
  },
  {
    label: "ช่างเทคนิคอาหาร",
    id: 1331,
  },
  {
    label: "นักเทคโนโลยีอาหาร",
    id: 1332,
  },
  {
    label: "โค้ชฟุตบอล",
    id: 1333,
  },
  {
    label: "หัวหน้างานสมัชชา",
    id: 1334,
  },
  {
    label: "รองเท้า CAD Patternmaker",
    id: 1335,
  },
  {
    label: "นักออกแบบรองเท้า",
    id: 1336,
  },
  {
    label: "ผู้ให้บริการคลังสินค้าโรงงาน",
    id: 1337,
  },
  {
    label: "การตกแต่งรองเท้าและผู้ประกอบการบรรจุภัณฑ์",
    id: 1338,
  },
  {
    label: "ท่อระบายน้ำมือ",
    id: 1339,
  },
  {
    label: "ช่างซ่อมรองเท้า",
    id: 1340,
  },
  {
    label: "รูปแบบรองเท้า",
    id: 1341,
  },
  {
    label: "นักพัฒนาผลิตภัณฑ์รองเท้า",
    id: 1342,
  },
  {
    label: "ผู้จัดการฝ่ายพัฒนาผลิตภัณฑ์รองเท้า",
    id: 1343,
  },
  {
    label: "ผู้ประกอบการเครื่องผลิตรองเท้า",
    id: 1344,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตรองเท้า",
    id: 1345,
  },
  {
    label: "หัวหน้างานผลิตรองเท้า",
    id: 1346,
  },
  {
    label: "ช่างเทคนิคการผลิตรองเท้า",
    id: 1347,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการควบคุมคุณภาพรองเท้า",
    id: 1348,
  },
  {
    label: "คอนโทรลเลอร์คุณภาพรองเท้า",
    id: 1349,
  },
  {
    label: "ผู้จัดการคุณภาพรองเท้า",
    id: 1350,
  },
  {
    label: "ช่างเทคนิคคุณภาพรองเท้า",
    id: 1351,
  },
  {
    label: "ผู้ประกอบการเครื่องเย็บรองเท้า",
    id: 1352,
  },
  {
    label: "ผู้จัดการพยากรณ์",
    id: 1353,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการยึดสังหาริมทรัพย์",
    id: 1354,
  },
  {
    label: "เจ้าหน้าที่การต่างประเทศ",
    id: 1355,
  },
  {
    label: "ผู้สื่อข่าวต่างประเทศ",
    id: 1356,
  },
  {
    label: "นายหน้าแลกเปลี่ยนเงินตราต่างประเทศ",
    id: 1357,
  },
  {
    label: "แคชเชียร์แลกเปลี่ยนเงินตราต่างประเทศ",
    id: 1358,
  },
  {
    label: "ผู้ค้าแลกเปลี่ยนเงินตราต่างประเทศ",
    id: 1359,
  },
  {
    label: "พนักงานติดต่อภาษาต่างประเทศ",
    id: 1360,
  },
  {
    label: "แรนเจอร์ป่า",
    id: 1361,
  },
  {
    label: "คนงานป่าไม้",
    id: 1362,
  },
  {
    label: "คนป่าไม้",
    id: 1363,
  },
  {
    label: "ที่ปรึกษาด้านป่าไม้",
    id: 1364,
  },
  {
    label: "ผู้ให้บริการอุปกรณ์ป่าไม้",
    id: 1365,
  },
  {
    label: "ผู้ตรวจการป่าไม้",
    id: 1366,
  },
  {
    label: "ช่างเทคนิคการป่าไม้",
    id: 1367,
  },
  {
    label: "ช่างตัดไม้",
    id: 1368,
  },
  {
    label: "ช่างเทคนิคอุปกรณ์ปลอมแปลง",
    id: 1369,
  },
  {
    label: "ผู้ประกอบการยก",
    id: 1370,
  },
  {
    label: "หมอดู",
    id: 1371,
  },
  {
    label: "ผู้จัดการการส่งต่อ",
    id: 1372,
  },
  {
    label: "ผู้ประกอบการโรงไฟฟ้าเชื้อเพลิงฟอสซิล",
    id: 1373,
  },
  {
    label: "ผู้สนับสนุนการดูแลอุปถัมภ์",
    id: 1374,
  },
  {
    label: "ผู้จัดการโรงหล่อ",
    id: 1375,
  },
  {
    label: "โรงหล่อ",
    id: 1376,
  },
  {
    label: "โรงหล่อ",
    id: 1377,
  },
  {
    label: "นักเคมีน้ำหอม",
    id: 1378,
  },
  {
    label: "ผู้ผลิตเฟรม",
    id: 1379,
  },
  {
    label: "วิศวกรการฉ้อโกง / ความเสี่ยง",
    id: 1380,
  },
  {
    label: "ผู้ตรวจสอบการฉ้อโกง",
    id: 1381,
  },
  {
    label: "ตัวแทนป้องกันการฉ้อโกง",
    id: 1382,
  },
  {
    label: "ผู้สนับสนุนกองบรรณาธิการอิสระ",
    id: 1383,
  },
  {
    label: "นักเขียนอิสระ",
    id: 1384,
  },
  {
    label: "ผู้ตรวจการขนส่งสินค้า",
    id: 1385,
  },
  {
    label: "Dispatcher ขนส่งสินค้า",
    id: 1386,
  },
  {
    label: "ครูโรงเรียน Freinet",
    id: 1387,
  },
  {
    label: "เจ้าหน้าที่แผนกต้อนรับ",
    id: 1388,
  },
  {
    label: "ตัวแทนแผนกต้อนรับ",
    id: 1389,
  },
  {
    label: "พนักงานต้อนรับด้านการแพทย์แนวหน้า",
    id: 1390,
  },
  {
    label: "หน้าผู้จัดการบ้าน",
    id: 1391,
  },
  {
    label: "ผู้จัดการสำนักงานด้านหน้า",
    id: 1392,
  },
  {
    label: "นักพัฒนาส่วนหน้า",
    id: 1393,
  },
  {
    label: "Froth Flotation Deinking Operator",
    id: 1394,
  },
  {
    label: "กระป๋องผักและผลไม้",
    id: 1395,
  },
  {
    label: "ตัวเลือกผลไม้และผัก",
    id: 1396,
  },
  {
    label: "ผู้ดูแลผลไม้และผัก",
    id: 1397,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายผักและผลไม้",
    id: 1398,
  },
  {
    label: "ผู้จัดการร้านขายผักและผลไม้",
    id: 1399,
  },
  {
    label: "ผักและผลไม้ผู้ขายเฉพาะ",
    id: 1400,
  },
  {
    label: "หัวหน้าทีมผลิตผลไม้",
    id: 1401,
  },
  {
    label: "ผู้ปฏิบัติงาน",
    id: 1402,
  },
  {
    label: "นักพัฒนาเซิร์ฟเวอร์ FTP",
    id: 1403,
  },
  {
    label: "ผู้จัดการสถานีน้ำมันเชื้อเพลิง",
    id: 1404,
  },
  {
    label: "ผู้ขายเฉพาะสถานีน้ำมันเชื้อเพลิง",
    id: 1405,
  },
  {
    label: "นักพัฒนาสแต็คเต็มรูปแบบ",
    id: 1406,
  },
  {
    label: "ผู้ระดมทุน",
    id: 1407,
  },
  {
    label: "ผู้ช่วยระดมทุน",
    id: 1408,
  },
  {
    label: "ผู้จัดการระดมทุน",
    id: 1409,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการระดมทุน",
    id: 1410,
  },
  {
    label: "ผู้ดูแลงานศพ",
    id: 1411,
  },
  {
    label: "ผู้อำนวยการฝ่ายบริการงานศพ",
    id: 1412,
  },
  {
    label: "พ่อแม่พันธุ์สัตว์ขนสัตว์",
    id: 1413,
  },
  {
    label: "แอสเซมเบลอร์เฟอร์นิเจอร์",
    id: 1414,
  },
  {
    label: "เครื่องทำความสะอาดเฟอร์นิเจอร์",
    id: 1415,
  },
  {
    label: "นักออกแบบเฟอร์นิเจอร์",
    id: 1416,
  },
  {
    label: "หมัดเด็ดเฟอร์นิเจอร์",
    id: 1417,
  },
  {
    label: "ผู้บูรณะเฟอร์นิเจอร์",
    id: 1418,
  },
  {
    label: "ผู้จัดการร้านเฟอร์นิเจอร์",
    id: 1419,
  },
  {
    label: "ผู้ขายเฉพาะเฟอร์นิเจอร์",
    id: 1420,
  },
  {
    label: "เบาะเฟอร์นิเจอร์",
    id: 1421,
  },
  {
    label: "เฟอร์นิเจอร์พรมและอุปกรณ์จัดจำหน่ายอุปกรณ์ส่องสว่าง",
    id: 1422,
  },
  {
    label: "เงินต้นการศึกษาเพิ่มเติม",
    id: 1423,
  },
  {
    label: "ครูการศึกษาเพิ่มเติม",
    id: 1424,
  },
  {
    label: "ผู้ซื้อขายล่วงหน้า",
    id: 1425,
  },
  {
    label: "ผู้จัดการการพนัน",
    id: 1426,
  },
  {
    label: "นักพัฒนาเกมการพนันการเดิมพันและลอตเตอรี",
    id: 1427,
  },
  {
    label: "การพนันการเดิมพันและนักออกแบบเกมลอตเตอรี",
    id: 1428,
  },
  {
    label: "วิศวกรการพนันการเดิมพันและการจับสลากลอตเตอรี",
    id: 1429,
  },
  {
    label: "แอนิเมชั่นเกม",
    id: 1430,
  },
  {
    label: "ศิลปินเกม",
    id: 1431,
  },
  {
    label: "นักพัฒนาเกม",
    id: 1432,
  },
  {
    label: "ผู้ดูแลเกม",
    id: 1433,
  },
  {
    label: "โปรแกรมเมอร์เกม",
    id: 1434,
  },
  {
    label: "ผู้จัดการฝ่ายพัฒนาเกม",
    id: 1435,
  },
  {
    label: "ตัวแทนจำหน่ายเกม",
    id: 1436,
  },
  {
    label: "ผู้ตรวจการเล่นเกม",
    id: 1437,
  },
  {
    label: "ผู้จัดการโรงรถ",
    id: 1438,
  },
  {
    label: "คนงานในสวน",
    id: 1439,
  },
  {
    label: "วิศวกรการกระจายก๊าซ",
    id: 1440,
  },
  {
    label: "ผู้ประกอบการห้องควบคุมโรงงานแปรรูปก๊าซ",
    id: 1441,
  },
  {
    label: "ผู้ประกอบการโรงงานแปรรูปก๊าซ",
    id: 1442,
  },
  {
    label: "หัวหน้างานโรงงานแปรรูปก๊าซ",
    id: 1443,
  },
  {
    label: "วิศวกรการผลิตก๊าซ",
    id: 1444,
  },
  {
    label: "ตัวแทนการจัดตารางก๊าซ",
    id: 1445,
  },
  {
    label: "ช่างเทคนิคบริการก๊าซ",
    id: 1446,
  },
  {
    label: "ผู้ให้บริการปั๊มน้ำมัน",
    id: 1447,
  },
  {
    label: "ผู้ประกอบการระบบส่งก๊าซ",
    id: 1448,
  },
  {
    label: "ยามประตู",
    id: 1449,
  },
  {
    label: "เครื่องวัด",
    id: 1450,
  },
  {
    label: "ช่างเครื่องเกียร์",
    id: 1451,
  },
  {
    label: "นักกัญชา",
    id: 1452,
  },
  {
    label: "นักลำดับวงศ์ตระกูล",
    id: 1453,
  },
  {
    label: "ที่ปรึกษาทั่วไป",
    id: 1454,
  },
  {
    label: "ผู้จัดการทั่วไป",
    id: 1455,
  },
  {
    label: "ผู้ประกอบการทั่วไป",
    id: 1456,
  },
  {
    label: "สัตวแพทย์ทั่วไป",
    id: 1457,
  },
  {
    label: "ผู้ศึกษาพันธุศาสตร์",
    id: 1458,
  },
  {
    label: "นักธรณีเคมี",
    id: 1459,
  },
  {
    label: "นักภูมิศาสตร์",
    id: 1460,
  },
  {
    label: "ผู้เชี่ยวชาญด้านระบบข้อมูลทางภูมิศาสตร์",
    id: 1461,
  },
  {
    label: "ครูสอนภูมิศาสตร์",
    id: 1462,
  },
  {
    label: "วิศวกรธรณีวิทยา",
    id: 1463,
  },
  {
    label: "นักธรณีวิทยา",
    id: 1464,
  },
  {
    label: "ช่างเทคนิคธรณีวิทยา",
    id: 1465,
  },
  {
    label: "นักธรณีฟิสิกส์",
    id: 1466,
  },
  {
    label: "นักธรณีเทคนิค",
    id: 1467,
  },
  {
    label: "ผู้ให้บริการโรงไฟฟ้าความร้อนใต้พิภพ",
    id: 1468,
  },
  {
    label: "ผู้ประกอบการงอก",
    id: 1469,
  },
  {
    label: "นักสังคมสงเคราะห์ผู้สูงอายุ",
    id: 1470,
  },
  {
    label: "ช่างแกะสลักแก้ว",
    id: 1471,
  },
  {
    label: "ศิลปินแก้ว",
    id: 1472,
  },
  {
    label: "แก้วแก้ว",
    id: 1473,
  },
  {
    label: "ช่างแกะสลักแก้ว",
    id: 1474,
  },
  {
    label: "ผู้ให้บริการเครื่องจักร",
    id: 1475,
  },
  {
    label: "หัวหน้างานติดตั้งแก้ว",
    id: 1476,
  },
  {
    label: "จิตรกรแก้ว",
    id: 1477,
  },
  {
    label: "เครื่องขัดแก้ว",
    id: 1478,
  },
  {
    label: "เครื่องเป่าแก้ว",
    id: 1479,
  },
  {
    label: "ผู้ผลิตถุงมือ",
    id: 1480,
  },
  {
    label: "ไปพัฒนา",
    id: 1481,
  },
  {
    label: "นักพัฒนา Golang",
    id: 1482,
  },
  {
    label: "ช่างทอง",
    id: 1483,
  },
  {
    label: "ผู้สอนกอล์ฟ",
    id: 1484,
  },
  {
    label: "รัฐบาล",
    id: 1485,
  },
  {
    label: "ผู้ตรวจการวางแผนรัฐบาล",
    id: 1486,
  },
  {
    label: "ผู้ดำเนินการระดับบัณฑิต",
    id: 1487,
  },
  {
    label: "นักเขียนให้",
    id: 1488,
  },
  {
    label: "ผู้ดูแลระบบทุน",
    id: 1489,
  },
  {
    label: "เจ้าหน้าที่จัดการทุน",
    id: 1490,
  },
  {
    label: "ผู้ให้บริการเครื่องจักร Granulator",
    id: 1491,
  },
  {
    label: "นักออกแบบกราฟิก",
    id: 1492,
  },
  {
    label: "ผู้พัฒนากราฟิก",
    id: 1493,
  },
  {
    label: "นักกราฟิก",
    id: 1494,
  },
  {
    label: "ผู้ดำเนินการกด Gravure",
    id: 1495,
  },
  {
    label: "ผู้ที่มีเลศนัย",
    id: 1496,
  },
  {
    label: "ผู้ซื้อกาแฟสีเขียว",
    id: 1497,
  },
  {
    label: "ผู้ประสานงานกาแฟสีเขียว",
    id: 1498,
  },
  {
    label: "ที่ปรึกษาด้าน ICT สีเขียว",
    id: 1499,
  },
  {
    label: "ย่างปรุงอาหาร",
    id: 1500,
  },
  {
    label: "ผู้ให้บริการเครื่องบด",
    id: 1501,
  },
  {
    label: "เจ้าบ่าว",
    id: 1502,
  },
  {
    label: "เจ้าหน้าที่แสงสว่าง",
    id: 1503,
  },
  {
    label: "กราวด์",
    id: 1504,
  },
  {
    label: "พนักงานเสิร์ฟบด/บด",
    id: 1505,
  },
  {
    label: "Groundsman/Groundswoman",
    id: 1506,
  },
  {
    label: "ช่างตรวจสอบน้ำใต้ดิน",
    id: 1507,
  },
  {
    label: "แฮ็กเกอร์การเจริญเติบโต",
    id: 1508,
  },
  {
    label: "ผู้จัดการการเจริญเติบโต",
    id: 1509,
  },
  {
    label: "นักการตลาดที่กำลังเติบโต",
    id: 1510,
  },
  {
    label: "วิศวกรการตลาดการเติบโต",
    id: 1511,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการเติบโต",
    id: 1512,
  },
  {
    label: "ผู้จัดการผู้เข้าพัก",
    id: 1513,
  },
  {
    label: "แนะนำ",
    id: 1514,
  },
  {
    label: "ผู้สอนสุนัขนำทาง",
    id: 1515,
  },
  {
    label: "ผู้ผลิตกีตาร์",
    id: 1516,
  },
  {
    label: "มือปืน",
    id: 1517,
  },
  {
    label: "ช่างเทคนิคการกำจัดขน",
    id: 1518,
  },
  {
    label: "ช่างออกแบบทรงผม",
    id: 1519,
  },
  {
    label: "ช่างทำผม",
    id: 1520,
  },
  {
    label: "ผู้ช่วยช่างทำผม",
    id: 1521,
  },
  {
    label: "อาจารย์สอนวิชาชีพ",
    id: 1522,
  },
  {
    label: "คนขายเนื้อฮาลาล",
    id: 1523,
  },
  {
    label: "ผู้สังหารฮาลาล",
    id: 1524,
  },
  {
    label: "มือ Brick Moulder",
    id: 1525,
  },
  {
    label: "ผู้ให้บริการที่ยั่งยืน",
    id: 1526,
  },
  {
    label: "ผู้ตรวจการกระเป๋าเดินทาง",
    id: 1527,
  },
  {
    label: "แพ็คเกอร์มือ",
    id: 1528,
  },
  {
    label: "ฮาร์ดแวร์และผู้จัดการร้านขายสี",
    id: 1530,
  },
  {
    label: "ผู้ขายฮาร์ดแวร์และสี",
    id: 1531,
  },
  {
    label: "อุปกรณ์ฮาร์ดแวร์ประปาและเครื่องทำความร้อนและอุปกรณ์จัดจำหน่าย",
    id: 1532,
  },
  {
    label: "ชั้นพื้นไม้เนื้อแข็ง",
    id: 1533,
  },
  {
    label: "ผู้ผลิตพิณ",
    id: 1534,
  },
  {
    label: "ผู้ผลิตพิณ",
    id: 1535,
  },
  {
    label: "นักดำน้ำ",
    id: 1536,
  },
  {
    label: "คนหาบเร่",
    id: 1537,
  },
  {
    label: "สารวัตรวัสดุอันตราย",
    id: 1538,
  },
  {
    label: "ผู้ตรวจการของเสียอันตราย",
    id: 1539,
  },
  {
    label: "ช่างเทคนิคขยะอันตราย",
    id: 1540,
  },
  {
    label: "หัวหน้าพ่อครัว",
    id: 1541,
  },
  {
    label: "หัวหน้าปัญญาประดิษฐ์",
    id: 1542,
  },
  {
    label: "หัวหน้าฝ่ายสื่อสาร",
    id: 1543,
  },
  {
    label: "หัวหน้าฝ่ายการตลาดเนื้อหา",
    id: 1544,
  },
  {
    label: "หัวหน้าความสำเร็จของลูกค้า",
    id: 1545,
  },
  {
    label: "หัวหน้าฝ่ายสนับสนุนลูกค้า",
    id: 1546,
  },
  {
    label: "หัวการเจริญเติบโต",
    id: 1547,
  },
  {
    label: "หัวหน้าสถาบันการศึกษาระดับอุดมศึกษา",
    id: 1548,
  },
  {
    label: "หัวหน้าฝ่ายการตลาด",
    id: 1549,
  },
  {
    label: "หัวหน้าคน",
    id: 1550,
  },
  {
    label: "หัวหน้าฝ่ายปฏิบัติการ",
    id: 1551,
  },
  {
    label: "หัวหน้าผลิตภัณฑ์",
    id: 1552,
  },
  {
    label: "หัวหน้าฝ่ายการตลาดผลิตภัณฑ์",
    id: 1553,
  },
  {
    label: "หัวหน้าโซเชียลมีเดีย",
    id: 1554,
  },
  {
    label: "หัวหน้าเวิร์กช็อป",
    id: 1555,
  },
  {
    label: "หัวหน้าพ่อครัวขนม",
    id: 1556,
  },
  {
    label: "หัวซอมเมลิเย่ร์",
    id: 1557,
  },
  {
    label: "หัวหน้าบริกร/พนักงานเสิร์ฟหัว",
    id: 1558,
  },
  {
    label: "ครูใหญ่",
    id: 1559,
  },
  {
    label: "วิศวกรสุขภาพและความปลอดภัย",
    id: 1560,
  },
  {
    label: "ผู้ตรวจสอบสุขภาพและความปลอดภัย",
    id: 1561,
  },
  {
    label: "เจ้าหน้าที่สุขภาพและความปลอดภัย",
    id: 1562,
  },
  {
    label: "นักจิตวิทยาสุขภาพ",
    id: 1563,
  },
  {
    label: "ผู้ช่วยด้านการดูแลสุขภาพ",
    id: 1564,
  },
  {
    label: "ที่ปรึกษาด้านการดูแลสุขภาพ",
    id: 1565,
  },
  {
    label: "ผู้ตรวจการดูแลสุขภาพ",
    id: 1566,
  },
  {
    label: "ผู้จัดการสถาบันการดูแลสุขภาพ",
    id: 1567,
  },
  {
    label: "อาจารย์ผู้เชี่ยวชาญด้านการดูแลสุขภาพ",
    id: 1568,
  },
  {
    label: "คนขับรถราง",
    id: 1569,
  },
  {
    label: "ผู้ประกอบการเครื่องปิดผนึกความร้อน",
    id: 1570,
  },
  {
    label: "ผู้ให้บริการเตาเผาความร้อน",
    id: 1571,
  },
  {
    label: "วิศวกรเครื่องทำความร้อน",
    id: 1572,
  },
  {
    label:
      "เครื่องทำความร้อนการระบายอากาศเครื่องปรับอากาศ (และเครื่องทำความเย็น) Drafter",
    id: 1573,
  },
  {
    label:
      "เครื่องทำความร้อนการระบายอากาศเครื่องปรับอากาศ (และเครื่องทำความเย็น) วิศวกรบริการ",
    id: 1574,
  },
  {
    label:
      "ช่างทำความร้อนเครื่องปรับอากาศเครื่องปรับอากาศและช่างวิศวกรรมเครื่องทำความเย็น",
    id: 1575,
  },
  {
    label: "เครื่องทำความร้อนการระบายอากาศวิศวกรเครื่องปรับอากาศ",
    id: 1576,
  },
  {
    label: "นักบินเฮลิคอปเตอร์",
    id: 1577,
  },
  {
    label: "ผู้ถือหางเสือเรือ",
    id: 1578,
  },
  {
    label: "ผู้จัดการฝ่ายช่วยเหลือ",
    id: 1579,
  },
  {
    label: "ตัวแทนช่วยเหลือ",
    id: 1580,
  },
  {
    label: "ผู้เชี่ยวชาญด้านความช่วยเหลือ",
    id: 1581,
  },
  {
    label: "นักบำบัดสมุนไพร",
    id: 1582,
  },
  {
    label: "ซ่อนนักเรียนระดับประถม",
    id: 1583,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายผลิตภัณฑ์หนังและผลิตภัณฑ์หนัง",
    id: 1584,
  },
  {
    label: "มีความเข้มข้นสูง",
    id: 1585,
  },
  {
    label: "วิทยากรศึกษาระดับอุดมศึกษา",
    id: 1586,
  },
  {
    label: "นักประวัติศาสตร์",
    id: 1587,
  },
  {
    label: "อาจารย์สอนประวัติศาสตร์",
    id: 1588,
  },
  {
    label: "ครูสอนประวัติศาสตร์โรงเรียนมัธยม",
    id: 1589,
  },
  {
    label: "ผู้ช่วยดูแลบ้าน",
    id: 1590,
  },
  {
    label: "ผู้ช่วยดูแลสุขภาพที่บ้านและการดูแลส่วนบุคคล",
    id: 1591,
  },
  {
    label: "ผู้ช่วยด้านสุขภาพที่บ้าน",
    id: 1592,
  },
  {
    label: "คนงานเร่ร่อน",
    id: 1593,
  },
  {
    label: "Homeopath",
    id: 1594,
  },
  {
    label: "ตัวแยกน้ำผึ้ง",
    id: 1595,
  },
  {
    label: "ชาวนาฮอป",
    id: 1596,
  },
  {
    label: "พ่อแม่พันธุ์ม้า",
    id: 1597,
  },
  {
    label: "ผู้สอนขี่ม้า",
    id: 1598,
  },
  {
    label: "ผู้ฝึกสอนม้า",
    id: 1599,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตพืชสวน",
    id: 1600,
  },
  {
    label: "หัวหน้าทีมผลิตพืชสวน",
    id: 1601,
  },
  {
    label: "คนงานพืชสวน",
    id: 1602,
  },
  {
    label: "พยาบาลบ้านพักรับรองพระธุดงค์",
    id: 1603,
  },
  {
    label: "เภสัชกรในโรงพยาบาล",
    id: 1604,
  },
  {
    label: "โรงพยาบาลพอร์เตอร์",
    id: 1605,
  },
  {
    label: "นักสังคมสงเคราะห์โรงพยาบาล",
    id: 1606,
  },
  {
    label: "ผู้จัดการฝ่ายการบริการด้านความบันเทิง",
    id: 1607,
  },
  {
    label: "พนักงานต้อนรับสถานประกอบการด้านการบริการ",
    id: 1608,
  },
  {
    label: "เจ้าหน้าที่รักษาความปลอดภัยสถานประกอบการด้านการบริการ",
    id: 1609,
  },
  {
    label: "ผู้จัดการฝ่ายต้อนรับ",
    id: 1610,
  },
  {
    label: "ผู้จัดการรายได้ด้านการต้อนรับ",
    id: 1611,
  },
  {
    label: "ครูสอนวิชาชีพการโรงแรม",
    id: 1612,
  },
  {
    label: "เป็นเจ้าภาพหรือพนักงานต้อนรับในร้านอาหารเลานจ์หรือร้านกาแฟ",
    id: 1613,
  },
  {
    label: "ผู้ให้บริการฟอยล์ร้อน",
    id: 1614,
  },
  {
    label: "Butler โรงแรม",
    id: 1615,
  },
  {
    label: "เจ้าหน้าที่อำนวยความสะดวกของโรงแรม",
    id: 1616,
  },
  {
    label: "ผู้จัดการโรงแรม",
    id: 1617,
  },
  {
    label: "พนักงานยกกระเป๋า",
    id: 1618,
  },
  {
    label: "ผู้สร้างบ้าน",
    id: 1619,
  },
  {
    label: "ผู้ดูแลบ้าน",
    id: 1620,
  },
  {
    label: "ช่างซ่อมเครื่องใช้ในครัวเรือน",
    id: 1621,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายของใช้ในครัวเรือน",
    id: 1622,
  },
  {
    label: "แม่บ้าน",
    id: 1623,
  },
  {
    label: "หัวหน้างานทำความสะอาด",
    id: 1624,
  },
  {
    label: "ผู้จัดการที่อยู่อาศัย",
    id: 1625,
  },
  {
    label: "เจ้าหน้าที่นโยบายการเคหะ",
    id: 1626,
  },
  {
    label: "พนักงานช่วยเหลือที่อยู่อาศัย",
    id: 1627,
  },
  {
    label: "นักวิเคราะห์ HRIS",
    id: 1628,
  },
  {
    label: "นักพัฒนา HTML5",
    id: 1629,
  },
  {
    label: "ผู้ช่วยทรัพยากรมนุษย์",
    id: 1630,
  },
  {
    label: "ที่ปรึกษาด้านทรัพยากรบุคคล",
    id: 1631,
  },
  {
    label: "ผู้ประสานงานทรัพยากรมนุษย์",
    id: 1632,
  },
  {
    label: "ผู้อำนวยการฝ่ายทรัพยากรบุคคล",
    id: 1633,
  },
  {
    label: "ทรัพยากรมนุษย์ทั่วไป",
    id: 1634,
  },
  {
    label: "ผู้จัดการฝ่ายทรัพยากรบุคคล",
    id: 1635,
  },
  {
    label: "เจ้าหน้าที่ฝ่ายทรัพยากรบุคคล",
    id: 1636,
  },
  {
    label: "ผู้เชี่ยวชาญด้านทรัพยากรมนุษย์",
    id: 1637,
  },
  {
    label: "เจ้าหน้าที่สิทธิมนุษยชน",
    id: 1638,
  },
  {
    label: "ที่ปรึกษาด้านมนุษยธรรม",
    id: 1639,
  },
  {
    label: "นักล่า",
    id: 1640,
  },
  {
    label: "คนงานกดไฮดรอลิก",
    id: 1641,
  },
  {
    label: "ผู้ประกอบการโรงไฟฟ้าพลังน้ำ",
    id: 1642,
  },
  {
    label: "ผู้ประกอบการเครื่องไฮโดรจิเนชัน",
    id: 1643,
  },
  {
    label: "นักอุทกธรณีวิทยา",
    id: 1644,
  },
  {
    label: "ช่างเทคนิคการสำรวจอุทกศาสตร์",
    id: 1645,
  },
  {
    label: "นักสำรวจอุทกศาสตร์",
    id: 1646,
  },
  {
    label: "นักอุทกวิทยา",
    id: 1647,
  },
  {
    label: "วิศวกรไฟฟ้าพลังน้ำ",
    id: 1648,
  },
  {
    label: "ช่างเทคนิคไฟฟ้าพลังน้ำ",
    id: 1649,
  },
  {
    label: "โค้ชสเก็ตน้ำแข็ง",
    id: 1650,
  },
  {
    label: "เครื่องทดสอบการเข้าถึง ICT",
    id: 1651,
  },
  {
    label: "ผู้จัดการบัญชี ICT",
    id: 1652,
  },
  {
    label: "ตัวกำหนดค่าแอปพลิเคชัน ICT",
    id: 1653,
  },
  {
    label: "ผู้พัฒนาแอปพลิเคชัน ICT",
    id: 1654,
  },
  {
    label: "ผู้จัดการผู้ตรวจสอบ ICT",
    id: 1655,
  },
  {
    label: "ผู้จัดการการวิเคราะห์ธุรกิจ ICT",
    id: 1656,
  },
  {
    label: "นักวิเคราะห์ธุรกิจ ICT",
    id: 1657,
  },
  {
    label: "ผู้จัดการฝ่ายพัฒนาธุรกิจ ICT",
    id: 1658,
  },
  {
    label: "ผู้ซื้อ ICT",
    id: 1659,
  },
  {
    label: "นักวางแผนกำลังการผลิต ICT",
    id: 1660,
  },
  {
    label: "ที่ปรึกษาด้านไอซีที",
    id: 1661,
  },
  {
    label: "นักวิเคราะห์การกู้คืน Disaster ICT",
    id: 1662,
  },
  {
    label: "ผู้จัดการเอกสาร ICT",
    id: 1663,
  },
  {
    label: "ผู้จัดการด้านสิ่งแวดล้อม ICT",
    id: 1664,
  },
  {
    label: "ตัวแทนแผนกช่วยเหลือ ICT",
    id: 1665,
  },
  {
    label: "ผู้จัดการแผนกช่วยเหลือ ICT",
    id: 1666,
  },
  {
    label: "ผู้จัดการข้อมูลและความรู้ ICT",
    id: 1667,
  },
  {
    label: "ผู้ทดสอบการรวม ICT",
    id: 1668,
  },
  {
    label: "นักออกแบบระบบอัจฉริยะ ICT",
    id: 1669,
  },
  {
    label: "ผู้ดูแลระบบเครือข่าย ICT",
    id: 1670,
  },
  {
    label: "สถาปนิกเครือข่าย ICT",
    id: 1671,
  },
  {
    label: "วิศวกรเครือข่าย ICT",
    id: 1672,
  },
  {
    label: "ช่างเทคนิคเครือข่าย ICT",
    id: 1673,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการ ICT",
    id: 1674,
  },
  {
    label: "วิศวกร ICT presales",
    id: 1675,
  },
  {
    label: "ผู้จัดการผลิตภัณฑ์ ICT",
    id: 1676,
  },
  {
    label: "ผู้จัดการโครงการ ICT",
    id: 1677,
  },
  {
    label: "ผู้จัดการการประกันคุณภาพ ICT",
    id: 1678,
  },
  {
    label: "ที่ปรึกษาด้านการวิจัย ICT",
    id: 1679,
  },
  {
    label: "ผู้จัดการฝ่ายวิจัย ICT",
    id: 1680,
  },
  {
    label: "ผู้จัดการความยืดหยุ่น ICT",
    id: 1681,
  },
  {
    label: "ผู้ดูแลความปลอดภัย ICT",
    id: 1682,
  },
  {
    label: "ที่ปรึกษาด้านความปลอดภัย ICT",
    id: 1683,
  },
  {
    label: "ผู้จัดการความปลอดภัย ICT",
    id: 1684,
  },
  {
    label: "ช่างเทคนิคการรักษาความปลอดภัย ICT",
    id: 1685,
  },
  {
    label: "ผู้ดูแลระบบ ICT",
    id: 1686,
  },
  {
    label: "นักวิเคราะห์ระบบ ICT",
    id: 1687,
  },
  {
    label: "สถาปนิกระบบ ICT",
    id: 1688,
  },
  {
    label: "นักพัฒนาระบบ ICT",
    id: 1689,
  },
  {
    label: "ที่ปรึกษาการรวมระบบ ICT",
    id: 1690,
  },
  {
    label: "เครื่องทดสอบระบบ ICT",
    id: 1691,
  },
  {
    label: "โรงเรียนมัธยม ICT",
    id: 1692,
  },
  {
    label: "ช่างเทคนิค ICT",
    id: 1693,
  },
  {
    label: "นักวิเคราะห์การทดสอบ ICT",
    id: 1694,
  },
  {
    label: "ผู้ฝึกสอน ICT",
    id: 1695,
  },
  {
    label: "ผู้ทดสอบการใช้งาน ICT",
    id: 1696,
  },
  {
    label: "ผู้จัดการความสัมพันธ์ของผู้ขาย ICT",
    id: 1697,
  },
  {
    label: "ผู้ผลิตเครื่องดนตรี Idiophone",
    id: 1698,
  },
  {
    label: "นักวาดภาพประกอบ",
    id: 1699,
  },
  {
    label: "ภาพ",
    id: 1700,
  },
  {
    label: "ที่ปรึกษาด้านการเข้าเมือง",
    id: 1701,
  },
  {
    label: "เจ้าหน้าที่ตรวจคนเข้าเมือง",
    id: 1702,
  },
  {
    label: "เจ้าหน้าที่นโยบายการเข้าเมือง",
    id: 1703,
  },
  {
    label: "นักภูมิคุ้มกันบกพร่อง",
    id: 1704,
  },
  {
    label: "นำเข้าผู้จัดการส่งออก",
    id: 1705,
  },
  {
    label: "นำเข้าผู้จัดการฝ่ายส่งออกในเครื่องจักรและอุปกรณ์การเกษตร",
    id: 1706,
  },
  {
    label: "ผู้จัดการนำเข้าส่งออกในวัตถุดิบเกษตรเมล็ดและอาหารสัตว์",
    id: 1707,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในเครื่องดื่ม",
    id: 1708,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในผลิตภัณฑ์เคมี",
    id: 1709,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในประเทศจีนและเครื่องแก้วอื่น ๆ",
    id: 1710,
  },
  {
    label: "นำเข้าผู้จัดการฝ่ายส่งออกในเสื้อผ้าและรองเท้า",
    id: 1711,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในกาแฟชาโกโก้และเครื่องเทศ",
    id: 1712,
  },
  {
    label:
      "นำเข้าผู้จัดการส่งออกในคอมพิวเตอร์อุปกรณ์อุปกรณ์ต่อพ่วงคอมพิวเตอร์และซอฟต์แวร์",
    id: 1713,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในผลิตภัณฑ์นมและน้ำมันที่กินได้",
    id: 1714,
  },
  {
    label: "ผู้จัดการการนำเข้าส่งออกในเครื่องใช้ไฟฟ้าในครัวเรือน",
    id: 1715,
  },
  {
    label:
      "นำเข้าผู้จัดการส่งออกในอุปกรณ์และชิ้นส่วนโทรคมนาคมและการสื่อสารโทรคมนาคม",
    id: 1716,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในปลากุ้งและหอย",
    id: 1717,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในดอกไม้และพืช",
    id: 1718,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในผักและผลไม้",
    id: 1719,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในเฟอร์นิเจอร์พรมและอุปกรณ์ส่องสว่าง",
    id: 1720,
  },
  {
    label:
      "นำเข้าผู้จัดการส่งออกในฮาร์ดแวร์อุปกรณ์ประปาและอุปกรณ์ทำความร้อนและอุปกรณ์เสริม",
    id: 1721,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในหนังสกินและผลิตภัณฑ์หนัง",
    id: 1722,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในของใช้ในครัวเรือน",
    id: 1723,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในสัตว์มีชีวิต",
    id: 1724,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในเครื่องมือเครื่องจักร",
    id: 1725,
  },
  {
    label:
      "นำเข้าผู้จัดการส่งออกในเครื่องจักรอุปกรณ์อุตสาหกรรมเรือและเครื่องบิน",
    id: 1726,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในผลิตภัณฑ์เนื้อสัตว์และเนื้อสัตว์",
    id: 1727,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในโลหะและแร่โลหะ",
    id: 1728,
  },
  {
    label: "นำเข้าผู้จัดการฝ่ายส่งออกในการทำเหมืองการก่อสร้างและวิศวกรรมโยธา",
    id: 1729,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในเฟอร์นิเจอร์สำนักงาน",
    id: 1730,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในเครื่องจักรและอุปกรณ์",
    id: 1731,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในน้ำหอมและเครื่องสำอาง",
    id: 1732,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในสินค้าเวชภัณฑ์",
    id: 1733,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในน้ำตาลช็อคโกแลตและน้ำตาล",
    id: 1734,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในเครื่องจักรอุตสาหกรรมสิ่งทอ",
    id: 1735,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในสิ่งทอและสิ่งทอกึ่งสำเร็จรูปและวัตถุดิบ",
    id: 1736,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในผลิตภัณฑ์ยาสูบ",
    id: 1737,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในของเสียและเศษ",
    id: 1738,
  },
  {
    label: "นำเข้าผู้จัดการส่งออกในนาฬิกาและอัญมณี",
    id: 1739,
  },
  {
    label: "ผู้จัดการนำเข้าจากวัสดุไม้และวัสดุก่อสร้าง",
    id: 1740,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้า",
    id: 1741,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในปลากุ้งและหอย",
    id: 1742,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้าด้านเครื่องจักรและอุปกรณ์การเกษตร",
    id: 1743,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้าในวัตถุดิบเกษตรเมล็ดและอาหารสัตว์",
    id: 1744,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเครื่องดื่ม",
    id: 1745,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในผลิตภัณฑ์เคมี",
    id: 1746,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในประเทศจีนและเครื่องแก้วอื่น ๆ",
    id: 1747,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเสื้อผ้าและรองเท้า",
    id: 1748,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในกาแฟชาโกโก้และเครื่องเทศ",
    id: 1749,
  },
  {
    label:
      "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในคอมพิวเตอร์อุปกรณ์ต่อพ่วงและซอฟต์แวร์",
    id: 1750,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้าผลิตภัณฑ์นมและน้ำมันที่กินได้",
    id: 1751,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้าในเครื่องใช้ไฟฟ้าในครัวเรือน",
    id: 1752,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในอุปกรณ์อิเล็กทรอนิกส์และโทรคมนาคม",
    id: 1753,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในดอกไม้และพืช",
    id: 1754,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในผักและผลไม้",
    id: 1755,
  },
  {
    label:
      "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเฟอร์นิเจอร์พรมและอุปกรณ์ส่องสว่าง",
    id: 1756,
  },
  {
    label:
      "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในอุปกรณ์ฮาร์ดแวร์ประปาและเครื่องทำความร้อน",
    id: 1757,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในหนังสกินและผลิตภัณฑ์หนัง",
    id: 1758,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้าในของใช้ในครัวเรือน",
    id: 1759,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในสัตว์มีชีวิต",
    id: 1760,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเครื่องมือเครื่องจักร",
    id: 1761,
  },
  {
    label:
      "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเครื่องจักรอุปกรณ์อุตสาหกรรมเรือและเครื่องบิน",
    id: 1762,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในผลิตภัณฑ์เนื้อสัตว์และเนื้อสัตว์",
    id: 1763,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในโลหะและแร่โลหะ",
    id: 1764,
  },
  {
    label:
      "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในการทำเหมืองการก่อสร้างเครื่องจักรวิศวกรรมโยธา",
    id: 1765,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเฟอร์นิเจอร์สำนักงาน",
    id: 1766,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเครื่องจักรและอุปกรณ์",
    id: 1767,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในน้ำหอมและเครื่องสำอาง",
    id: 1768,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกในสินค้าเภสัชกรรม",
    id: 1769,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในน้ำตาลช็อคโกแลตและน้ำตาล",
    id: 1770,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในเครื่องจักรอุตสาหกรรมสิ่งทอ",
    id: 1771,
  },
  {
    label:
      "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในสิ่งทอและสิ่งทอกึ่งสำเร็จรูปและวัตถุดิบ",
    id: 1772,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในผลิตภัณฑ์ยาสูบ",
    id: 1773,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในของเสียและเศษซาก",
    id: 1774,
  },
  {
    label: "นำเข้าผู้เชี่ยวชาญด้านการส่งออกในนาฬิกาและอัญมณี",
    id: 1775,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการส่งออกนำเข้าด้านไม้และวัสดุก่อสร้าง",
    id: 1776,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดขาเข้า",
    id: 1777,
  },
  {
    label: "ผู้บริหารบัญชีการขายขาเข้า",
    id: 1778,
  },
  {
    label: "ผู้ให้บริการเตาเผาขยะ",
    id: 1779,
  },
  {
    label: "ครูอาชีวศึกษาศิลปะอุตสาหกรรม",
    id: 1780,
  },
  {
    label: "หัวหน้างานสมัชชาอุตสาหกรรม",
    id: 1781,
  },
  {
    label: "ปรุงอาหารอุตสาหกรรม",
    id: 1782,
  },
  {
    label: "นักออกแบบอุตสาหกรรม",
    id: 1783,
  },
  {
    label: "ช่างไฟฟ้าอุตสาหกรรม",
    id: 1784,
  },
  {
    label: "วิศวกรรมอุตสาหการ",
    id: 1785,
  },
  {
    label: "ช่างเทคนิควิศวกรรมศาสตร์",
    id: 1786,
  },
  {
    label: "นักดับเพลิงอุตสาหกรรม",
    id: 1787,
  },
  {
    label: "ผู้ประกอบการเครื่องจักรอุตสาหกรรม",
    id: 1788,
  },
  {
    label: "ช่างเครื่อง",
    id: 1789,
  },
  {
    label: "หัวหน้างานบำรุงรักษาอุตสาหกรรม",
    id: 1790,
  },
  {
    label: "นักพัฒนาซอฟต์แวร์อุปกรณ์พกพาอุตสาหกรรม",
    id: 1791,
  },
  {
    label: "เภสัชกรอุตสาหกรรม",
    id: 1792,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตอุตสาหกรรม",
    id: 1793,
  },
  {
    label: "ผู้จัดการคุณภาพอุตสาหกรรม",
    id: 1794,
  },
  {
    label: "คอนโทรลเลอร์หุ่นยนต์อุตสาหกรรม",
    id: 1795,
  },
  {
    label: "วิศวกรออกแบบเครื่องมืออุตสาหกรรม",
    id: 1796,
  },
  {
    label: "ผู้ตรวจการขยะอุตสาหกรรม",
    id: 1797,
  },
  {
    label: "ทหารราบ",
    id: 1798,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดที่มีอิทธิพล",
    id: 1799,
  },
  {
    label: "ผู้จัดการข้อมูล",
    id: 1800,
  },
  {
    label: "ตัวแทนการประมวลผลข้อมูล",
    id: 1801,
  },
  {
    label: "ผู้อำนวยการด้านความปลอดภัยข้อมูล",
    id: 1802,
  },
  {
    label: "วิศวกรความปลอดภัยข้อมูล",
    id: 1803,
  },
  {
    label: "ผู้ประกอบการฉีดขึ้นรูป",
    id: 1804,
  },
  {
    label: "ผู้จัดการทั่วไปด้านการขนส่งทางน้ำภายในประเทศ",
    id: 1805,
  },
  {
    label: "Inside Inside Manager",
    id: 1806,
  },
  {
    label: "ตัวแทนขายภายใน",
    id: 1807,
  },
  {
    label: "ภายในพนักงานขาย",
    id: 1808,
  },
  {
    label: "วิศวกรการติดตั้ง",
    id: 1809,
  },
  {
    label: "นักออกแบบการเรียนการสอน",
    id: 1810,
  },
  {
    label: "ช่างเทคนิคเครื่องมือ",
    id: 1811,
  },
  {
    label: "วิศวกรเครื่องมือวัด",
    id: 1812,
  },
  {
    label: "ช่างเทคนิควิศวกรรมเครื่องมือวัด",
    id: 1813,
  },
  {
    label: "Winder หลอดฉนวน",
    id: 1814,
  },
  {
    label: "ผู้ดูแลฉนวน",
    id: 1815,
  },
  {
    label: "คนงานฉนวน",
    id: 1816,
  },
  {
    label: "ผู้จัดการ บริษัท ประกันภัย",
    id: 1817,
  },
  {
    label: "ตัวแทนประกัน",
    id: 1818,
  },
  {
    label: "นายหน้าประกันภัย",
    id: 1819,
  },
  {
    label: "เสมียนการเรียกร้องค่าประกันภัย",
    id: 1820,
  },
  {
    label: "ตัวจัดการการเรียกร้องค่าสินไหมทดแทน",
    id: 1821,
  },
  {
    label: "ผู้จัดการการเรียกร้องค่าประกันภัย",
    id: 1822,
  },
  {
    label: "เสมียนประกัน",
    id: 1823,
  },
  {
    label: "นักสะสมประกันภัย",
    id: 1824,
  },
  {
    label: "ผู้ตรวจสอบการฉ้อโกงประกันภัย",
    id: 1825,
  },
  {
    label: "ผู้จัดการผลิตภัณฑ์ประกันภัย",
    id: 1826,
  },
  {
    label: "นักวิเคราะห์การจัดอันดับประกันภัย",
    id: 1827,
  },
  {
    label: "ที่ปรึกษาด้านความเสี่ยงด้านการประกันภัย",
    id: 1828,
  },
  {
    label: "ตัวแทนขายประกันภัย",
    id: 1829,
  },
  {
    label: "ตัวแทนขายประกันภัย",
    id: 1830,
  },
  {
    label: "ผู้จัดจำหน่ายประกันภัย",
    id: 1831,
  },
  {
    label: "วิศวกรออกแบบวงจรรวม",
    id: 1832,
  },
  {
    label: "วิศวกรรวม",
    id: 1833,
  },
  {
    label: "ที่ปรึกษาด้านทรัพย์สินทางปัญญา",
    id: 1834,
  },
  {
    label: "ที่ปรึกษาด้านทรัพย์สินทางปัญญา",
    id: 1835,
  },
  {
    label: "Interceptor",
    id: 1836,
  },
  {
    label: "เจ้าหน้าที่ข่าวกรอง",
    id: 1837,
  },
  {
    label: "วิศวกรแสงอัจฉริยะ",
    id: 1838,
  },
  {
    label: "นักออกแบบปฏิสัมพันธ์",
    id: 1839,
  },
  {
    label: "ที่ปรึกษาด้านการสื่อสารระหว่างวัฒนธรรม",
    id: 1840,
  },
  {
    label: "สถาปนิกภายใน",
    id: 1841,
  },
  {
    label: "นักออกแบบตกแต่งภายใน",
    id: 1842,
  },
  {
    label: "ช่างซ่อมตกแต่งภายใน",
    id: 1843,
  },
  {
    label: "ผู้วางแผนภายใน",
    id: 1844,
  },
  {
    label: "ผู้จัดการโลจิสติกส์ระหว่างกัน",
    id: 1845,
  },
  {
    label: "ผู้ตรวจสอบภายใน",
    id: 1846,
  },
  {
    label: "ผู้ประสานงานการส่งต่อระหว่างประเทศ",
    id: 1847,
  },
  {
    label: "เจ้าหน้าที่ความสัมพันธ์ระหว่างประเทศ",
    id: 1848,
  },
  {
    label: "ผู้ประสานงานแลกเปลี่ยนนักศึกษาต่างชาติ",
    id: 1849,
  },
  {
    label: "ผู้จัดการหน่วยงานตีความ",
    id: 1850,
  },
  {
    label: "ล่าม",
    id: 1851,
  },
  {
    label: "ผู้ประสานงานสินค้าคงคลัง",
    id: 1852,
  },
  {
    label: "ผู้จัดการสินค้าคงคลัง",
    id: 1853,
  },
  {
    label: "ที่ปรึกษาการลงทุน",
    id: 1854,
  },
  {
    label: "นักวิเคราะห์การลงทุน",
    id: 1855,
  },
  {
    label: "เสมียนการลงทุน",
    id: 1856,
  },
  {
    label: "ผู้ช่วยการจัดการกองทุนการลงทุน",
    id: 1857,
  },
  {
    label: "ผู้จัดการกองทุนการลงทุน",
    id: 1858,
  },
  {
    label: "ผู้จัดการการลงทุน",
    id: 1859,
  },
  {
    label: "ผู้จัดการฝ่ายลงทุน",
    id: 1860,
  },
  {
    label: "นักพัฒนาอิออน",
    id: 1861,
  },
  {
    label: "นักพัฒนา iOS",
    id: 1862,
  },
  {
    label: "ตัวติดตั้งระบบชลประทาน",
    id: 1863,
  },
  {
    label: "ช่างชลประทาน",
    id: 1864,
  },
  {
    label: "ผู้สอบบัญชีด้านไอที",
    id: 1865,
  },
  {
    label: "ที่ปรึกษาด้านไอที",
    id: 1866,
  },
  {
    label: "ผู้ประสานงาน",
    id: 1867,
  },
  {
    label: "ผู้อำนวยการฝ่ายไอที",
    id: 1868,
  },
  {
    label: "มันเป็นช่างเทคนิค",
    id: 1869,
  },
  {
    label: "นักข่าวไอที",
    id: 1870,
  },
  {
    label: "ผู้จัดการฝ่ายไอที",
    id: 1871,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการดำเนินงานด้านไอที",
    id: 1872,
  },
  {
    label: "ช่างไอที",
    id: 1873,
  },
  {
    label: "นักพัฒนา j2ee",
    id: 1874,
  },
  {
    label: "ภารโรง",
    id: 1875,
  },
  {
    label: "ภารโรง/ทำความสะอาด",
    id: 1876,
  },
  {
    label: "นักพัฒนา Java",
    id: 1877,
  },
  {
    label: "นักพัฒนาจาวาสคริปต์",
    id: 1878,
  },
  {
    label: "เครื่องเพชรพลอย",
    id: 1879,
  },
  {
    label: "ผู้จัดการร้านเครื่องประดับและนาฬิกา",
    id: 1880,
  },
  {
    label: "เครื่องประดับและนาฬิกาผู้ขายพิเศษ",
    id: 1881,
  },
  {
    label: "เครื่องประดับอัญมณี",
    id: 1882,
  },
  {
    label: "นักออกแบบเครื่องประดับ",
    id: 1883,
  },
  {
    label: "ช่างแกะสลักเครื่องประดับ",
    id: 1884,
  },
  {
    label: "เครื่องประดับ",
    id: 1885,
  },
  {
    label: "เครื่องประดับ",
    id: 1886,
  },
  {
    label: "ช่างซ่อมเครื่องประดับ",
    id: 1887,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการวิเคราะห์งาน",
    id: 1888,
  },
  {
    label: "โค้ชงาน",
    id: 1889,
  },
  {
    label: "วารสารศาสตร์",
    id: 1890,
  },
  {
    label: "นักข่าว",
    id: 1891,
  },
  {
    label: "นักพัฒนา jQuery",
    id: 1892,
  },
  {
    label: "ผู้พิพากษา",
    id: 1893,
  },
  {
    label: "นักบัญชีจูเนียร์",
    id: 1894,
  },
  {
    label: "ความยุติธรรมของสันติภาพ",
    id: 1895,
  },
  {
    label: "เจ้าหน้าที่ราชทัณฑ์เยาวชน",
    id: 1896,
  },
  {
    label: "ผู้ควบคุมดูแลสุนัข",
    id: 1897,
  },
  {
    label: "คนงานสุนัข",
    id: 1898,
  },
  {
    label: "กาต้มน้ำนุ่ม",
    id: 1899,
  },
  {
    label: "ผู้จัดการบัญชีหลัก",
    id: 1900,
  },
  {
    label: "ผู้ผลิตเครื่องดนตรีคีย์บอร์ด",
    id: 1901,
  },
  {
    label: "Kiln Firer",
    id: 1902,
  },
  {
    label: "ครูสอนอนุบาล",
    id: 1903,
  },
  {
    label: "นักคณิตศาสตร์",
    id: 1904,
  },
  {
    label: "ผู้จัดการห้องครัวและห้องน้ำ",
    id: 1905,
  },
  {
    label: "ผู้ช่วยในครัว",
    id: 1906,
  },
  {
    label: "ห้องครัว",
    id: 1907,
  },
  {
    label: "ตัวติดตั้งหน่วยครัว",
    id: 1908,
  },
  {
    label: "คนถัก",
    id: 1909,
  },
  {
    label: "ผู้ประกอบการเครื่องถัก",
    id: 1910,
  },
  {
    label: "หัวหน้างานเครื่องถัก",
    id: 1911,
  },
  {
    label: "ช่างทอผ้าถักนิตติ้ง",
    id: 1912,
  },
  {
    label: "วิศวกรความรู้",
    id: 1913,
  },
  {
    label: "คนขายเนื้อโคเชอร์",
    id: 1914,
  },
  {
    label: "ผู้สังหารโคเชอร์",
    id: 1915,
  },
  {
    label: "นักพัฒนา Kotlin",
    id: 1916,
  },
  {
    label: "นักพัฒนา Kubernetes",
    id: 1917,
  },
  {
    label: "ผู้ช่วยห้องปฏิบัติการ",
    id: 1918,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการ",
    id: 1919,
  },
  {
    label: "เจ้าหน้าที่นโยบายตลาดแรงงาน",
    id: 1920,
  },
  {
    label: "เจ้าหน้าที่แรงงานสัมพันธ์",
    id: 1921,
  },
  {
    label: "เครื่องทำแล็คเกอร์",
    id: 1922,
  },
  {
    label: "ผู้ให้บริการปืนพ่น",
    id: 1923,
  },
  {
    label: "ผู้ให้บริการเครื่องลามิเนต",
    id: 1924,
  },
  {
    label: "ผู้วางแผนที่ดิน",
    id: 1925,
  },
  {
    label: "นักสำรวจที่ดิน",
    id: 1926,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรที่ใช้ที่ดิน",
    id: 1927,
  },
  {
    label: "หัวหน้างานเครื่องจักร",
    id: 1928,
  },
  {
    label: "ช่างเทคนิคเครื่องจักรที่ใช้ที่ดิน",
    id: 1929,
  },
  {
    label: "ผู้ดูแลหลุมฝังกลบ",
    id: 1930,
  },
  {
    label: "สถาปนิกภูมิทัศน์",
    id: 1931,
  },
  {
    label: "นักออกแบบภูมิทัศน์",
    id: 1932,
  },
  {
    label: "สวนภูมิทัศน์",
    id: 1933,
  },
  {
    label: "โค้ชภาษา",
    id: 1934,
  },
  {
    label: "วิศวกรภาษา",
    id: 1935,
  },
  {
    label: "ครูสอนภาษา",
    id: 1936,
  },
  {
    label: "นักพัฒนา Laravel",
    id: 1937,
  },
  {
    label: "ช่างเชื่อมลำแสงเลเซอร์",
    id: 1938,
  },
  {
    label: "ผู้ให้บริการเครื่องตัดเลเซอร์",
    id: 1939,
  },
  {
    label: "ผู้ประกอบการเครื่องทำเครื่องหมายเลเซอร์",
    id: 1940,
  },
  {
    label: "ผู้ให้บริการเครื่องที่ยั่งยืน",
    id: 1941,
  },
  {
    label: "เครื่องกลึงและเครื่องกลึงเครื่องจักร",
    id: 1942,
  },
  {
    label: "ผู้ดูแลเครื่องซักผ้า",
    id: 1943,
  },
  {
    label: "ผู้จัดการซักผ้าและซักแห้ง",
    id: 1944,
  },
  {
    label: "เครื่องซักผ้า",
    id: 1945,
  },
  {
    label: "คนงานซักรีด",
    id: 1946,
  },
  {
    label: "หัวหน้างานซักรีด",
    id: 1947,
  },
  {
    label: "ผู้อำนวยการกฎหมายและนโยบาย",
    id: 1948,
  },
  {
    label: "ผู้ปฏิบัติงานด้านกฎหมาย",
    id: 1949,
  },
  {
    label: "อาจารย์กฎหมาย",
    id: 1950,
  },
  {
    label: "ทนายความ",
    id: 1951,
  },
  {
    label: "นักกฎหมาย",
    id: 1952,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสร้างตะกั่ว",
    id: 1953,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเลี้ยงดู",
    id: 1954,
  },
  {
    label: "ตัวขูดใบ",
    id: 1955,
  },
  {
    label: "ชั้นใบ",
    id: 1956,
  },
  {
    label: "ผู้จัดจำหน่ายใบปลิว",
    id: 1957,
  },
  {
    label: "นักออกแบบการเรียนรู้",
    id: 1958,
  },
  {
    label: "นักออกแบบประสบการณ์การเรียนรู้",
    id: 1959,
  },
  {
    label: "ที่ปรึกษาการเรียนรู้",
    id: 1960,
  },
  {
    label: "ครูสนับสนุนการเรียนรู้",
    id: 1961,
  },
  {
    label: "ตัวแทนเช่าซื้อ",
    id: 1962,
  },
  {
    label: "ที่ปรึกษาเช่าซื้อ",
    id: 1963,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการการตกแต่งด้วยหนัง",
    id: 1964,
  },
  {
    label: "ผู้ให้บริการตกแต่งหนัง",
    id: 1965,
  },
  {
    label: "เครื่องหนังช่างฝีมือ",
    id: 1966,
  },
  {
    label: "เครื่องทำหนัง CAD ลวดลาย",
    id: 1967,
  },
  {
    label: "นักออกแบบเครื่องหนัง",
    id: 1968,
  },
  {
    label: "เครื่องหนังเครื่องหนัง",
    id: 1969,
  },
  {
    label: "ผู้ให้บริการตัดมือเครื่องหนัง",
    id: 1970,
  },
  {
    label: "เครื่องใส่มือเครื่องหนังตะเข็บมือ",
    id: 1971,
  },
  {
    label: "วิศวกรอุตสาหกรรมเครื่องหนัง",
    id: 1972,
  },
  {
    label: "ผู้ให้บริการเครื่องหนังเครื่องหนัง",
    id: 1973,
  },
  {
    label: "ช่างซ่อมบำรุงเครื่องหนัง",
    id: 1974,
  },
  {
    label: "ผู้ประกอบการด้วยตนเอง",
    id: 1975,
  },
  {
    label: "ช่างการผลิตเครื่องหนัง",
    id: 1976,
  },
  {
    label: "ผู้ประกอบการบรรจุสินค้าเครื่องหนัง",
    id: 1977,
  },
  {
    label: "เครื่องทำรูปแบบเครื่องหนัง",
    id: 1978,
  },
  {
    label: "นักพัฒนาผลิตภัณฑ์เครื่องหนัง",
    id: 1979,
  },
  {
    label: "ผู้จัดการพัฒนาผลิตภัณฑ์เครื่องหนัง",
    id: 1980,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตเครื่องหนัง",
    id: 1981,
  },
  {
    label: "หัวหน้างานผลิตเครื่องหนัง",
    id: 1982,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการควบคุมคุณภาพเครื่องหนัง",
    id: 1983,
  },
  {
    label: "คอนโทรลเลอร์คุณภาพสินค้าเครื่องหนัง",
    id: 1984,
  },
  {
    label: "ผู้จัดการคุณภาพเครื่องหนังคุณภาพ",
    id: 1985,
  },
  {
    label: "ช่างเทคนิคคุณภาพเครื่องหนัง",
    id: 1986,
  },
  {
    label: "ผู้ให้บริการเครื่องเย็บเครื่องหนัง",
    id: 1987,
  },
  {
    label: "ผู้ให้บริการคลังสินค้าเครื่องหนัง",
    id: 1988,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการเครื่องหนัง",
    id: 1989,
  },
  {
    label: "ผู้ให้บริการเครื่องวัดหนัง",
    id: 1990,
  },
  {
    label: "ผู้ให้บริการเครื่องหนัง",
    id: 1991,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตเครื่องหนัง",
    id: 1992,
  },
  {
    label: "วางแผนการผลิตเครื่องหนัง",
    id: 1993,
  },
  {
    label: "ผู้จัดการซื้อวัตถุดิบหนัง",
    id: 1994,
  },
  {
    label: "เครื่องทำหนัง",
    id: 1995,
  },
  {
    label: "ผู้จัดการแผนกประมวลผลหนังเปียก",
    id: 1996,
  },
  {
    label: "ผู้ช่วยด้านกฎหมาย",
    id: 1997,
  },
  {
    label: "ผู้ช่วยเหลือทางกฎหมาย",
    id: 1998,
  },
  {
    label: "ที่ปรึกษาด้านกฎหมาย",
    id: 1999,
  },
  {
    label: "ที่ปรึกษาทางกฎหมาย",
    id: 2000,
  },
  {
    label: "ผู้ปกครองตามกฎหมาย",
    id: 2001,
  },
  {
    label: "เจ้าหน้าที่นโยบายกฎหมาย",
    id: 2002,
  },
  {
    label: "เลขานุการกฎหมาย",
    id: 2003,
  },
  {
    label: "ผู้จัดการฝ่ายบริการด้านกฎหมาย",
    id: 2004,
  },
  {
    label: "DRAFTER",
    id: 2005,
  },
  {
    label: "ผู้ดูแลการพักผ่อน",
    id: 2006,
  },
  {
    label: "ตัวแทนให้",
    id: 2007,
  },
  {
    label: "สัญญาณข้ามระดับ",
    id: 2008,
  },
  {
    label: "ผู้ทำพจนานุกรม",
    id: 2009,
  },
  {
    label: "บรรณารักษ์",
    id: 2010,
  },
  {
    label: "ผู้ช่วยห้องสมุด",
    id: 2011,
  },
  {
    label: "ผู้จัดการห้องสมุด",
    id: 2012,
  },
  {
    label: "แพทย์ที่ได้รับใบอนุญาต",
    id: 2013,
  },
  {
    label: "พยาบาลปฏิบัติที่ได้รับใบอนุญาต (LPN)",
    id: 2014,
  },
  {
    label: "ผู้จัดการใบอนุญาต",
    id: 2015,
  },
  {
    label: "เจ้าหน้าที่ใบอนุญาต",
    id: 2016,
  },
  {
    label: "ผู้หมวด",
    id: 2017,
  },
  {
    label: "ผู้จัดการการจัดจำหน่าย Life & Health",
    id: 2018,
  },
  {
    label: "โค้ชชีวิต",
    id: 2019,
  },
  {
    label: "ผู้พิทักษ์ชีวิต",
    id: 2020,
  },
  {
    label: "ผู้สอนทหารรักษาพระองค์",
    id: 2021,
  },
  {
    label: "ยกวิศวกร",
    id: 2022,
  },
  {
    label: "ยกผู้ดูแลการติดตั้ง",
    id: 2023,
  },
  {
    label: "ผู้ประกอบการบอร์ดเบา",
    id: 2024,
  },
  {
    label: "นักออกแบบแสงสว่าง",
    id: 2025,
  },
  {
    label: "ผู้อำนวยการแสงสว่าง",
    id: 2026,
  },
  {
    label: "ช่างแสง",
    id: 2027,
  },
  {
    label: "ผู้ประกอบการเตาเผามะนาว",
    id: 2028,
  },
  {
    label: "cook line cook",
    id: 2029,
  },
  {
    label: "ผู้ดูแลห้องผ้าลินิน",
    id: 2030,
  },
  {
    label: "นักภาษาศาสตร์",
    id: 2031,
  },
  {
    label: "อาจารย์สอนภาษาศาสตร์",
    id: 2032,
  },
  {
    label: "ผู้ดูแลระบบ Linux",
    id: 2033,
  },
  {
    label: "นักพัฒนากราฟิก Linux",
    id: 2034,
  },
  {
    label: "วิศวกรเชื้อเพลิงเหลว",
    id: 2035,
  },
  {
    label: "ผู้ประกอบการโรงงานบำบัดของเสีย",
    id: 2036,
  },
  {
    label: "เครื่องปั่นสุรา",
    id: 2037,
  },
  {
    label: "ผู้ประกอบการโรงงานบดสุรา",
    id: 2038,
  },
  {
    label: "นักวิชาการวรรณกรรม",
    id: 2039,
  },
  {
    label: "ครูสอนวรรณกรรม",
    id: 2040,
  },
  {
    label: "นักพิมพ์หิน",
    id: 2041,
  },
  {
    label: "ผู้ฟ้องคดี",
    id: 2042,
  },
  {
    label: "ผู้ขนส่งสัตว์มีชีวิต",
    id: 2043,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายสัตว์มีชีวิต",
    id: 2044,
  },
  {
    label: "ผู้ให้บริการแชทสด",
    id: 2045,
  },
  {
    label: "ที่ปรึกษาปศุสัตว์",
    id: 2046,
  },
  {
    label: "คนงานปศุสัตว์",
    id: 2047,
  },
  {
    label: "เจ้าหน้าที่สินเชื่อ",
    id: 2048,
  },
  {
    label: "ตัวประมวลผลเงินกู้",
    id: 2049,
  },
  {
    label: "คนท้องถิ่น",
    id: 2050,
  },
  {
    label: "ผู้จัดการสถานที่",
    id: 2051,
  },
  {
    label: "ผู้ดูแลห้องล็อกเกอร์",
    id: 2052,
  },
  {
    label: "ช่างทำกุญแจ",
    id: 2053,
  },
  {
    label: "นักวิเคราะห์โลจิสติกส์",
    id: 2054,
  },
  {
    label: "ผู้จัดการด้านโลจิสติกส์และการกระจาย",
    id: 2055,
  },
  {
    label: "ผู้ประสานงานด้านโลจิสติกส์",
    id: 2056,
  },
  {
    label: "วิศวกรโลจิสติกส์",
    id: 2057,
  },
  {
    label: "ผู้จัดการโลจิสติกส์",
    id: 2058,
  },
  {
    label: "ตัวปรับการสูญเสีย",
    id: 2059,
  },
  {
    label: "แคชเชียร์ลอตเตอรี",
    id: 2060,
  },
  {
    label: "ผู้จัดการลอตเตอรี",
    id: 2061,
  },
  {
    label: "ผู้ให้บริการลอตเตอรี",
    id: 2062,
  },
  {
    label: "คนตัดไม้",
    id: 2063,
  },
  {
    label: "นักแต่งเพลง",
    id: 2064,
  },
  {
    label: "วิศวกรการเรียนรู้ของเครื่องจักร",
    id: 2065,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเรียนรู้ของเครื่องจักร",
    id: 2066,
  },
  {
    label: "หัวหน้าผู้ปฏิบัติงานเครื่องจักร",
    id: 2067,
  },
  {
    label: "ผู้ประสานงานการประกอบเครื่องจักร",
    id: 2068,
  },
  {
    label: "หัวหน้างานประกอบเครื่องจักร",
    id: 2069,
  },
  {
    label:
      "เครื่องจักรอุปกรณ์อุตสาหกรรมเรือและผู้จัดการการจัดจำหน่ายเครื่องบิน",
    id: 2070,
  },
  {
    label: "ช่างเครื่อง",
    id: 2071,
  },
  {
    label: "นักพัฒนา MacOS",
    id: 2072,
  },
  {
    label: "ผู้ผลิตบทความสิ่งทอที่สร้างขึ้น",
    id: 2073,
  },
  {
    label: "บรรณาธิการนิตยสาร",
    id: 2074,
  },
  {
    label: "นักพัฒนา Magento 2",
    id: 2075,
  },
  {
    label: "ผู้จัดการโครงการ Ecommerce Magento",
    id: 2076,
  },
  {
    label: "แม่บ้าน",
    id: 2077,
  },
  {
    label: "เสมียนจดหมาย",
    id: 2078,
  },
  {
    label: "วิศวกรการบำรุงรักษาและซ่อมแซม",
    id: 2079,
  },
  {
    label: "ผู้จัดการซ่อมบำรุง",
    id: 2080,
  },
  {
    label: "หัวหน้างานบำรุงรักษา",
    id: 2081,
  },
  {
    label: "ช่างซ่อมบำรุง",
    id: 2082,
  },
  {
    label: "เครื่องสำอางและนักออกแบบผม",
    id: 2084,
  },
  {
    label: "ช่างแต่งหน้า",
    id: 2085,
  },
  {
    label: "ช่างแต่งหน้า",
    id: 2086,
  },
  {
    label: "หัวหน้าบ้านมอลต์",
    id: 2087,
  },
  {
    label: "ผู้ประกอบการมอลต์เตาเผา",
    id: 2088,
  },
  {
    label: "นายมอลต์",
    id: 2089,
  },
  {
    label: "นักวิจัยมัลแวร์",
    id: 2090,
  },
  {
    label: "สปินเนอร์ไฟเบอร์ที่มนุษย์สร้างขึ้น",
    id: 2091,
  },
  {
    label: "นักบัญชีการจัดการ",
    id: 2092,
  },
  {
    label: "นักวิเคราะห์การจัดการ",
    id: 2093,
  },
  {
    label: "ผู้ช่วยผู้จัดการ",
    id: 2094,
  },
  {
    label: "กรรมการผู้จัดการ",
    id: 2095,
  },
  {
    label: "บรรณาธิการบริหาร",
    id: 2096,
  },
  {
    label: "ช่างทำเล็บ",
    id: 2097,
  },
  {
    label: "ประกอบอาคารไม้ที่ผลิตขึ้น",
    id: 2098,
  },
  {
    label: "ตัวประมาณต้นทุนการผลิต",
    id: 2099,
  },
  {
    label: "วิศวกรรมการผลิต",
    id: 2100,
  },
  {
    label: "ผู้จัดการโรงงานผลิต",
    id: 2101,
  },
  {
    label: "ผู้จัดการฝ่ายผลิต",
    id: 2102,
  },
  {
    label: "ผู้ตรวจการทางทะเล",
    id: 2103,
  },
  {
    label: "หัวหน้าวิศวกรทางทะเล",
    id: 2104,
  },
  {
    label: "ช่างไฟฟ้าทางทะเล",
    id: 2105,
  },
  {
    label: "ช่างเทคนิคอิเล็กทรอนิกส์ทางทะเล",
    id: 2106,
  },
  {
    label: "วิศวกรทางทะเล",
    id: 2107,
  },
  {
    label: "Drafter วิศวกรรมทางทะเล",
    id: 2108,
  },
  {
    label: "ช่างเทคนิควิศวกรรมทางทะเล",
    id: 2109,
  },
  {
    label: "นักดับเพลิงทางทะเล",
    id: 2110,
  },
  {
    label: "ช่างเดินเรือ",
    id: 2111,
  },
  {
    label: "นักสำรวจทางทะเล",
    id: 2112,
  },
  {
    label: "ช่างทำเบาะนาวิกโยธิน",
    id: 2113,
  },
  {
    label: "ผู้สอนการเดินเรือ",
    id: 2114,
  },
  {
    label: "นักบินทางทะเล",
    id: 2115,
  },
  {
    label: "ผู้จัดการทั่วไปด้านการขนส่งทางน้ำทางทะเล",
    id: 2116,
  },
  {
    label: "นักวิเคราะห์การวิจัยตลาด",
    id: 2117,
  },
  {
    label: "ผู้สัมภาษณ์ตลาด",
    id: 2118,
  },
  {
    label: "ผู้ขายตลาด",
    id: 2119,
  },
  {
    label: "นักการตลาด",
    id: 2120,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดและการขาย",
    id: 2121,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการวิเคราะห์การตลาด",
    id: 2122,
  },
  {
    label: "ผู้ช่วยการตลาด",
    id: 2123,
  },
  {
    label: "ผู้ช่วยการตลาด",
    id: 2124,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดอัตโนมัติ",
    id: 2125,
  },
  {
    label: "ที่ปรึกษาด้านการตลาด",
    id: 2126,
  },
  {
    label: "นักเขียนคำโฆษณาทางการตลาด",
    id: 2127,
  },
  {
    label: "ผู้อำนวยการฝ่ายการตลาด",
    id: 2128,
  },
  {
    label: "ผู้บริหารการตลาด",
    id: 2129,
  },
  {
    label: "แฮ็กเกอร์การเติบโตทางการตลาด",
    id: 2130,
  },
  {
    label: "การตลาดฝึกงาน",
    id: 2131,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาด",
    id: 2132,
  },
  {
    label: "นักวิเคราะห์การตลาด",
    id: 2133,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาด",
    id: 2134,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาด",
    id: 2135,
  },
  {
    label: "นักยุทธศาสตร์การตลาด",
    id: 2136,
  },
  {
    label: "ที่ปรึกษาการแต่งงาน",
    id: 2137,
  },
  {
    label: "ผู้ผลิตหน้ากาก",
    id: 2138,
  },
  {
    label: "นักบำบัดการนวด",
    id: 2139,
  },
  {
    label: "หมอนวด/หมอนวด",
    id: 2140,
  },
  {
    label: "คั่วกาแฟหลัก",
    id: 2141,
  },
  {
    label: "วิศวกรการเรียนรู้",
    id: 2142,
  },
  {
    label: "ผู้เชี่ยวชาญด้านความเสียหายวัสดุ",
    id: 2143,
  },
  {
    label: "นักวิเคราะห์ความเครียดวัสดุ",
    id: 2144,
  },
  {
    label: "ช่างเทคนิคการทดสอบวัสดุ",
    id: 2145,
  },
  {
    label: "วิศวกรวัสดุ",
    id: 2146,
  },
  {
    label: "เครื่องจัดการวัสดุ",
    id: 2147,
  },
  {
    label: "ผู้จัดการวัสดุ",
    id: 2148,
  },
  {
    label: "พนักงานสนับสนุนการคลอดบุตร",
    id: 2149,
  },
  {
    label: "นักคณิตศาสตร์",
    id: 2150,
  },
  {
    label: "อาจารย์คณิตศาสตร์",
    id: 2151,
  },
  {
    label: "โรงเรียนคณิตศาสตร์โรงเรียนมัธยม",
    id: 2152,
  },
  {
    label: "ผู้สอนวิชาคณิตศาสตร์",
    id: 2153,
  },
  {
    label: "matrose",
    id: 2154,
  },
  {
    label: "ผู้ผลิตที่นอน",
    id: 2155,
  },
  {
    label: "ผู้ประกอบการเครื่องทำที่นอน",
    id: 2156,
  },
  {
    label: "หมายถึงนักพัฒนาสแต็ก",
    id: 2157,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายผลิตภัณฑ์เนื้อสัตว์และเนื้อสัตว์",
    id: 2158,
  },
  {
    label: "ผู้จัดการร้านขายเนื้อสัตว์และเนื้อสัตว์",
    id: 2159,
  },
  {
    label: "ผู้ขายเนื้อสัตว์และเนื้อสัตว์",
    id: 2160,
  },
  {
    label: "เครื่องตัดเนื้อสัตว์",
    id: 2161,
  },
  {
    label: "ผู้ประกอบการเตรียมเนื้อสัตว์",
    id: 2162,
  },
  {
    label: "วิศวกรเครื่องกล",
    id: 2163,
  },
  {
    label: "วิศวกรรมเครื่องกล Drafter",
    id: 2164,
  },
  {
    label: "ช่างเทคนิควิศวกรรมเครื่องกล",
    id: 2165,
  },
  {
    label: "เครื่องกดเครื่องจักรกลด้วยเครื่องจักร",
    id: 2166,
  },
  {
    label: "แอสเซมเบลอร์ Mechatronics",
    id: 2167,
  },
  {
    label: "วิศวกร Mechatronics",
    id: 2168,
  },
  {
    label: "ช่างเทคนิควิศวกรรมเมคคาทรอนิกส์",
    id: 2169,
  },
  {
    label: "ผู้จัดการฝ่ายสื่อและนักวิเคราะห์ความสัมพันธ์",
    id: 2170,
  },
  {
    label: "ผู้อำนวยการด้านสื่อ",
    id: 2171,
  },
  {
    label: "นักวิทยาศาสตร์สื่อ",
    id: 2172,
  },
  {
    label: "ผู้จัดการการไกล่เกลี่ยและการศึกษา",
    id: 2173,
  },
  {
    label: "ผู้ไกล่เกลี่ย",
    id: 2174,
  },
  {
    label: "ผู้ช่วยดูแลการแพทย์",
    id: 2175,
  },
  {
    label: "ผู้ช่วยแพทย์",
    id: 2176,
  },
  {
    label: "นักเขียน",
    id: 2177,
  },
  {
    label: "ผู้ตรวจสอบรหัสทางการแพทย์",
    id: 2178,
  },
  {
    label: "เครื่องประกอบอุปกรณ์การแพทย์",
    id: 2179,
  },
  {
    label: "วิศวกรอุปกรณ์การแพทย์",
    id: 2180,
  },
  {
    label: "ช่างเทคนิควิศวกรรมอุปกรณ์การแพทย์",
    id: 2181,
  },
  {
    label: "ผู้อำนวยการด้านการแพทย์",
    id: 2182,
  },
  {
    label: "ผู้จัดการร้านขายสินค้าทางการแพทย์",
    id: 2183,
  },
  {
    label: "สินค้าทางการแพทย์ผู้ขายเฉพาะ",
    id: 2184,
  },
  {
    label: "ผู้ช่วยห้องปฏิบัติการทางการแพทย์",
    id: 2185,
  },
  {
    label: "ผู้จัดการห้องปฏิบัติการทางการแพทย์",
    id: 2186,
  },
  {
    label: "ครูสอนอาชีพด้านเทคโนโลยีห้องปฏิบัติการทางการแพทย์",
    id: 2187,
  },
  {
    label: "ผู้จัดการสำนักงานแพทย์",
    id: 2188,
  },
  {
    label: "ผู้จัดการฝึกปฏิบัติทางการแพทย์",
    id: 2189,
  },
  {
    label: "นักวิเคราะห์เวชระเบียน",
    id: 2190,
  },
  {
    label: "เวชระเบียนเสมียน",
    id: 2191,
  },
  {
    label: "ผู้จัดการเวชระเบียน",
    id: 2192,
  },
  {
    label: "ตัวแทนขายทางการแพทย์",
    id: 2193,
  },
  {
    label: "นักถอดเสียงทางการแพทย์",
    id: 2194,
  },
  {
    label: "อาจารย์แพทย์",
    id: 2195,
  },
  {
    label: "ปานกลาง",
    id: 2196,
  },
  {
    label: "การประชุมการประชุมและการวางแผนกิจกรรม",
    id: 2197,
  },
  {
    label: "ผู้ดูแลระบบสมาชิก",
    id: 2198,
  },
  {
    label: "ผู้จัดการสมาชิก",
    id: 2199,
  },
  {
    label: "Membranophone Musical Instruments Maker",
    id: 2200,
  },
  {
    label: "นักสังคมสงเคราะห์สุขภาพจิต",
    id: 2201,
  },
  {
    label: "ผู้ปฏิบัติงานด้านสุขภาพจิต",
    id: 2202,
  },
  {
    label: "ผู้ขายสินค้า",
    id: 2203,
  },
  {
    label: "นักวิเคราะห์การควบรวมและซื้อกิจการ",
    id: 2204,
  },
  {
    label: "ผู้สื่อสาร",
    id: 2205,
  },
  {
    label: "ช่างแกะสลักโลหะ",
    id: 2206,
  },
  {
    label: "ผู้ประกอบการเครื่องวาดภาพโลหะ",
    id: 2207,
  },
  {
    label: "ช่างแกะสลักโลหะ",
    id: 2208,
  },
  {
    label: "ผู้ให้บริการเตาเผาโลหะ",
    id: 2209,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรโลหะเฟอร์นิเจอร์",
    id: 2210,
  },
  {
    label: "ผู้ให้บริการตอดโลหะ",
    id: 2211,
  },
  {
    label: "ผู้ให้บริการโลหะ",
    id: 2212,
  },
  {
    label: "เครื่องขัดโลหะ",
    id: 2213,
  },
  {
    label: "ผู้ตรวจการควบคุมคุณภาพผลิตภัณฑ์โลหะ",
    id: 2214,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตโลหะ",
    id: 2215,
  },
  {
    label: "หัวหน้างานผลิตโลหะ",
    id: 2216,
  },
  {
    label: "แอสเซมเบลอร์ผลิตภัณฑ์โลหะ",
    id: 2217,
  },
  {
    label: "ผู้ให้บริการโรงสีโลหะกลิ้งโลหะ",
    id: 2218,
  },
  {
    label: "ผู้ประกอบการเครื่องเลื่อยโลหะ",
    id: 2219,
  },
  {
    label: "ผู้จัดการฝ่ายโลหะ",
    id: 2220,
  },
  {
    label: "ช่างโลหะวิทยา",
    id: 2221,
  },
  {
    label: "นักโลหะวิทยา",
    id: 2222,
  },
  {
    label: "Metals และ Metal Ores Distribution Manager",
    id: 2223,
  },
  {
    label: "เครื่องกลึงโลหะ",
    id: 2224,
  },
  {
    label: "นักอุตุนิยมวิทยา",
    id: 2225,
  },
  {
    label: "ช่างอุตุนิยมวิทยา",
    id: 2226,
  },
  {
    label: "เครื่องอ่านมิเตอร์",
    id: 2227,
  },
  {
    label: "นักแพทย์",
    id: 2228,
  },
  {
    label: "ช่างมาตรวิทยา",
    id: 2229,
  },
  {
    label: "นักจุลชีววิทยา",
    id: 2230,
  },
  {
    label: "วิศวกรไมโครอิเล็กทรอนิกส์",
    id: 2231,
  },
  {
    label: "ช่างเทคนิควิศวกรรมอิเล็กทรอนิกส์",
    id: 2232,
  },
  {
    label: "วิศวกร Microsystem",
    id: 2233,
  },
  {
    label: "ช่างเทคนิควิศวกรรมระบบ Microsystem",
    id: 2234,
  },
  {
    label: "นักวิเคราะห์สำนักงานกลาง",
    id: 2235,
  },
  {
    label: "นักพัฒนามิดเดิลแวร์",
    id: 2236,
  },
  {
    label: "ผดุงครรภ์",
    id: 2237,
  },
  {
    label: "นักสังคมสงเคราะห์",
    id: 2238,
  },
  {
    label: "วิศวกรทหาร",
    id: 2239,
  },
  {
    label: "คนงานสวัสดิการทหาร",
    id: 2240,
  },
  {
    label: "ผู้ประกอบการกระบวนการบำบัดความร้อนนม",
    id: 2241,
  },
  {
    label: "ผู้ประกอบการรับนม",
    id: 2242,
  },
  {
    label: "มิลเลอร์",
    id: 2243,
  },
  {
    label: "มิลลิกรัม",
    id: 2244,
  },
  {
    label: "ผู้ประกอบการเครื่องกัด",
    id: 2245,
  },
  {
    label: "ผู้ให้บริการห้องควบคุมเหมือง",
    id: 2246,
  },
  {
    label: "วิศวกรพัฒนาเหมือง",
    id: 2247,
  },
  {
    label: "วิศวกรไฟฟ้าเหมือง",
    id: 2248,
  },
  {
    label: "นักธรณีวิทยาของฉัน",
    id: 2249,
  },
  {
    label: "วิศวกรสุขภาพและความปลอดภัยของฉัน",
    id: 2250,
  },
  {
    label: "ผู้จัดการเหมือง",
    id: 2251,
  },
  {
    label: "วิศวกรเครื่องกลของฉัน",
    id: 2252,
  },
  {
    label: "วิศวกรวางแผนเหมือง",
    id: 2253,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตเหมือง",
    id: 2254,
  },
  {
    label: "เจ้าหน้าที่กู้ภัยเหมือง",
    id: 2255,
  },
  {
    label: "เจ้าหน้าที่ความปลอดภัยของเหมือง",
    id: 2256,
  },
  {
    label: "Mine Shift Manager",
    id: 2257,
  },
  {
    label: "หัวหน้างานเหมือง",
    id: 2258,
  },
  {
    label: "ช่างสำรวจเหมือง",
    id: 2259,
  },
  {
    label: "นักสำรวจเหมืองแร่",
    id: 2260,
  },
  {
    label: "วิศวกรระบายอากาศของฉัน",
    id: 2261,
  },
  {
    label: "ผู้ให้บริการบดแร่",
    id: 2262,
  },
  {
    label: "วิศวกรประมวลผลแร่",
    id: 2263,
  },
  {
    label: "ผู้ดำเนินการประมวลผลแร่",
    id: 2264,
  },
  {
    label: "นักขุดแร่",
    id: 2265,
  },
  {
    label: "นักออกแบบชุดขนาดเล็ก",
    id: 2266,
  },
  {
    label: "ผู้ช่วยขุด",
    id: 2267,
  },
  {
    label: "ช่างไฟฟ้าเหมืองแร่",
    id: 2268,
  },
  {
    label: "ช่างอุปกรณ์เหมืองแร่",
    id: 2269,
  },
  {
    label: "วิศวกร Geotechnical Mining",
    id: 2270,
  },
  {
    label:
      "ผู้จัดการฝ่ายจัดจำหน่ายเครื่องจักรการทำเหมืองการก่อสร้างและวิศวกรรมโยธา",
    id: 2271,
  },
  {
    label: "รัฐมนตรีว่าการกระทรวงศาสนา",
    id: 2272,
  },
  {
    label: "ผู้สอนศาสนา",
    id: 2273,
  },
  {
    label: "ชาวนาผสม",
    id: 2274,
  },
  {
    label: "วิศวกรมือถือและท่อส่ง",
    id: 2275,
  },
  {
    label: "ผู้พัฒนาแอปพลิเคชันมือถือ",
    id: 2276,
  },
  {
    label: "ผู้ให้บริการรถเครนมือถือ",
    id: 2277,
  },
  {
    label: "นักพัฒนามือถือ",
    id: 2278,
  },
  {
    label: "ช่างอุปกรณ์มือถือ",
    id: 2279,
  },
  {
    label: "ช่างซ่อมโทรศัพท์มือถือ",
    id: 2280,
  },
  {
    label: "ผู้สร้างนางแบบ",
    id: 2281,
  },
  {
    label: "อาจารย์สอนภาษาสมัยใหม่",
    id: 2282,
  },
  {
    label: "โรงเรียนสอนภาษาสมัยใหม่โรงเรียนมัธยมศึกษา",
    id: 2283,
  },
  {
    label: "นักพัฒนา MongoDB",
    id: 2284,
  },
  {
    label: "พระ/แม่ชี",
    id: 2285,
  },
  {
    label: "ครูโรงเรียนมอนเตสซอรี่",
    id: 2286,
  },
  {
    label: "นายหน้าจำนอง",
    id: 2287,
  },
  {
    label: "หน่วยประมวลผลสินเชื่อจำนอง",
    id: 2288,
  },
  {
    label: "ผู้จัดจำหน่ายสินเชื่อจำนอง",
    id: 2289,
  },
  {
    label: "นักพัฒนาภาพยนตร์ภาพยนตร์",
    id: 2290,
  },
  {
    label: "ยานยนต์ Aftersales Manager",
    id: 2291,
  },
  {
    label: "เครื่องประกอบยานยนต์",
    id: 2292,
  },
  {
    label: "ผู้ตรวจการประกอบยานยนต์",
    id: 2293,
  },
  {
    label: "หัวหน้างานประกอบยานยนต์",
    id: 2294,
  },
  {
    label: "ผู้ประกอบร่างกายยานยนต์",
    id: 2295,
  },
  {
    label: "เครื่องยนต์เครื่องยนต์ยานยนต์",
    id: 2296,
  },
  {
    label: "ผู้ตรวจการเครื่องยนต์ยานยนต์",
    id: 2297,
  },
  {
    label: "เครื่องทดสอบเครื่องยนต์ยานยนต์",
    id: 2298,
  },
  {
    label: "เครื่องประกอบชิ้นส่วนยานยนต์",
    id: 2299,
  },
  {
    label: "ผู้จัดการร้านค้ายานยนต์",
    id: 2300,
  },
  {
    label: "เครื่องทำเบาะยานยนต์",
    id: 2301,
  },
  {
    label: "ที่ปรึกษาชิ้นส่วนยานยนต์",
    id: 2302,
  },
  {
    label: "ยานยนต์ผู้ขายพิเศษ",
    id: 2303,
  },
  {
    label: "แอสเซมเบลอร์มอเตอร์ไซค์",
    id: 2304,
  },
  {
    label: "ผู้ส่งรถจักรยานยนต์",
    id: 2305,
  },
  {
    label: "ผู้สอนมอเตอร์ไซค์",
    id: 2306,
  },
  {
    label: "ผู้ประกอบการเครื่องขึ้นรูป",
    id: 2307,
  },
  {
    label: "ช่างเทคนิคเครื่องขึ้นรูป",
    id: 2308,
  },
  {
    label: "ผู้สร้าง Mouldmaker",
    id: 2309,
  },
  {
    label: "คู่มือภูเขา",
    id: 2310,
  },
  {
    label: "ย้ายผู้ประสานงาน",
    id: 2311,
  },
  {
    label: "ย้ายผู้จัดการ",
    id: 2312,
  },
  {
    label: "ผู้เสนอญัตติ",
    id: 2313,
  },
  {
    label: "ผู้จัดจำหน่ายภาพยนตร์",
    id: 2314,
  },
  {
    label: "คนขับรถบรรทุกเคลื่อนที่",
    id: 2315,
  },
  {
    label: "คนตัดโคลน",
    id: 2316,
  },
  {
    label: "ผู้อำนวยการพิพิธภัณฑ์",
    id: 2317,
  },
  {
    label: "นักวิทยาศาสตร์พิพิธภัณฑ์",
    id: 2318,
  },
  {
    label: "ช่างเทคนิคพิพิธภัณฑ์",
    id: 2319,
  },
  {
    label: "ผู้จัดการเพลงและวิดีโอร้านวิดีโอ",
    id: 2320,
  },
  {
    label: "ผู้ขายเพลงและร้านวิดีโอ",
    id: 2321,
  },
  {
    label: "เครื่องแต่งเพลง",
    id: 2322,
  },
  {
    label: "ผู้กำกับเพลง",
    id: 2323,
  },
  {
    label: "ผู้สอนดนตรี",
    id: 2324,
  },
  {
    label: "ผู้ผลิตเพลง",
    id: 2325,
  },
  {
    label: "ครูสอนดนตรี",
    id: 2326,
  },
  {
    label: "โรงเรียนสอนดนตรีโรงเรียนมัธยม",
    id: 2327,
  },
  {
    label: "นักดนตรีบำบัด",
    id: 2328,
  },
  {
    label: "ผู้ควบคุมวงดนตรี",
    id: 2329,
  },
  {
    label: "ช่างเทคนิคดนตรี",
    id: 2330,
  },
  {
    label: "นักดนตรี",
    id: 2331,
  },
  {
    label: "นายหน้ากองทุนรวม",
    id: 2332,
  },
  {
    label: "นักพัฒนา MySQL",
    id: 2333,
  },
  {
    label: "ผู้ให้บริการเครื่องตอกตะปู",
    id: 2334,
  },
  {
    label: "พี่เลี้ยงเด็ก",
    id: 2335,
  },
  {
    label: "เครื่องนาโน",
    id: 2336,
  },
  {
    label: "ผู้จัดการบัญชีแห่งชาติ",
    id: 2337,
  },
  {
    label: "ผู้จัดการฝ่ายขายแห่งชาติ",
    id: 2338,
  },
  {
    label: "นักพัฒนามือถือพื้นเมือง",
    id: 2339,
  },
  {
    label: "ที่ปรึกษาด้านทรัพยากรธรรมชาติ",
    id: 2340,
  },
  {
    label: "เจ้าหน้าที่อนุรักษ์ธรรมชาติ",
    id: 2341,
  },
  {
    label: "สถาปนิกกองทัพเรือ",
    id: 2342,
  },
  {
    label: "กองทัพเรือ",
    id: 2343,
  },
  {
    label: "ผู้ดูแลระบบเครือข่าย",
    id: 2344,
  },
  {
    label: "วิศวกรเครือข่าย",
    id: 2345,
  },
  {
    label: "ผู้จัดการเครือข่าย",
    id: 2346,
  },
  {
    label: "นักการตลาดเครือข่าย",
    id: 2347,
  },
  {
    label: "ช่างเทคนิคเครือข่าย",
    id: 2348,
  },
  {
    label: "ผู้ประกาศข่าว",
    id: 2349,
  },
  {
    label: "ผู้ผลิตข่าว",
    id: 2350,
  },
  {
    label: "นักเขียนข่าวนักข่าวหรือนักข่าว",
    id: 2351,
  },
  {
    label: "บรรณาธิการหนังสือพิมพ์",
    id: 2352,
  },
  {
    label: "ผู้สอบบัญชีกลางคืน",
    id: 2353,
  },
  {
    label: "ผู้ให้บริการไนเตรท",
    id: 2354,
  },
  {
    label: "nitroglycerin neutraliser",
    id: 2355,
  },
  {
    label: "ตัวแยก Nitroglycerin",
    id: 2356,
  },
  {
    label: "ผู้พัฒนา Node.js",
    id: 2357,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการทดสอบแบบไม่ทำลาย",
    id: 2358,
  },
  {
    label: "ผู้ให้บริการทั่วไปที่ไม่ใช่เรือ",
    id: 2359,
  },
  {
    label: "ช่างสิ่งทอที่ไม่ว่องแวก",
    id: 2360,
  },
  {
    label: "ผู้ประกอบการเครื่องจักรเส้นใยแบบวูฟเวน",
    id: 2361,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรหลักแบบวูฟเวน",
    id: 2362,
  },
  {
    label: "นักพัฒนา NOSQL",
    id: 2363,
  },
  {
    label: "ทนายความ",
    id: 2364,
  },
  {
    label: "วิศวกรนิวเคลียร์",
    id: 2365,
  },
  {
    label: "นักถ่ายภาพรังสีเวชศาสตร์นิวเคลียร์",
    id: 2366,
  },
  {
    label: "ผู้ประกอบการเครื่องปฏิกรณ์นิวเคลียร์",
    id: 2367,
  },
  {
    label: "ช่างเทคนิคนิวเคลียร์",
    id: 2368,
  },
  {
    label: "เครื่องมือเชิงตัวเลขและโปรแกรมเมอร์ควบคุมกระบวนการ",
    id: 2369,
  },
  {
    label: "พยาบาล",
    id: 2370,
  },
  {
    label: "ผู้ช่วยพยาบาล",
    id: 2371,
  },
  {
    label: "ผู้ตรวจสอบพยาบาล",
    id: 2372,
  },
  {
    label: "ผู้จัดการกรณีพยาบาล",
    id: 2373,
  },
  {
    label: "ผู้จัดการพยาบาล",
    id: 2374,
  },
  {
    label: "ผู้ประกอบการพยาบาล",
    id: 2375,
  },
  {
    label: "พยาบาลรับผิดชอบการดูแลทั่วไป",
    id: 2376,
  },
  {
    label: "ครูใหญ่โรงเรียนอนุบาล",
    id: 2377,
  },
  {
    label: "อาจารย์พยาบาล",
    id: 2378,
  },
  {
    label: "หัวหน้างานพยาบาล",
    id: 2379,
  },
  {
    label: "ครูสอนการพยาบาล",
    id: 2380,
  },
  {
    label: "นักโภชนาการ",
    id: 2381,
  },
  {
    label: "นักวิเคราะห์อาชีพ",
    id: 2382,
  },
  {
    label: "ผู้สอนการขับขี่อาชีพ",
    id: 2383,
  },
  {
    label: "ผู้ตรวจสอบอาชีวอนามัยและความปลอดภัย",
    id: 2384,
  },
  {
    label: "อาจารย์ผู้สอนรถไฟ",
    id: 2385,
  },
  {
    label: "นักกิจกรรมบำบัด",
    id: 2386,
  },
  {
    label: "ผู้ช่วยกิจกรรมบำบัด",
    id: 2387,
  },
  {
    label: "นักมหาสมุทร",
    id: 2388,
  },
  {
    label: "คอมไพเลอร์",
    id: 2389,
  },
  {
    label: "นักวิทยา",
    id: 2390,
  },
  {
    label: "ผู้ดูแลสำนักงาน",
    id: 2391,
  },
  {
    label: "ผู้ช่วยสำนักงาน",
    id: 2392,
  },
  {
    label: "เสมียนสำนักงาน",
    id: 2393,
  },
  {
    label: "ผู้ประสานงานสำนักงาน",
    id: 2394,
  },
  {
    label: "ช่างซ่อมอุปกรณ์สำนักงาน",
    id: 2395,
  },
  {
    label: "ผู้จัดการสำนักงาน",
    id: 2396,
  },
  {
    label: "สัตวแพทย์",
    id: 2397,
  },
  {
    label: "เครื่องพิมพ์ชดเชย",
    id: 2398,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตน้ำมันและก๊าซ",
    id: 2399,
  },
  {
    label: "ผู้ให้บริการโรงสีน้ำมัน",
    id: 2400,
  },
  {
    label: "ผู้ให้บริการห้องควบคุมน้ำมันโรงกลั่นน้ำมัน",
    id: 2401,
  },
  {
    label: "มอเตอร์แท่นขุดเจาะน้ำมัน",
    id: 2402,
  },
  {
    label: "เครื่องกดน้ำมัน",
    id: 2403,
  },
  {
    label: "ผู้ตรวจการแผ่นดิน",
    id: 2404,
  },
  {
    label: "On Foot Aquatic Resources Collector",
    id: 2405,
  },
  {
    label: "ผู้จัดการโครงการ Onboarding",
    id: 2406,
  },
  {
    label: "ผู้เชี่ยวชาญการจองออนไลน์",
    id: 2407,
  },
  {
    label: "หัวหน้าทีมแชทออนไลน์",
    id: 2408,
  },
  {
    label: "ผู้จัดการชุมชนออนไลน์",
    id: 2409,
  },
  {
    label: "ครูสอนภาษาอังกฤษออนไลน์",
    id: 2410,
  },
  {
    label: "นักการตลาดออนไลน์",
    id: 2411,
  },
  {
    label: "ผู้จัดการช่องทางการขายออนไลน์",
    id: 2412,
  },
  {
    label: "พยาบาลห้องผ่าตัด",
    id: 2413,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการ",
    id: 2414,
  },
  {
    label: "นักวิเคราะห์การวิจัยการดำเนินงาน",
    id: 2415,
  },
  {
    label: "ผู้ประกอบการเครื่องจักรแบบออพติคอลดิสก์",
    id: 2416,
  },
  {
    label: "วิศวกรออพติคอล",
    id: 2417,
  },
  {
    label: "เครื่องประกอบเครื่องมือออพติคอล",
    id: 2418,
  },
  {
    label: "หัวหน้างานการผลิตเครื่องมือออพติคอล",
    id: 2419,
  },
  {
    label: "ผู้ซ่อมเครื่องมือออพติคอล",
    id: 2420,
  },
  {
    label: "ช่างเทคนิคแสง",
    id: 2421,
  },
  {
    label: "ช่างแว่นตา",
    id: 2422,
  },
  {
    label: "วิศวกร Optoelectronic",
    id: 2423,
  },
  {
    label: "ช่างเทคนิควิศวกรรมออพโตอิเล็กทรอนิกส์",
    id: 2424,
  },
  {
    label: "วิศวกร Optomechanical",
    id: 2425,
  },
  {
    label: "ช่างเทคนิควิศวกรรมออพโตเมคนิก",
    id: 2426,
  },
  {
    label: "นักตรวจสายตา",
    id: 2427,
  },
  {
    label: "สั่งซื้อ",
    id: 2428,
  },
  {
    label: "ลูกเรือธรรมดา",
    id: 2429,
  },
  {
    label: "ผู้สร้างอวัยวะ",
    id: 2430,
  },
  {
    label: "คนงานโลหะประดับ",
    id: 2431,
  },
  {
    label: "ช่างเทคนิครองเท้าศัลยกรรมกระดูก",
    id: 2432,
  },
  {
    label: "Orthopaedic Supplies ผู้ขายพิเศษ",
    id: 2433,
  },
  {
    label: "ผู้จัดการร้านค้าศัลยกรรมกระดูก",
    id: 2434,
  },
  {
    label: "นักออร์โธปิสต์",
    id: 2435,
  },
  {
    label: "osteopath",
    id: 2436,
  },
  {
    label: "ตัวแทนขายขาออก",
    id: 2437,
  },
  {
    label: "ผู้ประสานงานกิจกรรมกลางแจ้ง",
    id: 2438,
  },
  {
    label: "ผู้สอนกิจกรรมกลางแจ้ง",
    id: 2439,
  },
  {
    label: "อนิเมเตอร์กลางแจ้ง",
    id: 2440,
  },
  {
    label: "ผู้ป่วยนอก",
    id: 2441,
  },
  {
    label: "Outreach and Partnerships Manager",
    id: 2442,
  },
  {
    label: "ผู้ปฏิบัติงานสายเหนือศีรษะ",
    id: 2443,
  },
  {
    label: "ผู้ประกอบการเครื่องเผาผลาญน้ำมันเชื้อเพลิง",
    id: 2444,
  },
  {
    label: "ผู้ให้บริการบรรจุภัณฑ์และเครื่องเติมเต็ม",
    id: 2445,
  },
  {
    label: "ผู้จัดการการผลิตบรรจุภัณฑ์",
    id: 2446,
  },
  {
    label: "วิศวกรเครื่องจักรบรรจุภัณฑ์",
    id: 2447,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดที่ชำระเงิน",
    id: 2448,
  },
  {
    label: "เครื่องผสมสี",
    id: 2449,
  },
  {
    label: "จิตรกร",
    id: 2450,
  },
  {
    label: "นักบรรพชีวินวิทยา",
    id: 2451,
  },
  {
    label: "นักสังคมสงเคราะห์การดูแลแบบประคับประคอง",
    id: 2452,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรถุงกระดาษ",
    id: 2453,
  },
  {
    label: "ผู้ประกอบการตัดกระดาษ",
    id: 2454,
  },
  {
    label: "กระดาษที่รวบรวมผู้ให้บริการกด",
    id: 2455,
  },
  {
    label: "วิศวกรกระดาษ",
    id: 2456,
  },
  {
    label: "ผู้ให้บริการเครื่องกระดาษ",
    id: 2457,
  },
  {
    label: "หัวหน้างานโรงสี",
    id: 2458,
  },
  {
    label: "ผู้ประกอบการปั้นเยื่อกระดาษ",
    id: 2459,
  },
  {
    label: "ผู้ให้บริการเครื่องเขียนกระดาษ",
    id: 2460,
  },
  {
    label: "Assembler ผลิตภัณฑ์กระดาษบอร์ด",
    id: 2461,
  },
  {
    label: "กระดาษ",
    id: 2462,
  },
  {
    label: "หัวหน้างาน Paperhanger",
    id: 2463,
  },
  {
    label: "เกี่ยวกับทัศนัย",
    id: 2464,
  },
  {
    label: "แพทย์",
    id: 2465,
  },
  {
    label: "แพทย์ในการตอบสนองฉุกเฉิน",
    id: 2466,
  },
  {
    label: "คู่มืออุทยาน",
    id: 2467,
  },
  {
    label: "ที่จอดรถ",
    id: 2468,
  },
  {
    label: "ผู้ช่วยรัฐสภา",
    id: 2469,
  },
  {
    label: "คอนโทรลเลอร์ผู้โดยสาร",
    id: 2470,
  },
  {
    label: "เจ้าหน้าที่หนังสือเดินทาง",
    id: 2471,
  },
  {
    label: "ผู้ผลิตพาสต้า",
    id: 2472,
  },
  {
    label: "ผู้ให้บริการพาสต้า",
    id: 2473,
  },
  {
    label: "คนงานอภิบาล",
    id: 2474,
  },
  {
    label: "พ่อครัวขนม",
    id: 2475,
  },
  {
    label: "ผู้ผลิตขนม",
    id: 2476,
  },
  {
    label: "วิศวกรสิทธิบัตร",
    id: 2477,
  },
  {
    label: "ผู้ประสานงานการดูแลผู้ป่วย",
    id: 2478,
  },
  {
    label: "ช่างเทคนิคการดูแลผู้ป่วย",
    id: 2479,
  },
  {
    label: "คนขับบริการขนส่งผู้ป่วย",
    id: 2480,
  },
  {
    label: "โรงรับจำนำ",
    id: 2481,
  },
  {
    label: "พนักงานจ่ายเงินเดือนและเวลา",
    id: 2482,
  },
  {
    label: "พนักงานบัญชีเงินเดือน",
    id: 2483,
  },
  {
    label: "ผู้เชี่ยวชาญด้านเงินเดือน",
    id: 2484,
  },
  {
    label: "คนทำเล็บเท้า",
    id: 2485,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการทดสอบการเจาะ",
    id: 2486,
  },
  {
    label: "ผู้จัดการโครงการบำนาญ",
    id: 2487,
  },
  {
    label: "ผู้ดูแลระบบบำนาญ",
    id: 2488,
  },
  {
    label: "ศิลปินการแสดง",
    id: 2489,
  },
  {
    label: "ช่างทำผมประสิทธิภาพ",
    id: 2490,
  },
  {
    label: "ผู้จัดการฝ่ายผลิตประสิทธิภาพ",
    id: 2491,
  },
  {
    label: "ช่างเช่าประสิทธิภาพ",
    id: 2492,
  },
  {
    label: "อาจารย์สอนเต้นรำโรงเรียนศิลปะการแสดง",
    id: 2493,
  },
  {
    label: "อาจารย์สอนการแสดงศิลปะโรงละคร",
    id: 2494,
  },
  {
    label: "ผู้จัดการการกระจายน้ำหอมและเครื่องสำอาง",
    id: 2495,
  },
  {
    label: "ผู้ดำเนินการเครื่องจักรน้ำหอม",
    id: 2496,
  },
  {
    label: "ผู้ช่วยส่วนตัว",
    id: 2497,
  },
  {
    label: "ผู้ช่วยดูแลส่วนบุคคล",
    id: 2498,
  },
  {
    label: "ผู้ประเมินราคาส่วนบุคคล",
    id: 2499,
  },
  {
    label: "ผู้จัดการบริการส่วนบุคคล",
    id: 2500,
  },
  {
    label: "นักช้อปส่วนตัว",
    id: 2501,
  },
  {
    label: "สไตลิสต์ส่วนตัว",
    id: 2502,
  },
  {
    label: "ผู้ฝึกสอนส่วนตัว",
    id: 2503,
  },
  {
    label: "เจ้าหน้าที่ความน่าเชื่อถือส่วนบุคคล",
    id: 2504,
  },
  {
    label: "พนักงานจัดการศัตรูพืช",
    id: 2505,
  },
  {
    label: "เครื่องผสมยาฆ่าแมลง",
    id: 2506,
  },
  {
    label: "เครื่องพ่นยาฆ่าแมลง",
    id: 2507,
  },
  {
    label: "ผู้จัดการร้านขายสัตว์เลี้ยงและสัตว์เลี้ยง",
    id: 2508,
  },
  {
    label: "ผู้ขายอาหารสัตว์เลี้ยงและสัตว์เลี้ยง",
    id: 2509,
  },
  {
    label: "ผู้เลี้ยงสัตว์เลี้ยง",
    id: 2510,
  },
  {
    label: "วิศวกรปิโตรเลียม",
    id: 2511,
  },
  {
    label: "ผู้ประกอบการระบบปั๊มปิโตรเลียม",
    id: 2512,
  },
  {
    label: "วิศวกรเภสัชกรรม",
    id: 2513,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายสินค้า",
    id: 2514,
  },
  {
    label: "ผู้เชี่ยวชาญด้านเภสัชกรรม",
    id: 2515,
  },
  {
    label: "ตัวแทนขายยา",
    id: 2516,
  },
  {
    label: "เภสัชกร",
    id: 2517,
  },
  {
    label: "เภสัชวิทยา",
    id: 2518,
  },
  {
    label: "ผู้ช่วยเภสัชกรรม",
    id: 2519,
  },
  {
    label: "อาจารย์เภสัชกรรม",
    id: 2520,
  },
  {
    label: "ช่างเทคนิคเภสัชกรรม",
    id: 2521,
  },
  {
    label: "นักปรัชญา",
    id: 2522,
  },
  {
    label: "อาจารย์ปรัชญา",
    id: 2523,
  },
  {
    label: "ครูสอนปรัชญาโรงเรียนมัธยม",
    id: 2524,
  },
  {
    label: "นัก Phlebotomist",
    id: 2525,
  },
  {
    label: "นักพัฒนาฟีนิกซ์",
    id: 2526,
  },
  {
    label: "ตัวแทนขายโทรศัพท์",
    id: 2527,
  },
  {
    label: "นักพัฒนา PHONEGAP",
    id: 2528,
  },
  {
    label: "บรรณาธิการภาพถ่าย",
    id: 2529,
  },
  {
    label: "ช่างภาพ",
    id: 2530,
  },
  {
    label: "นักพัฒนาภาพ",
    id: 2531,
  },
  {
    label: "เครื่องประกอบอุปกรณ์ถ่ายภาพ",
    id: 2532,
  },
  {
    label: "ผู้จัดการร้านถ่ายภาพ",
    id: 2533,
  },
  {
    label: "ครูถ่ายภาพ",
    id: 2534,
  },
  {
    label: "นักถ่ายภาพ",
    id: 2535,
  },
  {
    label: "วิศวกร Photonics",
    id: 2536,
  },
  {
    label: "ช่างเทคนิควิศวกรรมโฟโตนิกส์",
    id: 2537,
  },
  {
    label: "นักพัฒนา PHP",
    id: 2538,
  },
  {
    label: "ครูพลศึกษา",
    id: 2539,
  },
  {
    label: "ครูสอนพลศึกษาโรงเรียนมัธยม",
    id: 2540,
  },
  {
    label: "ครูสอนวิชาชีพพลศึกษา",
    id: 2541,
  },
  {
    label: "กายภาพบำบัด",
    id: 2542,
  },
  {
    label: "ผู้ช่วยนักกายภาพบำบัด",
    id: 2543,
  },
  {
    label: "ผู้ช่วยแพทย์",
    id: 2544,
  },
  {
    label: "นักข้อมูลแพทย์",
    id: 2545,
  },
  {
    label: "นักฟิสิกส์",
    id: 2546,
  },
  {
    label: "วิทยากรฟิสิกส์",
    id: 2547,
  },
  {
    label: "ครูสอนวิชาฟิสิกส์",
    id: 2548,
  },
  {
    label: "ช่างฟิสิกส์",
    id: 2549,
  },
  {
    label: "นักสรีรศาสตร์",
    id: 2550,
  },
  {
    label: "นักกายภาพบำบัด",
    id: 2551,
  },
  {
    label: "ผู้ช่วยกายภาพบำบัด",
    id: 2552,
  },
  {
    label: "ผู้ผลิตเปียโน",
    id: 2553,
  },
  {
    label: "ผู้ดูแลระบบการเก็บภาพและการสื่อสาร",
    id: 2554,
  },
  {
    label: "ตัวแก้ไขรูปภาพ",
    id: 2555,
  },
  {
    label: "ผู้เพาะพันธุ์หมู",
    id: 2556,
  },
  {
    label: "ครูพิลาทิส",
    id: 2557,
  },
  {
    label: "ผู้ให้บริการค้อนขับรถกอง",
    id: 2558,
  },
  {
    label: "ผู้ให้บริการยา",
    id: 2559,
  },
  {
    label: "ช่างเชื่อมท่อ",
    id: 2560,
  },
  {
    label: "ผู้ประสานงานการปฏิบัติตามไปป์ไลน์",
    id: 2561,
  },
  {
    label: "วิศวกรท่อ",
    id: 2562,
  },
  {
    label: "ผู้จัดการโครงการด้านสิ่งแวดล้อมท่อส่ง",
    id: 2563,
  },
  {
    label: "พนักงานซ่อมบำรุงท่อ",
    id: 2564,
  },
  {
    label: "ผู้ให้บริการปั๊ม",
    id: 2565,
  },
  {
    label: "ผู้จัดการเส้นทางท่อส่ง",
    id: 2566,
  },
  {
    label: "Pipeline¬†ผู้กำกับ",
    id: 2567,
  },
  {
    label: "พิซซ่า",
    id: 2568,
  },
  {
    label: "ผู้ให้บริการความหนา",
    id: 2569,
  },
  {
    label: "ผู้จัดการโรงงาน",
    id: 2570,
  },
  {
    label: "ผู้ให้บริการเครื่องตัดพลาสม่า",
    id: 2571,
  },
  {
    label: "เครื่องปูน",
    id: 2572,
  },
  {
    label: "หัวหน้างานปูนปลาสเตอร์",
    id: 2573,
  },
  {
    label: "หัวหน้างานผลิตผลิตภัณฑ์พลาสติกและยาง",
    id: 2574,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรพลาสติกเฟอร์นิเจอร์",
    id: 2575,
  },
  {
    label: "ผู้ประกอบการอุปกรณ์บำบัดความร้อนพลาสติก",
    id: 2576,
  },
  {
    label: "เครื่องประกอบผลิตภัณฑ์พลาสติก",
    id: 2577,
  },
  {
    label: "ผู้ให้บริการเครื่องกลิ้งพลาสติก",
    id: 2578,
  },
  {
    label: "ตัวติดตั้งเพลทแก้ว",
    id: 2579,
  },
  {
    label: "ผู้ให้บริการ Plodder",
    id: 2580,
  },
  {
    label: "ช่างประปา",
    id: 2581,
  },
  {
    label: "หัวหน้างานประปา",
    id: 2582,
  },
  {
    label: "ช่างเทคนิควิศวกรรมนิวเมติก",
    id: 2583,
  },
  {
    label: "ช่างเทคนิคระบบนิวเมติก",
    id: 2584,
  },
  {
    label: "โฮสต์พอดคาสต์",
    id: 2585,
  },
  {
    label: "แพทย์แก้โรคเท้า",
    id: 2586,
  },
  {
    label: "ผู้ช่วยแก้โรคเท้า",
    id: 2587,
  },
  {
    label: "ข้าราชการตำรวจ",
    id: 2588,
  },
  {
    label: "นักสืบตำรวจ",
    id: 2589,
  },
  {
    label: "ผู้ตรวจการตำรวจ",
    id: 2590,
  },
  {
    label: "เจ้าหน้าที่ตำรวจ",
    id: 2591,
  },
  {
    label: "เทรนเนอร์ตำรวจ",
    id: 2592,
  },
  {
    label: "ผู้จัดการนโยบาย",
    id: 2593,
  },
  {
    label: "เจ้าหน้าที่นโยบาย",
    id: 2594,
  },
  {
    label: "เจ้าหน้าที่ฝ่ายการเมือง",
    id: 2595,
  },
  {
    label: "นักวิเคราะห์การเมือง",
    id: 2596,
  },
  {
    label: "เจ้าหน้าที่รณรงค์ทางการเมือง",
    id: 2597,
  },
  {
    label: "นักข่าวการเมือง",
    id: 2598,
  },
  {
    label: "ตัวแทนพรรคการเมือง",
    id: 2599,
  },
  {
    label: "นักวิทยาศาสตร์การเมือง",
    id: 2600,
  },
  {
    label: "วิทยากรการเมือง",
    id: 2601,
  },
  {
    label: "ผู้ตรวจสอบโพลีกราฟ",
    id: 2602,
  },
  {
    label: "จิตรกรพอร์ซเลน",
    id: 2603,
  },
  {
    label: "ผู้ประสานงานท่าเรือ",
    id: 2604,
  },
  {
    label: "พนักงานที่ทำการไปรษณีย์เคาน์เตอร์",
    id: 2605,
  },
  {
    label: "หัวหน้างานหลังการผลิต",
    id: 2606,
  },
  {
    label: "บุรุษไปรษณีย์/โพสต์",
    id: 2607,
  },
  {
    label: "เครื่องปั้นดินเผาและเครื่องเคลือบ",
    id: 2608,
  },
  {
    label: "พ่อแม่พันธุ์สัตว์ปีก",
    id: 2609,
  },
  {
    label: "เซ็กส์เมอร์สัตว์ปีก",
    id: 2610,
  },
  {
    label: "วิศวกรการกระจายพลังงาน",
    id: 2611,
  },
  {
    label: "หัวหน้าสายไฟฟ้า",
    id: 2612,
  },
  {
    label: "ผู้ให้บริการห้องควบคุมโรงไฟฟ้า",
    id: 2613,
  },
  {
    label: "ผู้จัดการโรงไฟฟ้า",
    id: 2614,
  },
  {
    label: "ผู้ประกอบการโรงงานผลิตไฟฟ้า",
    id: 2615,
  },
  {
    label: "ช่างซ่อมเครื่องมือไฟฟ้า",
    id: 2616,
  },
  {
    label: "ผู้จัดการบัญชี PPC",
    id: 2617,
  },
  {
    label: "ผู้ให้บริการล่วงหน้า",
    id: 2618,
  },
  {
    label: "ผู้ให้บริการเครื่องเย็บล่วงหน้า",
    id: 2619,
  },
  {
    label: "โพลน",
    id: 2620,
  },
  {
    label: "เครื่องตัดหินมีค่า",
    id: 2621,
  },
  {
    label: "ผู้ตั้งค่าหินมีค่า",
    id: 2622,
  },
  {
    label: "ผู้ตรวจสอบอุปกรณ์ที่แม่นยำ",
    id: 2623,
  },
  {
    label: "วิศวกรที่มีความแม่นยำ",
    id: 2624,
  },
  {
    label: "เครื่องประกอบเครื่องมือที่มีความแม่นยำ",
    id: 2625,
  },
  {
    label: "ช่างที่มีความแม่นยำ",
    id: 2626,
  },
  {
    label: "หัวหน้างานกลศาสตร์ที่แม่นยำ",
    id: 2627,
  },
  {
    label: "เตรียมปรุงอาหาร",
    id: 2628,
  },
  {
    label: "นักโภชนาการที่เตรียมอาหาร",
    id: 2629,
  },
  {
    label: "ผู้ให้บริการเนื้อสัตว์ที่เตรียมไว้",
    id: 2630,
  },
  {
    label: "ผู้ประกอบการ",
    id: 2631,
  },
  {
    label: "ช่างเตรียมพิมพ์",
    id: 2632,
  },
  {
    label: "ครูก่อนวัยเรียน",
    id: 2633,
  },
  {
    label: "ผู้นำเสนอ",
    id: 2634,
  },
  {
    label: "ผู้ประสานงานกด & Outreach",
    id: 2635,
  },
  {
    label: "ผู้จัดการร้านอาหารและเครื่องเขียน",
    id: 2636,
  },
  {
    label: "ผู้ขายเฉพาะทางกดและเครื่องเขียน",
    id: 2637,
  },
  {
    label: "ผู้เชี่ยวชาญด้านราคา",
    id: 2638,
  },
  {
    label: "ครูใหญ่โรงเรียนประถมศึกษา",
    id: 2639,
  },
  {
    label: "ครูโรงเรียนประถม",
    id: 2640,
  },
  {
    label: "ผู้ช่วยสอนการสอนระดับประถมศึกษา",
    id: 2641,
  },
  {
    label: "พิมพ์แบบพับได้",
    id: 2642,
  },
  {
    label: "หัวหน้างานพิมพ์สตูดิโอ",
    id: 2643,
  },
  {
    label: "แอสเซมเบลอร์กระดานวงจรพิมพ์",
    id: 2644,
  },
  {
    label: "นักออกแบบแผงวงจรพิมพ์",
    id: 2645,
  },
  {
    label: "ช่างเทคนิคการทดสอบแผงวงจรพิมพ์",
    id: 2646,
  },
  {
    label: "การพิมพ์ช่างเทคนิคสิ่งทอ",
    id: 2647,
  },
  {
    label: "เครื่องพิมพ์",
    id: 2648,
  },
  {
    label: "ผู้สอนในเรือนจำ",
    id: 2649,
  },
  {
    label: "เจ้าหน้าที่เรือนจำ",
    id: 2650,
  },
  {
    label: "คนขับรถส่วนตัว",
    id: 2651,
  },
  {
    label: "พ่อครัวส่วนตัว",
    id: 2652,
  },
  {
    label: "นักสืบเอกชน",
    id: 2653,
  },
  {
    label: "นักบินเอกชน",
    id: 2654,
  },
  {
    label: "เจ้าหน้าที่คุมประพฤติ",
    id: 2655,
  },
  {
    label: "การแก้ปัญหา",
    id: 2656,
  },
  {
    label: "นักวิเคราะห์กระบวนการ",
    id: 2657,
  },
  {
    label: "วิศวกรประมวลผล",
    id: 2658,
  },
  {
    label: "ช่างเทคนิควิศวกรรมกระบวนการ",
    id: 2659,
  },
  {
    label: "กระบวนการนักโลหะวิทยา",
    id: 2660,
  },
  {
    label: "ผู้จัดการฝ่ายจัดซื้อ",
    id: 2661,
  },
  {
    label: "ผู้ผลิต",
    id: 2662,
  },
  {
    label: "นักวิเคราะห์ผลิตภัณฑ์",
    id: 2663,
  },
  {
    label: "ผู้จัดการผลิตภัณฑ์และบริการ",
    id: 2664,
  },
  {
    label: "ผู้ตรวจการประกอบผลิตภัณฑ์",
    id: 2665,
  },
  {
    label: "ผู้อำนวยการออกแบบผลิตภัณฑ์",
    id: 2666,
  },
  {
    label: "นักออกแบบผลิตภัณฑ์",
    id: 2667,
  },
  {
    label: "วิศวกรรมการพัฒนาผลิตภัณฑ์ Drafter",
    id: 2668,
  },
  {
    label: "ช่างเทคนิคการพัฒนาผลิตภัณฑ์",
    id: 2669,
  },
  {
    label: "ผู้จัดการพัฒนาผลิตภัณฑ์",
    id: 2670,
  },
  {
    label: "ผู้อำนวยการผลิตภัณฑ์",
    id: 2671,
  },
  {
    label: "ผลิตภัณฑ์ชั้นประถมศึกษาปี",
    id: 2672,
  },
  {
    label: "ผู้จัดการฝ่ายผลิต",
    id: 2673,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดผลิตภัณฑ์",
    id: 2674,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดผลิตภัณฑ์",
    id: 2675,
  },
  {
    label: "เจ้าของสินค้า",
    id: 2676,
  },
  {
    label: "คอนโทรลเลอร์คุณภาพสินค้า",
    id: 2677,
  },
  {
    label: "ผู้ตรวจสอบคุณภาพสินค้า",
    id: 2678,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสนับสนุนผลิตภัณฑ์",
    id: 2679,
  },
  {
    label: "ผู้ช่วยฝ่ายผลิต",
    id: 2680,
  },
  {
    label: "ผู้ออกแบบงานสร้าง",
    id: 2681,
  },
  {
    label: "วิศวกรการผลิต",
    id: 2682,
  },
  {
    label: "ช่างเทคนิควิศวกรรมการผลิต",
    id: 2683,
  },
  {
    label: "ผู้ให้บริการรถเครนผลิต",
    id: 2684,
  },
  {
    label: "พอตเตอร์ผลิต",
    id: 2685,
  },
  {
    label: "ผู้ดูแลควบคุมสายการผลิต",
    id: 2686,
  },
  {
    label: "นักกีฬามืออาชีพ",
    id: 2687,
  },
  {
    label: "ผู้ประสานงานโปรแกรม",
    id: 2688,
  },
  {
    label: "ผู้อำนวยการโครงการ",
    id: 2689,
  },
  {
    label: "ผู้จัดการโปรแกรม",
    id: 2690,
  },
  {
    label: "ผู้จัดการโครงการระดมทุน",
    id: 2691,
  },
  {
    label: "ผู้จัดการโครงการ",
    id: 2692,
  },
  {
    label: "โปรแกรมเมอร์",
    id: 2693,
  },
  {
    label: "ผู้ประสานงานโครงการ",
    id: 2694,
  },
  {
    label: "วิศวกรโครงการ",
    id: 2695,
  },
  {
    label: "ผู้จัดการโครงการ",
    id: 2696,
  },
  {
    label: "ผู้ฉายภาพ",
    id: 2697,
  },
  {
    label: "ผู้ก่อการ",
    id: 2698,
  },
  {
    label: "ผู้ช่วยโปรโมชั่น",
    id: 2699,
  },
  {
    label: "ผู้จัดการส่งเสริมการขาย",
    id: 2700,
  },
  {
    label: "ผู้สาธิตโปรโมชั่น",
    id: 2701,
  },
  {
    label: "ผู้จัดหา",
    id: 2702,
  },
  {
    label: "ผู้พิสูจน์อักษร",
    id: 2703,
  },
  {
    label: "ผู้ผลิต",
    id: 2704,
  },
  {
    label: "ผู้ดูแลหลัก/นายหญิง",
    id: 2705,
  },
  {
    label: "ผู้จัดการซื้ออสังหาริมทรัพย์",
    id: 2706,
  },
  {
    label: "ผู้ประเมินราคาอสังหาริมทรัพย์",
    id: 2707,
  },
  {
    label: "ผู้ช่วยอสังหาริมทรัพย์",
    id: 2708,
  },
  {
    label: "ผู้พัฒนาทรัพย์สิน",
    id: 2709,
  },
  {
    label: "ประกันภัยทรัพย์สิน",
    id: 2710,
  },
  {
    label: "ผู้จัดการทรัพย์สิน",
    id: 2711,
  },
  {
    label: "ผู้ช่วยพัฒนาข้อเสนอ",
    id: 2712,
  },
  {
    label: "อัยการ",
    id: 2713,
  },
  {
    label: "ช่างเทคนิคเทียม",
    id: 2714,
  },
  {
    label: "ผู้นับถือศาสนาคริสต์นิกายเทียม",
    id: 2715,
  },
  {
    label: "ผู้ผลิตเครื่องแต่งกายเสื้อผ้าป้องกัน",
    id: 2716,
  },
  {
    label: "ผู้ว่าราชการจังหวัด",
    id: 2717,
  },
  {
    label: "เกี่ยวกับจิต",
    id: 2718,
  },
  {
    label: "นักจิตวิทยา",
    id: 2719,
  },
  {
    label: "อาจารย์จิตวิทยา",
    id: 2720,
  },
  {
    label: "นักจิตบำบัด",
    id: 2721,
  },
  {
    label: "ผู้จัดการฝ่ายบริหารสาธารณะ",
    id: 2722,
  },
  {
    label: "ที่ปรึกษากิจการสาธารณะ",
    id: 2723,
  },
  {
    label: "ผู้จัดการบริการการจ้างงานสาธารณะ",
    id: 2724,
  },
  {
    label: "นักบัญชีการเงินสาธารณะ",
    id: 2725,
  },
  {
    label: "ที่ปรึกษาเงินทุนสาธารณะ",
    id: 2726,
  },
  {
    label: "เจ้าหน้าที่นโยบายสาธารณสุข",
    id: 2727,
  },
  {
    label: "ผู้จัดการที่อยู่อาศัยสาธารณะ",
    id: 2728,
  },
  {
    label: "ผู้ช่วยประชาสัมพันธ์",
    id: 2729,
  },
  {
    label: "ผู้บริหารประชาสัมพันธ์",
    id: 2730,
  },
  {
    label: "การประชาสัมพันธ์ฝึกงาน",
    id: 2731,
  },
  {
    label: "ผู้จัดการประชาสัมพันธ์",
    id: 2732,
  },
  {
    label: "เจ้าหน้าที่ประชาสัมพันธ์",
    id: 2733,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการประชาสัมพันธ์",
    id: 2734,
  },
  {
    label: "โค้ชพูดในที่สาธารณะ",
    id: 2735,
  },
  {
    label: "ผู้ประสานงานสิ่งพิมพ์",
    id: 2736,
  },
  {
    label: "ผู้จัดการสิทธิ์การเผยแพร่",
    id: 2737,
  },
  {
    label: "ผู้ให้บริการควบคุมเยื่อกระดาษ",
    id: 2738,
  },
  {
    label: "เยื่อกระดาษ",
    id: 2739,
  },
  {
    label: "ช่างเทคนิคเยื่อกระดาษ",
    id: 2740,
  },
  {
    label: "ผู้ให้บริการเครื่องปัสสาวะ",
    id: 2741,
  },
  {
    label: "ผู้ให้บริการปั๊ม",
    id: 2742,
  },
  {
    label: "ผู้ให้บริการกด Punch",
    id: 2743,
  },
  {
    label: "นักออกแบบหุ่นเชิด",
    id: 2744,
  },
  {
    label: "ผู้เชิดหุ่น",
    id: 2745,
  },
  {
    label: "ผู้วางแผนซื้อ",
    id: 2746,
  },
  {
    label: "ผู้ซื้อ",
    id: 2747,
  },
  {
    label: "ตัวแทนจัดซื้อ",
    id: 2748,
  },
  {
    label: "ผู้จัดการฝ่ายจัดซื้อ",
    id: 2749,
  },
  {
    label: "นักออกแบบดอกไม้ไฟ",
    id: 2750,
  },
  {
    label: "พลเมือง",
    id: 2751,
  },
  {
    label: "นักพัฒนา Python",
    id: 2752,
  },
  {
    label: "นักวิเคราะห์ QA",
    id: 2753,
  },
  {
    label: "วิศวกรระบบอัตโนมัติของ QA",
    id: 2754,
  },
  {
    label: "วิศวกร QA",
    id: 2755,
  },
  {
    label: "ผู้จัดการทดสอบ QA",
    id: 2756,
  },
  {
    label: "นักวิเคราะห์การประกันคุณภาพ",
    id: 2757,
  },
  {
    label: "วิศวกรประกันคุณภาพ",
    id: 2758,
  },
  {
    label: "ผู้จัดการการประกันคุณภาพ",
    id: 2759,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการประกันคุณภาพ",
    id: 2760,
  },
  {
    label: "วิศวกรคุณภาพ",
    id: 2761,
  },
  {
    label: "ช่างเทคนิควิศวกรรมคุณภาพ",
    id: 2762,
  },
  {
    label: "ผู้ตรวจสอบคุณภาพ",
    id: 2763,
  },
  {
    label: "ผู้จัดการคุณภาพ",
    id: 2764,
  },
  {
    label: "ผู้จัดการบริการคุณภาพ",
    id: 2765,
  },
  {
    label: "นักวิจัยเชิงปริมาณ",
    id: 2766,
  },
  {
    label: "นักสำรวจปริมาณ",
    id: 2767,
  },
  {
    label: "วิศวกรเหมืองหิน",
    id: 2768,
  },
  {
    label: "ผู้จัดการเหมืองหิน",
    id: 2769,
  },
  {
    label: "สมาชิกลูกเรือร้านอาหารบริการด่วน",
    id: 2770,
  },
  {
    label: "หัวหน้าทีมร้านอาหารบริการด่วน",
    id: 2771,
  },
  {
    label: "ผู้พัฒนา",
    id: 2772,
  },
  {
    label: "วิศวกร R&D",
    id: 2773,
  },
  {
    label: "เจ้าหน้าที่คุ้มครองรังสี",
    id: 2774,
  },
  {
    label: "ช่างเทคนิคการป้องกันรังสี",
    id: 2775,
  },
  {
    label: "ผู้ผลิตวิทยุ",
    id: 2776,
  },
  {
    label: "ช่างเทคนิควิทยุ",
    id: 2777,
  },
  {
    label: "นักถ่ายภาพรังสี",
    id: 2778,
  },
  {
    label: "หัวหน้างานก่อสร้างทางรถไฟ",
    id: 2779,
  },
  {
    label: "ผู้ให้บริการอุปกรณ์ intermodal",
    id: 2780,
  },
  {
    label: "ชั้นรถไฟ",
    id: 2781,
  },
  {
    label: "ผู้ประสานงานด้านโลจิสติกส์",
    id: 2782,
  },
  {
    label: "ช่างซ่อมบำรุงรถไฟ",
    id: 2783,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการรถไฟ",
    id: 2784,
  },
  {
    label: "วิศวกรโครงการรถไฟ",
    id: 2785,
  },
  {
    label: "Switchperson ทางรถไฟ",
    id: 2786,
  },
  {
    label: "ผู้ควบคุมการจราจรทางรถไฟ",
    id: 2787,
  },
  {
    label: "นักพัฒนารางรถไฟ",
    id: 2788,
  },
  {
    label: "เบาะรถยนต์รถไฟ",
    id: 2789,
  },
  {
    label: "ช่างเทคนิคอิเล็กทรอนิกส์ทางรถไฟ",
    id: 2790,
  },
  {
    label: "ผู้ตรวจสอบโครงสร้างพื้นฐานทางรถไฟ",
    id: 2791,
  },
  {
    label: "ตัวแทนบริการผู้โดยสารรถไฟ",
    id: 2792,
  },
  {
    label: "ตัวแทนขายรถไฟ",
    id: 2793,
  },
  {
    label: "ผู้จัดการสถานีรถไฟ",
    id: 2794,
  },
  {
    label: "นักยุทธศาสตร์การจัดอันดับ",
    id: 2795,
  },
  {
    label: "ผู้ประกอบการรับวัตถุดิบ",
    id: 2796,
  },
  {
    label: "ผู้เชี่ยวชาญด้านคลังสินค้าวัตถุดิบ",
    id: 2797,
  },
  {
    label: "React Developer",
    id: 2798,
  },
  {
    label: "React Native Developer",
    id: 2799,
  },
  {
    label: "ตัวแทนอสังหาริมทรัพย์",
    id: 2800,
  },
  {
    label: "ผู้ประเมินราคาอสังหาริมทรัพย์",
    id: 2801,
  },
  {
    label: "นายหน้าค้าอสังหาริมทรัพย์",
    id: 2802,
  },
  {
    label: "นักลงทุนอสังหาริมทรัพย์",
    id: 2803,
  },
  {
    label: "ผู้จัดการเช่าอสังหาริมทรัพย์",
    id: 2804,
  },
  {
    label: "ผู้จัดการอสังหาริมทรัพย์",
    id: 2805,
  },
  {
    label: "นักสำรวจอสังหาริมทรัพย์",
    id: 2806,
  },
  {
    label: "พนักงานต้อนรับ",
    id: 2807,
  },
  {
    label: "บันทึกการกด",
    id: 2808,
  },
  {
    label: "ช่างเทคนิคสตูดิโอบันทึกเสียง",
    id: 2809,
  },
  {
    label: "ผู้ผลิตนางแบบสันทนาการ",
    id: 2810,
  },
  {
    label: "เจ้าหน้าที่นโยบายสันทนาการ",
    id: 2811,
  },
  {
    label: "ผู้จัดการสิ่งอำนวยความสะดวกด้านสันทนาการ",
    id: 2812,
  },
  {
    label: "นักบำบัดการพักผ่อนหย่อนใจ",
    id: 2813,
  },
  {
    label: "ผู้สรรหา",
    id: 2814,
  },
  {
    label: "ผู้ประสานงานการสรรหา",
    id: 2815,
  },
  {
    label: "ที่ปรึกษาด้านการสรรหา",
    id: 2816,
  },
  {
    label: "ผู้จัดการรับสมัคร",
    id: 2817,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดจัดหางาน",
    id: 2818,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการรีไซเคิล",
    id: 2819,
  },
  {
    label: "คนงานรีไซเคิล",
    id: 2820,
  },
  {
    label: "ผู้จัดการกะโรงกลั่น",
    id: 2821,
  },
  {
    label: "ผู้ดำเนินการเครื่องกลั่น",
    id: 2822,
  },
  {
    label: "ช่างเทคนิคการปรับปรุงใหม่",
    id: 2823,
  },
  {
    label: "ปฏิเสธนักสะสม",
    id: 2824,
  },
  {
    label: "คนขับรถปฏิเสธ",
    id: 2825,
  },
  {
    label: "เจ้าหน้าที่นโยบายการพัฒนาภูมิภาค",
    id: 2826,
  },
  {
    label: "ผู้จัดการการตลาดภาคสนามระดับภูมิภาค",
    id: 2827,
  },
  {
    label: "พยาบาลที่ลงทะเบียน",
    id: 2828,
  },
  {
    label: "ผู้จัดการฝ่ายกำกับดูแล",
    id: 2829,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการปฏิบัติตามกฎระเบียบ",
    id: 2830,
  },
  {
    label: "พนักงานสนับสนุนการฟื้นฟูสมรรถภาพ",
    id: 2831,
  },
  {
    label: "ผู้จัดการธนาคารสัมพันธ์",
    id: 2832,
  },
  {
    label: "ผู้จัดการความสัมพันธ์",
    id: 2833,
  },
  {
    label: "วิศวกรปลดปล่อย",
    id: 2834,
  },
  {
    label: "นักวิจัยด้านวิทยาศาสตร์ศาสนา",
    id: 2835,
  },
  {
    label: "ครูสอนศาสนาโรงเรียนมัธยมศึกษา",
    id: 2836,
  },
  {
    label: "อาจารย์สอนศาสนา",
    id: 2837,
  },
  {
    label: "เจ้าหน้าที่ย้ายถิ่นฐาน",
    id: 2838,
  },
  {
    label: "ช่างเทคนิคการตรวจจับระยะไกล",
    id: 2839,
  },
  {
    label: "ที่ปรึกษาด้านพลังงานทดแทน",
    id: 2840,
  },
  {
    label: "วิศวกรพลังงานทดแทน",
    id: 2841,
  },
  {
    label: "ตัวแทนขายพลังงานทดแทน",
    id: 2842,
  },
  {
    label: "ผู้จัดการให้เช่า",
    id: 2843,
  },
  {
    label: "ตัวแทนบริการให้เช่า",
    id: 2844,
  },
  {
    label: "ตัวแทนบริการให้เช่าในเครื่องจักรและอุปกรณ์การเกษตร",
    id: 2845,
  },
  {
    label: "ตัวแทนบริการให้เช่าในอุปกรณ์ขนส่งทางอากาศ",
    id: 2846,
  },
  {
    label: "ตัวแทนบริการให้เช่าในรถยนต์และยานยนต์เบา ๆ",
    id: 2847,
  },
  {
    label: "ตัวแทนบริการให้เช่าในเครื่องจักรก่อสร้างและวิศวกรรมโยธา",
    id: 2848,
  },
  {
    label: "ตัวแทนบริการให้เช่าในเครื่องจักรอุปกรณ์และสินค้าที่จับต้องได้",
    id: 2849,
  },
  {
    label: "ตัวแทนบริการให้เช่าในเครื่องจักรและอุปกรณ์",
    id: 2850,
  },
  {
    label: "ตัวแทนบริการให้เช่าในสินค้าส่วนบุคคลและครัวเรือน",
    id: 2851,
  },
  {
    label: "ตัวแทนบริการให้เช่าในการพักผ่อนหย่อนใจและสินค้ากีฬา",
    id: 2852,
  },
  {
    label: "ตัวแทนบริการให้เช่าในรถบรรทุก",
    id: 2853,
  },
  {
    label: "ตัวแทนบริการให้เช่าในเทปวิดีโอและดิสก์",
    id: 2854,
  },
  {
    label: "ตัวแทนบริการให้เช่าในอุปกรณ์ขนส่งน้ำ",
    id: 2855,
  },
  {
    label: "répétiteur",
    id: 2856,
  },
  {
    label: "ผู้สื่อข่าว",
    id: 2857,
  },
  {
    label: "ช่างเทคนิคการแสดง",
    id: 2858,
  },
  {
    label: "ผู้จัดการศูนย์กู้ภัย",
    id: 2859,
  },
  {
    label: "นักดำน้ำกู้ภัย",
    id: 2860,
  },
  {
    label: "ผู้จัดการวิจัยและพัฒนา",
    id: 2861,
  },
  {
    label: "ผู้ประสานงานวิจัย",
    id: 2862,
  },
  {
    label: "วิศวกรวิจัย",
    id: 2863,
  },
  {
    label: "นักวิจัย",
    id: 2864,
  },
  {
    label: "ผู้จัดการวิจัย",
    id: 2865,
  },
  {
    label: "ตัวแทนจำหน่ายตั๋วและการขนส่ง",
    id: 2866,
  },
  {
    label: "คนงานที่อยู่อาศัยดูแลบ้าน",
    id: 2867,
  },
  {
    label: "พนักงานดูแลเด็กที่อยู่อาศัย",
    id: 2868,
  },
  {
    label: "พนักงานดูแลผู้ใหญ่ที่อยู่อาศัย",
    id: 2869,
  },
  {
    label: "ที่อยู่อาศัยบ้านผู้สูงอายุดูแลผู้ใหญ่",
    id: 2870,
  },
  {
    label: "บ้านที่อยู่อาศัยคนหนุ่มสาวดูแลคนงาน",
    id: 2871,
  },
  {
    label: "ชั้นพื้นยืดหยุ่น",
    id: 2872,
  },
  {
    label: "ผู้จัดการทรัพยากร",
    id: 2873,
  },
  {
    label: "ช่างเทคนิคการบำบัดระบบทางเดินหายใจ",
    id: 2874,
  },
  {
    label: "โฮสต์ร้านอาหาร/ร้านอาหาร",
    id: 2875,
  },
  {
    label: "ผู้จัดการร้านอาหาร",
    id: 2876,
  },
  {
    label: "เซิร์ฟเวอร์ร้านอาหาร",
    id: 2877,
  },
  {
    label: "ช่างเทคนิคการฟื้นฟู",
    id: 2878,
  },
  {
    label: "นักเขียนประวัติย่อ",
    id: 2879,
  },
  {
    label: "ผู้ซื้อปลีก",
    id: 2880,
  },
  {
    label: "ผู้จัดการฝ่ายค้าปลีก",
    id: 2881,
  },
  {
    label: "ผู้ประกอบการค้าปลีก",
    id: 2882,
  },
  {
    label: "ผู้จัดการร้านค้าปลีก",
    id: 2883,
  },
  {
    label: "ตัวแทนขายค้าปลีก",
    id: 2884,
  },
  {
    label: "ช่างนั่งรถ",
    id: 2885,
  },
  {
    label: "ความเข้มข้น",
    id: 2886,
  },
  {
    label: "ผู้ดูแลเสื้อผ้า",
    id: 2887,
  },
  {
    label: "ผู้จัดการความเสี่ยง",
    id: 2888,
  },
  {
    label: "เครื่องหมุด",
    id: 2889,
  },
  {
    label: "หัวหน้างานก่อสร้างถนน",
    id: 2890,
  },
  {
    label: "คนงานก่อสร้างถนน",
    id: 2891,
  },
  {
    label: "ช่างซ่อมบำรุงถนน",
    id: 2892,
  },
  {
    label: "คนงานบำรุงรักษาถนน",
    id: 2893,
  },
  {
    label: "เครื่องหมายถนน",
    id: 2894,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการถนน",
    id: 2895,
  },
  {
    label: "ผู้ให้บริการลูกกลิ้งถนน",
    id: 2896,
  },
  {
    label: "ตัวติดตั้งป้ายถนน",
    id: 2897,
  },
  {
    label: "ผู้จัดการแผนกการขนส่งทางถนน",
    id: 2898,
  },
  {
    label: "ตารางการบำรุงรักษาถนน",
    id: 2899,
  },
  {
    label: "ช่างเทคนิคยานพาหนะริมถนน",
    id: 2900,
  },
  {
    label: "วิศวกรหุ่นยนต์",
    id: 2901,
  },
  {
    label: "ช่างเทคนิควิศวกรรมหุ่นยนต์",
    id: 2902,
  },
  {
    label: "โรลลิ่งสสต็อกแอสเซมบลี",
    id: 2903,
  },
  {
    label: "ผู้ตรวจการประกอบสต็อก",
    id: 2904,
  },
  {
    label: "หัวหน้างานประกอบสต็อก",
    id: 2905,
  },
  {
    label: "เครื่องใช้ไฟฟ้าของสต็อก",
    id: 2906,
  },
  {
    label: "ผู้ตรวจการเครื่องยนต์สต็อกกลิ้ง",
    id: 2907,
  },
  {
    label: "เครื่องทดสอบเครื่องยนต์สต็อกกลิ้ง",
    id: 2908,
  },
  {
    label: "Rolling Stock Engineer",
    id: 2909,
  },
  {
    label: "Rolling Stock Engineering Drafter",
    id: 2910,
  },
  {
    label: "ช่างเทคนิควิศวกรรมหุ้นกลิ้ง",
    id: 2911,
  },
  {
    label: "ผู้ตรวจการสต็อก",
    id: 2912,
  },
  {
    label: "ช่างมุงหลังคา",
    id: 2913,
  },
  {
    label: "ผู้ดูแลหลังคา",
    id: 2914,
  },
  {
    label: "ผู้ดูแลห้องพัก",
    id: 2915,
  },
  {
    label: "ผู้จัดการแผนกห้องพัก",
    id: 2916,
  },
  {
    label: "วิศวกรอุปกรณ์หมุน",
    id: 2917,
  },
  {
    label: "ช่างอุปกรณ์หมุน",
    id: 2918,
  },
  {
    label: "อันธพาล",
    id: 2919,
  },
  {
    label: "Roustabout",
    id: 2920,
  },
  {
    label: "ผู้ให้บริการเราเตอร์",
    id: 2921,
  },
  {
    label: "เครื่องตัดยางนุ่ม",
    id: 2922,
  },
  {
    label: "ผู้ให้บริการเครื่องจุ่มยาง",
    id: 2923,
  },
  {
    label: "เครื่องประกอบสินค้ายาง",
    id: 2924,
  },
  {
    label: "ผู้ประกอบการเครื่องทำยางผลิตภัณฑ์",
    id: 2925,
  },
  {
    label: "นักพัฒนาทับทิม",
    id: 2926,
  },
  {
    label: "นักพัฒนา Ruby on Rails",
    id: 2927,
  },
  {
    label: "การกันสนิม",
    id: 2928,
  },
  {
    label: "ผู้ประสานงานด้านความปลอดภัย",
    id: 2929,
  },
  {
    label: "ผู้จัดการความปลอดภัย",
    id: 2930,
  },
  {
    label: "เจ้าหน้าที่ความปลอดภัย",
    id: 2931,
  },
  {
    label: "กะลาสี",
    id: 2932,
  },
  {
    label: "ผู้จัดการบัญชีการขาย",
    id: 2933,
  },
  {
    label: "ที่ปรึกษาด้านการขาย",
    id: 2934,
  },
  {
    label: "ผู้ช่วยฝ่ายขาย",
    id: 2935,
  },
  {
    label: "พนักงานขาย",
    id: 2936,
  },
  {
    label: "ที่ปรึกษาด้านการขาย",
    id: 2937,
  },
  {
    label: "ผู้ประสานงานการขาย",
    id: 2938,
  },
  {
    label: "ตัวแทนการพัฒนาการขาย",
    id: 2939,
  },
  {
    label: "ผู้อำนวยการฝ่ายขาย",
    id: 2940,
  },
  {
    label: "วิศวกรขาย",
    id: 2941,
  },
  {
    label: "ผู้บริหารฝ่ายขาย",
    id: 2942,
  },
  {
    label: "ผู้จัดการฝ่ายขาย",
    id: 2943,
  },
  {
    label: "โปรเซสเซอร์การขาย",
    id: 2944,
  },
  {
    label: "ตัวแทนฝ่ายขาย",
    id: 2945,
  },
  {
    label: "ผู้ช่วยฝ่ายสนับสนุนการขาย",
    id: 2946,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสนับสนุนการขาย",
    id: 2947,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการฝึกอบรมการขาย",
    id: 2948,
  },
  {
    label: "นักพัฒนา SAP",
    id: 2949,
  },
  {
    label: "ที่ปรึกษาด้านเทคนิคของ SAP",
    id: 2950,
  },
  {
    label: "วิศวกรดาวเทียม",
    id: 2951,
  },
  {
    label: "ผู้ประกอบการผลิตซอส",
    id: 2952,
  },
  {
    label: "ผู้ให้บริการโรงเลื่อย",
    id: 2953,
  },
  {
    label: "นักพัฒนาสกาล่า",
    id: 2954,
  },
  {
    label: "ผู้ให้บริการสแกน",
    id: 2955,
  },
  {
    label: "ช่างเทคนิคทิวทัศน์",
    id: 2956,
  },
  {
    label: "จิตรกรชมวิว",
    id: 2957,
  },
  {
    label: "ผู้กำหนดตารางเวลา",
    id: 2958,
  },
  {
    label: "การกำหนดเวลาโปรแกรมเมอร์การเพิ่มประสิทธิภาพ",
    id: 2959,
  },
  {
    label: "ผู้ดูแลรถโรงเรียน",
    id: 2960,
  },
  {
    label: "ที่ปรึกษาโรงเรียน",
    id: 2961,
  },
  {
    label: "ครูสอนวิทยาศาสตร์โรงเรียนมัธยม",
    id: 2962,
  },
  {
    label: "นักเขียนวิทยาศาสตร์",
    id: 2963,
  },
  {
    label: "ช่างเทคนิคห้องปฏิบัติการวิทยาศาสตร์",
    id: 2964,
  },
  {
    label: "ผู้เล่นสกอป",
    id: 2965,
  },
  {
    label: "เศษโลหะ",
    id: 2966,
  },
  {
    label: "ผู้ให้บริการมีดโกน",
    id: 2967,
  },
  {
    label: "ช่างทำหน้าจอ",
    id: 2968,
  },
  {
    label: "เครื่องพิมพ์หน้าจอ",
    id: 2969,
  },
  {
    label: "ผู้ให้บริการเครื่องสกรู",
    id: 2970,
  },
  {
    label: "หัวหน้างานสคริปต์",
    id: 2971,
  },
  {
    label: "นักเขียนสคริปต์",
    id: 2972,
  },
  {
    label: "Scrum Master",
    id: 2973,
  },
  {
    label: "ช่างแกะสลัก",
    id: 2974,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดของเครื่องมือค้นหา",
    id: 2975,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการเพิ่มประสิทธิภาพกลไกค้นหา",
    id: 2976,
  },
  {
    label: "เจ้าหน้าที่คนที่สอง",
    id: 2977,
  },
  {
    label: "ผู้ขายสินค้ามือสอง",
    id: 2978,
  },
  {
    label: "ผู้จัดการร้านค้ามือสอง",
    id: 2979,
  },
  {
    label: "หัวหน้าแผนกโรงเรียนมัธยม",
    id: 2980,
  },
  {
    label: "ครูใหญ่โรงเรียนมัธยม",
    id: 2981,
  },
  {
    label: "ครูมัธยมศึกษา",
    id: 2982,
  },
  {
    label: "ผู้ช่วยสอนการสอนระดับมัธยมศึกษา",
    id: 2983,
  },
  {
    label: "เลขานุการ",
    id: 2984,
  },
  {
    label: "เลขาธิการ",
    id: 2985,
  },
  {
    label: "เลขานุการของรัฐ",
    id: 2986,
  },
  {
    label: "แอสเซมเบลอร์แบบแยกสายพาน",
    id: 2987,
  },
  {
    label: "นักวิเคราะห์หลักทรัพย์",
    id: 2988,
  },
  {
    label: "นายหน้าหลักทรัพย์",
    id: 2989,
  },
  {
    label: "ผู้ค้าหลักทรัพย์",
    id: 2990,
  },
  {
    label: "ผู้จัดจำหน่ายหลักทรัพย์",
    id: 2991,
  },
  {
    label: "ผู้ตรวจสอบสัญญาณเตือนความปลอดภัย",
    id: 2992,
  },
  {
    label: "ช่างเทคนิคการเตือนความปลอดภัย",
    id: 2993,
  },
  {
    label: "นักวิเคราะห์ความปลอดภัย",
    id: 2994,
  },
  {
    label: "ที่ปรึกษาด้านความปลอดภัย",
    id: 2995,
  },
  {
    label: "วิศวกรความปลอดภัย",
    id: 2996,
  },
  {
    label: "ผู้รักษาความปลอดภัย",
    id: 2997,
  },
  {
    label: "หัวหน้าเจ้าหน้าที่รักษาความปลอดภัย",
    id: 2998,
  },
  {
    label: "ผู้จัดการความปลอดภัย",
    id: 2999,
  },
  {
    label: "เจ้าหน้าที่รักษาความปลอดภัย",
    id: 3000,
  },
  {
    label: "นักไหว",
    id: 3001,
  },
  {
    label: "โปรเซสเซอร์เซมิคอนดักเตอร์",
    id: 3002,
  },
  {
    label: "วิศวกรเซ็นเซอร์",
    id: 3003,
  },
  {
    label: "ช่างเทคนิควิศวกรรมเซ็นเซอร์",
    id: 3004,
  },
  {
    label: "นักวิทยาศาสตร์ประสาทสัมผัส",
    id: 3005,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาด SEO และเนื้อหา",
    id: 3006,
  },
  {
    label: "ที่ปรึกษา SEO",
    id: 3007,
  },
  {
    label: "นักพัฒนา SEO",
    id: 3008,
  },
  {
    label: "ผู้จัดการ SEO",
    id: 3009,
  },
  {
    label: "ผู้เชี่ยวชาญ SEO",
    id: 3010,
  },
  {
    label: "ผู้ให้บริการถังบำบัดน้ำเสีย",
    id: 3011,
  },
  {
    label: "จ่า",
    id: 3012,
  },
  {
    label: "ผู้ดูแลระบบเซิร์ฟเวอร์",
    id: 3013,
  },
  {
    label: "วิศวกรเซิร์ฟเวอร์",
    id: 3014,
  },
  {
    label: "ผู้จัดการบริการ",
    id: 3015,
  },
  {
    label: "ตั้งค่าตัวสร้าง",
    id: 3016,
  },
  {
    label: "ตั้งค่าผู้ซื้อ",
    id: 3017,
  },
  {
    label: "ตั้งค่านักออกแบบ",
    id: 3018,
  },
  {
    label: "หัวหน้างานก่อสร้างท่อระบายน้ำ",
    id: 3019,
  },
  {
    label: "คนงานก่อสร้างท่อระบายน้ำ",
    id: 3020,
  },
  {
    label: "น้ำยาทำความสะอาดท่อระบายน้ำ",
    id: 3021,
  },
  {
    label: "ช่างซ่อมบำรุงน้ำทิ้ง",
    id: 3022,
  },
  {
    label: "การดำเนินงานเครือข่ายท่อระบายน้ำทิ้ง",
    id: 3023,
  },
  {
    label: "ผู้จัดการระบบระบายน้ำทิ้ง",
    id: 3024,
  },
  {
    label: "ผู้ให้บริการจักรเย็บผ้า",
    id: 3025,
  },
  {
    label: "ช่างเครื่องเย็บผ้า",
    id: 3026,
  },
  {
    label: "ที่ปรึกษาความรุนแรงทางเพศ",
    id: 3027,
  },
  {
    label: "พ่อแม่พันธุ์",
    id: 3028,
  },
  {
    label: "คนงานโลหะแผ่น",
    id: 3029,
  },
  {
    label: "ฟิลเลอร์ชั้นวาง",
    id: 3030,
  },
  {
    label: "คนเลี้ยงแกะ",
    id: 3031,
  },
  {
    label: "ผู้ประกอบการ shiatsu",
    id: 3032,
  },
  {
    label: "เรือผู้ช่วยวิศวกร",
    id: 3033,
  },
  {
    label: "กัปตันเรือ",
    id: 3034,
  },
  {
    label: "วิศวกรหน้าที่จัดส่ง",
    id: 3035,
  },
  {
    label: "เรือนำร่อง",
    id: 3036,
  },
  {
    label: "เรือวางแผน",
    id: 3037,
  },
  {
    label: "เรือสจ๊วต/พนักงานเสิร์ฟ",
    id: 3038,
  },
  {
    label: "นายหน้าซื้อขายเรือ",
    id: 3039,
  },
  {
    label: "ตัวแทนจัดส่ง",
    id: 3040,
  },
  {
    label: "ผู้จัดการการจัดส่ง",
    id: 3041,
  },
  {
    label: "นักจัดส่ง",
    id: 3042,
  },
  {
    label: "ผู้จัดการร้านขายรองเท้าและเครื่องหนัง",
    id: 3043,
  },
  {
    label: "อุปกรณ์เสริมรองเท้าและเครื่องหนัง",
    id: 3044,
  },
  {
    label: "ซ่อมรองเท้า",
    id: 3045,
  },
  {
    label: "ช่างทำรองเท้า",
    id: 3046,
  },
  {
    label: "ผู้ช่วยพนักงานขาย",
    id: 3047,
  },
  {
    label: "ผู้จัดการร้าน",
    id: 3048,
  },
  {
    label: "หัวหน้างานร้านค้า",
    id: 3049,
  },
  {
    label: "นักพัฒนา Shopify",
    id: 3050,
  },
  {
    label: "นักพัฒนาธีม Shopify",
    id: 3051,
  },
  {
    label: "shotfirer",
    id: 3052,
  },
  {
    label: "นักเล่นกล",
    id: 3053,
  },
  {
    label: "ล่ามภาษามือ",
    id: 3054,
  },
  {
    label: "ครูสอนภาษามือ",
    id: 3055,
  },
  {
    label: "ผู้ทำเครื่องหมาย",
    id: 3056,
  },
  {
    label: "เครื่องเงิน",
    id: 3057,
  },
  {
    label: "นักร้อง",
    id: 3058,
  },
  {
    label: "วิศวกรความน่าเชื่อถือของไซต์",
    id: 3059,
  },
  {
    label: "ผู้สอนสกี",
    id: 3060,
  },
  {
    label: "กัปตัน",
    id: 3061,
  },
  {
    label: "มิกเซอร์กระดานชนวน",
    id: 3062,
  },
  {
    label: "ผู้สังหาร",
    id: 3063,
  },
  {
    label: "ผู้ให้บริการตัวเลื่อน",
    id: 3064,
  },
  {
    label: "คนงานล้างหิมะ",
    id: 3065,
  },
  {
    label: "ผู้สอนสโนว์บอร์ด",
    id: 3066,
  },
  {
    label: "สบู่ chipper",
    id: 3067,
  },
  {
    label: "ตัวดำเนินการเครื่องอบแห้งสบู่",
    id: 3068,
  },
  {
    label: "ผู้ผลิตสบู่",
    id: 3069,
  },
  {
    label: "ผู้ให้บริการ Soap Tower",
    id: 3070,
  },
  {
    label: "ผู้ดูแลสังคม",
    id: 3071,
  },
  {
    label: "ที่ปรึกษาทางสังคม",
    id: 3072,
  },
  {
    label: "ผู้ประสานงานโซเชียลมีเดีย",
    id: 3073,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการออกแบบโซเชียลมีเดียและความคิดสร้างสรรค์",
    id: 3074,
  },
  {
    label: "ผู้จัดการโซเชียลมีเดีย",
    id: 3075,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการตลาดโซเชียลมีเดีย",
    id: 3076,
  },
  {
    label: "ผู้เชี่ยวชาญด้านโซเชียลมีเดีย",
    id: 3077,
  },
  {
    label: "นักยุทธศาสตร์โซเชียลมีเดีย",
    id: 3078,
  },
  {
    label: "PEDAGOGUE SOCIAL",
    id: 3079,
  },
  {
    label: "ผู้ดูแลระบบประกันสังคม",
    id: 3080,
  },
  {
    label: "ผู้ตรวจสอบประกันสังคม",
    id: 3081,
  },
  {
    label: "เจ้าหน้าที่ประกันสังคม",
    id: 3082,
  },
  {
    label: "ที่ปรึกษาด้านบริการสังคม",
    id: 3083,
  },
  {
    label: "ผู้จัดการฝ่ายบริการสังคม",
    id: 3084,
  },
  {
    label: "เจ้าหน้าที่นโยบายบริการสังคม",
    id: 3085,
  },
  {
    label: "ผู้ช่วยงานสังคมสงเคราะห์",
    id: 3086,
  },
  {
    label: "อาจารย์สอนสังคมสงเคราะห์",
    id: 3087,
  },
  {
    label: "นักการศึกษาฝึกหัดสังคมสงเคราะห์",
    id: 3088,
  },
  {
    label: "นักวิจัยสังคมสงเคราะห์",
    id: 3089,
  },
  {
    label: "หัวหน้างานสังคมสงเคราะห์",
    id: 3090,
  },
  {
    label: "นักสังคมสงเคราะห์",
    id: 3091,
  },
  {
    label: "นักสังคมวิทยา",
    id: 3092,
  },
  {
    label: "อาจารย์สังคมวิทยา",
    id: 3093,
  },
  {
    label: "นักวิเคราะห์ซอฟต์แวร์",
    id: 3094,
  },
  {
    label: "สถาปนิกซอฟต์แวร์",
    id: 3095,
  },
  {
    label: "นักพัฒนาซอฟต์แวร์",
    id: 3096,
  },
  {
    label: "วิศวกรรมซอฟต์แวร์",
    id: 3097,
  },
  {
    label: "ผู้จัดการซอฟต์แวร์",
    id: 3098,
  },
  {
    label: "วิศวกรความปลอดภัยของซอฟต์แวร์",
    id: 3099,
  },
  {
    label: "ผู้ทดสอบซอฟต์แวร์",
    id: 3100,
  },
  {
    label: "นักวิทยาศาสตร์ดิน",
    id: 3101,
  },
  {
    label: "ช่างสำรวจดิน",
    id: 3102,
  },
  {
    label: "วิศวกรพลังงานแสงอาทิตย์",
    id: 3103,
  },
  {
    label: "ที่ปรึกษาด้านการขายพลังงานแสงอาทิตย์",
    id: 3104,
  },
  {
    label: "ช่างเทคนิคพลังงานแสงอาทิตย์",
    id: 3105,
  },
  {
    label: "ผู้ให้บริการโรงไฟฟ้าพลังงานแสงอาทิตย์",
    id: 3106,
  },
  {
    label: "บัดกรี",
    id: 3107,
  },
  {
    label: "ผู้ให้บริการ แต่เพียงผู้เดียวและส้นเท้า",
    id: 3108,
  },
  {
    label: "ผู้ให้บริการขยะมูลฝอย",
    id: 3109,
  },
  {
    label: "สถาปนิกโซลูชั่น",
    id: 3110,
  },
  {
    label: "ซอมเมอลิเออร์",
    id: 3111,
  },
  {
    label: "นักปราบปราม",
    id: 3112,
  },
  {
    label: "ผู้ใช้แรงงาน Sorter",
    id: 3113,
  },
  {
    label: "ศิลปินเสียง",
    id: 3114,
  },
  {
    label: "นักออกแบบเสียง",
    id: 3115,
  },
  {
    label: "ตัวแก้ไขเสียง",
    id: 3116,
  },
  {
    label: "วิศวกรเสียง",
    id: 3117,
  },
  {
    label: "ผู้ให้บริการเสียง",
    id: 3118,
  },
  {
    label: "ผู้ที่มีแหล่งกำเนิด",
    id: 3119,
  },
  {
    label: "ผู้จัดการจัดหา",
    id: 3120,
  },
  {
    label: "พ่อครัว Sous",
    id: 3121,
  },
  {
    label: "ผู้ดูแลสปา",
    id: 3122,
  },
  {
    label: "ผู้จัดการสปา",
    id: 3123,
  },
  {
    label: "นักบำบัดสปา",
    id: 3124,
  },
  {
    label: "วิทยากรวิทยาศาสตร์อวกาศ",
    id: 3125,
  },
  {
    label: "ครูสอนภาษาสเปน",
    id: 3126,
  },
  {
    label: "ผู้ให้บริการเครื่องสึกกร่อน",
    id: 3127,
  },
  {
    label: "ผู้ช่วยความต้องการการศึกษาพิเศษ",
    id: 3128,
  },
  {
    label: "ผู้ประสานงานความต้องการการศึกษาพิเศษ",
    id: 3129,
  },
  {
    label: "ความต้องการการศึกษาพิเศษครูใหญ่",
    id: 3130,
  },
  {
    label: "ครูผู้สอนการท่องเที่ยวต้องการการศึกษาพิเศษ",
    id: 3131,
  },
  {
    label: "ความต้องการการศึกษาพิเศษครู",
    id: 3132,
  },
  {
    label: "โรงเรียนประถมศึกษาความต้องการการศึกษาพิเศษ",
    id: 3133,
  },
  {
    label: "โรงเรียนมัธยมความต้องการการศึกษาพิเศษ",
    id: 3134,
  },
  {
    label: "ศิลปินเอฟเฟกต์พิเศษ",
    id: 3135,
  },
  {
    label: "เจ้าหน้าที่กองกำลังพิเศษ",
    id: 3136,
  },
  {
    label: "เจ้าหน้าที่กลุ่มดอกเบี้ยพิเศษ",
    id: 3137,
  },
  {
    label: "ตัวแทนจำหน่ายโบราณ",
    id: 3138,
  },
  {
    label: "แพทย์พิเศษ",
    id: 3139,
  },
  {
    label: "ผู้จัดการจำหน่ายสินค้าพิเศษ",
    id: 3140,
  },
  {
    label: "อนิเมเตอร์กลางแจ้งพิเศษ",
    id: 3141,
  },
  {
    label: "ผู้ขายพิเศษ",
    id: 3142,
  },
  {
    label: "สัตวแพทย์",
    id: 3143,
  },
  {
    label: "นักวิทยาศาสตร์ชีวการแพทย์ผู้เชี่ยวชาญ",
    id: 3144,
  },
  {
    label: "หมอนวดผู้เชี่ยวชาญ",
    id: 3145,
  },
  {
    label: "ทันตแพทย์ผู้เชี่ยวชาญ",
    id: 3146,
  },
  {
    label: "พยาบาลผู้เชี่ยวชาญ",
    id: 3147,
  },
  {
    label: "เภสัชกรผู้เชี่ยวชาญ",
    id: 3148,
  },
  {
    label: "นักบำบัดการพูดและภาษา",
    id: 3149,
  },
  {
    label: "นักเขียนคำพูด",
    id: 3150,
  },
  {
    label: "ผู้ให้บริการเครื่องปั่น",
    id: 3151,
  },
  {
    label: "ช่างทำสิ่งทอหมุน",
    id: 3152,
  },
  {
    label: "โฆษก",
    id: 3153,
  },
  {
    label: "ผู้ดูแลกีฬา",
    id: 3154,
  },
  {
    label: "ผู้จัดการฝ่ายกีฬา",
    id: 3155,
  },
  {
    label: "เจ้าหน้าที่กีฬา",
    id: 3156,
  },
  {
    label: "นักบำบัดกีฬา",
    id: 3157,
  },
  {
    label: "อุปกรณ์เสริมกีฬาผู้ขายพิเศษ",
    id: 3158,
  },
  {
    label: "ผู้จัดการร้านกีฬาและอุปกรณ์เสริมกลางแจ้ง",
    id: 3159,
  },
  {
    label: "โค้ชกีฬา",
    id: 3160,
  },
  {
    label: "ช่างซ่อมอุปกรณ์กีฬา",
    id: 3161,
  },
  {
    label: "ผู้สอนกีฬา",
    id: 3162,
  },
  {
    label: "นักข่าวกีฬา",
    id: 3163,
  },
  {
    label: "ผู้ประสานงานโปรแกรมกีฬา",
    id: 3164,
  },
  {
    label: "ช่างเชื่อมสปอต",
    id: 3165,
  },
  {
    label: "นักพัฒนาฤดูใบไม้ผลิ",
    id: 3166,
  },
  {
    label: "ผู้ผลิตฤดูใบไม้ผลิ",
    id: 3167,
  },
  {
    label: "สปริงเกลอร์",
    id: 3168,
  },
  {
    label: "นักพัฒนา SQL",
    id: 3169,
  },
  {
    label: "กองเรือ",
    id: 3170,
  },
  {
    label: "ผู้ประสานงานพนักงาน",
    id: 3171,
  },
  {
    label: "ผู้กำกับเวที",
    id: 3172,
  },
  {
    label: "ช่างเครื่องบนเวที",
    id: 3173,
  },
  {
    label: "ผู้จัดการเวที",
    id: 3174,
  },
  {
    label: "ช่างเทคนิคเวที",
    id: 3175,
  },
  {
    label: "มือ",
    id: 3176,
  },
  {
    label: "ตัวติดตั้งบันได",
    id: 3177,
  },
  {
    label: "ผู้ดำเนินการกดปั๊ม",
    id: 3178,
  },
  {
    label: "ยืนอยู่ใน",
    id: 3179,
  },
  {
    label: "นักแสดงตลกยืนขึ้น",
    id: 3180,
  },
  {
    label: "ผู้ประกอบการแปลงแป้ง",
    id: 3181,
  },
  {
    label: "ผู้ประกอบการสกัดแป้ง",
    id: 3182,
  },
  {
    label: "ผู้ช่วยสถิติ",
    id: 3183,
  },
  {
    label: "โปรแกรมเมอร์",
    id: 3184,
  },
  {
    label: "นักสถิติ",
    id: 3185,
  },
  {
    label: "วิศวกรไอน้ำ",
    id: 3186,
  },
  {
    label: "ผู้ให้บริการโรงงานไอน้ำ",
    id: 3187,
  },
  {
    label: "ผู้ให้บริการกังหันไอน้ำ",
    id: 3188,
  },
  {
    label: "ยอดแหลม",
    id: 3189,
  },
  {
    label: "ครูโรงเรียน Steiner",
    id: 3190,
  },
  {
    label: "ช่างเทคนิคบริการที่ผ่านการฆ่าเชื้อ",
    id: 3191,
  },
  {
    label: "สตีเวดอร์",
    id: 3192,
  },
  {
    label: "ผู้กำกับ Stevedore",
    id: 3193,
  },
  {
    label: "สจ๊วต/พนักงานเสิร์ฟ",
    id: 3194,
  },
  {
    label: "นายหน้าซื้อขายหุ้น",
    id: 3195,
  },
  {
    label: "ผู้ค้าหุ้น",
    id: 3196,
  },
  {
    label: "เครื่องเจาะหิน",
    id: 3197,
  },
  {
    label: "ช่างแกะสลักหิน",
    id: 3198,
  },
  {
    label: "กบหิน",
    id: 3199,
  },
  {
    label: "เครื่องขัดหิน",
    id: 3200,
  },
  {
    label: "ตัวแยกหิน",
    id: 3201,
  },
  {
    label: "สโตนเมสัน",
    id: 3202,
  },
  {
    label: "อนิเมเตอร์สต็อปโมชั่น",
    id: 3203,
  },
  {
    label: "จัดเก็บนักสืบ",
    id: 3204,
  },
  {
    label: "ผู้จัดการร้าน",
    id: 3205,
  },
  {
    label: "ศิลปินบอร์ด",
    id: 3206,
  },
  {
    label: "ผู้ให้บริการเครื่องยืด",
    id: 3207,
  },
  {
    label: "ผู้บริหารบัญชีเชิงกลยุทธ์",
    id: 3208,
  },
  {
    label: "ผู้จัดการบัญชีกลยุทธ์",
    id: 3209,
  },
  {
    label: "ผู้จัดการวางแผนกลยุทธ์",
    id: 3210,
  },
  {
    label: "สตรีมมิ่งโปรแกรมเมอร์เสียง",
    id: 3211,
  },
  {
    label: "ศิลปินสตรีท",
    id: 3212,
  },
  {
    label: "ผู้ขายอาหารข้างถนน",
    id: 3213,
  },
  {
    label: "ช่างไฟฟ้าไฟถนน",
    id: 3214,
  },
  {
    label: "นักแสดงข้างถนน",
    id: 3215,
  },
  {
    label: "คนกวาดถนน",
    id: 3216,
  },
  {
    label: "ผู้คุมสตรีท",
    id: 3217,
  },
  {
    label: "เครื่องดนตรีสตริง",
    id: 3218,
  },
  {
    label: "หัวหน้างานโครงสร้างเหล็ก",
    id: 3219,
  },
  {
    label: "ช่างเหล็กโครงสร้าง",
    id: 3220,
  },
  {
    label: "ผู้ประสานงานการสนับสนุนทางการเงินของนักเรียน",
    id: 3221,
  },
  {
    label: "นักแสดงผาดโผน",
    id: 3222,
  },
  {
    label: "ผู้ปฏิบัติงานในทางที่ผิด",
    id: 3223,
  },
  {
    label: "วิศวกรสถานีย่อย",
    id: 3224,
  },
  {
    label: "ผู้บรรยาย",
    id: 3225,
  },
  {
    label: "ผู้ให้บริการโรงกลั่นน้ำตาล",
    id: 3226,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายน้ำตาลช็อคโกแลตและน้ำตาล",
    id: 3227,
  },
  {
    label: "ผู้จัดการซุปเปอร์มาร์เก็ต",
    id: 3228,
  },
  {
    label: "ผู้ช่วยซัพพลายเชน",
    id: 3229,
  },
  {
    label: "ที่ปรึกษาซัพพลายเชน",
    id: 3230,
  },
  {
    label: "วิศวกรซัพพลายเชน",
    id: 3231,
  },
  {
    label: "ผู้จัดการซัพพลายเชน",
    id: 3232,
  },
  {
    label: "วิศวกรสนับสนุน",
    id: 3233,
  },
  {
    label: "ผู้จัดการทีมสนับสนุน",
    id: 3234,
  },
  {
    label: "วิศวกรพื้นผิว",
    id: 3235,
  },
  {
    label: "ผู้ประกอบการเครื่องบดพื้นผิว",
    id: 3236,
  },
  {
    label: "ผู้ประกอบการโรงงานเหมืองพื้นผิว",
    id: 3237,
  },
  {
    label: "คนขุดพื้นผิว",
    id: 3238,
  },
  {
    label: "ผู้ประกอบการรักษาพื้นผิว",
    id: 3239,
  },
  {
    label: "ผู้ประกอบการเครื่องเทคโนโลยีพื้นผิว",
    id: 3240,
  },
  {
    label: "ผู้ผลิตเครื่องมือผ่าตัด",
    id: 3241,
  },
  {
    label: "การสำรวจ",
    id: 3242,
  },
  {
    label: "ช่างเทคนิคการสำรวจ",
    id: 3243,
  },
  {
    label: "ผู้สอนการอยู่รอด",
    id: 3244,
  },
  {
    label: "ผู้ดำเนินการเครื่องจักร",
    id: 3245,
  },
  {
    label: "นักพัฒนา Swift",
    id: 3246,
  },
  {
    label: "ผู้ดูแลสถานที่ว่ายน้ำ",
    id: 3247,
  },
  {
    label: "ครูสอนว่ายน้ำ",
    id: 3248,
  },
  {
    label: "วิศวกรวัสดุสังเคราะห์",
    id: 3249,
  },
  {
    label: "ผู้ดูแลระบบ",
    id: 3250,
  },
  {
    label: "วิเคราะห์ระบบ",
    id: 3251,
  },
  {
    label: "ตัวกำหนดค่าระบบ",
    id: 3252,
  },
  {
    label: "วิศวกรความปลอดภัยของระบบ",
    id: 3253,
  },
  {
    label: "ผู้เชี่ยวชาญด้านความปลอดภัยของระบบ",
    id: 3254,
  },
  {
    label: "วิศวกรระบบ",
    id: 3255,
  },
  {
    label: "ผู้ประกอบการตาราง",
    id: 3256,
  },
  {
    label: "ช่างตัดเสื้อ",
    id: 3257,
  },
  {
    label: "ผู้จัดการการซื้อกิจการ Talent",
    id: 3258,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการได้มาซึ่งความสามารถพิเศษ",
    id: 3259,
  },
  {
    label: "ตัวแทนพรสวรรค์",
    id: 3260,
  },
  {
    label: "ผู้มีความสามารถ",
    id: 3261,
  },
  {
    label: "คนฟอกหนัง",
    id: 3262,
  },
  {
    label: "ที่ปรึกษาฟอกหนัง",
    id: 3263,
  },
  {
    label: "นักบัญชีภาษี",
    id: 3264,
  },
  {
    label: "ที่ปรึกษาด้านภาษี",
    id: 3265,
  },
  {
    label: "เสมียนภาษี",
    id: 3266,
  },
  {
    label: "เจ้าหน้าที่ปฏิบัติตามภาษี",
    id: 3267,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการยื่นภาษี",
    id: 3268,
  },
  {
    label: "ผู้ตรวจสอบภาษี",
    id: 3269,
  },
  {
    label: "ผู้จัดการภาษี",
    id: 3270,
  },
  {
    label: "นักวิเคราะห์นโยบายภาษี",
    id: 3271,
  },
  {
    label: "ผู้ควบคุมรถแท็กซี่",
    id: 3272,
  },
  {
    label: "คนขับแท็กซี่",
    id: 3273,
  },
  {
    label: "ผู้เสียภาษี",
    id: 3274,
  },
  {
    label: "ครู",
    id: 3275,
  },
  {
    label: "ครูของนักเรียนที่มีความสามารถและมีพรสวรรค์",
    id: 3276,
  },
  {
    label: "หัวหน้ากลุ่ม",
    id: 3277,
  },
  {
    label: "ผู้จัดการบัญชีเทคนิค",
    id: 3278,
  },
  {
    label: "นักสื่อสารด้านเทคนิค",
    id: 3279,
  },
  {
    label: "เครื่องมือแก้ไขทางเทคนิค",
    id: 3280,
  },
  {
    label: "ผู้อำนวยการด้านเทคนิค",
    id: 3281,
  },
  {
    label: "ผู้จัดการฝ่ายการตลาดด้านเทคนิค",
    id: 3282,
  },
  {
    label: "ผู้จัดการผลิตภัณฑ์ด้านเทคนิค",
    id: 3283,
  },
  {
    label: "ผู้เชี่ยวชาญด้านเทคนิค",
    id: 3284,
  },
  {
    label: "ผู้จัดการโปรแกรมเทคนิค",
    id: 3285,
  },
  {
    label: "ผู้จัดการโครงการด้านเทคนิค",
    id: 3286,
  },
  {
    label: "ผู้สรรหาทางเทคนิค",
    id: 3287,
  },
  {
    label: "ตัวแทนขายด้านเทคนิค",
    id: 3288,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในเครื่องจักรและอุปกรณ์การเกษตร",
    id: 3289,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในผลิตภัณฑ์เคมี",
    id: 3290,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในอุปกรณ์อิเล็กทรอนิกส์และโทรคมนาคม",
    id: 3291,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในอุปกรณ์ฮาร์ดแวร์ประปาและเครื่องทำความร้อน",
    id: 3292,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในเครื่องจักรและอุปกรณ์อุตสาหกรรม",
    id: 3293,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในการขุดและเครื่องจักรก่อสร้าง",
    id: 3294,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในเครื่องจักรและอุปกรณ์สำนักงาน",
    id: 3295,
  },
  {
    label: "ตัวแทนขายด้านเทคนิคในอุตสาหกรรมเครื่องจักรสิ่งทอ",
    id: 3296,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการจัดหาทางเทคนิค",
    id: 3297,
  },
  {
    label: "ตัวแทนฝ่ายสนับสนุนด้านเทคนิค",
    id: 3298,
  },
  {
    label: "วิศวกรสนับสนุนด้านเทคนิค",
    id: 3299,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการสนับสนุนด้านเทคนิค",
    id: 3300,
  },
  {
    label: "ผู้ฝึกสอนด้านเทคนิค",
    id: 3301,
  },
  {
    label: "นักเขียนด้านเทคนิค",
    id: 3302,
  },
  {
    label: "ผู้จัดการร้านค้าโทรคมนาคม",
    id: 3303,
  },
  {
    label: "นักวิเคราะห์โทรคมนาคม",
    id: 3304,
  },
  {
    label: "วิศวกรโทรคมนาคม",
    id: 3305,
  },
  {
    label: "ช่างเทคนิควิศวกรรมโทรคมนาคม",
    id: 3306,
  },
  {
    label: "ผู้ดูแลอุปกรณ์โทรคมนาคม",
    id: 3307,
  },
  {
    label: "ผู้ขายอุปกรณ์โทรคมนาคม",
    id: 3308,
  },
  {
    label: "ผู้จัดการโทรคมนาคม",
    id: 3309,
  },
  {
    label: "ผู้เชี่ยวชาญด้านโทรคมนาคม",
    id: 3310,
  },
  {
    label: "ช่างเทคนิคการสื่อสารโทรคมนาคม",
    id: 3311,
  },
  {
    label: "การตลาดทางโทรศัพท์",
    id: 3312,
  },
  {
    label: "ผู้ให้บริการสวิตช์โทรศัพท์",
    id: 3313,
  },
  {
    label: "ตัวแทน Telesales",
    id: 3314,
  },
  {
    label: "ตัวแทน Telesales",
    id: 3315,
  },
  {
    label: "ผู้บอกเล่า",
    id: 3316,
  },
  {
    label: "โค้ชเทนนิส",
    id: 3317,
  },
  {
    label: "ตัวติดตั้งเต็นท์",
    id: 3318,
  },
  {
    label: "Terrazzo Setter",
    id: 3319,
  },
  {
    label: "หัวหน้างาน Terrazzo Setter",
    id: 3320,
  },
  {
    label: "ทดสอบวิศวกรระบบอัตโนมัติ",
    id: 3321,
  },
  {
    label: "วิศวกรทดสอบ",
    id: 3322,
  },
  {
    label: "ช่างเทคนิคคุณภาพสารเคมีสิ่งทอ",
    id: 3323,
  },
  {
    label: "นักเคมีสิ่งทอ",
    id: 3324,
  },
  {
    label: "Colourist สิ่งทอ",
    id: 3325,
  },
  {
    label: "นักออกแบบสิ่งทอ",
    id: 3326,
  },
  {
    label: "ช่างย้อมสีสิ่งทอ",
    id: 3327,
  },
  {
    label: "สิ่งทอ",
    id: 3328,
  },
  {
    label: "ผู้ให้บริการเครื่องตกแต่งสิ่งทอ",
    id: 3329,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายเครื่องจักรอุตสาหกรรมสิ่งทอ",
    id: 3330,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรสิ่งทอ",
    id: 3331,
  },
  {
    label: "ช่างเทคนิคสิ่งทอ",
    id: 3332,
  },
  {
    label: "ผู้จัดการฝ่ายปฏิบัติการสิ่งทอ",
    id: 3333,
  },
  {
    label: "ทำรูปแบบสิ่งทอ",
    id: 3334,
  },
  {
    label: "เครื่องพิมพ์สิ่งทอ",
    id: 3335,
  },
  {
    label: "คอนโทรลเลอร์กระบวนการสิ่งทอ",
    id: 3336,
  },
  {
    label: "นักพัฒนาผลิตภัณฑ์สิ่งทอ",
    id: 3337,
  },
  {
    label: "ผู้ตรวจสอบคุณภาพสิ่งทอ",
    id: 3338,
  },
  {
    label: "ผู้จัดการคุณภาพสิ่งทอ",
    id: 3339,
  },
  {
    label: "ช่างเทคนิคคุณภาพสิ่งทอ",
    id: 3340,
  },
  {
    label: "ผู้จัดการร้านขายสิ่งทอ",
    id: 3341,
  },
  {
    label: "ผู้จัดหาสิ่งทอ",
    id: 3342,
  },
  {
    label: "ผู้ขายเฉพาะทางสิ่งทอ",
    id: 3343,
  },
  {
    label: "นักเทคโนโลยีสิ่งทอ",
    id: 3344,
  },
  {
    label: "นักวิจัยสิ่งทอหนังและรองเท้า",
    id: 3345,
  },
  {
    label: "สิ่งทอ, สิ่งทอกึ่งสำเร็จรูปและผู้จัดการการจัดจำหน่ายวัตถุดิบ",
    id: 3346,
  },
  {
    label: "นักวิจัย Thanatology",
    id: 3347,
  },
  {
    label: "ช่างโรงละคร",
    id: 3348,
  },
  {
    label: "นักถ่ายภาพรังสีรักษา",
    id: 3349,
  },
  {
    label: "วิศวกรความร้อน",
    id: 3350,
  },
  {
    label: "ผู้ให้บริการเครื่องกลวง",
    id: 3351,
  },
  {
    label: "พนักงานออกตั๋ว",
    id: 3352,
  },
  {
    label: "ตัวแทนขายตั๋ว",
    id: 3353,
  },
  {
    label: "กระเบื้อง",
    id: 3354,
  },
  {
    label: "หัวหน้างานปูกระเบื้อง",
    id: 3355,
  },
  {
    label: "ผู้ค้าไม้",
    id: 3356,
  },
  {
    label: "กระดาษทิชชูที่เจาะรูและผู้ให้บริการย้อนกลับ",
    id: 3357,
  },
  {
    label: "ชื่อใกล้ชิดยิ่งขึ้น",
    id: 3358,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายผลิตภัณฑ์ยาสูบ",
    id: 3359,
  },
  {
    label: "ผู้จัดการร้านค้ายาสูบ",
    id: 3360,
  },
  {
    label: "ผู้ขายเฉพาะทางยาสูบ",
    id: 3361,
  },
  {
    label: "ผู้ดูแลห้องน้ำ",
    id: 3362,
  },
  {
    label: "เครื่องมือและผู้ผลิตตาย",
    id: 3363,
  },
  {
    label: "เครื่องบดเครื่องมือ",
    id: 3364,
  },
  {
    label: "ดันเครื่องมือ",
    id: 3365,
  },
  {
    label: "วิศวกรเครื่องมือ",
    id: 3366,
  },
  {
    label: "ผู้ให้บริการ",
    id: 3367,
  },
  {
    label: "ตัวแทนผู้ให้บริการทัวร์",
    id: 3368,
  },
  {
    label: "ผู้จัดการทัวร์",
    id: 3369,
  },
  {
    label: "ผู้จัดทัวร์",
    id: 3370,
  },
  {
    label: "ผู้เจรจาสัญญาการท่องเที่ยว",
    id: 3371,
  },
  {
    label: "ผู้อำนวยการนโยบายการท่องเที่ยว",
    id: 3372,
  },
  {
    label: "ผู้จัดการผลิตภัณฑ์การท่องเที่ยว",
    id: 3373,
  },
  {
    label: "อนิเมเตอร์นักท่องเที่ยว",
    id: 3374,
  },
  {
    label: "ผู้จัดการศูนย์ข้อมูลการท่องเที่ยว",
    id: 3375,
  },
  {
    label: "เจ้าหน้าที่ข้อมูลการท่องเที่ยว",
    id: 3376,
  },
  {
    label: "ผู้ประกอบการหอคอยเครน",
    id: 3377,
  },
  {
    label: "นักพิษวิทยา",
    id: 3378,
  },
  {
    label: "คนทำ",
    id: 3379,
  },
  {
    label: "ผู้จัดการร้านขายของเล่นและเกม",
    id: 3380,
  },
  {
    label: "ของเล่นและเกมผู้ขายพิเศษ",
    id: 3381,
  },
  {
    label: "Tracer Powder Blender",
    id: 3382,
  },
  {
    label: "เจ้าหน้าที่พัฒนาการค้า",
    id: 3383,
  },
  {
    label: "ผู้จัดการภูมิภาคการค้า",
    id: 3384,
  },
  {
    label: "นักบำบัดการแพทย์แผนจีน",
    id: 3385,
  },
  {
    label: "ผู้จัดการการเข้าซื้อกิจการ",
    id: 3386,
  },
  {
    label: "ผู้ดูแลรถไฟ",
    id: 3387,
  },
  {
    label: "เครื่องทำความสะอาดรถไฟ",
    id: 3388,
  },
  {
    label: "ตัวนำรถไฟ",
    id: 3389,
  },
  {
    label: "ผู้ส่งรถไฟ",
    id: 3390,
  },
  {
    label: "คนขับรถไฟ",
    id: 3391,
  },
  {
    label: "ผู้จัดเตรียมรถไฟ",
    id: 3392,
  },
  {
    label: "ผู้ประสานงานการฝึกอบรม",
    id: 3393,
  },
  {
    label: "ผู้จัดการฝึกหัด",
    id: 3394,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการฝึกอบรม",
    id: 3395,
  },
  {
    label: "คอนโทรลเลอร์รถราง",
    id: 3396,
  },
  {
    label: "คนขับรถราง",
    id: 3397,
  },
  {
    label: "ผู้ใช้งาน",
    id: 3398,
  },
  {
    label: "นักถอดเสียง",
    id: 3399,
  },
  {
    label: "ผู้จัดการหน่วยงานแปล",
    id: 3400,
  },
  {
    label: "ผู้แปล",
    id: 3401,
  },
  {
    label: "นักแปล - ทั่วไป",
    id: 3402,
  },
  {
    label: "นักแปล - ถูกกฎหมาย",
    id: 3403,
  },
  {
    label: "นักแปล - เทคนิค",
    id: 3404,
  },
  {
    label: "วิศวกรขนส่ง",
    id: 3405,
  },
  {
    label: "จิตรกรอุปกรณ์ขนส่ง",
    id: 3406,
  },
  {
    label: "ผู้ตรวจสอบสุขภาพและความปลอดภัยขนส่ง",
    id: 3407,
  },
  {
    label: "นักวางแผนขนส่ง",
    id: 3408,
  },
  {
    label: "ครูสอนวิชาเทคโนโลยีการขนส่ง",
    id: 3409,
  },
  {
    label: "ผู้จัดการตัวแทนการท่องเที่ยว",
    id: 3410,
  },
  {
    label: "ตัวแทนการท่องเที่ยว",
    id: 3411,
  },
  {
    label: "ครูสอนวิชาชีพการท่องเที่ยวและการท่องเที่ยว",
    id: 3412,
  },
  {
    label: "ที่ปรึกษาด้านการท่องเที่ยว",
    id: 3413,
  },
  {
    label: "นักเขียนคู่มือการท่องเที่ยว",
    id: 3414,
  },
  {
    label: "เหรัญญิก",
    id: 3415,
  },
  {
    label: "ผู้จัดการคลังสมบัติ",
    id: 3416,
  },
  {
    label: "ศัลยแพทย์ต้นไม้",
    id: 3417,
  },
  {
    label: "คนขับรถบัสรถเข็น",
    id: 3418,
  },
  {
    label: "อาจารย์สอนขับรถบรรทุก",
    id: 3419,
  },
  {
    label: "ผู้ให้บริการ Tufting",
    id: 3420,
  },
  {
    label: "ผู้ปฏิบัติงานเครื่องจักรกล",
    id: 3421,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรที่น่าเบื่อ",
    id: 3422,
  },
  {
    label: "ผู้ให้บริการเตาเผา",
    id: 3423,
  },
  {
    label: "ครูสอนพิเศษ",
    id: 3424,
  },
  {
    label: "ผู้ประกอบการเครื่องบิด",
    id: 3425,
  },
  {
    label: "นักพัฒนา TypeScript",
    id: 3426,
  },
  {
    label: "เครื่องเรียงพิมพ์",
    id: 3427,
  },
  {
    label: "ผู้พิมพ์ดีด",
    id: 3428,
  },
  {
    label: "ผู้สร้างยางรถยนต์",
    id: 3429,
  },
  {
    label: "Fitter ยาง",
    id: 3430,
  },
  {
    label: "ยาง Vulcaniser",
    id: 3431,
  },
  {
    label: "นักออกแบบ UI",
    id: 3432,
  },
  {
    label: "ผู้ให้บริการอุปกรณ์หนักใต้ดิน",
    id: 3433,
  },
  {
    label: "เหมืองใต้ดิน",
    id: 3434,
  },
  {
    label: "หัวหน้างานก่อสร้างใต้น้ำ",
    id: 3435,
  },
  {
    label: "ผู้รับประกันภัย",
    id: 3436,
  },
  {
    label: "นักพัฒนาเอกภาพ",
    id: 3437,
  },
  {
    label: "นักพัฒนา Unity3D",
    id: 3438,
  },
  {
    label: "หัวหน้าภาควิชามหาวิทยาลัย",
    id: 3439,
  },
  {
    label: "อาจารย์วรรณกรรมมหาวิทยาลัย",
    id: 3440,
  },
  {
    label: "ผู้ช่วยวิจัยมหาวิทยาลัย",
    id: 3441,
  },
  {
    label: "ผู้ช่วยสอนมหาวิทยาลัย",
    id: 3442,
  },
  {
    label: "ช่างตกแต่ง",
    id: 3443,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรที่ทำให้อารมณ์เสีย",
    id: 3444,
  },
  {
    label: "ผู้วางแผนในเมือง",
    id: 3445,
  },
  {
    label: "ผู้กำกับประสบการณ์และการออกแบบผู้อำนวยการ",
    id: 3446,
  },
  {
    label: "นักวิเคราะห์ประสบการณ์ผู้ใช้",
    id: 3447,
  },
  {
    label: "ที่ปรึกษาประสบการณ์ผู้ใช้",
    id: 3448,
  },
  {
    label: "นักออกแบบประสบการณ์ผู้ใช้",
    id: 3449,
  },
  {
    label: "ตัวออกแบบส่วนต่อประสานผู้ใช้",
    id: 3450,
  },
  {
    label: "ผู้พัฒนาส่วนต่อประสานผู้ใช้",
    id: 3451,
  },
  {
    label: "นำ",
    id: 3452,
  },
  {
    label: "ผู้ตรวจการสาธารณูปโภค",
    id: 3453,
  },
  {
    label: "นักออกแบบ UX",
    id: 3454,
  },
  {
    label: "นักออกแบบผลิตภัณฑ์ UX",
    id: 3455,
  },
  {
    label: "ผู้เชี่ยวชาญด้านการวิจัย UX",
    id: 3456,
  },
  {
    label: "นักวิจัย UX",
    id: 3457,
  },
  {
    label: "นักเขียน UX",
    id: 3458,
  },
  {
    label: "นักออกแบบ UX/UI",
    id: 3459,
  },
  {
    label: "ผู้สร้างสายพันธุ์ V",
    id: 3460,
  },
  {
    label: "V-Belt Coverer",
    id: 3461,
  },
  {
    label: "หมัดเด็ด v-belt",
    id: 3462,
  },
  {
    label: "ผู้ประกอบการเครื่องดูดฝุ่น",
    id: 3463,
  },
  {
    label: "วิศวกรตรวจสอบความถูกต้อง",
    id: 3464,
  },
  {
    label: "ศิลปินวาไรตี้",
    id: 3465,
  },
  {
    label: "เครื่องเคลือบเคลือบเงา",
    id: 3466,
  },
  {
    label: "เครื่องทำความสะอาดยานพาหนะ",
    id: 3467,
  },
  {
    label: "ตัวติดตั้งอุปกรณ์อิเล็กทรอนิกส์ยานพาหนะ",
    id: 3468,
  },
  {
    label: "ยานพาหนะ Glazier",
    id: 3469,
  },
  {
    label: "ผู้ดูแลการบำรุงรักษายานพาหนะ",
    id: 3470,
  },
  {
    label: "หัวหน้างานบำรุงรักษายานพาหนะ",
    id: 3471,
  },
  {
    label: "ตัวแทนเช่ายานพาหนะ",
    id: 3472,
  },
  {
    label: "ช่างเทคนิคยานพาหนะ",
    id: 3473,
  },
  {
    label: "ผู้ให้บริการตู้จำหน่ายเครื่องจำหน่าย",
    id: 3474,
  },
  {
    label: "Veneer Grader",
    id: 3475,
  },
  {
    label: "ผู้ให้บริการวีเนียร์ตัวแยก",
    id: 3476,
  },
  {
    label: "ผู้ร่วมทุน",
    id: 3477,
  },
  {
    label: "ผู้อำนวยการสถานที่",
    id: 3478,
  },
  {
    label: "โปรแกรมเมอร์สถานที่",
    id: 3479,
  },
  {
    label: "ความขยันขันแข็ง",
    id: 3480,
  },
  {
    label: "ผู้ผลิตเวอร์มุต",
    id: 3481,
  },
  {
    label: "ผู้ตรวจการประกอบเรือ",
    id: 3482,
  },
  {
    label: "หัวหน้างานประกอบเรือ",
    id: 3483,
  },
  {
    label: "เครื่องประกอบเครื่องยนต์เรือ",
    id: 3484,
  },
  {
    label: "ผู้ตรวจการเครื่องยนต์เรือ",
    id: 3485,
  },
  {
    label: "เครื่องทดสอบเครื่องยนต์เรือ",
    id: 3486,
  },
  {
    label: "ผู้ประสานงานการปฏิบัติการเรือ",
    id: 3487,
  },
  {
    label: "อาจารย์สอนการบังคับเลี้ยว",
    id: 3488,
  },
  {
    label: "อาจารย์สัตวแพทย์",
    id: 3489,
  },
  {
    label: "พยาบาลสัตวแพทย์",
    id: 3490,
  },
  {
    label: "พนักงานต้อนรับสัตวแพทย์",
    id: 3491,
  },
  {
    label: "ช่างเทคนิคสัตวแพทย์",
    id: 3492,
  },
  {
    label: "เจ้าหน้าที่สนับสนุนเหยื่อ",
    id: 3493,
  },
  {
    label: "ผู้กำกับวิดีโอและภาพยนตร์",
    id: 3494,
  },
  {
    label: "บรรณาธิการวิดีโอและภาพยนตร์",
    id: 3495,
  },
  {
    label: "ผู้ผลิตวิดีโอและภาพยนตร์",
    id: 3496,
  },
  {
    label: "ศิลปินวิดีโอ",
    id: 3497,
  },
  {
    label: "นักการตลาดเนื้อหาวิดีโอ",
    id: 3498,
  },
  {
    label: "นักออกแบบวิดีโอ",
    id: 3499,
  },
  {
    label: "บรรณาธิการวิดีโอ",
    id: 3500,
  },
  {
    label: "ผู้ให้บริการวิดีโอ",
    id: 3501,
  },
  {
    label: "ผู้เชี่ยวชาญด้านวิดีโอ",
    id: 3502,
  },
  {
    label: "ช่างเทคนิควิดีโอ",
    id: 3503,
  },
  {
    label: "Master Vineyard Cellar Master",
    id: 3504,
  },
  {
    label: "ผู้ประกอบการเครื่องจักรไร่องุ่น",
    id: 3505,
  },
  {
    label: "ผู้จัดการไร่องุ่น",
    id: 3506,
  },
  {
    label: "หัวหน้างานไร่องุ่น",
    id: 3507,
  },
  {
    label: "คนงานไร่องุ่น",
    id: 3508,
  },
  {
    label: "ผู้ผลิตไวโอลิน",
    id: 3509,
  },
  {
    label: "ผู้ช่วยเสมือนจริง",
    id: 3510,
  },
  {
    label: "ครูทัศนศิลป์",
    id: 3511,
  },
  {
    label: "นักออกแบบภาพ",
    id: 3512,
  },
  {
    label: "ผู้ขายสินค้าทางสายตา",
    id: 3513,
  },
  {
    label: "ที่ปรึกษาการปลูกองุ่น",
    id: 3514,
  },
  {
    label: "คนขายดี",
    id: 3515,
  },
  {
    label: "ครูสอนอาชีพ",
    id: 3516,
  },
  {
    label: "ศิลปินพากย์เสียง",
    id: 3517,
  },
  {
    label: "วิศวกรซอฟต์แวร์ VoIP",
    id: 3518,
  },
  {
    label: "ผู้ประสานงานอาสาสมัคร",
    id: 3519,
  },
  {
    label: "นักพัฒนา vue.js",
    id: 3520,
  },
  {
    label: "รอพนักงาน",
    id: 3521,
  },
  {
    label: "บริกร/พนักงานเสิร์ฟ",
    id: 3522,
  },
  {
    label: "ผู้ประสานงานรายชื่อรอ",
    id: 3523,
  },
  {
    label: "คลังสินค้า",
    id: 3524,
  },
  {
    label: "ผู้จัดการคลังสินค้า",
    id: 3525,
  },
  {
    label: "ผู้ให้บริการคลังสินค้าสำหรับเสื้อผ้า",
    id: 3526,
  },
  {
    label: "ตัวเลือกคำสั่งคลังสินค้า",
    id: 3527,
  },
  {
    label: "หัวหน้าคลังสินค้า",
    id: 3528,
  },
  {
    label: "คนงานคลังสินค้า",
    id: 3529,
  },
  {
    label: "ผู้เชี่ยวชาญด้านสงคราม",
    id: 3530,
  },
  {
    label: "ล้างผู้ดำเนินการ deinking",
    id: 3531,
  },
  {
    label: "ผู้จัดการการกระจายของเสียและเศษซาก",
    id: 3532,
  },
  {
    label: "นายหน้าของเสีย",
    id: 3533,
  },
  {
    label: "เจ้าหน้าที่จัดการขยะ",
    id: 3534,
  },
  {
    label: "หัวหน้างานจัดการขยะ",
    id: 3535,
  },
  {
    label: "วิศวกรบำบัดขยะ",
    id: 3536,
  },
  {
    label: "วิศวกรน้ำเสีย",
    id: 3537,
  },
  {
    label: "ผู้ให้บริการบำบัดน้ำเสีย",
    id: 3538,
  },
  {
    label: "ช่างเทคนิคบำบัดน้ำเสีย",
    id: 3539,
  },
  {
    label: "ดูและซ่อมนาฬิกา",
    id: 3540,
  },
  {
    label: "นาฬิกาและผู้จัดการฝ่ายจัดจำหน่ายเครื่องประดับ",
    id: 3541,
  },
  {
    label: "ช่างเทคนิคการอนุรักษ์น้ำ",
    id: 3542,
  },
  {
    label: "หัวหน้าช่างเทคนิคการอนุรักษ์น้ำ",
    id: 3543,
  },
  {
    label: "วิศวกรน้ำ",
    id: 3544,
  },
  {
    label: "ช่างเทคนิควิศวกรรมน้ำ",
    id: 3545,
  },
  {
    label: "ผู้ประกอบการเครื่องตัดน้ำ",
    id: 3546,
  },
  {
    label: "การผ่าตัดเครือข่ายน้ำ",
    id: 3547,
  },
  {
    label: "ช่างเทคนิคโรงงานน้ำ",
    id: 3548,
  },
  {
    label: "นักวิเคราะห์คุณภาพน้ำ",
    id: 3549,
  },
  {
    label: "ผู้ประสานงานการจราจรทางน้ำ",
    id: 3550,
  },
  {
    label: "ผู้จัดการโรงงานบำบัดน้ำ",
    id: 3551,
  },
  {
    label: "ผู้ประกอบการระบบบำบัดน้ำ",
    id: 3552,
  },
  {
    label: "ช่างเทคนิคการเพาะเลี้ยงสัตว์น้ำ",
    id: 3553,
  },
  {
    label: "คนงานเพาะเลี้ยงสัตว์น้ำ",
    id: 3554,
  },
  {
    label: "แรงงานก่อสร้างทางน้ำ",
    id: 3555,
  },
  {
    label: "ผู้ประกอบการเครื่องบดคลื่น",
    id: 3556,
  },
  {
    label: "อัฒจันทร์แว็กซ์",
    id: 3557,
  },
  {
    label: "สวมใส่เครื่องแต่งกาย",
    id: 3558,
  },
  {
    label: "สวมใส่เครื่องแต่งกาย",
    id: 3559,
  },
  {
    label: "พยากรณ์อากาศ",
    id: 3560,
  },
  {
    label: "ช่างทอผ้า",
    id: 3561,
  },
  {
    label: "ผู้ให้บริการเครื่องทอผ้า",
    id: 3562,
  },
  {
    label: "หัวหน้างานเครื่องทอผ้า",
    id: 3563,
  },
  {
    label: "ช่างทอผ้าทอผ้า",
    id: 3564,
  },
  {
    label: "นักวิเคราะห์เว็บ",
    id: 3565,
  },
  {
    label: "ตัวจัดการเนื้อหาเว็บ",
    id: 3566,
  },
  {
    label: "นักออกแบบเว็บไซต์",
    id: 3567,
  },
  {
    label: "นักพัฒนาเว็บ",
    id: 3568,
  },
  {
    label: "บรรณาธิการเว็บ",
    id: 3569,
  },
  {
    label: "ผู้ดูแลเว็บ",
    id: 3570,
  },
  {
    label: "ผู้จัดการเนื้อหาเว็บไซต์",
    id: 3571,
  },
  {
    label: "ผู้พัฒนาเว็บไซต์",
    id: 3572,
  },
  {
    label: "การวางแผนงานแต่งงาน",
    id: 3573,
  },
  {
    label: "ที่ปรึกษาการลดน้ำหนัก",
    id: 3574,
  },
  {
    label: "น้ำหนักและมาตรการตรวจสอบ",
    id: 3575,
  },
  {
    label: "ช่างเชื่อม",
    id: 3576,
  },
  {
    label: "ผู้ประสานงานการเชื่อม",
    id: 3577,
  },
  {
    label: "วิศวกรเชื่อม",
    id: 3578,
  },
  {
    label: "ผู้ตรวจการเชื่อม",
    id: 3579,
  },
  {
    label: "ทำให้ดีขึ้น",
    id: 3580,
  },
  {
    label: "พ่อค้าขายส่ง",
    id: 3581,
  },
  {
    label: "พ่อค้าขายส่งในเครื่องจักรและอุปกรณ์การเกษตร",
    id: 3582,
  },
  {
    label: "พ่อค้าขายส่งในวัตถุดิบเกษตรเมล็ดและอาหารสัตว์",
    id: 3583,
  },
  {
    label: "พ่อค้าขายส่งในเครื่องดื่ม",
    id: 3584,
  },
  {
    label: "พ่อค้าขายส่งในผลิตภัณฑ์เคมี",
    id: 3585,
  },
  {
    label: "พ่อค้าขายส่งในประเทศจีนและเครื่องแก้วอื่น ๆ",
    id: 3586,
  },
  {
    label: "พ่อค้าขายส่งในเสื้อผ้าและรองเท้า",
    id: 3587,
  },
  {
    label: "พ่อค้าขายส่งในกาแฟชาโกโก้และเครื่องเทศ",
    id: 3588,
  },
  {
    label:
      "ผู้ค้าขายส่งในคอมพิวเตอร์อุปกรณ์อุปกรณ์ต่อพ่วงคอมพิวเตอร์และซอฟต์แวร์",
    id: 3589,
  },
  {
    label: "พ่อค้าขายส่งในผลิตภัณฑ์นมและน้ำมันที่กินได้",
    id: 3590,
  },
  {
    label: "พ่อค้าขายส่งในเครื่องใช้ไฟฟ้าในครัวเรือน",
    id: 3591,
  },
  {
    label: "ขายส่งพ่อค้าในอุปกรณ์อิเล็กทรอนิกส์และโทรคมนาคมและชิ้นส่วน",
    id: 3592,
  },
  {
    label: "พ่อค้าขายส่งในปลากุ้งและหอย",
    id: 3593,
  },
  {
    label: "พ่อค้าขายส่งในดอกไม้และพืช",
    id: 3594,
  },
  {
    label: "พ่อค้าขายส่งในผักและผลไม้",
    id: 3595,
  },
  {
    label: "พ่อค้าขายส่งในเฟอร์นิเจอร์พรมและอุปกรณ์ส่องสว่าง",
    id: 3596,
  },
  {
    label:
      "พ่อค้าขายส่งในฮาร์ดแวร์อุปกรณ์ประปาและอุปกรณ์ทำความร้อนและวัสดุสิ้นเปลือง",
    id: 3597,
  },
  {
    label: "พ่อค้าขายส่งในที่ซ่อนสกินและผลิตภัณฑ์เครื่องหนัง",
    id: 3598,
  },
  {
    label: "พ่อค้าขายส่งในของใช้ในครัวเรือน",
    id: 3599,
  },
  {
    label: "พ่อค้าขายส่งในสัตว์มีชีวิต",
    id: 3600,
  },
  {
    label: "ผู้ค้าส่งในเครื่องมือเครื่องจักร",
    id: 3601,
  },
  {
    label: "พ่อค้าขายส่งในเครื่องจักรอุปกรณ์อุตสาหกรรมเรือและเครื่องบิน",
    id: 3602,
  },
  {
    label: "พ่อค้าขายส่งในผลิตภัณฑ์เนื้อสัตว์และเนื้อสัตว์",
    id: 3603,
  },
  {
    label: "พ่อค้าขายส่งในโลหะและแร่โลหะ",
    id: 3604,
  },
  {
    label: "พ่อค้าขายส่งในการทำเหมืองการก่อสร้างและวิศวกรรมโยธา",
    id: 3605,
  },
  {
    label: "พ่อค้าขายส่งในเฟอร์นิเจอร์สำนักงาน",
    id: 3606,
  },
  {
    label: "พ่อค้าขายส่งในเครื่องจักรและอุปกรณ์",
    id: 3607,
  },
  {
    label: "พ่อค้าขายส่งในน้ำหอมและเครื่องสำอาง",
    id: 3608,
  },
  {
    label: "พ่อค้าขายส่งในสินค้าเภสัชกรรม",
    id: 3609,
  },
  {
    label: "พ่อค้าขายส่งในน้ำตาลช็อคโกแลตและน้ำตาล",
    id: 3610,
  },
  {
    label: "พ่อค้าขายส่งในเครื่องจักรอุตสาหกรรมสิ่งทอ",
    id: 3611,
  },
  {
    label: "พ่อค้าขายส่งในสิ่งทอและสิ่งทอกึ่งสำเร็จรูปและวัตถุดิบ",
    id: 3612,
  },
  {
    label: "พ่อค้าขายส่งในผลิตภัณฑ์ยาสูบ",
    id: 3613,
  },
  {
    label: "พ่อค้าขายส่งในของเสียและเศษซาก",
    id: 3614,
  },
  {
    label: "พ่อค้าขายส่งในนาฬิกาและอัญมณี",
    id: 3615,
  },
  {
    label: "พ่อค้าขายส่งในไม้และวัสดุก่อสร้าง",
    id: 3616,
  },
  {
    label: "เครื่องทำเฟอร์นิเจอร์หวาย",
    id: 3617,
  },
  {
    label: "ผู้ผลิตวิกผมและผม",
    id: 3618,
  },
  {
    label: "วิศวกรพลังงานลม",
    id: 3619,
  },
  {
    label: "เครื่องดนตรีเครื่องดนตรีลม",
    id: 3620,
  },
  {
    label: "ช่างเทคนิคกังหันลม",
    id: 3621,
  },
  {
    label: "ผู้ให้บริการเครื่องม้วน",
    id: 3622,
  },
  {
    label: "ทำความสะอาดหน้าต่าง",
    id: 3623,
  },
  {
    label: "ตัวติดตั้งหน้าต่าง",
    id: 3624,
  },
  {
    label: "เครื่องหมักไวน์",
    id: 3625,
  },
  {
    label: "ไวน์ซอมเมลิเย่ร์",
    id: 3626,
  },
  {
    label: "สายรัดสายไฟ",
    id: 3627,
  },
  {
    label: "ผู้ให้บริการเครื่องทอผ้าลวด",
    id: 3628,
  },
  {
    label: "นักพัฒนา WooCommerce",
    id: 3629,
  },
  {
    label: "ผู้จัดการการจัดจำหน่ายไม้และวัสดุก่อสร้าง",
    id: 3630,
  },
  {
    label: "หัวหน้างานประกอบไม้",
    id: 3631,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรที่น่าเบื่อ",
    id: 3632,
  },
  {
    label: "หม้อ",
    id: 3633,
  },
  {
    label: "ผู้ให้บริการเตาเผาไม้อบแห้ง",
    id: 3634,
  },
  {
    label: "ผู้จัดการโรงงานไม้",
    id: 3635,
  },
  {
    label: "เม็ดเชื้อเพลิงไม้",
    id: 3636,
  },
  {
    label: "จิตรกรไม้",
    id: 3637,
  },
  {
    label: "เครื่องทำพาเลทไม้",
    id: 3638,
  },
  {
    label: "หัวหน้างานผลิตไม้",
    id: 3639,
  },
  {
    label: "ชุดประกอบผลิตภัณฑ์ไม้",
    id: 3640,
  },
  {
    label: "ผู้ให้บริการเราเตอร์ไม้",
    id: 3641,
  },
  {
    label: "เครื่องขัดไม้",
    id: 3642,
  },
  {
    label: "วิศวกรเทคโนโลยีไม้",
    id: 3643,
  },
  {
    label: "ไม้กางเขน",
    id: 3644,
  },
  {
    label: "ช่างแกะสลักไม้",
    id: 3645,
  },
  {
    label: "ผู้ให้บริการเครื่องจักรเฟอร์นิเจอร์ไม้",
    id: 3646,
  },
  {
    label: "ช่างไม้",
    id: 3647,
  },
  {
    label: "นักพัฒนา WordPress",
    id: 3648,
  },
  {
    label: "นักเขียน",
    id: 3649,
  },
  {
    label: "นักพัฒนาซามาริน",
    id: 3650,
  },
  {
    label: "เส้นด้ายสปินเนอร์",
    id: 3651,
  },
  {
    label: "ยีสต์กลั่น",
    id: 3652,
  },
  {
    label: "ผู้จัดการศูนย์เยาวชน",
    id: 3653,
  },
  {
    label: "เยาวชนที่กระทำผิดทีมงาน",
    id: 3654,
  },
  {
    label: "ผู้อำนวยการโครงการเยาวชน",
    id: 3655,
  },
  {
    label: "คนงานเยาวชน",
    id: 3656,
  },
  {
    label: "ภัณฑารักษ์สวนสัตว์",
    id: 3657,
  },
  {
    label: "นักการศึกษาสวนสัตว์",
    id: 3658,
  },
  {
    label: "นายทะเบียนสวนสัตว์",
    id: 3659,
  },
  {
    label: "หัวหน้าส่วนสวน",
    id: 3660,
  },
  {
    label: "Zookeeper",
    id: 3661,
  },
  {
    label: "ช่างเทคนิคสัตววิทยา",
    id: 3662,
  },
  {
    label: "อื่น",
    id: 3663,
  },
  {
    label: "พนักงานบริษัท",
    id: 3664,
  },
  {
    label: "พนักงานทั่วไป",
    id: 3665,
  },
];
