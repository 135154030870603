import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";

import { Close } from "@mui/icons-material";

import Button from "../form/Button";
import ButtonIcon from "../form/ButtonIcon";

const StyleDialog = styled(Dialog)(({ theme }) => ({}));

const Div = styled.div`
  /* padding: 50px; */
`;

const SubContentDialog = ({
  open = false,
  onClose,
  maxWidth = "md",
  title = " Title Dialog",
  alignTitle = "left",
  alignSubTitle = "left",
  subTitle,
  children,
  ...props
}) => {
  return (
    <StyleDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <Div>
        <DialogTitle id="alert-dialog-title">
          <Typography
            className="title"
            variant="h4"
            sx={{ color: "#005CC9" }}
            align={alignTitle}
            gutterBottom
          >
            {title}
          </Typography>
          {subTitle && (
            <Typography
              variant="h6"
              color="initial"
              align={alignSubTitle}
              gutterBottom
            >
              {subTitle}
            </Typography>
          )}
          {onClose ? (
            <ButtonIcon
              aria-label="close"
              onClick={onClose}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </ButtonIcon>
          ) : null}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {children}
          </DialogContentText>
        </DialogContent>
      </Div>
    </StyleDialog>
  );
};

export default SubContentDialog;
