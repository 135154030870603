import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams, useLocation } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";
import Stack from "components/layout/Stack";

import Typography from "@mui/material/Typography";

import SuccessIcon from "assets/icon/success.png";
import { Container } from "@mui/material";
import { verifyEmail } from "redux/actions/register";
import { toggleLogin } from "redux/actions/share";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const Verify = () => {
  // const { token } = useParams();
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");

  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);

  useEffect(() => {
    dispatch(verifyEmail(token)).then((data) => {
      console.log("data then", data);
      if (data?.error === "not found user") {
        history.replace("/expired");
      }
    });
  }, [token]);

  const handleLogin = () => {
    dispatch(toggleLogin(true));
  };

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <Stack
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" align="center" color="initial">
                <img width="100" alt="success" src={SuccessIcon} />
              </Typography>

              <Typography
                variant="h2"
                color="initial"
                align="center"
                gutterBottom
              >
                Account verified
              </Typography>
              <br />

              <Typography variant="h6" align="center" color="initial">
                Thank you. Your account has been verified.
                <Typography
                  variant="h6"
                  align="center"
                  gutterBottom
                  color="initial"
                >
                  You can now use our services.
                </Typography>
              </Typography>

              <br />
              <br />
              <Button
                sx={{ width: { xs: "100%", sm: 300 } }}
                onClick={() => handleLogin()}
              >
                Login
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default Verify;
