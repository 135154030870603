import React from "react";
import "quill/dist/quill.snow.css";
import { useState, useEffect } from "react";
import { useQuill, quill } from "react-quilljs";
import Script from "next/script";
import useOnclickOutside from "react-cool-onclickoutside";

export default function RichTextEditor({
  onRichTextBlur,
  placeHolder,
  initValue,
  index,
}) {
  const [isInFocusEditor, setIsInFocusEditor] = useState(false);

  const theme = "snow";
  const modules = {
    toolbar: [
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      ["link", "image", "video"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ header: 2 }],
      [{ color: [] }, { background: [] }],
      ["formula"],
      [{ script: "sub" }, { script: "super" }],
      ["clean"],
    ],
  };
  const placeholder = placeHolder || "Type here...";
  const formats = [
    "background",
    "bold",
    "color",
    "font",
    "code",
    "italic",
    "link",
    "size",
    "strike",
    "underline",
    "blockquote",
    "header",
    "list",
    "align",
    "direction",
    "code-block",
    "image",
    "video",
    "svg",
    "mathjax",
    "script",
  ];
  const { quill, quillRef, Quill } = useQuill({
    theme,
    formats,
    modules,
    placeholder,
  });

  useEffect(() => {
    if (Quill) {
      if (Quill && !quill) {
        var Embed = Quill.import("blots/embed");
        Quill.register(
          class extends Embed {
            static create(value) {
              const node = super.create(value);
              if (typeof value === "string") {
                let svg = this.tex2svg(value);
                node.innerHTML = svg;
                node.contentEditable = "false";
                node.setAttribute("data-value", svg);
              }
              return node;
            }

            static value(domNode) {
              return domNode.getAttribute("data-value");
            }

            static tex2svg(latex, event) {
              let MathJaxNode = document.createElement("DIV");
              MathJaxNode.setAttribute("id", "math");
              MathJaxNode.style.visibility = "hidden";
              let node = document.getElementById("math");
              let position = latex.search("MathML");
              console.log("position", position);
              if (position == -1) {
                MathJaxNode.innerHTML = "\\(" + latex + "\\)";
              } else {
                console.log("latex", latex);
                console.log("MathJaxNode", MathJaxNode);
                MathJaxNode.innerHTML = `${latex}`;
              }
              document.body.appendChild(MathJaxNode);
              if (window?.MathJax) {
                window.MathJax.typeset();
              }
              if (latex?.search("<svg") != -1) {
                document.body.removeChild(MathJaxNode);
                return latex;
              }
              console.log("MathJaxNode", MathJaxNode);
              let innerHtml =
                MathJaxNode.getElementsByTagName("mjx-container")[0].innerHTML;
              console.log("innerHtml", innerHtml);
              let svg = innerHtml?.split("<mjx-assistive-mml")[0];
              document.body.removeChild(MathJaxNode);
              return svg;
            }

            static blotName = "mathjax";
            static className = "ql-mathjax";
            static tagName = "SPAN";
          },
          true
        );
      } else if (quill) {
        quill.getModule("toolbar").addHandler("formula", onFormula);
      }
    }
  }, [quill, Quill]);

  useEffect(() => {
    if (quill) {
      if (initValue) {
        const isDelta = initValue?.toString().startsWith("[");
        if (isDelta) {
          quill.setContents(initValue);
        }
      }
    }
  }, [initValue, quill]);

  const onFormula = () => {
    var latex = prompt("Enter formula:", "");
    if (latex) {
      var range = quill.getSelection(true);
      quill.deleteText(range.index, range.length);
      quill.insertEmbed(range.index, "mathjax", latex);
      quill.insertText(range.index + range.length + 1, " ");
      quill.setSelection(range.index + range.length + 1);
    }
  };

  const inputWrapRef = useOnclickOutside(() => {
    if (isInFocusEditor) {
      setIsInFocusEditor(false);
      onRichTextBlur(quill.getContents()?.ops);
    }
  });

  const onEditorClicked = () => {
    if (!isInFocusEditor) {
      setIsInFocusEditor(true);
    }
  };

  return (
    <>
      <Script
        id="mathjax-js"
        src="https://cdnjs.cloudflare.com/ajax/libs/mathjax/3.0.5/es5/tex-mml-svg.js"
        strategy="afterInteractive"
      />
      <div
        ref={inputWrapRef}
        style={{ width: "100%", display: "block", height: "130px" }}
        onClick={onEditorClicked}
        onMouseOver={onEditorClicked}
      >
        <div ref={quillRef}>
          <div dangerouslySetInnerHTML={{ __html: initValue }}></div>
        </div>
      </div>
    </>
  );
}
