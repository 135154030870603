import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Typography,
} from "@mui/material";

import Button from "../form/Button";

const StyleDialog = styled(Dialog)(({ theme }) => ({}));

const ConfirmDialog = ({
  open = false,
  onClose,
  onConfirm,
  title = " Title Dialog",
  content = "Content Dialog",
  maxWidth = "sm",
  confirmName = title,
  ...props
}) => {
  return (
    <StyleDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      {...props}
    >
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h4" align="center" color="initial">
          {title}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          sx={{ textAlign: "center" }}
          id="alert-dialog-description"
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ my: 1, justifyContent: "center" }}>
        <Button outlined onClick={onClose}>
          Cancel
        </Button>
        <Button onClick={onConfirm} autoFocus>
          {confirmName}
        </Button>
      </DialogActions>
    </StyleDialog>
  );
};

export default ConfirmDialog;
