import React, { useState, useEffect, useCallback, Fragment } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AddCircle as AddIcon,
  AccessTime as Clock,
  RemoveRedEye as Eye,
  Search,
  ArrowBack,
  ArrowForward,
} from "@mui/icons-material";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
} from "@mui/material";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";

import Pagination from "../../components/navigation/Pagination";

import Button from "../../components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import Select from "../../components/form/Select";
import TextField from "../../components/form/TextField";
import Checkbox from "../../components/form/Checkbox";
import Chip from "../../components/form/Chip";

import SubContentDialog from "../../components/dialog/SubContentDialog";

import BoxIcon from "../../containers/BoxIcon";

import Quiz from "../../assets/icon/quiz.png";
import Language from "../../assets/icon/language.png";
import Time from "../../assets/icon/time.png";
import Level from "../../assets/icon/level.png";
import AvatarIcon from "../../assets/icon/avatar.png";
import Question from "../../assets/icon/essay.png";

import { INITIAL_SCENARIOS } from "utils/dataConfig";

import {
  getExamSetList,
  getExamSetQuestionById,
  deleteExamsetById,
} from "../../redux/actions/assessments";
import TestCard from "containers/TestCard";
import { DisplyDeltaOrHtml } from "components/form/ConvertHtmlToString";
import ConfirmDialog from "components/dialog/ConfirmDialog";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;

  .background-menu-filter {
    background-color: #f5f7fc;
    border-radius: 8px;
    /* margin: 0 auto; */
  }

  ol,
  ul {
    padding-left: 0;
  }
`;

// const INITIAL_SCENARIOS = [
//   {
//     label: "1st Test",
//   },
//   {
//     label: "2nd Test",
//   },
//   {
//     label: "3rd Test",
//   },
//   {
//     label: "4th Test",
//   },
//   {
//     label: "5th Test",
//   },
// ];

const TestSet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { examSetList, pageCount, limit, total, examsetQuestionById } =
    useSelector((state) => state.assessments);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentData, setCurrentData] = useState("");

  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);
  const [isOpenModalQuestion, setIsOpenModalQuestion] = useState(false);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);

  const [examSetDisplay, setExamSetDisplay] = useState(examsetQuestionById);
  const [displayQuestion, setDisplayQuestion] = useState(0);
  const [currentExamSet, setCurrentExamSet] = useState("");

  const [filter, setFilter] = useState({
    skip: 0,
    is_published: 1,
    question_type: [],
    level: [],
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getExamSetList(filter));
  }, []);

  useEffect(() => {
    let item = examsetQuestionById?.[displayQuestion]?.detail;
    console.log("item", item);
    setExamSetDisplay(item);
  }, [displayQuestion, examsetQuestionById]);

  useEffect(() => {
    console.log("examsetQuestionById", examsetQuestionById);
  }, [examsetQuestionById]);

  useEffect(() => {
    setTimeout(() => {
      fetchExamSetList();
    }, 250);
  }, [filter]);

  const fetchExamSetList = useCallback(() => {
    dispatch(getExamSetList(filter));
  }, [filter]);

  const onChangePage = (event, value) => {
    setPage(value);
    setFilter((prev) => ({
      ...prev,
      skip: limit * (value - 1),
    }));
  };

  const onChangeFilter = (fieldName, newValue) => {
    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  };

  const onChangeMultiCheck = (fieldName, value, type) => {
    let newArr = [...filter?.[fieldName]];
    if (value) {
      newArr.push(type);
    } else {
      newArr = newArr.filter((it) => it !== type);
    }

    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newArr,
    }));
  };

  const handleOpenDialog = () => {
    setIsOpenModalFilter(!isOpenModalFilter);
  };
  const handleCloseDialog = () => {
    setIsOpenModalFilter(!isOpenModalFilter);
  };

  const handleOpenModalDetail = (data) => {
    setCurrentExamSet(data);
    setIsOpenModalDetail(!isOpenModalDetail);
  };

  const handleCloseModalDetail = () => {
    setIsOpenModalDetail(!isOpenModalDetail);
  };

  const handleOpenModalQuestion = (data) => {
    setCurrentExamSet(data);
    dispatch(getExamSetQuestionById(data?.id));
    setIsOpenModalQuestion(!isOpenModalQuestion);
  };

  const handleCloseModalQuestion = () => {
    setDisplayQuestion(0);
    setIsOpenModalQuestion(!isOpenModalQuestion);
  };

  const handleOpenConfirmDialog = (data) => {
    setCurrentData(data);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleDeleteExamset = () => {
    handleCloseConfirmDialog();

    dispatch(deleteExamsetById(currentData?.id)).then(() => {
      setOpenConfirmDialog(!openConfirmDialog);
      fetchExamSetList();
    });
  };

  const findAnswer = (choice = []) => {
    let index = choice?.findIndex((item) => item.is_answer === 1);
    return index !== -1 ? index + 1 : "ไม่มีข้อถูก";
  };

  return (
    <Div>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDeleteExamset}
        title="Delete"
        content={`Examset: ${currentData?.name}`}
        confirmName={"Delete"}
      />
      <SubContentDialog
        open={isOpenModalDetail}
        title={currentExamSet?.name}
        onClose={handleCloseModalDetail}
        maxWidth="md"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Stack>
                <Grid container spacing={2} alignItems="baseline">
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      icon={Quiz}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      label={currentExamSet?.question_type}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      icon={Time}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      label={`${currentExamSet?.exam_time} Min`}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      icon={Level}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      label={`Level ${currentExamSet?.level} `}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      icon={Question}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      label={`${currentExamSet?.amount_question} questions`}
                    />
                  </Grid>
                </Grid>
              </Stack>

              <Typography variant="h6" color="#172B4D">
                Covered skills
              </Typography>
              <Typography variant="body1" color="initial">
                <ul>
                  {currentExamSet?.topic_name?.split(",").map((item) => (
                    <li>
                      <Typography variant="body1" color="initial" gutterBottom>
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={4}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                    color="initial"
                  >
                    Subject
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {currentExamSet?.subject_name}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                    color="initial"
                  >
                    Exam Mode
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {currentExamSet?.exam_mode}
                  </Typography>
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600 }}
                  color="initial"
                >
                  Description
                </Typography>
                <Typography variant="body1" color="initial">
                  {currentExamSet?.description}
                </Typography>
              </Stack>

              <Stack spacing={2}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600 }}
                  color="initial"
                >
                  Author
                </Typography>
                <Typography variant="body1" color="initial">
                  {currentExamSet?.author}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>

      <SubContentDialog
        fullScreen
        title="Filter"
        open={isOpenModalFilter}
        onClose={handleCloseDialog}
      >
        <Grid container>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Select Library{" "}
              </Typography>
              <Checkbox
                id="check-center-library"
                checked={filter?.is_published === 1}
                label=" Center library"
                onChange={() => onChangeFilter("is_published", 1)}
              />
              <Checkbox
                id="check-my-library"
                checked={filter?.is_published === 0}
                label="My library"
                onChange={() => onChangeFilter("is_published", 0)}
              />
            </Stack>

            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Test Type{" "}
              </Typography>
              <Checkbox
                id="check-video"
                label="Video"
                checked={filter?.question_type?.includes("video")}
                onChange={(e) =>
                  onChangeMultiCheck("question_type", e.target.checked, "video")
                }
              />
              <Checkbox
                id="check-essay"
                label="Essay"
                checked={filter?.question_type?.includes("essay")}
                onChange={(e) =>
                  onChangeMultiCheck("question_type", e.target.checked, "essay")
                }
              />
              <Checkbox
                id="check-multiple"
                label="Multiple-choice"
                checked={filter?.question_type?.includes("multiple-choice")}
                onChange={(e) =>
                  onChangeMultiCheck(
                    "question_type",
                    e.target.checked,
                    "multiple-choice"
                  )
                }
              />
              <Checkbox
                id="check-file"
                label="File Upload"
                checked={filter?.question_type?.includes("fileupload")}
                onChange={(e) =>
                  onChangeMultiCheck(
                    "question_type",
                    e.target.checked,
                    "fileupload"
                  )
                }
              />
              <Checkbox
                id="check-etc"
                label="Etc."
                checked={filter?.question_type?.includes("etc")}
                onChange={(e) =>
                  onChangeMultiCheck("question_type", e.target.checked, "etc")
                }
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Date Posted
              </Typography>
              <Checkbox variant="" label="Last Hour" />
              <Checkbox label="Last 24 Hour" />
              <Checkbox label="Last 7 Days" />
              <Checkbox label="Last 14 Days" />
              <Checkbox label="Last 30 Days" />
              <Checkbox label="All" />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Test Level
              </Typography>
              <Checkbox
                id="check-level-1"
                label="1 Level"
                checked={filter?.level?.includes("1")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "1")
                }
              />
              <Checkbox
                id="check-level-2"
                label="2 Level"
                checked={filter?.level?.includes("2")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "2")
                }
              />
              <Checkbox
                id="check-level-3"
                label="3 Level"
                checked={filter?.level?.includes("3")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "3")
                }
              />
              <Checkbox
                id="check-level-4"
                label="4 Level"
                checked={filter?.level?.includes("4")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "4")
                }
              />
              <Checkbox
                id="check-level-5"
                label="5 Level"
                checked={filter?.level?.includes("5")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "5")
                }
              />
              <Checkbox
                id="check-level-6"
                label="6 Level"
                checked={filter?.level?.includes("6")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "6")
                }
              />
              <Checkbox
                id="check-level-7"
                label="7 Level"
                checked={filter?.level?.includes("7")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "7")
                }
              />
              <Checkbox
                id="check-level-8"
                label="8 Level"
                checked={filter?.level?.includes("8")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "8")
                }
              />
              <Checkbox
                id="check-level-9"
                label="9 Level"
                checked={filter?.level?.includes("9")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "9")
                }
              />
              <Checkbox
                id="check-level-10"
                label="10 Level"
                checked={filter?.level?.includes("10")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "10")
                }
              />
              <Checkbox
                id="check-level-11"
                label="11 Level"
                checked={filter?.level?.includes("11")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "11")
                }
              />
              <Box px={1}>
                <Button variant="text" startIcon={<AddIcon />}>
                  View more
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </SubContentDialog>

      <SubContentDialog
        title=""
        open={isOpenModalQuestion}
        onClose={handleCloseModalQuestion}
        maxWidth="md"
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Stack spacing={2} sx={{}}>
              <Typography variant="subtitle1" color="initial" gutterBottom>
                Sample:{" "}
                <Typography
                  variant="subtitle1"
                  color="primary"
                  component={"span"}
                >
                  {examSetDisplay?.topic_name}
                </Typography>
              </Typography>

              <Typography variant="body1" color="initial" gutterBottom>
                {DisplyDeltaOrHtml(examSetDisplay?.choice_description)}
                {examSetDisplay?.question_image !== "" ? (
                  <div>
                    <img
                      className="question-img"
                      src={examSetDisplay?.question_image}
                    />
                  </div>
                ) : (
                  ""
                )}
                <ol>
                  {examSetDisplay?.choice?.map((choice, index) => (
                    <li key={index}>
                      {DisplyDeltaOrHtml(choice?.choice_description)}
                      {choice?.image_url !== "" ? (
                        <div>
                          <img
                            width={200}
                            className="question-img"
                            src={choice?.image_url}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ol>
              </Typography>

              <Typography variant="body1" color="initial" gutterBottom>
                Correct answer : {findAnswer(examSetDisplay?.choice)}
              </Typography>
            </Stack>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={2}
            >
              <ButtonIcon
                sx={{
                  borderRadius: "50%",
                  bgcolor: "primary.main",
                }}
                disabled={
                  examsetQuestionById.length === 0 || displayQuestion === 0
                }
                onClick={() => setDisplayQuestion(displayQuestion - 1)}
                color="white"
              >
                <ArrowBack />
              </ButtonIcon>
              <ButtonIcon
                sx={{ borderRadius: "50%", bgcolor: "primary.main" }}
                disabled={examsetQuestionById.length === displayQuestion + 1}
                onClick={() => setDisplayQuestion(displayQuestion + 1)}
                color="white"
              >
                <ArrowForward />
              </ButtonIcon>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>

      <Grid container direction="column" spacing={4}>
        <Grid
          item
          xs
          container
          sx={{ display: { xs: "none", sm: "flex" } }}
          spacing={2}
        >
          <Grid xs={12} sm={2} item>
            <Button block>TestSet</Button>
          </Grid>
          <Grid xs={12} sm={2} item>
            <Button
              block
              outlined
              onClick={() => history.push("/test/questions")}
            >
              Question
            </Button>
          </Grid>
        </Grid>

        <Grid item container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}></Grid>
          <Grid item xs={12} sm={8} md={9} container spacing={4}>
            <Grid
              item
              xs={12}
              sm={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="h5" color="initial">
                    Your Test Set
                  </Typography>

                  <Button variant="text" onClick={() => handleOpenDialog()}>
                    <Typography
                      sx={{
                        display: { xs: "flex", sm: "none" },
                        textTransform: "initial",
                      }}
                      variant="h4"
                      color="#696969"
                    >
                      Filter
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button
                  block
                  onClick={() => history.push("/assessments/create-test")}
                >
                  Create Test
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: { xs: "transparency", sm: "#f5f7fc" },
              }}
              p={{ xs: 0, sm: 2 }}
            >
              <Stack spacing={2} justifyContent="flex-start">
                <Stack spacing={2}>
                  <Typography variant="subtitle1" color="initial">
                    Search by Keywords
                  </Typography>

                  <TextField
                    type="search"
                    placeholder="Search"
                    innerIconFieldStart={<Search />}
                  />
                </Stack>

                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Select Library{" "}
                    </Typography>
                    <Checkbox
                      id="check-center-library"
                      checked={filter?.is_published === 1}
                      label=" Center library"
                      onChange={() => onChangeFilter("is_published", 1)}
                    />
                    <Checkbox
                      id="check-my-library"
                      checked={filter?.is_published === 0}
                      label="My library"
                      onChange={() => onChangeFilter("is_published", 0)}
                    />
                  </Stack>
                </Box>

                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Test Type{" "}
                    </Typography>
                    <Checkbox
                      id="check-video"
                      label="Video"
                      checked={filter?.question_type?.includes("video")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "video"
                        )
                      }
                    />
                    <Checkbox
                      id="check-essay"
                      label="Essay"
                      checked={filter?.question_type?.includes("essay")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "essay"
                        )
                      }
                    />
                    <Checkbox
                      id="check-multiple"
                      label="Multiple-choice"
                      checked={filter?.question_type?.includes(
                        "multiple-choice"
                      )}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "multiple-choice"
                        )
                      }
                    />
                    <Checkbox
                      id="check-file"
                      label="File Upload"
                      checked={filter?.question_type?.includes("fileupload")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "fileupload"
                        )
                      }
                    />
                    <Checkbox
                      id="check-etc"
                      label="Etc."
                      checked={filter?.question_type?.includes("etc")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "etc"
                        )
                      }
                    />
                  </Stack>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Date Posted
                    </Typography>
                    <Checkbox label="Last Hour" />
                    <Checkbox label="Last 24 Hour" />
                    <Checkbox label="Last 7 Days" />
                    <Checkbox label="Last 14 Days" />
                    <Checkbox label="Last 30 Days" />
                    <Checkbox label="All" />
                  </Stack>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Test Level
                    </Typography>
                    <Checkbox
                      id="check-level-1"
                      label="1 Level"
                      checked={filter?.level?.includes("1")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "1")
                      }
                    />
                    <Checkbox
                      id="check-level-2"
                      label="2 Level"
                      checked={filter?.level?.includes("2")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "2")
                      }
                    />
                    <Checkbox
                      id="check-level-3"
                      label="3 Level"
                      checked={filter?.level?.includes("3")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "3")
                      }
                    />
                    <Checkbox
                      id="check-level-4"
                      label="4 Level"
                      checked={filter?.level?.includes("4")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "4")
                      }
                    />
                    <Checkbox
                      id="check-level-5"
                      label="5 Level"
                      checked={filter?.level?.includes("5")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "5")
                      }
                    />
                    <Checkbox
                      id="check-level-6"
                      label="6 Level"
                      checked={filter?.level?.includes("6")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "6")
                      }
                    />
                    <Checkbox
                      id="check-level-7"
                      label="7 Level"
                      checked={filter?.level?.includes("7")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "7")
                      }
                    />
                    <Checkbox
                      id="check-level-8"
                      label="8 Level"
                      checked={filter?.level?.includes("8")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "8")
                      }
                    />
                    <Checkbox
                      id="check-level-9"
                      label="9 Level"
                      checked={filter?.level?.includes("9")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "9")
                      }
                    />
                    <Checkbox
                      id="check-level-10"
                      label="10 Level"
                      checked={filter?.level?.includes("10")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "10")
                      }
                    />
                    <Checkbox
                      id="check-level-11"
                      label="11 Level"
                      checked={filter?.level?.includes("11")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "11")
                      }
                    />

                    <Box px={1}>
                      <Button variant="text" startIcon={<AddIcon />}>
                        View more
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9} container spacing={4}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="initial">
                    Showing {examSetList?.length} of {total} Test
                  </Typography>

                  <Button variant="text" onClick={() => handleOpenDialog()}>
                    <Typography
                      sx={{
                        display: { xs: "flex", sm: "none" },
                        textTransform: "initial",
                      }}
                      variant="h4"
                      color="#696969"
                    >
                      Filter
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={3}>
                <Select defaultValue="Sort by date" value={""} items={[]} />{" "}
              </Grid>
            </Grid>
            {examSetList?.length > 0 ? (
              examSetList.map((item, index) => (
                <Grid container item xs={12} sm={6} md={4} key={index}>
                  <TestCard
                    headerLink={`/assessments/create-test/${item?.id}`}
                    textBtn="Sample"
                    data={item}
                    onDetail={() => handleOpenModalDetail(item)}
                    onInsert={() => handleOpenModalQuestion(item)}
                    onDelete={() => handleOpenConfirmDialog(item)}
                    isDisabledDelete={filter?.is_published === 1 ? true : false}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sm={12}>
                <Typography
                  p={4}
                  align="center"
                  variant="subtitle1"
                  color="primary"
                >
                  NO DATA
                </Typography>
              </Grid>
            )}

            {examSetList?.length > 0 && (
              <Grid xs={12} item>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={onChangePage}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default TestSet;
