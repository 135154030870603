import styled from "styled-components";
import { Link, NavLink } from "react-router-dom";

import formatValue from "../utils/formatValue";

import imgLogo from "../assets/images/fuse-on-logo-ch.png";

// import iconNoti from "../../../assets/images/icon-noti.svg";
// import iconBag from "../../../assets/images/icon-bag.svg";
import Button from "../components/form/Button";

import Hamburger from "../assets/icon/hamburger.png";
import Credit from "../assets/icon/credit.png";

const Div = styled.header`
  width: 100%;
  max-width: var(--width-screen);
  padding: var(--padding);
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  background: var(--background2);

  .logo {
    width: 25%;
    max-width: 200px;
  }

  .hamburger-menu {
    float: right;
    img {
      cursor: pointer;
    }
    @media screen and (min-width: 769px) {
      display: none;
    }
  }

  .main-menu {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 3;

    .nav-link-to,
    .login-panel {
      display: flex;
      align-items: center;

      @media screen and (max-width: 768px) {
        display: none;
      }

      > a {
        font-size: var(--body2);
        margin-left: 3vw;
        color: var(--text-gray);
        text-decoration: none;

        :active,
        :focus {
          color: var(--text-blue);
        }
        &.login {
          margin-left: 0;
          margin-right: 2vw;
        }
        /* &.menu-noti,
        &.menu-bag {
          margin-left: 26px;

          > img {
            display: block;
            width: 18px;
          }
        }

        &.menu-profile {
          display: block;
          min-width: 32px;
          height: 32px;
          padding: 4px;
          margin-left: 26px;
          text-align: center;
          color: var(--disabled);
          background-color: var(--text-body);
          border-radius: 50%;
          text-decoration: none !important;
        } */

        &:hover {
          text-decoration: underline;
        }
      }
    }
    .nav-link-to {
      margin-left: 3vw;
    }

    /* .login-panel {
      > button {
        width: 10vw;
      }
    } */
  }
`;

const Header = () => {
  const accessToken = localStorage.getItem("access_token");

  const renderLoginPanel = () => {
    return accessToken ? (
      <>
        <Link className="login" to="#">
          Login
        </Link>
        <Button>Register</Button>
      </>
    ) : (
      <>
        <span>
          <img src={Credit} alt="" /> {formatValue("Decimal", 1000)}
        </span>
        {/* <Link className="menu-noti" to="#">
            <img src={iconNoti} />
          </Link>
          <Link className="menu-bag" to="#">
            <img src={iconBag} />
          </Link>
          <Link className="menu-profile" to="#">
            N
          </Link> */}
      </>
    );
  };
  return (
    <Div>
      <img className="logo" src={imgLogo} />
      {/* <div className="main-menu">
        <div className="nav-link-to">
          <NavLink className="menu-product" to="/assessments">
            My Assessments
          </NavLink>
          <NavLink className="menu-register" to="candidates">
            My Candidates
          </NavLink>
          <NavLink className="menu-our-team" to="test">
            Test
          </NavLink>
        </div>

        <div className="login-panel">{renderLoginPanel()}</div>
      </div>
      <div className="hamburger-menu">
        <img c src={Hamburger} alt="" />
      </div> */}
    </Div>
  );
};

export default Header;
