import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "../form/Button";
import Stack from "../layout/Stack";

const Input = styled("input")({
  display: "none",
});

const ButtonUpload = ({ id, onChange, ...props }) => {
  return (
    <label htmlFor={id}>
      <Input
        accept="image/*"
        id={id}
        onChange={onChange}
        //    multiple
        type="file"
      />
      <Button component="span" {...props}>
        Upload Image
      </Button>
    </label>
  );
};

export default ButtonUpload;
