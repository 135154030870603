import axios from "axios";

import { BASE_API } from "configs/app";

const fetchApi = async (options = {}) => {
  const { url, headers, ...restOptions } = options;
  const accessToken = localStorage.getItem("access_token");
  const defaultOptions = {
    baseURL: BASE_API,
    timeout: 50000,
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
    data: {},
    params: {},
  };

  if (accessToken) {
    defaultOptions.headers.Authorization = `Bearer ${accessToken}`;
  }

  defaultOptions.headers = {
    ...defaultOptions.headers,
    ...headers,
  };

  try {
    const { data } = await axios.request({
      url,
      ...defaultOptions,
      ...restOptions,
    });

    return data;
  } catch (error) {
    // if (error.response?.status === 401) {
    //   localStorage.removeItem('access_token')
    //   localStorage.removeItem('user')
    //   window.location.href = '/login'
    //   return null
    // }

    const { response: { data = {} } = {}, message } = error;
    const responseMessage = typeof data === "string" ? data : data.message;

    return Promise.reject(responseMessage || message);
  }
};

export const getAPI = (options = {}) => fetchApi(options);

export const postAPI = (options = {}) =>
  fetchApi({
    ...options,
    method: "POST",
  });

export const putAPI = async (options = {}) =>
  fetchApi({
    ...options,
    method: "PUT",
  });

export const patchAPI = async (options = {}) =>
  fetchApi({
    ...options,
    method: "PATCH",
  });

export const deleteAPI = async (options = {}) =>
  fetchApi({
    ...options,
    method: "DELETE",
  });
