import React from "react";
import styled from "styled-components";
import { Grid as GridMui } from "@mui/material";

const StyleGrid = styled(GridMui)``;

const Grid = ({ classname, ...props }) => {
  return <StyleGrid className={classname} {...props} />;
};

export default Grid;
