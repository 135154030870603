import {
  TOGGLE_LOGIN,
  TRIGGER_LOGOUT,
  GET_MEMBER_INFO,
  CLEAR_FORM_INDIVIDUAL_MEMBER,
  UPDATE_FORM_INDIVIDUAL,
  UPDATE_FORM_CONDITIONS,
  UPDATE_FORM_TESTER,
  UPDATE_FORM_CONDITIONS_TESTER,
} from "redux/actionTypes";
import { INITIAL_ADDRESS } from "utils/dataConfig";

const INITIAL_INDIVIDUAL_MEMBER = {
  email: "",
  first_name: "",
  last_name: "",
  phone: "",
  password: "",
  confirmpassword: "",
  address: [INITIAL_ADDRESS],
};

const initialState = {
  formIndividual: INITIAL_INDIVIDUAL_MEMBER,
  conditions: "",
  formTester: INITIAL_INDIVIDUAL_MEMBER,
  conditionsTester: "",
  memberInfo: {},
  openLogin: false,
  triggerLogout: false,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case TOGGLE_LOGIN:
      return {
        ...state,
        openLogin: action.payload,
      };
    case TRIGGER_LOGOUT:
      return {
        ...state,
        triggerLogout: action.payload,
      };
    case UPDATE_FORM_INDIVIDUAL:
      return {
        ...state,
        formIndividual: action.payload,
      };
    case UPDATE_FORM_CONDITIONS:
      return {
        ...state,
        conditions: action.payload,
      };
    case UPDATE_FORM_TESTER:
      return {
        ...state,
        formTester: action.payload,
      };
    case UPDATE_FORM_CONDITIONS_TESTER:
      return {
        ...state,
        conditionsTester: action.payload,
      };
    case CLEAR_FORM_INDIVIDUAL_MEMBER:
      return {
        ...state,
        formIndividual: INITIAL_INDIVIDUAL_MEMBER,
        conditions: "",
      };
    case GET_MEMBER_INFO.SUCCESS:
      return {
        ...state,
        memberInfo: action.payload,
      };

    default:
      return state;
  }
};
