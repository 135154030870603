import { useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useLocation,
} from "react-router-dom";
import SlideAlert from "containers/SlideAlert";
import MainLoading from "containers/MainLoading";

import Home from "../pages/Home";
import AssessmentMain from "../pages/assessments/AssessmentMain";
import AssessmentDetail from "../pages/assessments/AssessmentDetail";
import AssessmentCreate from "../pages/assessments/AssessmentCreate";
import AssessmentSelectTest from "../pages/assessments/AssessmentSelectTest";
import AssessmentCreateTest from "../pages/assessments/AssessmentCreateTest";
import AssessmentCreateTestEdit from "../pages/assessments/AssessmentCreateTestEdit";
import AssessmentCreateTestNew from "../pages/assessments/AssessmentCreateTestNew";
import AssessmentAddExamSet from "../pages/assessments/AssessmentAddExamSet";
import AssessmentOrdering from "../pages/assessments/AssessmentOrdering";
import AssessmentAddQuestion from "../pages/assessments/AssessmentAddQuestion";
import CreateCustomQuestion from "../pages/assessments/CreateCustomQuestion";
import AssessmentReview from "../pages/assessments/AssessmentReview";
import DraftEmail from "../pages/assessments/DraftEmail";
import Report from "pages/report/Report";
import SubDetailReport from "pages/report/SubDetailReport";

import MainLayout from "../layouts/MainLayout";

import SecureRoute from "./SecureRoute";
import TestSet from "pages/createtest/TestSet";
import Questions from "pages/createtest/Questions";
import SubDetailQuestion from "pages/createtest/SubDetailQuestion";
import ForgetPassword from "pages/register/ForgetPassword";
import SuccessForget from "pages/register/SuccessForget";
import ResetPassword from "pages/register/ResetPassword";
import SuccessReset from "pages/register/SuccessReset";
import Verify from "pages/register/Verify";
import Individual from "pages/register/Individual";
import IndividualTester from "pages/register/IndividualTester";
import Business from "pages/register/Business";
import Conditions from "pages/register/Conditions";
import Success from "pages/register/Success";
import SuccessBusiness from "pages/register/SuccessBusiness";
import Expired from "pages/register/Expired";
import NotFound from "./NotFound";
import VerifyBusiness from "pages/register/VerifyBusiness";
import Welcome from "pages/register/Welcome";
import ConditionsTester from "pages/register/ConditionsTester";
import SuccessTester from "pages/register/SuccessTester";
import SecureRouteBusiness from "./SecureRouteBusiness";
import Error from "pages/register/Error";

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const Routes = ({ theme }) => {
  return (
    <Router>
      <MainLayout>
        <ScrollToTop />

        <Switch>
          <Route path="/" component={Home} exact />
          <Route path="/forget-password" component={ForgetPassword} exact />
          <Route path="/success-forget" component={SuccessForget} exact />
          <Route path="/sendResetPwd" component={ResetPassword} exact />
          <Route path="/success-reset" component={SuccessReset} exact />
          <Route path="/verifySignup" component={Verify} exact />
          <Route
            path="/confirmUpgradeBusiness"
            component={VerifyBusiness}
            exact
          />
          <Route path="/expired" component={Expired} exact />
          <Route path="/welcome" component={Welcome} exact />

          <Route path="/register/individual" component={Individual} exact />
          <Route path="/register/tester" component={IndividualTester} exact />
          <Route path="/register/conditions" component={Conditions} exact />
          <Route
            path="/register/conditions-tester"
            component={ConditionsTester}
            exact
          />
          <Route path="/register/business" component={Business} exact />
          <Route path="/register/success" component={Success} exact />
          <Route
            path="/register/success-business"
            component={SuccessBusiness}
            exact
          />
          <Route
            path="/register/success-tester"
            component={SuccessTester}
            exact
          />

          {/* <Route path="/login" component={Login} exact />
          <Route path="/logout" component={Logout} exact /> */}
          <SecureRouteBusiness
            path="/assessments"
            component={AssessmentMain}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create"
            component={AssessmentCreate}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/select-test"
            component={AssessmentSelectTest}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create-test"
            component={AssessmentCreateTest}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create-test-new"
            component={AssessmentCreateTestNew}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create-test/add-exam-set"
            component={AssessmentAddExamSet}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create-test/ordering"
            component={AssessmentOrdering}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create-test/:id"
            component={AssessmentCreateTestEdit}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/add-question"
            component={AssessmentAddQuestion}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/create-custom/:type/:id?"
            component={CreateCustomQuestion}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/review"
            component={AssessmentReview}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/draft-email"
            component={DraftEmail}
            exact
          />
          <SecureRouteBusiness
            path="/assessments/:id"
            component={AssessmentDetail}
            exact
          />
          <SecureRouteBusiness
            path="/report/:slug/detail/:id"
            component={SubDetailReport}
            exact
          />
          <SecureRouteBusiness path="/test/testset" component={TestSet} exact />
          <SecureRouteBusiness
            path="/test/questions"
            component={Questions}
            exact
          />
          <SecureRouteBusiness
            path="/test/questions/:id"
            component={SubDetailQuestion}
            exact
          />
          <SecureRouteBusiness path="/report/:id" component={Report} exact />

          <Route path="/404" component={NotFound} exact />
          <Route path="/error" component={Error} exact />
          <Redirect path="*" to={"404"} exact />
        </Switch>
        <SlideAlert />
        <MainLoading />
      </MainLayout>
    </Router>
  );
};

export default Routes;
