import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/form/Button";

import Grid from "components/layout/Grid";
import Stack from "components/layout/Stack";

import Box from "components/surfaces/Box";

import { Container, Typography } from "@mui/material";

import SuccessIcon from "assets/icon/success.png";
import { clearFormIndividualMember } from "redux/actions/register";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const Success = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);
  const email = localStorage.getItem("email");

  useEffect(() => {
    return () => {
      dispatch(clearFormIndividualMember());
    };
  }, []);

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Stack
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" align="center" color="initial">
                <img width="100" alt="success" src={SuccessIcon} />
              </Typography>

              <Typography
                variant="h2"
                color="initial"
                align="center"
                gutterBottom
              >
                Thank you
              </Typography>

              <Typography variant="body1" align="center" color="initial">
                We sent an email to
              </Typography>

              <Typography variant="h5" align="center" color="initial">
                {email}
              </Typography>

              <Typography variant="body1" align="center" color="initial">
                Click confirmation link in the mail to verify your account
              </Typography>

              <Stack
                direction={{ xs: "column", sm: "row" }}
                alignItems="center"
                justifyContent="center"
                spacing={2}
              >
                <Button outlined onClick={() => history.push("/")}>
                  Go to Home
                </Button>
                {/* <Button onClick={() => history.push("business")}>
                  Free Upgrade to Business Account 30 Day Trial
                </Button> */}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default Success;
