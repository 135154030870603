import { getAPI, postAPI } from "utils/api";
import { showLoading, hideLoading } from "redux/actions/loading";
import {} from "../actionTypes";

import { UPLOAD_IMAGE, UPLOAD_VIDEO, TOGGLE_LOGIN } from "../actionTypes";

export const toggleLogin = (bool) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: TOGGLE_LOGIN, payload: bool });
  dispatch(hideLoading());
};

export const uploadImage = (file) => (dispatch) => {
  const form_data = new FormData();
  form_data.append("file", file);

  dispatch({ type: UPLOAD_IMAGE.REQUEST });

  return postAPI({
    url: "front_upload_image",
    data: form_data,
  })
    .then(({ path }) => {
      return dispatch({
        type: UPLOAD_IMAGE.SUCCESS,
        path,
        showAlert: true,
        alertMessage: "Upload Image Success",
      });
    })
    .catch((error) => dispatch({ type: UPLOAD_IMAGE.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const uploadVideo = (file) => (dispatch) => {
  const form_data = new FormData();
  form_data.append("file", file);

  dispatch({ type: UPLOAD_VIDEO.REQUEST });

  return postAPI({
    url: "front_upload_vdo",
    data: form_data,
  })
    .then(({ path }) => {
      return dispatch({
        type: UPLOAD_VIDEO.SUCCESS,
        path,
        showAlert: true,
        alertMessage: "Upload Video Success",
      });
    })
    .catch((error) => dispatch({ type: UPLOAD_VIDEO.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
