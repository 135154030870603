import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import {
  AddCircle as AddIcon,
  AccessTime as Clock,
  RemoveRedEye as Eye,
  ArrowForward,
  ArrowBack,
  Search,
} from "@mui/icons-material";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Avatar,
} from "@mui/material";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";

import Stepper from "../../components/navigation/Stepper";
import Pagination from "../../components/navigation/Pagination";

import Button from "../../components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import Select from "../../components/form/Select";
import TextField from "../../components/form/TextField";
import Checkbox from "../../components/form/Checkbox";
import Chip from "../../components/form/Chip";

import SubContentDialog from "../../components/dialog/SubContentDialog";

import BoxIcon from "../../containers/BoxIcon";
import TestCard from "containers/TestCard";

import Quiz from "../../assets/icon/quiz.png";
import Language from "../../assets/icon/language.png";
import Question from "../../assets/icon/essay.png";
import Time from "../../assets/icon/time.png";
import Level from "../../assets/icon/level.png";
import AvatarIcon from "../../assets/icon/avatar.png";
import error from "../../assets/icon/error.png";

import { INITIAL_SCENARIOS } from "utils/dataConfig";

import {
  getExamSetList,
  saveFormAssessmentSelcetTest,
  saveSummaryTest,
} from "../../redux/actions/assessments";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;

  .background-menu-filter {
    background-color: #f5f7fc;
    border-radius: 8px;
    /* margin: 0 auto; */
  }

  .headline {
    color: #005cc9;
  }
`;

const steps = [
  "Assessment Details",
  "Select Test",
  "Add Question",
  "Review & Finishing",
];

const ARR_NUMBER = [1, 2, 3, 4, 5];

const AssessmentSelectTest = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    examSetList,
    pageCount,
    limit,
    total,
    formAssessmentSelectTest,
    formAssessmentDetails,
  } = useSelector((state) => state.assessments);

  const [isOpenModalFilter, setIsOpenModalFilter] = useState(false);
  const [isOpenModalDetail, setIsOpenModalDetail] = useState(false);

  const [examSet, setExamSet] = useState([]);
  const [currentOrder, setCurrentOrder] = useState(1);

  const [currentExamSet, setCurrentExamSet] = useState("");

  const [scenarios, setScenarios] = useState(formAssessmentSelectTest);
  const [summaryTest, setSummaryTest] = useState({
    amountTest: 0,
    amountTime: 0,
  });
  const [filter, setFilter] = useState({
    skip: 0,
    is_published: 1,
    question_type: [],
    level: [],
  });
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(getExamSetList(filter));
  }, []);

  useEffect(() => {
    console.log({ currentOrder });
  }, [currentOrder]);

  useEffect(() => {
    console.log({ formAssessmentSelectTest });
    if (formAssessmentSelectTest.length > 0) {
      calculateNextOrder(formAssessmentSelectTest);
    }
  }, [formAssessmentSelectTest]);

  useEffect(() => {
    calculateSummary(scenarios);

    dispatch(saveFormAssessmentSelcetTest(scenarios));
  }, [scenarios]);

  useEffect(() => {
    setTimeout(() => {
      fetchExamSetList();
    }, 250);
  }, [filter]);

  useEffect(() => {
    dispatch(saveSummaryTest(summaryTest));
  }, [summaryTest]);

  const fetchExamSetList = useCallback(() => {
    dispatch(getExamSetList(filter));
  }, [filter]);

  const calculateNextOrder = (oldArr) => {
    let arr = [...oldArr].map((item) => item.order_no);
    let misArr = findDisappearedNumbers(arr);
    let nextOrder = Math.min(...misArr);
    console.log({ currentOrder, arr, misArr, min: nextOrder });

    nextOrder = [...ARR_NUMBER].includes(nextOrder) ? nextOrder : 6;

    setCurrentOrder(nextOrder);
  };

  const calculateSummary = () => {
    let amountTest = 0;
    let amountTime = 0;
    scenarios.forEach((v) => {
      if (v.exam_sets_id) {
        amountTest++;
        amountTime += v.exam_set_time;
      }
    });

    setSummaryTest({
      amountTest,
      amountTime,
    });
  };

  const onChangePage = (event, value) => {
    setPage(value);
    setFilter((prev) => ({
      ...prev,
      skip: limit * (value - 1),
    }));
  };

  const findDisappearedNumbers = (nums) => {
    const map = {};
    const result = [];

    for (let a = 0; a < nums.length; a++) {
      map[nums[a]] = a;
    }

    for (let b = 0; b < nums.length; b++) {
      if (map[b + 1] === undefined) {
        result.push(b + 1);
      }
    }

    return result;
  };

  const onChangeFilter = (fieldName, newValue) => {
    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newValue,
    }));
  };

  const onChangeMultiCheck = (fieldName, value, type) => {
    let newArr = [...filter?.[fieldName]];
    if (value) {
      newArr.push(type);
    } else {
      newArr = newArr.filter((it) => it !== type);
    }

    setFilter((prevState) => ({
      ...prevState,
      [fieldName]: newArr,
    }));
  };

  const handleOpenDialog = () => {
    setIsOpenModalFilter(!isOpenModalFilter);
  };
  const handleCloseDialog = () => {
    setIsOpenModalFilter(!isOpenModalFilter);
  };

  const handleOpenModalDetail = (data) => {
    setCurrentExamSet(data);
    setIsOpenModalDetail(!isOpenModalDetail);
  };

  const handleCloseModalDetail = () => {
    setIsOpenModalDetail(!isOpenModalDetail);
  };

  const handleAddTest = (data) => {
    let newArr = [...scenarios];

    newArr[currentOrder - 1] = {
      label: data?.name,
      exam_sets_id: data?.id,
      order_no: currentOrder,
      exam_set_time: data?.exam_time,
    };

    setScenarios(newArr);
  };

  const handleDeleteTest = (data) => () => {
    let index = data?.order_no - 1;
    let newArr = [...scenarios];
    newArr[index] = [...INITIAL_SCENARIOS][index];

    setScenarios(newArr);
  };

  const setOrderPrevious = (data) => {
    const index = data?.order_no - 1;
    const tempIndex = index - 1;
    const order = data?.order_no;
    const tempOrder = data?.order_no - 1;
    const newArr = [...scenarios];

    const tempData = newArr[tempIndex];

    newArr[tempIndex] = { ...data, order_no: tempOrder };
    newArr[index] = { ...tempData, order_no: order };

    setScenarios(newArr);
  };

  const setOrderNext = (data) => {
    const index = data?.order_no - 1;
    const tempIndex = index + 1;
    const order = data?.order_no;
    const tempOrder = data?.order_no + 1;
    const newArr = [...scenarios];

    const tempData = newArr[tempIndex];

    newArr[tempIndex] = { ...data, order_no: tempOrder };
    newArr[index] = { ...tempData, order_no: order };

    setScenarios(newArr);
  };

  const goNext = () => {
    history.push("add-question");
  };

  return (
    <Div>
      <SubContentDialog
        open={isOpenModalDetail}
        title={currentExamSet?.name}
        onClose={handleCloseModalDetail}
        maxWidth="md"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Stack>
                <Grid container spacing={2} alignItems="baseline">
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      icon={Quiz}
                      label={currentExamSet?.question_type}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      icon={Time}
                      label={`${currentExamSet?.exam_time} Min`}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      icon={Level}
                      label={`Level ${currentExamSet?.level} `}
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <BoxIcon
                      sx={{ border: "none" }}
                      widthButton={50}
                      fontSizeTextVariant="body2"
                      icon={Question}
                      label={`${currentExamSet?.amount_question} questions`}
                    />
                  </Grid>
                </Grid>
              </Stack>

              <Typography variant="h6" color="#172B4D">
                Covered skills
              </Typography>
              <Typography variant="body1" color="initial">
                <ul>
                  {currentExamSet?.topic_name?.split(",").map((item) => (
                    <li>
                      <Typography variant="body1" color="initial" gutterBottom>
                        {item}
                      </Typography>
                    </li>
                  ))}
                </ul>
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Stack spacing={4}>
              <Stack direction="row" justifyContent="space-between">
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                    color="initial"
                  >
                    Subject
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {currentExamSet?.subject_name}
                  </Typography>
                </Stack>
                <Stack direction="column" spacing={2}>
                  <Typography
                    variant="body1"
                    sx={{ fontWeight: 600 }}
                    color="initial"
                  >
                    Exam Mode
                  </Typography>
                  <Typography variant="body1" color="initial">
                    {currentExamSet?.exam_mode}
                  </Typography>
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600 }}
                  color="initial"
                >
                  Description
                </Typography>
                <Typography variant="body1" color="initial">
                  {currentExamSet?.description}
                </Typography>
              </Stack>

              <Stack spacing={2}>
                <Typography
                  variant="body1"
                  sx={{ fontWeight: 600 }}
                  color="initial"
                >
                  Author
                </Typography>
                <Typography variant="body1" color="initial">
                  {currentExamSet?.author}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>

      <SubContentDialog
        fullScreen
        title="Filter"
        open={isOpenModalFilter}
        onClose={handleCloseDialog}
      >
        <Grid container>
          <Stack spacing={2}>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Select Library{" "}
              </Typography>
              <Checkbox
                id="check-center-library"
                checked={filter?.is_published === 1}
                label=" Center library"
                onChange={() => onChangeFilter("is_published", 1)}
              />
              <Checkbox
                id="check-my-library"
                checked={filter?.is_published === 0}
                label="My library"
                onChange={() => onChangeFilter("is_published", 0)}
              />
            </Stack>

            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Test Type{" "}
              </Typography>
              <Checkbox
                id="check-video"
                label="Video"
                checked={filter?.question_type?.includes("video")}
                onChange={(e) =>
                  onChangeMultiCheck("question_type", e.target.checked, "video")
                }
              />
              <Checkbox
                id="check-essay"
                label="Essay"
                checked={filter?.question_type?.includes("essay")}
                onChange={(e) =>
                  onChangeMultiCheck("question_type", e.target.checked, "essay")
                }
              />
              <Checkbox
                id="check-multiple"
                label="Multiple-choice"
                checked={filter?.question_type?.includes("multiple-choice")}
                onChange={(e) =>
                  onChangeMultiCheck(
                    "question_type",
                    e.target.checked,
                    "multiple-choice"
                  )
                }
              />
              <Checkbox
                id="check-file"
                label="File Upload"
                checked={filter?.question_type?.includes("fileupload")}
                onChange={(e) =>
                  onChangeMultiCheck(
                    "question_type",
                    e.target.checked,
                    "fileupload"
                  )
                }
              />
              <Checkbox
                id="check-etc"
                label="Etc."
                checked={filter?.question_type?.includes("etc")}
                onChange={(e) =>
                  onChangeMultiCheck("question_type", e.target.checked, "etc")
                }
              />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Date Posted
              </Typography>
              <Checkbox variant="" label="Last Hour" />
              <Checkbox label="Last 24 Hour" />
              <Checkbox label="Last 7 Days" />
              <Checkbox label="Last 14 Days" />
              <Checkbox label="Last 30 Days" />
              <Checkbox label="All" />
            </Stack>
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                Test Level
              </Typography>
              <Checkbox
                id="check-level-1"
                label="1 Level"
                checked={filter?.level?.includes("1")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "1")
                }
              />
              <Checkbox
                id="check-level-2"
                label="2 Level"
                checked={filter?.level?.includes("2")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "2")
                }
              />
              <Checkbox
                id="check-level-3"
                label="3 Level"
                checked={filter?.level?.includes("3")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "3")
                }
              />
              <Checkbox
                id="check-level-4"
                label="4 Level"
                checked={filter?.level?.includes("4")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "4")
                }
              />
              <Checkbox
                id="check-level-5"
                label="5 Level"
                checked={filter?.level?.includes("5")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "5")
                }
              />
              <Checkbox
                id="check-level-6"
                label="6 Level"
                checked={filter?.level?.includes("6")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "6")
                }
              />
              <Checkbox
                id="check-level-7"
                label="7 Level"
                checked={filter?.level?.includes("7")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "7")
                }
              />
              <Checkbox
                id="check-level-8"
                label="8 Level"
                checked={filter?.level?.includes("8")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "8")
                }
              />
              <Checkbox
                id="check-level-9"
                label="9 Level"
                checked={filter?.level?.includes("9")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "9")
                }
              />
              <Checkbox
                id="check-level-10"
                label="10 Level"
                checked={filter?.level?.includes("10")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "10")
                }
              />
              <Checkbox
                id="check-level-11"
                label="11 Level"
                checked={filter?.level?.includes("11")}
                onChange={(e) =>
                  onChangeMultiCheck("level", e.target.checked, "11")
                }
              />
              <Box px={1}>
                <Button variant="text" startIcon={<AddIcon />}>
                  View more
                </Button>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </SubContentDialog>

      <Grid container direction="column" spacing={4}>
        <Grid
          item
          xs
          container
          // sx={{ display: { xs: "none", sm: "flex" } }}
          spacing={2}
          alignItems="center"
        >
          {" "}
          <Grid xs={2} item sx={{ display: { xs: "flex", sm: "none" } }}>
            <ButtonIcon block onClick={() => history.goBack()}>
              <ArrowBack />
            </ButtonIcon>
          </Grid>
          <Grid sx={{ display: { xs: "none", sm: "flex" } }} sm={2} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
          <Grid xs={8} sm={7} item container>
            <Grid xs={12} sm={6} item>
              <Typography
                className="headline"
                variant="h5"
                align="left"
                gutterBottom
              >
                {formAssessmentDetails?.campaign_name}
              </Typography>
              <Typography
                color="initial"
                variant="body"
                align="left"
                gutterBottom
              >
                <i class="las la-pen"></i> {summaryTest.amountTest} Test ;{" "}
                <i class="las la-clock"></i> {summaryTest.amountTime} Mins
              </Typography>
            </Grid>
            <Grid
              xs={0}
              sm={6}
              sx={{ display: { xs: "none", sm: "flex" } }}
              item
            ></Grid>
          </Grid>
          <Grid xs={4} sm={3} item sx={{ display: { xs: "none", sm: "flex" } }}>
            <Button
              block
              onClick={() => goNext()}
              disabled={currentOrder === 1}
            >
              Process to next step
            </Button>
          </Grid>
          <Grid xs={2} item>
            <ButtonIcon
              block
              sx={{ display: { xs: "flex", sm: "none" } }}
              onClick={() => goNext()}
              disabled={currentOrder === 1}
            >
              <ArrowForward />
            </ButtonIcon>
          </Grid>
        </Grid>
        <Grid item container xs spacing={2}>
          <Grid item xs={12} sx={{ display: { xs: "flex", sm: "none" } }}>
            <Stack spacing={2}>
              <Typography
                variant="h5"
                color="initial"
                align="center"
                gutterBottom
              >
                Create New Assessment
              </Typography>
              <Typography
                variant="h6"
                color="initial"
                align="center"
                gutterBottom
              >
                Select Test
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={12} sm></Grid>
          <Grid item xs={12} sm={8}>
            <Stepper listStep={steps} activeStep={1} />
          </Grid>
          <Grid item xs={12} sm></Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="body1" color="initial">
            Your assessment can include up to 5 tests. Browse from test set that
            we provided below, or you can create your own new test.
          </Typography>
        </Grid>

        <Grid item container xs={12} spacing={3}>
          <Grid item xs={12}>
            <strong>Test Scenario</strong>
          </Grid>
          <Grid item xs={12} sm={12} container spacing={1}>
            {scenarios?.map((item, index) => {
              return (
                <Grid sx={{ height: 48 }} item xs={6} sm key={index}>
                  {[
                    "1st Test",
                    "2nd Test",
                    "3rd Test",
                    "4th Test",
                    "5th Test",
                  ].includes(item?.label) ? (
                    <Button block outlined color="grey" disabled>
                      {item?.label}
                    </Button>
                  ) : (
                    <Chip
                      sx={{ width: "100%", height: "100%", borderRadius: 1 }}
                      label={
                        <Stack spacing={2} direction="row" alignItems="center">
                          {item?.label}{" "}
                          {index !== 4 && (
                            <ArrowForward
                              sx={{
                                position: "absolute",
                                right: 30,
                                cursor: "pointer",
                              }}
                              onClick={() => setOrderNext(item)}
                            />
                          )}
                        </Stack>
                      }
                      // avatar={<ArrowBack />}
                      icon={
                        index !== 0 ? (
                          <ArrowBack onClick={() => setOrderPrevious(item)} />
                        ) : (
                          ""
                        )
                      }
                      // icon={<ArrowForward />}
                      onDelete={handleDeleteTest(item)}
                    />
                  )}
                </Grid>
              );
            })}
          </Grid>
        </Grid>
        <Grid item xs>
          <Stack direction="row" spacing={2}>
            <Avatar
              sx={{ mr: 1, width: 24, height: 24 }}
              alt="alert-error"
              src={error}
            />
            <Typography variant="subtitle1" color="red">
              Adaptive Test Set can not be mixed with other type of test set
              within one campaign. Please create new campaign separately.
            </Typography>
          </Stack>
        </Grid>

        <Grid item container spacing={4} alignItems="flex-start">
          <Grid item xs={12} sm={4} md={3} spacing={4}>
            <Box
              sx={{
                width: "100%",
                backgroundColor: { xs: "transparency", sm: "#f5f7fc" },
              }}
              p={{ xs: 0, sm: 2 }}
            >
              <Stack spacing={2} justifyContent="flex-start">
                <Stack spacing={2}>
                  <Typography variant="subtitle1" color="initial">
                    Search by Keywords
                  </Typography>

                  <TextField
                    type="search"
                    placeholder="Search"
                    innerIconFieldStart={<Search />}
                  />
                </Stack>

                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Select Library{" "}
                    </Typography>
                    <Checkbox
                      id="check-center-library"
                      checked={filter?.is_published === 1}
                      label=" Center library"
                      onChange={() => onChangeFilter("is_published", 1)}
                    />
                    <Checkbox
                      id="check-my-library"
                      checked={filter?.is_published === 0}
                      label="My library"
                      onChange={() => onChangeFilter("is_published", 0)}
                    />
                  </Stack>
                </Box>

                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Test Type{" "}
                    </Typography>
                    <Checkbox
                      id="check-video"
                      label="Video"
                      checked={filter?.question_type?.includes("video")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "video"
                        )
                      }
                    />
                    <Checkbox
                      id="check-essay"
                      label="Essay"
                      checked={filter?.question_type?.includes("essay")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "essay"
                        )
                      }
                    />
                    <Checkbox
                      id="check-multiple"
                      label="Multiple-choice"
                      checked={filter?.question_type?.includes(
                        "multiple-choice"
                      )}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "multiple-choice"
                        )
                      }
                    />
                    <Checkbox
                      id="check-file"
                      label="File Upload"
                      checked={filter?.question_type?.includes("fileupload")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "fileupload"
                        )
                      }
                    />
                    <Checkbox
                      id="check-etc"
                      label="Etc."
                      checked={filter?.question_type?.includes("etc")}
                      onChange={(e) =>
                        onChangeMultiCheck(
                          "question_type",
                          e.target.checked,
                          "etc"
                        )
                      }
                    />
                  </Stack>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Date Posted
                    </Typography>
                    <Checkbox label="Last Hour" />
                    <Checkbox label="Last 24 Hour" />
                    <Checkbox label="Last 7 Days" />
                    <Checkbox label="Last 14 Days" />
                    <Checkbox label="Last 30 Days" />
                    <Checkbox label="All" />
                  </Stack>
                </Box>
                <Box sx={{ display: { xs: "none", sm: "flex" } }}>
                  <Stack spacing={2}>
                    <Typography variant="subtitle1" color="initial">
                      Test Level
                    </Typography>
                    <Checkbox
                      id="check-level-1"
                      label="1 Level"
                      checked={filter?.level?.includes("1")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "1")
                      }
                    />
                    <Checkbox
                      id="check-level-2"
                      label="2 Level"
                      checked={filter?.level?.includes("2")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "2")
                      }
                    />
                    <Checkbox
                      id="check-level-3"
                      label="3 Level"
                      checked={filter?.level?.includes("3")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "3")
                      }
                    />
                    <Checkbox
                      id="check-level-4"
                      label="4 Level"
                      checked={filter?.level?.includes("4")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "4")
                      }
                    />
                    <Checkbox
                      id="check-level-5"
                      label="5 Level"
                      checked={filter?.level?.includes("5")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "5")
                      }
                    />
                    <Checkbox
                      id="check-level-6"
                      label="6 Level"
                      checked={filter?.level?.includes("6")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "6")
                      }
                    />
                    <Checkbox
                      id="check-level-7"
                      label="7 Level"
                      checked={filter?.level?.includes("7")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "7")
                      }
                    />
                    <Checkbox
                      id="check-level-8"
                      label="8 Level"
                      checked={filter?.level?.includes("8")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "8")
                      }
                    />
                    <Checkbox
                      id="check-level-9"
                      label="9 Level"
                      checked={filter?.level?.includes("9")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "9")
                      }
                    />
                    <Checkbox
                      id="check-level-10"
                      label="10 Level"
                      checked={filter?.level?.includes("10")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "10")
                      }
                    />
                    <Checkbox
                      id="check-level-11"
                      label="11 Level"
                      checked={filter?.level?.includes("11")}
                      onChange={(e) =>
                        onChangeMultiCheck("level", e.target.checked, "11")
                      }
                    />

                    <Box px={1}>
                      <Button variant="text" startIcon={<AddIcon />}>
                        View more
                      </Button>
                    </Box>
                  </Stack>
                </Box>
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} sm={8} md={9} container spacing={4}>
            <Grid
              item
              xs={12}
              container
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12} sm>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <Typography variant="body1" color="initial">
                    Showing {examSetList?.length} of {total} Test
                  </Typography>

                  <Button variant="text" onClick={() => handleOpenDialog()}>
                    <Typography
                      sx={{
                        display: { xs: "flex", sm: "none" },
                        textTransform: "initial",
                      }}
                      variant="h4"
                      color="#696969"
                    >
                      Filter
                    </Typography>
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={6} sm={3}>
                <Select defaultValue="Sort by date" value={""} items={[]} />{" "}
              </Grid>
              <Grid item xs={6} sm={3}>
                <Button block onClick={() => history.push("create-test")}>
                  Create Test
                </Button>
              </Grid>
            </Grid>
            {examSetList?.length > 0 ? (
              examSetList.map((item, index) => (
                <Grid container item xs={12} sm={6} md={4} key={index}>
                  <TestCard
                    data={item}
                    onDetail={() => handleOpenModalDetail(item)}
                    onInsert={() => handleAddTest(item)}
                    isDisabled={![...ARR_NUMBER].includes(currentOrder)}
                  />
                </Grid>
              ))
            ) : (
              <Grid item xs={12} sm={12}>
                <Typography
                  p={4}
                  align="center"
                  variant="subtitle1"
                  color="primary"
                >
                  NO DATA
                </Typography>
              </Grid>
            )}

            {examSetList?.length > 0 && (
              <Grid xs={12} item>
                <Pagination
                  count={pageCount}
                  page={page}
                  onChange={onChangePage}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default AssessmentSelectTest;
