import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";

import Typography from "@mui/material/Typography";
import Taps from "components/surfaces/Taps";
import Paper from "components/surfaces/Paper";
import TabPanel from "components/surfaces/TabPanel";
import Stack from "components/layout/Stack";
import Checkbox from "components/form/Checkbox";
import {
  upgradeTester,
  saveFormConditionsTester,
} from "redux/actions/register";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const ConditionsTester = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formTester, conditionsTester } = useSelector(
    (state) => state.register
  );
  const [tabActive, setTabActive] = useState(0);
  const [form, setForm] = useState(conditionsTester?.split(","));

  const onChangeForm = (index, value) => {
    let newArr = [...form];

    if (value) {
      newArr[index] = index + 1;
    }
    setForm([...newArr]);
    dispatch(saveFormConditionsTester(newArr.join(",")));
  };

  const createMember = () => {
    let haveAddress = formTester?.address?.[0].province_id;
    let body = {
      ...formTester,
      consent_accept: form.join(","),
      address: haveAddress ? formTester.address : [],
    };
    dispatch(upgradeTester(body)).then((data) => {
      if (![400, 401].includes(data?.code)) {
        history.push("success-tester");
      }
    });
  };

  return (
    <Div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <Stack spacing={2} alignItems="center" justifyContent="center">
            <Typography variant="h2" align="center" color="initial">
              Register
            </Typography>
            <Typography
              variant="h5"
              align="center"
              gutterBottom
              color="initial"
            >
              Read Terms and conditions
            </Typography>

            <Paper>
              <Taps
                listTap={[
                  "นโยบายความเป็นส่วนตัว",
                  "นโยบายความเป็นส่วนตัว 2",
                  "นโยบายความเป็นส่วนตัว 3",
                ]}
                variant="scrollable"
                scrollButtons="auto"
                valueCurrentTab={tabActive}
                onChange={(event, newValue) => setTabActive(newValue)}
              />
              <TabPanel value={tabActive} index={0}>
                <Stack spacing={2}>
                  <Typography variant="body1" color="initial" paragraph>
                    Lorem ipsum dolor, sit amet consectetur adipisicing elit.
                    Optio cum consequuntur dolores eligendi dicta iusto velit
                    modi aliquam maxime dolorem temporibus exercitationem, quae
                    amet assumenda possimus hic voluptate pariatur explicabo.
                    Voluptatum sunt veritatis labore saepe debitis vitae quaerat
                    ullam totam nemo maiores quos laudantium quibusdam ex rem,
                    facilis et ab deserunt! Aperiam possimus magnam nisi ab
                    autem at accusantium, quo exercitationem illum odio animi
                    officia tempora dolorem, ipsam perspiciatis dolor.
                    Perferendis, eum minima quos rerum reiciendis ipsa fuga
                    vitae veniam asperiores maiores optio quam quisquam nisi
                    dolor sed laboriosam, libero soluta magni repudiandae
                    aperiam quia totam eius. Fuga odit recusandae error
                    doloremque facere? Ad omnis dolorum necessitatibus eius
                    iste, aliquam dolor, natus beatae vero illum veritatis
                    quisquam tenetur quaerat, temporibus dolore nesciunt cum
                    molestias sint quibusdam neque molestiae sapiente error
                    perferendis. Quae, aperiam fuga dignissimos deleniti iste
                    repudiandae dicta qui, sint illum, aliquam at! Nobis maxime,
                    vel nemo autem voluptates corporis eaque minima nihil, fugit
                    debitis perspiciatis modi aspernatur sed officiis expedita.
                    Exercitationem mollitia qui illum dicta facere dolorum sed
                    obcaecati veniam deserunt explicabo, rem odio nesciunt
                    velit, libero aspernatur maxime at quis neque nulla ullam
                    eligendi. Eius excepturi earum sed, magni quo non illo quis,
                    ea dolorum sunt tempore. Lorem ipsum dolor sit amet,
                    consectetur adipisicing elit. Repudiandae sit recusandae
                    blanditiis deleniti laborum in, cum quibusdam culpa at non,
                    libero aliquam, tempore itaque eius voluptatibus ex
                    explicabo exercitationem ipsum commodi pariatur natus
                    maxime? Esse quis ut ea at harum, sed ipsa culpa soluta
                    ratione molestiae exercitationem sunt eveniet perferendis
                    nostrum voluptatibus, sequi expedita facilis amet assumenda
                    error ducimus quia veritatis est praesentium! Rerum a illo
                    fuga error consequatur nemo earum, omnis amet mollitia,
                    natus dolorum, vel sint nihil esse. Vero id harum expedita
                    numquam odit deleniti voluptate placeat quam. fugit, a enim
                    sit nisi architecto neque eaque sunt dolorem
                  </Typography>
                  <Checkbox
                    id="agree-1"
                    checked={form.includes(1)}
                    label="I agree with privacy policy and terms of services"
                    onChange={(e) => onChangeForm(0, e.target.checked)}
                  />
                </Stack>
              </TabPanel>
              <TabPanel value={tabActive} index={1}>
                <Stack spacing={2}>
                  <Typography variant="body1" color="initial" paragraph>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Minus sunt animi, doloribus, excepturi quisquam laudantium
                    voluptas enim, at blanditiis eaque nesciunt? Dolor corrupti,
                    at ratione repellat deserunt qui beatae nisi assumenda.
                    Iste, vitae at, saepe pariatur facilis quo incidunt
                    accusamus necessitatibus modi debitis similique beatae.
                    Quod, aliquid reiciendis itaque quos voluptate nisi nihil
                    blanditiis praesentium minima sed numquam perferendis natus,
                    rem corrupti nesciunt. Quae officiis doloribus animi sed id
                    dignissimos accusantium recusandae et ipsam iusto quo itaque
                    distinctio magni quam repudiandae at repellat, eveniet ab
                    tenetur debitis eius voluptates dolorum cupiditate amet?
                    Dolor, minima ab explicabo iure, perferendis, esse
                    aspernatur sunt non fugiat voluptas hic. Similique provident
                    quo voluptatibus deleniti harum reprehenderit quaerat qui
                    possimus sed? Doloribus alias nulla obcaecati, architecto
                    aut error dolore dignissimos. Explicabo fugiat iure cum
                    repudiandae sunt modi. Tempora exercitationem voluptatibus
                    pariatur, fugit nobis ipsum consequatur ullam vero
                    consectetur natus, dolorum quod nihil? A eligendi ex
                    cupiditate debitis quisquam esse aperiam voluptates nam
                    voluptate, sit maxime minus molestias tenetur. Quod dolorem
                    cupiditate mollitia corporis cum, porro quae tempore
                    exercitationem ipsam aliquid culpa, iste temporibus. Modi
                    ratione eius earum explicabo iure, aliquid consequuntur
                    nostrum mollitia sint repellendus, ea excepturi maxime
                    nesciunt porro perferendis. Ipsum sequi ipsa et minus
                    temporibus similique dolore alias at, obcaecati commodi
                    quidem consequatur nobis, debitis beatae. Blanditiis hic
                    animi repellat! Ea quos sed accusantium laboriosam quisquam,
                    quas maiores esse eaque quis expedita excepturi tenetur
                    repellendus voluptates suscipit recusandae voluptatibus ad
                    nihil unde assumenda nostrum nesciunt. Omnis nulla
                    dignissimos quaerat voluptatibus, temporibus dolorem
                    accusamus soluta nisi nam nesciunt, quos pariatur reiciendis
                    illum eveniet rem? Officiis odit dignissimos delectus
                    repudiandae eligendi sapiente iure alias magnam fugit
                    itaque, aliquid ab nobis sequi eius enim quas sunt quibusdam
                    velit consequuntur! Pariatur itaque vel adipisci impedit,
                    amet totam illum tenetur nemo molestias architecto, ipsa
                    sequi natus quidem necessitatibus!
                  </Typography>
                  <Checkbox
                    id="agree-2"
                    checked={form.includes(2)}
                    label="I agree with privacy policy and terms of services"
                    onChange={(e) => onChangeForm(1, e.target.checked)}
                  />
                </Stack>
              </TabPanel>
              <TabPanel value={tabActive} index={2}>
                <Stack spacing={2}>
                  <Typography variant="body1" color="initial" paragraph>
                    Lorem ipsum dolor sit, amet consectetur adipisicing elit.
                    Accusantium distinctio quisquam dolore excepturi et unde
                    officiis neque repellendus pariatur sapiente blanditiis vero
                    voluptas alias earum optio tenetur, odio odit sunt totam
                    rerum doloremque! Magni voluptatem, rerum odio quaerat modi
                    illo quam consequatur dicta fugit ipsam provident, mollitia
                    deleniti reiciendis dolorem omnis eligendi temporibus
                    reprehenderit. Deleniti fugiat voluptatibus id mollitia
                    nesciunt porro eum inventore laboriosam facilis! Cum aliquam
                    quibusdam facilis assumenda pariatur! Voluptates beatae eius
                    quia officia, deserunt cum molestias nihil impedit nostrum
                    adipisci tempora sed quos aperiam velit labore rerum,
                    officiis voluptas corrupti excepturi dolores nulla. Fugiat,
                    voluptatem tempore pariatur omnis itaque tenetur! Possimus
                    eligendi dignissimos ratione dolor quae blanditiis sit,
                    ducimus nihil voluptatem temporibus animi est, fugit maxime,
                    dolorem illo facere numquam et praesentium error neque
                    similique quaerat. Fuga aspernatur illo molestiae, ratione
                    facilis dolore expedita aut veritatis dolores atque pariatur
                    at dolor ex nostrum, necessitatibus repellendus voluptatem
                    nisi consequatur esse earum quae? Commodi cumque quas culpa
                    voluptas ullam soluta adipisci cupiditate illum alias quae,
                    praesentium deleniti possimus distinctio enim inventore
                    laboriosam autem maiores? Aliquid, excepturi? Dolorem
                    libero, ea totam aliquam autem at porro odit doloremque,
                    culpa hic dicta natus quidem similique tempore distinctio
                    voluptate voluptatibus cumque alias maxime assumenda placeat
                    earum consequuntur quod? Fugit libero ab cupiditate.
                    Sapiente quia rem consectetur quae nisi ex exercitationem.
                    Nostrum harum, et inventore iste quaerat culpa, facere
                    libero cupiditate illum, voluptatibus asperiores repellat
                    doloremque beatae ex deserunt adipisci? Perspiciatis ad quia
                    eos voluptas qui porro nam commodi praesentium aliquid ipsa
                    maxime fuga quos labore minima voluptatibus velit,
                    asperiores recusandae facere temporibus aperiam eius.
                    Excepturi laboriosam perferendis harum nobis obcaecati sint
                    dolor corrupti maxime libero! Dolorum praesentium itaque
                    tenetur aspernatur, nihil reprehenderit veritatis? Eligendi
                    veniam at ducimus quidem, sequi cum? Quaerat aperiam maiores
                    esse sunt rerum dignissimos deserunt voluptatum eum
                    nesciunt? Expedita, deserunt.
                  </Typography>
                  <Checkbox
                    id="agree-3"
                    checked={form.includes(3)}
                    label="I agree with privacy policy and terms of services"
                    onChange={(e) => onChangeForm(2, e.target.checked)}
                  />{" "}
                </Stack>
              </TabPanel>
            </Paper>

            <Button
              sx={{ width: { xs: "100%", sm: 600 } }}
              disabled={form.length !== 3}
              onClick={() => createMember()}
            >
              Next
            </Button>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </Div>
  );
};

export default ConditionsTester;
