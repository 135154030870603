import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Button from "components/form/Button";

import Grid from "components/layout/Grid";

import Typography from "@mui/material/Typography";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const NotFound = () => {
  const history = useHistory();

  return (
    <Div>
      <Typography variant="h5" color="initial">
        404 Not Found Page
      </Typography>

      <Button onClick={() => history.push("/")}>Go to Home</Button>
    </Div>
  );
};

export default NotFound;
