import { INITIAL_SCENARIOS } from "utils/dataConfig";
import {
  GET_CAMPAIGN_LIST,
  GET_CAMPAIGN_BY_ID,
  GET_QUESTION_BY_ID,
  GET_EXAMSET_BY_ID,
  GET_EXAMSET_QUESTION_BY_ID,
  GET_EXAM_HISTORY_BY_UID,
  GET_EXAM_HISTORY_DETAIL_BY_ID,
  GET_CAMPAIGN_SUMMARY_BY_ID,
  GET_CHOICE_DETAIL,
  GET_CUSTOM_QUESTION_LIST,
  GET_EXAM_SET_LIST,
  GET_EXAM_QUESTION_LIST,
  GET_QUESTION_LIST,
  GET_QUESTION_LIST_RAW,
  UPDATE_TO_MODE_EDIT_CAMPAIGN,
  UPDATE_FORM_ASSESSMENT_DETAIL,
  UPDATE_FORM_ASSESSMENT_SELECT_TEST,
  UPDATE_FORM_ASSESSMENT_CREATE_EXAM,
  UPDATE_FORM_ASSESSMENT_REVIEW,
  UPDATE_EXAM_CUSTOM_LIST,
  UPDATE_FORM_EXAM_SET,
  CLEAR_FORM_EXAM_SET,
  GET_CAMPAIGN_INVITE_LIST,
  CLEAR_FORM_CAMPAIGN,
  SET_SUMMARY_TEST,
} from "../actionTypes";

const INITIAL_ASSESSMENT_DETAIL = {
  campaign_name: "",
  job_role_id: "",
  job_role_level: "",
  campaign_start_date: "",
  campaign_end_date: "",
  campaign_type: "",
  use_in: "",
  campaign_detail: "",
  is_publish: 1,
};

const INITIAL_ASSESSMENT_REVIEW = {
  duration_time: 0,
  extra_time: "",
  is_show_vdo: false,
  is_snapshot: false,
  vdo_url: "",
  vdo_embed: "",
};

const INITIAL_FORM_EXAM_SET = {
  name: "",
  author: "",
  amount_question: "",
  description: "",
  subject_id: "",
  exam_time: "",
  exam_mode: "",
  level: "",
  company_id: 1,
  language: "ไทย",
  question: [],
  is_share: false,
  summary: [
    {
      exam_level: 1,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 2,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 3,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 4,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 5,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 6,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 7,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 8,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 9,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 10,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
    {
      exam_level: 11,
      right_weight: "",
      wrong_weight: "",
      no_answer_weight: "",
    },
  ],
  result_mapping: [
    {
      name: "แย่",
      min_score: 0,
      max_score: "",
      description: "คุณมีความสามารถในระดับแย่",
    },
    {
      name: "ปานกลาง",
      min_score: "",
      max_score: "",
      description: "คุณมีความสามารถในระดับปานกลาง",
    },
    {
      name: "ดี",
      min_score: "",
      max_score: "",
      description: "คุณมีความสามารถในระดับดี",
    },
  ],
  client: [
    {
      company_id: 1, //fix
    },
  ],
};

const initialState = {
  isModeEditCampaign: false,
  limit: 12,
  pageCount: 0,
  skip: 0,
  total: 0,
  campaignList: [],
  campaignById: {},
  examSetList: [],
  customQuestionList: [],
  formAssessmentDetails: INITIAL_ASSESSMENT_DETAIL,
  summaryTest: {
    amountTest: 1,
    amountTime: 1,
  },
  formAssessmentSelectTest: [...INITIAL_SCENARIOS],
  formAssessmentCreateExam: [],
  formAssessmentReview: INITIAL_ASSESSMENT_REVIEW,
  examCustomList: [],
  formExamSet: INITIAL_FORM_EXAM_SET,
  questionList: [],
  questionListRaw: [],
  examQuestionList: [],
  inviteExamerList: [],
  questionById: {},
  examsetById: {},
  examsetQuestionById: [],
  choiceDetailById: [],
  campaignSummary: {},
  examerHistory: {},
  examerHistoryDetail: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CAMPAIGN_LIST.SUCCESS:
      return {
        ...state,
        campaignList: action.payload?.data,
        total: action.payload?.total,
      };
    case GET_CAMPAIGN_BY_ID.SUCCESS:
      return {
        ...state,
        campaignById: action.payload,
      };
    case GET_QUESTION_BY_ID.SUCCESS:
      return {
        ...state,
        questionById: action.payload,
      };
    case GET_EXAMSET_BY_ID.SUCCESS:
      return {
        ...state,
        examsetById: action.payload,
      };
    case GET_EXAMSET_QUESTION_BY_ID.SUCCESS:
      return {
        ...state,
        examsetQuestionById: action.payload,
      };

    case GET_CAMPAIGN_SUMMARY_BY_ID.SUCCESS:
      return {
        ...state,
        campaignSummary: action.payload,
      };
    case GET_EXAM_HISTORY_BY_UID.SUCCESS:
      return {
        ...state,
        examerHistory: action.payload,
      };
    case GET_EXAM_HISTORY_DETAIL_BY_ID.SUCCESS:
      return {
        ...state,
        examerHistoryDetail: action.payload,
      };
    case GET_CHOICE_DETAIL.SUCCESS:
      return {
        ...state,
        choiceDetailById: action.payload,
      };
    case GET_EXAM_SET_LIST.SUCCESS:
      return {
        ...state,
        examSetList: action.payload?.data,
        total: action.payload?.total,
        skip: action.payload?.skip,
        limit: action.payload?.limit,
        pageCount: Math.ceil(action.payload?.total / action.payload?.limit),
      };
    case GET_EXAM_QUESTION_LIST.SUCCESS:
      return {
        ...state,
        examQuestionList: action.payload?.data,
        total: action.payload?.total,
        skip: action.payload?.skip,
        limit: action.payload?.limit,
        pageCount: Math.ceil(action.payload?.total / action.payload?.limit),
      };
    case GET_CAMPAIGN_INVITE_LIST.SUCCESS:
      return {
        ...state,
        inviteExamerList: action.payload?.data,
        total: action.payload?.total,
        skip: action.payload?.skip,
        limit: action.payload?.limit,
        pageCount: Math.ceil(action.payload?.total / action.payload?.limit),
      };
    case GET_CUSTOM_QUESTION_LIST.SUCCESS:
      return {
        ...state,
        customQuestionList: action.payload?.data,
        total: action.payload?.total,
      };
    case UPDATE_TO_MODE_EDIT_CAMPAIGN:
      return {
        ...state,
        isModeEditCampaign: action.payload,
      };
    case UPDATE_FORM_ASSESSMENT_DETAIL:
      return {
        ...state,
        formAssessmentDetails: action.payload,
      };
    case SET_SUMMARY_TEST:
      return {
        ...state,
        summaryTest: action.payload,
      };
    case UPDATE_FORM_ASSESSMENT_SELECT_TEST:
      return {
        ...state,
        formAssessmentSelectTest: action.payload,
      };
    case UPDATE_FORM_ASSESSMENT_CREATE_EXAM:
      return {
        ...state,
        formAssessmentCreateExam: action.payload,
      };
    case UPDATE_FORM_ASSESSMENT_REVIEW:
      return {
        ...state,
        formAssessmentReview: action.payload,
      };
    case UPDATE_EXAM_CUSTOM_LIST:
      return {
        ...state,
        examCustomList: action.payload,
      };
    case UPDATE_FORM_EXAM_SET:
      return {
        ...state,
        formExamSet: action.payload,
      };
    case CLEAR_FORM_EXAM_SET:
      return {
        ...state,
        formExamSet: INITIAL_FORM_EXAM_SET,
      };
    case CLEAR_FORM_CAMPAIGN:
      return {
        ...state,
        formAssessmentDetails: INITIAL_ASSESSMENT_DETAIL,
        formAssessmentSelectTest: [...INITIAL_SCENARIOS],
        formAssessmentCreateExam: [],
        formAssessmentReview: INITIAL_ASSESSMENT_REVIEW,
        examCustomList: [],
        isModeEditCampaign: false,
      };
    case GET_QUESTION_LIST_RAW.SUCCESS:
      return {
        ...state,
        questionListRaw: action.payload?.data.sort((a, b) => a.id - b.id),
      };
    case GET_QUESTION_LIST.SUCCESS:
      return {
        ...state,
        questionList: action.payload?.data.sort((a, b) => a.id - b.id),
        total: action.payload?.total,
        skip: action.payload?.skip,
        limit: action.payload?.limit,
        pageCount: Math.ceil(action.payload?.total / action.payload?.limit),
      };
    default:
      return state;
  }
};
