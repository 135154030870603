import React, { useEffect, useCallback } from "react";
import { useDispatch } from "react-redux";

import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { login } from "../redux/actions/auth";

import Header from "./Header";
import HeaderNew from "./HeaderNew";
import Footer from "./Footer";
import FooterNew from "./FooterNew";
import { Container } from "@mui/material";
import {
  getMasterDataDistrict,
  getMasterDataJob,
  getMasterDataProvince,
  getMasterDataSubDistrict,
  getMasterDataJobLevel,
  getMasterDataSubject,
} from "redux/actions/masterData";

const Div = styled.div`
  .main-content {
    flex: 1;
    min-height: 100vh;
  }
`;

const MainLayout = ({ children, ...props }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accessToken = localStorage.getItem("access_token");

  useEffect(() => {
    handleGetJob();
    handleGetSubject();
    handleGetProvince();
    handleGetDistrict();
    handleGetJobLevel();
    handleGetSubDistrict();
  }, []);

  const handleGetJob = useCallback(() => {
    dispatch(getMasterDataJob());
  }, [dispatch, accessToken]);

  const handleGetJobLevel = useCallback(() => {
    dispatch(getMasterDataJobLevel());
  }, [dispatch, accessToken]);

  const handleGetSubject = useCallback(() => {
    dispatch(getMasterDataSubject());
  }, [dispatch, accessToken]);

  const handleGetProvince = useCallback(() => {
    dispatch(getMasterDataProvince());
  }, [dispatch, accessToken]);

  const handleGetDistrict = useCallback(() => {
    dispatch(getMasterDataDistrict());
  }, [dispatch, accessToken]);

  const handleGetSubDistrict = useCallback(() => {
    dispatch(getMasterDataSubDistrict());
  }, [dispatch, accessToken]);

  return (
    <Div {...props}>
      <Container maxWidth="xl" disableGutters>
        <HeaderNew />
        {/* <Header /> */}
        <div className="main-content">{children}</div>
        {/* <Footer /> */}
        <FooterNew />
      </Container>
    </Div>
  );
};

export default MainLayout;
