import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const StyledLink = styled(Link)`
  color: #0075ca;
  text-decoration: underline;
`;
const RouterLinkCell = ({ to, children, ...props }) => (
  <StyledLink to={to} {...props}>
    {children}
  </StyledLink>
);

export default RouterLinkCell;
