import React from "react";
import styled from "styled-components";

import {
  Pagination as PaginationMui,
  PaginationItem,
  Stack,
} from "@mui/material";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { textAlign } from "@mui/system";

const StylePagination = styled(PaginationMui)(({ theme }) => ({}));

const Pagination = ({ count = 3, ...props }) => {
  return (
    <Stack spacing={2}>
      <StylePagination
        sx={{ mx: "auto" }}
        count={count}
        color="primary"
        size="large"
        renderItem={(item) => (
          <PaginationItem
            components={{ previous: ArrowBack, next: ArrowForward }}
            {...item}
          />
        )}
        {...props}
      />
    </Stack>
  );
};

export default Pagination;
