import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";

import Select from "components/form/Select";
import TextField from "components/form/TextField";

import Stack from "components/layout/Stack";

import Typography from "@mui/material/Typography";
import { createMemberBusiness, getMemberInfo } from "redux/actions/register";
import { INITIAL_ADDRESS } from "utils/dataConfig";
import { validateOrganizationId } from "utils/validation";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const INITIAL_FORM = {
  name: "",
  organization_id: "",
  organization_type: "",
};

const Business = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    masterDataOrganization,
    masterDataProvince,
    masterDataDistrict,
    masterDataSubDistrict,
  } = useSelector((state) => state.masterData);
  const { memberInfo } = useSelector((state) => state.register);

  const [dataProvince, setDataProvince] = useState([]);
  const [dataDistrict, setDataDistrict] = useState([]);
  const [dataSubDistrict, setDataSubDistrict] = useState([]);

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    dispatch(getMemberInfo());
  }, []);

  useEffect(() => {
    setDataProvince(masterDataProvince);
  }, [masterDataProvince]);

  useEffect(() => {
    setForm((prev) => ({
      ...prev,
      member_id: memberInfo.member_id,
      address: [
        {
          address_type: 1,
          address: memberInfo?.address || "",
          road: memberInfo?.road || "",
          province_id: memberInfo?.province_id || "",
          district_id: memberInfo?.district_id || "",
          sub_district_id: memberInfo?.sub_district_id || "",
          zip_code: memberInfo?.zip_code || "",
        },
      ],
    }));
    const districts = masterDataDistrict.filter(
      (d) => d.province_id === +memberInfo?.province_id
    );
    setDataDistrict(districts);
    const subDistricts = masterDataSubDistrict.filter(
      (s) => s.district_id === +memberInfo?.district_id
    );

    setDataSubDistrict(subDistricts);
  }, [memberInfo]);

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  const onChangeForm = (event) => {
    const { name, value } = event.target;
    const valueSet =
      name === "organization_id" ? value.replace(/[^0-9]/gi, "") : value;

    setForm((prev) => ({
      ...prev,
      [name]: valueSet,
    }));

    validateForm({ [name]: value });
  };

  const handleProvinceChange = (value) => {
    const districts = masterDataDistrict.filter(
      (d) => d.province_id === +value
    );
    setDataDistrict(districts);
    onChangeAddress("province_id", +value);
    // validate({ province: value });
  };
  const handleDistrictChange = (value) => {
    const subDistricts = masterDataSubDistrict.filter(
      (s) => s.district_id === +value
    );

    setDataSubDistrict(subDistricts);
    onChangeAddress("district_id", +value);
    // validate({ district: value });
  };

  const handleSubDistrictChange = (value) => {
    onChangeAddress("sub_district_id", +value);
    // validate({ subdistrict: value });
  };

  const onChangeAddress = (name, value) => {
    let newObj = form?.address?.[0] || INITIAL_ADDRESS;

    switch (name) {
      case "province_id":
        newObj = {
          ...newObj,
          [name]: value,
          district_id: "",
          sub_district_id: "",
          zip_code: "",
        };
        break;

      case "district_id":
        newObj = {
          ...newObj,
          [name]: value,
          sub_district_id: "",
          zip_code: "",
        };
        break;

      case "sub_district_id":
        const postcode = masterDataSubDistrict
          .filter((s) => s.value === +value)
          .map((z) => z.postcode)[0];
        console.log({ postcode });
        newObj = {
          ...newObj,
          [name]: value,
          zip_code: postcode,
        };
        break;

      default:
        newObj = {
          ...newObj,
          [name]: value,
        };
        break;
    }

    setForm((prev) => ({
      ...prev,
      address: [newObj],
    }));
  };

  const validateForm = (fieldValues = { ...form }) => {
    let temp = { ...errors };

    if ("name" in fieldValues)
      temp.name = fieldValues.name !== "" ? "" : "กรุณากรอก";

    if ("organization_id" in fieldValues)
      temp.organization_id =
        fieldValues.organization_id !== ""
          ? (temp.email = validateOrganizationId(fieldValues.organization_id)
              ? ""
              : "กรุณากรอกรูปแบบให้ถูกต้อง 13 ตัว")
          : "กรุณากรอก";

    if ("organization_type" in fieldValues)
      temp.organization_type =
        fieldValues.organization_type !== "" ? "" : "กรุณาเลือก";

    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    const isValid = validateForm();
    const body = {
      ...form,
      address: memberInfo?.address ? [...memberInfo?.address] : [],
    };

    if (isValid) {
      dispatch(createMemberBusiness(body)).then((data) => {
        if (![400, 401, 403, 404, 500].includes(data?.code)) {
          history.push("success-business");
        } else history.push("error");
      });
    }
  };

  return (
    <Div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={2}></Grid>
        <Grid item xs={12} sm={8}>
          <form onSubmit={handleSubmit}>
            <Stack
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Stack spacing={2}>
                <Typography
                  variant="h2"
                  align="center"
                  color="initial"
                  gutterBottom
                >
                  Business Register
                </Typography>

                <Typography variant="h5" color="initial">
                  Your Information
                </Typography>

                <TextField
                  label="Organization "
                  placeholder={"Organization "}
                  name="name"
                  value={form?.name}
                  error={errors?.name}
                  onChange={onChangeForm}
                  // required
                />

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <TextField
                    label="Organization ID"
                    placeholder={"Organization ID (13 digit)"}
                    name="organization_id"
                    value={form?.organization_id}
                    error={errors?.organization_id}
                    onChange={onChangeForm}
                    // required
                  />

                  <Select
                    label="Organization Type"
                    defaultValue={"Organization Type  "}
                    items={masterDataOrganization}
                    name="organization_type"
                    value={form?.organization_type || ""}
                    error={errors?.organization_type}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>
              </Stack>

              <Stack spacing={2}>
                <Typography variant="h5" color="initial">
                  Business Address
                </Typography>

                {form?.address?.map((item, index) => (
                  <Fragment key={index}>
                    <Stack
                      spacing={4}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                    >
                      <TextField
                        label="Address"
                        placeholder={"Address"}
                        name="address"
                        value={item?.address}
                        // error={errors?.address}
                        onChange={(e) =>
                          onChangeAddress("address", e.target.value)
                        }
                      />
                      <TextField
                        label="Road"
                        placeholder={"Road"}
                        name="road"
                        value={item?.road}
                        // error={errors?.road}
                        onChange={(e) =>
                          onChangeAddress("road", e.target.value)
                        }
                      />
                    </Stack>

                    <Stack
                      spacing={4}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                    >
                      <Select
                        label="Province"
                        defaultValue={"Province"}
                        items={dataProvince}
                        value={item?.province_id}
                        // error={errors?.province_id}
                        onChange={(e) => handleProvinceChange(e.target.value)}
                      />
                      <Select
                        label="District"
                        defaultValue={"District"}
                        items={dataDistrict}
                        disabled={dataDistrict.length === 0}
                        value={item?.district_id}
                        // error={errors?.district_id}
                        onChange={(e) => handleDistrictChange(e.target.value)}
                      />
                    </Stack>
                    <Stack
                      spacing={4}
                      direction={{ xs: "column", sm: "row" }}
                      justifyContent="space-between"
                    >
                      <Select
                        label="Sub District"
                        defaultValue={"Sub-District"}
                        items={dataSubDistrict}
                        value={item?.sub_district_id}
                        disabled={dataSubDistrict.length === 0}
                        // error={errors?.sub_district_id}
                        onChange={(e) =>
                          handleSubDistrictChange(e.target.value)
                        }
                      />
                      <TextField
                        label="Postal Code"
                        placeholder={"Postal Code"}
                        value={item?.zip_code}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    </Stack>
                  </Fragment>
                ))}
              </Stack>

              <Button sx={{ width: { xs: "100%", sm: 600 } }} type="submit">
                Next
              </Button>
            </Stack>
          </form>
        </Grid>
        <Grid item xs={12} sm={2}></Grid>
      </Grid>
    </Div>
  );
};

export default Business;
