import React from "react";
import styled from "styled-components";
import { Chip as ChipMui } from "@mui/material";

const StyleChip = styled(ChipMui)(({ theme }) => ({
  "& .MuiChip-icon": {
    position: "absolute",
    left: 20,
    cursor: "pointer",
  },
  "& .MuiChip-deleteIcon": {
    position: "absolute",
    right: 1,
  },
}));

const Chip = ({ color = "primary", size = "medium", ...props }) => {
  return <StyleChip color={color} size={size} {...props} />;
};

export default Chip;
