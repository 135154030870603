import React from "react";
import styled from "styled-components";
import { Radio as RadioMui } from "@mui/material";

const StyledRadio = styled(RadioMui)(({ theme }) => ({}));

const Radio = ({ color = "primary", ...props }) => {
  return <StyledRadio color={color} {...props} />;
};

export default Radio;
