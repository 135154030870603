export const getFileNameFromUrl = (url) => {
  const pathName = url.split("/").slice(-1)[0];
  return pathName.split(".").slice(0, -1).join(".");
};

export const checkValue = (value) => {
  return value || "-";
};

export const isNumber = (str = "") => {
  if (str?.toString().trim() === "") {
    return false;
  }

  return !isNaN(str);
};

export const getUniqueListBy = (arr, key) => {
  return [...new Map(arr.map((item) => [item[key], item])).values()];
};
