import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";

import { Typography, Stack, Container } from "@mui/material";
import SuccessIcon from "assets/icon/success.png";
import { toggleLogin } from "redux/actions/share";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const SuccessReset = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);

  const goLogin = () => {
    dispatch(toggleLogin(true));
  };

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Stack spacing={4} alignItems="center" justifyContent="center">
              <Typography variant="body1" align="center" color="initial">
                <img width="100" alt="success" src={SuccessIcon} />
              </Typography>

              <Typography
                sx={{ display: { xs: "none", sm: "flex" } }}
                align="center"
                variant={"h2"}
                gutterBottom
                color="#005cc9"
              >
                Password has been reset
              </Typography>
              <Typography
                sx={{ display: { xs: "flex", sm: "none" } }}
                align="center"
                variant={"h3"}
                gutterBottom
                color="#005cc9"
              >
                Password has been reset
              </Typography>

              <Typography variant="h5" align="center" color="initial">
                Before you continue using our service.
                <Typography variant="h5" align="center" color="initial">
                  Please log in again with your new password.
                </Typography>
              </Typography>

              <br />

              <Button
                sx={{ width: { xs: "100%", sm: 300 } }}
                onClick={() => goLogin()}
              >
                Login
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default SuccessReset;
