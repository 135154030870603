import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import Button from "../../components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import TextEditor from "../../components/form/TextEditor";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";

import BannerEmail from "../../assets/images/banner-email.png";
import { updateCampaignEmail } from "redux/actions/assessments";

const Div = styled.div`
  padding: 2vw 7vw;

  .preview-text {
    height: 100%;
    min-height: 50vh;
    white-space: pre-wrap;
    overflow-y: auto;
    word-wrap: break-word;

    * {
      max-width: 100%;
    }
  }
`;

const DraftEmail = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { campaignById } = useSelector((state) => state.assessments);

  const [editorData, setEditorData] = useState("");

  useEffect(() => {
    setEditorData(campaignById?.email_content);
  }, [campaignById]);

  const handleCancelDraft = () => {
    history.goBack();
  };

  const handleSaveDraftEmail = () => {
    // TODO: Handle;
    handleCancelDraft();
    dispatch(
      updateCampaignEmail(campaignById?.id, {
        email_content: editorData,
      })
    );
  };

  return (
    <Div>
      <Grid container spacing={4}>
        <Grid item container justifyContent="space-between" xs={12}>
          <Typography variant="h2" color="initial">
            Logic Test
          </Typography>
          <ButtonIcon
            aria-label="close"
            onClick={handleCancelDraft}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <Close />
          </ButtonIcon>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography variant="h5" color="initial" gutterBottom>
              Email Content
            </Typography>

            <Box>
              <TextEditor onChange={setEditorData} editValue={editorData} />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack spacing={2}>
            <Typography variant="h5" color="initial" gutterBottom>
              Preview
            </Typography>

            <Box
              sx={{
                p: 2,
                border: "1px solid",
                opacity: 0.9,
                borderColor: (theme) => theme.palette.grey[500],
              }}
            >
              <Stack spacing={2}>
                <img src={BannerEmail} alt="" />
                <Box>
                  <div
                    className="preview-text"
                    dangerouslySetInnerHTML={{
                      __html: editorData,
                    }}
                  ></div>
                </Box>
              </Stack>
            </Box>

            <Button block onClick={() => handleSaveDraftEmail()}>
              {" "}
              Save
            </Button>
          </Stack>
        </Grid>
      </Grid>
    </Div>
  );
};

export default DraftEmail;
