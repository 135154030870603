import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import Stack from "components/layout/Stack";
import Grid from "../../components/layout/Grid";
import Button from "../../components/form/Button";
import Typography from "@mui/material/Typography";
import ButtonIcon from "../../components/form/ButtonIcon";
import Table from "../../components/table/Table";

import Divider from "../../components/surfaces/Divider";

import { Edit as EditIcon } from "@mui/icons-material";

import { Box, Container } from "@mui/material";
import SubContentDialog from "components/dialog/SubContentDialog";
import Select from "components/form/Select";
import TextField from "components/form/TextField";

import {
  getQuestionById,
  updateQuestionInfo,
  updateQuestionStatus,
  updateQuestionChoice,
  getChoiceDetail,
} from "../../redux/actions/assessments";
import formatValue from "utils/formatValue";
import { DisplyDeltaOrHtml } from "components/form/ConvertHtmlToString";
import Checkbox from "components/form/Checkbox";
import UploadImageChoice from "containers/UploadImageChoice";
import Toggle from "components/form/Toggle";
import RichTextEditor from "components/form/RichTextEditor";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const SubDetailQuestion = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { questionById, choiceDetailById } = useSelector(
    (state) => state.assessments
  );
  const { masterDataSubject, masterDataLevel, masterDataJob } = useSelector(
    (state) => state.masterData
  );

  const [form, setForm] = useState({});
  const [formChoice, setFormChoice] = useState([]);

  const [errors, setErrors] = useState({});

  const [isOpenEditInfoQDialog, setIsOpenEditInfoQDialog] = useState(false);
  const [isOpenEditStatusQDialog, setIsOpenEditStatusQDialog] = useState(false);
  const [isOpenEditChoiceQDialog, setIsOpenEditChoiceQDialog] = useState(false);

  useEffect(() => {
    dispatch(getQuestionById(id));
  }, []);

  useEffect(() => {
    console.log("questionbById", questionById);
    setForm(questionById);
  }, [questionById]);

  useEffect(() => {
    console.log("formChoice", formChoice);
  }, [formChoice]);

  useEffect(() => {
    console.log("questionbById", questionById);
    setForm(questionById);
  }, [questionById]);

  useEffect(() => {
    console.log("choiceDetailById", choiceDetailById);

    setFormChoice(choiceDetailById);
  }, [choiceDetailById]);

  const onChangeForm = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateForm({ [name]: value });
  };

  const handleOpenEditInfoQ = () => {
    setIsOpenEditInfoQDialog(true);
  };

  const handleCloseEditInfoQ = () => {
    setIsOpenEditInfoQDialog(false);
  };

  const validateForm = (
    fieldValues = {
      ...form,
    }
  ) => {
    let temp = { ...errors };

    if ("subject_id" in fieldValues)
      temp.subject_id = fieldValues.subject_id !== "" ? "" : "กรุณาเลือก";
    if ("topic_name" in fieldValues)
      temp.topic_name = fieldValues.topic_name !== "" ? "" : "กรุณากรอก";
    if ("level" in fieldValues)
      temp.level = fieldValues.level !== "" ? "" : "กรุณาเลือก";
    if ("job_position_id" in fieldValues)
      temp.job_position_id =
        fieldValues.job_position_id !== "" ? "" : "กรุณาเลือก";
    if ("exam_owner" in fieldValues)
      temp.exam_owner = fieldValues.exam_owner !== "" ? "" : "กรุณากรอก";

    setErrors({ ...temp });

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const handleSubmitEditInfoQ = (event) => {
    event.preventDefault();

    let isValid = validateForm();

    if (isValid) {
      dispatch(updateQuestionInfo(id, form)).then(() => {
        handleCloseEditInfoQ();
        dispatch(getQuestionById(id));
      });
    }
  };

  const handleOpenEditStatusQ = () => {
    setIsOpenEditStatusQDialog(true);
  };

  const handleCloseEditStatusQ = () => {
    setIsOpenEditStatusQDialog(false);
  };

  const handleSubmitEditStatusQ = (event) => {
    event.preventDefault();

    let body = {
      createdBy: form?.created_by,
      createdAt: form?.created_at,
    };
    dispatch(updateQuestionStatus(id, body)).then(() => {
      handleCloseEditStatusQ();
      dispatch(getQuestionById(id));
    });
  };

  const handleChangeRandomChoice = (event) => {
    const { name, checked } = event.target;

    setForm((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const handleChangeAuswerCheckbox = (event, index) => {
    const { checked } = event.target;

    let newArr = [...formChoice];
    newArr[index] = {
      ...newArr[index],
      is_answer: checked,
    };
    setFormChoice(newArr);
  };

  const handleSetDelta = (value = "", index) => {
    let newArr = [...formChoice];
    newArr[index] = {
      ...newArr[index],
      choice_description: JSON.stringify(value),
    };

    setFormChoice(newArr);
  };
  const handleUploadImage = (order, path) => {
    let newArr = [...formChoice];
    newArr[order].choice_image[0] = {
      ...newArr[order].choice_image[0],
      image_url: path,
    };

    setFormChoice(newArr);
  };

  const handleOpenEditChoiceQ = () => {
    setIsOpenEditChoiceQDialog(true);
    dispatch(getChoiceDetail(id));
  };

  const handleCloseEditChoiceQ = () => {
    setIsOpenEditChoiceQDialog(false);
    dispatch(getQuestionById(id));
  };

  const handleSubmitEditChoiceQ = (event) => {
    event.preventDefault();

    let newArrImage = [];
    let flatImage = choiceDetailById.map((item) => [item?.choice_image]);
    for (const item of flatImage) {
      newArrImage = [...newArrImage, item.flat()];
    }

    let body = {
      is_random_answer: form?.is_random_answer,
      choices: formChoice?.map((item) => ({
        choice_no: item?.choice_no,
        choice_description: item?.choice_description,
        choice_type: item?.choice_type,
        choice_image: "",
        is_answer: item?.is_answer,
        answer_point: item?.answer_point,
      })),
      images: newArrImage.flat().map((item) => ({
        choice_no: item?.choice_no,
        image_url: item?.image_url,
        image_order: item?.image_order,
      })),
    };

    dispatch(updateQuestionChoice(id, body)).then(() => {
      handleCloseEditChoiceQ();
    });
  };

  const checkDelta = (data) => {
    return data.startsWith("[") ? JSON.parse(data) : data;
  };

  return (
    <Div>
      <SubContentDialog
        open={isOpenEditInfoQDialog}
        title={"Edit Question Information "}
        alignTitle="center"
        onClose={handleCloseEditInfoQ}
        maxWidth="md"
      >
        <form onSubmit={handleSubmitEditInfoQ}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Stack spacing={2}>
                <Typography variant="subtitle1" color="primary">
                  Question ID : {questionById?.code}
                </Typography>
                <Select
                  defaultValue="Subject"
                  name="subject_id"
                  items={masterDataSubject}
                  onChange={onChangeForm}
                  value={form?.subject_id}
                  error={errors?.subject_id}
                />

                <TextField
                  placeholder="Topic"
                  name="topic_name"
                  onChange={onChangeForm}
                  value={form?.topic_name}
                  error={errors?.topic_name}
                />
                <Select
                  defaultValue="Level of Difficulty"
                  name="level"
                  onChange={onChangeForm}
                  value={form?.level}
                  items={masterDataLevel}
                  error={errors?.level}
                />
                <Select
                  defaultValue="Job Position"
                  name="job_position_id"
                  onChange={onChangeForm}
                  value={form?.job_position_id}
                  items={masterDataJob}
                  error={errors?.job_position_id}
                />
                <TextField
                  placeholder="Author"
                  value={form?.exam_owner}
                  onChange={onChangeForm}
                  name="exam_owner"
                  error={errors?.exam_owner}
                />

                <Typography variant="subtitle1" color="inherit">
                  <b>Remark</b> : When you edit question. you need to approve
                  question again so that the question can be used in test set.
                </Typography>
              </Stack>
            </Grid>
            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button outlined block onClick={() => handleCloseEditInfoQ()}>
                    Cancel
                  </Button>
                  <Button block type="submit">
                    Save
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>
      <SubContentDialog
        open={isOpenEditStatusQDialog}
        title={"Edit Question Information "}
        alignTitle="center"
        onClose={handleCloseEditStatusQ}
        maxWidth="md"
      >
        <form onSubmit={handleSubmitEditStatusQ}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12}>
              <Stack spacing={2}>
                <Typography variant="subtitle1" color="primary">
                  Question ID : {questionById?.code}
                </Typography>

                <TextField
                  placeholder="Creator"
                  value={form?.created_by}
                  onChange={onChangeForm}
                  name="created_by"
                />
                <TextField
                  type="datetime-local"
                  placeholder={"Create Date "}
                  name="created_at"
                  value={form?.created_at}
                  // error={errorsInfo?.create_at}
                  onChange={onChangeForm}
                  // required
                />

                <Typography variant="subtitle1" color="inherit">
                  <b>Remark</b> : When you edit question. you need to approve
                  question again so that the question can be used in test set.
                </Typography>
              </Stack>
            </Grid>
            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    block
                    outlined
                    onClick={() => handleCloseEditStatusQ()}
                  >
                    Cancel
                  </Button>
                  <Button block type="submit">
                    Save
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>
      <SubContentDialog
        open={isOpenEditChoiceQDialog}
        title={"Edit Question Information "}
        alignTitle="center"
        onClose={handleCloseEditChoiceQ}
        maxWidth="lg"
      >
        <form onSubmit={handleSubmitEditChoiceQ}>
          <Grid item container spacing={2} xs={12} sm={12}>
            <Grid item container xs={12} sm={12} spacing={2}>
              {formChoice?.map((item, index) => (
                <Fragment key={index}>
                  <Grid item xs={12} sm={12}>
                    <Stack direction="row" alignItems="center" spacing={2}>
                      <Typography variant="body1" color="initial">
                        {index === 0 ? "Question" : `Choice ${item?.choice_no}`}
                      </Typography>
                      {index !== 0 && (
                        <Checkbox
                          label="Select as correct answer"
                          checked={item?.is_answer}
                          onChange={(e) => handleChangeAuswerCheckbox(e, index)}
                        />
                      )}
                    </Stack>
                  </Grid>
                  <Grid item container xs={12} sm={12} spacing={2}>
                    <Grid item xs={12} sm={8}>
                      <RichTextEditor
                        initValue={checkDelta(item?.choice_description)}
                        onRichTextBlur={(e) => handleSetDelta(e, index)}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <UploadImageChoice
                        inputID={`image-question:${index}`}
                        index={index}
                        defaultImage={item?.choice_image?.[0].image_url}
                        setImage={handleUploadImage}
                      />
                    </Grid>
                  </Grid>
                </Fragment>
              ))}
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Typography variant="subtitle1" color="initial">
                    Remark :
                  </Typography>
                  <Typography variant="body1" color="initial">
                    You need to approve question before using in test set.
                  </Typography>
                </Stack>

                <Box sx={{ width: "100%" }}>
                  <Typography variant="subtitle1" color="initial" align="right">
                    Do you want to random choices?
                    <Toggle
                      // value="public-link"
                      name="is_random_answer"
                      checked={form?.is_random_answer}
                      onChange={handleChangeRandomChoice}
                    />
                  </Typography>
                </Box>
              </Stack>
            </Grid>
            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Button
                    block
                    outlined
                    onClick={() => {
                      handleCloseEditChoiceQ();
                    }}
                  >
                    Cancel
                  </Button>
                  <Button block type="submit">
                    Save
                  </Button>
                </Stack>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>

      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} container spacing={2} align="center">
          <Grid xs={2} sm={2} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Typography variant="h4" color="initial">
            {questionById?.code}
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} container spacing={2} item>
          <Grid xs={12} sm={6} item container spacing={2}>
            <Grid item xs={12} sm>
              <Stack
                spacing={2}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ border: "1px solid #ECEDF2", padding: 1 }}
              >
                <Typography variant="h6" color="initial">
                  Question information
                </Typography>
                <Button outlined onClick={() => handleOpenEditInfoQ()}>
                  <EditIcon />
                  Edit
                </Button>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Subject
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.name_upper}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Topic
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.topic_name}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Level of Difficulty
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.level}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Job Position
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.name_en}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Author
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.exam_owner}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} item container spacing={2}>
            <Grid item xs={12} sm>
              <Stack
                spacing={2}
                alignItems="center"
                direction="row"
                justifyContent="space-between"
                sx={{ border: "1px solid #ECEDF2", padding: 1 }}
              >
                <Typography variant="h6" color="initial">
                  Question Status
                </Typography>
                <Button
                  outlined
                  disabled
                  onClick={() => handleOpenEditStatusQ()}
                >
                  <EditIcon />
                  Edit
                </Button>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Creator
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.created_by || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Create Date
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {formatValue("Date", questionById?.created_at)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Approver
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.approve_by || "-"}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Approve Date
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {formatValue("Date", questionById?.approve_date)}
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Status
                </Typography>
                <Typography
                  sx={{ flex: 1 }}
                  variant="body1"
                  align="right"
                  color="initial"
                >
                  {questionById?.status}
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Stack spacing={2}>
            <Stack
              spacing={2}
              alignItems="center"
              direction="row"
              justifyContent="space-between"
              sx={{ border: "1px solid #ECEDF2", padding: 1 }}
            >
              <Typography variant="h6" color="initial">
                Question
              </Typography>
              <Button outlined onClick={() => handleOpenEditChoiceQ()}>
                <EditIcon />
                Edit
              </Button>
            </Stack>
            <Stack spacing={2} sx={{ px: 1 }}>
              <Typography variant="body1" color="initial">
                {DisplyDeltaOrHtml(questionById?.choice_description)}
                {questionById?.question_image !== "" ? (
                  <div>
                    <img
                      className="question-img"
                      src={questionById?.question_image}
                    />
                  </div>
                ) : (
                  ""
                )}
              </Typography>
              <Divider />
              {questionById?.question_choice?.map((item, index) => (
                <Stack direction="row" alignItems="center" key={index}>
                  <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                    Choice {item?.choice_no}
                  </Typography>
                  <Typography sx={{ flex: 3 }} variant="body1" color="initial">
                    {DisplyDeltaOrHtml(item?.choice_answer)}
                    {item?.image !== "" ? (
                      <div>
                        <img
                          className="question-img"
                          width={200}
                          src={item?.image}
                        />
                      </div>
                    ) : (
                      ""
                    )}
                  </Typography>
                </Stack>
              ))}
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Correct Answer
                </Typography>
                <Typography sx={{ flex: 3 }} variant="body1" color="initial">
                  {questionById?.question_answer?.[0]}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Random Choice
                </Typography>
                <Typography sx={{ flex: 3 }} variant="body1" color="initial">
                  {questionById?.is_random_answer ? "True" : "False"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Author
                </Typography>
                <Typography sx={{ flex: 3 }} variant="body1" color="initial">
                  {questionById?.exam_owner}
                </Typography>
              </Stack>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
    </Div>
  );
};

export default SubDetailQuestion;
