import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import formatValue from "utils/formatValue";

import { Typography } from "@mui/material";

import {
  AddCircle as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import Box from "../../components/surfaces/Box";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";
import Divider from "../../components/surfaces/Divider";
import Table from "../../components/table/Table";

import TextField from "../../components/form/TextField";
import TextFieldArea from "../../components/form/TextFieldArea";
import Select from "../../components/form/Select";
import Checkbox from "../../components/form/Checkbox";
import Button from "../../components/form/Button";
import ButtonIcon from "components/form/ButtonIcon";

import Pagination from "../../components/navigation/Pagination";
import SubContentDialog from "components/dialog/SubContentDialog";

import {
  createExamSet,
  saveFormExamSet,
  clearFormExamSet,
  randomQuestion,
} from "../../redux/actions/assessments";
import { RenderHtmlToString } from "components/form/ConvertHtmlToString";
const Div = styled.div`
  padding: 5vw 7vw;
  .topic {
    color: #005cc9;
    margin-bottom: 20px;
  }

  .headline {
    font-weight: bold;
  }
`;

const RANGE_SCORE = [
  { value: "-5", text: "-5" },
  { value: "-4", text: "-4" },
  { value: "-3", text: "-3" },
  { value: "-2", text: "-2" },
  { value: "-1", text: "-1" },
  { value: "0", text: "0" },
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
];

const EXAM_MODE_DDL = [
  { value: "adt", text: "Adaptive AI Test" },
  { value: "shuffle", text: "Shuffle" },
  { value: "normal", text: "Normal" },
];

const INITIAL_RANDOM_FORM = {
  random_type: "adt",
  subject_id: "",
  topic_name: "",
  job_position_id: "",
  levels: [
    {
      level: "",
      total_question: "",
    },
  ],
};

const AssessmentCreateTestNew = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { masterDataSubject, masterDataLevel, masterDataJob } = useSelector(
    (state) => state.masterData
  );
  const { formExamSet } = useSelector((state) => state.assessments);

  const [form, setForm] = useState(formExamSet);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState({});

  const [randomForm, setRandomForm] = useState(INITIAL_RANDOM_FORM);
  const [isSubmitRandom, setIsSubmitRandom] = useState(false);
  const [errorsRandom, setErrorsRandom] = useState({});

  const [isOpenModalRandomShuffle, setIsOpenModalRandomShuffle] =
    useState(false);
  const [isOpenModalRandomADT, setIsOpenModalRandomADT] = useState(false);

  const columnsTest = [
    // {
    //   field: "id",
    //   headerName: "ลำดับ ",
    //   minWidth: 100,
    // },
    {
      field: "code",
      headerName: "รหัสข้อสอบ",
      minWidth: 200,
    },
    {
      field: "question",
      headerName: "โจทย์",
      minWidth: 550,
      renderCell: RenderHtmlToString,
    },
    {
      field: "name_upper",
      headerName: "หมวดวิชา",
      minWidth: 120,
    },
    {
      field: "topic_name",
      headerName: "หัวข้อ",
      minWidth: 120,
    },
    {
      field: "level",
      headerName: "Center Level",
      minWidth: 120,
    },
    {
      field: "my_level",
      headerName: "My Level",
      renderCell: ({ value, row }) => (
        <Select
          defaultValue={"My Level"}
          value={value}
          items={masterDataLevel}
          onChange={(e) => handleChangeMyLevel(e, row?.id)}
        />
      ),
    },
    {
      field: "exam_owner",
      headerName: "ชื่อผู้ออกข้อสอบ",
    },
    {
      field: "created_at",
      headerName: "วันที่สร้างข้อสอบ",
      renderCell: ({ value }) => formatValue("Date", value),
    },
  ];

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  useEffect(() => {
    console.log("randomForm", randomForm);
  }, [randomForm]);

  useEffect(() => {
    console.log("errors", errors);
  }, [errors]);

  const validateForm = (
    fieldValues = { ...form, company_id: "fix", language: "fix", client: "fix" }
  ) => {
    let temp = {
      ...errors,
      result_mapping: errors?.result_mapping
        ? errors?.result_mapping
        : [
            {
              min_score: 0,
              max_score: "",
            },
            {
              min_score: "",
              max_score: "",
            },
            {
              min_score: "",
              max_score: "",
            },
          ],
      summary: errors?.summary
        ? errors?.summary
        : Array.from(Array(11)).map((it) => ({
            right_weight: "",
            wrong_weight: "",
            no_answer_weight: "",
          })),
    };

    console.log("เข้าเข้า");

    if ("name" in fieldValues)
      temp.name = fieldValues.name !== "" ? "" : "กรุณากรอกชื่อชุดข้อสอบ";
    if ("author" in fieldValues)
      temp.author = fieldValues.author !== "" ? "" : "กรุณากรอก Author";
    if ("amount_question" in fieldValues)
      temp.amount_question =
        fieldValues.amount_question !== "" ? "" : "กรุณากรอกจำนวนข้อสอบ";
    if ("subject_id" in fieldValues)
      temp.subject_id = fieldValues.subject_id !== "" ? "" : "กรุณาเลือกวิชา";
    if ("level" in fieldValues)
      temp.level = fieldValues.level !== "" ? "" : "กรุณาเลือก Lever";
    if ("description" in fieldValues)
      temp.description =
        fieldValues.description !== "" ? "" : "กรุณากรอกรายละเอียดข้อสอบ";
    if ("exam_mode" in fieldValues)
      temp.exam_mode =
        fieldValues.exam_mode !== "" ? "" : "กรุณาเลือก Exam Mode";
    if ("exam_time" in fieldValues)
      temp.exam_time =
        fieldValues.exam_time !== "" ? "" : "กรุณากรอกเวลาในการทำข้อสอบ ";

    if ("result_mapping" in fieldValues) {
      for (let index = 0; index < 3; index++) {
        if (index !== 0) {
          temp.result_mapping[index] = {
            ...temp.result_mapping[index],
            min_score:
              fieldValues?.result_mapping?.[index]?.min_score !== ""
                ? ""
                : "กรุณากรอกช่วงคะแนน",
          };
        }
        temp.result_mapping[index] = {
          ...temp.result_mapping[index],
          max_score:
            fieldValues?.result_mapping?.[index]?.max_score !== ""
              ? ""
              : "กรุณากรอกช่วงคะแนน",
        };
      }
    }

    if ("summary" in fieldValues) {
      for (let index = 0; index < fieldValues.summary.length; index++) {
        if (!countExam(index + 1) > 0) {
          continue;
        }
        temp.summary[index] = {
          ...temp.summary[index],
          right_weight:
            fieldValues?.summary?.[index]?.right_weight !== ""
              ? ""
              : "กรุณาเลือก",
        };
        temp.summary[index] = {
          ...temp.summary[index],
          wrong_weight:
            fieldValues?.summary?.[index]?.wrong_weight !== ""
              ? ""
              : "กรุณาเลือก",
        };
        temp.summary[index] = {
          ...temp.summary[index],
          no_answer_weight:
            fieldValues?.summary?.[index]?.no_answer_weight !== ""
              ? ""
              : "กรุณาเลือก",
        };
      }
    }

    setErrors({ ...temp });

    console.log("value", Object.values(fieldValues));

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const validateFormRandom = (
    fieldValues = { ...randomForm, topic_name: "not-required" }
  ) => {
    let temp = {
      ...errorsRandom,
      levels: errorsRandom?.levels
        ? errorsRandom?.levels
        : [
            {
              level: "",
              total_question: "",
            },
          ],
    };

    if ("subject_id" in fieldValues)
      temp.subject_id = fieldValues.subject_id !== "" ? "" : "กรุณาเลือก";
    if ("job_position_id" in fieldValues)
      temp.job_position_id =
        fieldValues.job_position_id !== "" ? "" : "กรุณาเลือก";

    if ("levels" in fieldValues) {
      for (let index = 0; index < fieldValues?.levels?.length; index++) {
        temp.levels[index] = {
          ...temp.levels[index],
          level: fieldValues?.levels?.[index]?.level !== "" ? "" : "กรุณาเลือก",
        };

        temp.levels[index] = {
          ...temp.levels[index],
          total_question:
            fieldValues?.levels?.[index]?.total_question !== ""
              ? ""
              : "กรุณากรอกจำนวน",
        };
      }
    }
    setErrorsRandom({ ...temp });

    console.log("value", Object.values(fieldValues));

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const handleChangeMyLevel = (e, id) => {
    const { value } = e.target;

    let newArr = [...form?.question];
    let tempIndex = newArr.findIndex((it) => it.id === id);

    newArr[tempIndex] = { ...newArr[tempIndex], my_level: +value };

    setForm((prev) => ({
      ...prev,
      question: newArr,
    }));
  };

  const addQuestionToExam = () => {
    dispatch(saveFormExamSet(form));
    history.push("create-test/add-exam-set");
  };

  const countExam = (level) => {
    return form?.question?.filter((item) => item.my_level === level).length;
  };

  const handleRandomExam = () => {
    form?.exam_mode === "adt"
      ? setIsOpenModalRandomADT(true)
      : setIsOpenModalRandomShuffle(true);

    setRandomForm((prev) => ({
      ...prev,
      random_type: form?.exam_mode,
    }));
  };

  const handleSubmitRandomForm = (e) => {
    e.preventDefault();

    const isValid = validateFormRandom();

    if (isSubmitRandom && isValid) {
      dispatch(randomQuestion(randomForm))
        .then(({ payload }) => {
          console.log("payload", payload);
          let mapArr = payload?.data.map((item) => ({
            ...item,
            my_level: item?.level,
          }));

          console.log("mapArr", mapArr);

          let tempArr = [].concat(form?.question, mapArr);

          console.log("tempArr", tempArr);

          setForm((prev) => ({
            ...prev,
            question: tempArr,
          }));
        })
        .then(() =>
          randomForm?.random_type === "adt"
            ? handleCloseModalADT()
            : handleCloseModalShuffle()
        );
    }
    setIsSubmitRandom(true);
  };

  const handleCloseModalShuffle = () => {
    setIsOpenModalRandomShuffle(!isOpenModalRandomShuffle);
  };

  const handleCloseModalADT = () => {
    setIsOpenModalRandomADT(!isOpenModalRandomADT);
  };

  const handleChangeRandomForm = (e) => {
    const { name, value } = e.target;

    setRandomForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateFormRandom({ [name]: value });
  };

  const handleChangeLevel = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...randomForm?.levels];
    newArr[index] = {
      ...newArr[index],
      [name]: +value,
    };

    setRandomForm((prev) => ({
      ...prev,
      levels: newArr,
    }));

    validateFormRandom({
      levels: newArr,
    });
  };

  const handleAddLevel = () => {
    let newArr = [...randomForm?.levels];

    newArr.push({
      level: "",
      total_question: "",
    });

    setRandomForm((prev) => ({
      ...prev,
      levels: newArr,
    }));
  };

  const handleDeleteLevel = (indexDelete) => {
    let newArr = randomForm?.levels?.filter(
      (item, index) => index !== indexDelete
    );

    setRandomForm((prev) => ({
      ...prev,
      levels: newArr,
    }));
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateForm({ [name]: value });
  };

  const handleChangeResultMapping = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...form?.result_mapping];
    newArr[index] = { ...newArr[index], [name]: value };

    setForm((prev) => ({
      ...prev,
      result_mapping: newArr,
    }));

    validateForm({
      result_mapping: newArr,
    });
  };

  const handleChangeQuestion = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...form?.question];
    newArr[index] = { ...newArr[index], [name]: value };

    setForm((prev) => ({
      ...prev,
      question: newArr,
    }));
  };

  const handleChangeSummary = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...form?.summary];
    newArr[index] = { ...newArr[index], [name]: value };

    setForm((prev) => ({
      ...prev,
      summary: newArr,
    }));

    validateForm({ summary: newArr });
  };

  const mappingBody = (prevForm) => {
    let question_time_avg = +prevForm?.exam_time / +prevForm?.question?.length;
    return {
      ...prevForm,
      question: prevForm.question.map((item) => ({
        exam_question_id: item?.id,
        level: item?.level !== item?.my_level ? item?.my_level : item?.level,
        question_time: question_time_avg,
      })),
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();

    if (form?.question?.length === 0) {
      alert("กรุณาเพิ่มข้อสอบเข้าชุดข้อสอบ");
    }

    if (isSubmit && isValid) {
      let body = mappingBody(form);

      dispatch(createExamSet(body)).then(() => {
        history.goBack();
        dispatch(clearFormExamSet());
      });
    }

    setIsSubmit(true);
  };

  return (
    <Div>
      <SubContentDialog
        open={isOpenModalRandomShuffle}
        title={"สุ่มข้อสอบเข้าสู่ชุดข้อสอบ"}
        subTitle={"แบบ Shuffle"}
        alignTitle="center"
        alignSubTitle="center"
        onClose={handleCloseModalShuffle}
        maxWidth="md"
      >
        <form onSubmit={handleSubmitRandomForm}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="วิชา"
                name="subject_id"
                onChange={handleChangeRandomForm}
                error={errorsRandom?.subject_id}
                items={masterDataSubject}
                // required
                value={randomForm?.subject_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="หัวข้อ"
                name="topic_name"
                onChange={handleChangeRandomForm}
                value={randomForm?.topic_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="ตำแหน่งงาน"
                items={masterDataJob}
                // required
                name="job_position_id"
                error={errorsRandom?.job_position_id}
                onChange={handleChangeRandomForm}
                value={randomForm?.job_position_id}
              />
            </Grid>

            {randomForm?.levels.map((item, index) => (
              <Grid item container xs={12} sm={12} spacing={4} key={index}>
                <Grid item xs={12} sm={6}>
                  <Select
                    defaultValue="level"
                    items={masterDataLevel}
                    // required
                    name="level"
                    error={errorsRandom?.levels?.[index]?.level}
                    value={item?.level}
                    onChange={(e) => handleChangeLevel(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="จำนวนข้อสอบ "
                    // required
                    type="number"
                    name="total_question"
                    error={errorsRandom?.levels?.[index]?.total_question}
                    value={item?.total_question}
                    onChange={(e) => handleChangeLevel(e, index)}
                  />
                </Grid>
              </Grid>
            ))}

            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Button block type="submit">
                  Random
                </Button>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>

      <SubContentDialog
        open={isOpenModalRandomADT}
        title={"สุ่มข้อสอบเข้าสู่ชุดข้อสอบ"}
        subTitle={"แบบ ADT"}
        alignTitle="center"
        alignSubTitle="center"
        onClose={handleCloseModalADT}
        maxWidth="md"
      >
        <form onSubmit={handleSubmitRandomForm}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="วิชา"
                // required
                name="subject_id"
                onChange={handleChangeRandomForm}
                items={masterDataSubject}
                error={errorsRandom?.subject_id}
                value={randomForm?.subject_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="หัวข้อ"
                name="topic_name"
                onChange={handleChangeRandomForm}
                value={randomForm?.topic_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="ตำแหน่งงาน"
                // required
                items={masterDataJob}
                name="job_position_id"
                onChange={handleChangeRandomForm}
                error={errorsRandom?.job_position_id}
                value={randomForm?.job_position_id}
              />
            </Grid>

            {randomForm?.levels.map((item, index) => (
              <Grid item container xs={12} sm={12} spacing={4} key={index}>
                <Grid item xs={12} sm={6}>
                  <Select
                    defaultValue="level"
                    items={masterDataLevel}
                    value={item?.level}
                    error={errorsRandom?.levels?.[index]?.level}
                    // required
                    name="level"
                    onChange={(e) => handleChangeLevel(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <TextField
                      placeholder="จำนวนข้อสอบ "
                      value={item?.total_question}
                      error={errorsRandom?.levels?.[index]?.total_question}
                      type="number"
                      // required
                      name="total_question"
                      onChange={(e) => handleChangeLevel(e, index)}
                    />
                    {index === 0 ? (
                      <ButtonIcon
                        type="button"
                        onClick={() => handleAddLevel()}
                      >
                        <AddIcon />
                      </ButtonIcon>
                    ) : (
                      <ButtonIcon
                        type="button"
                        onClick={() => handleDeleteLevel(index)}
                      >
                        <DeleteIcon />
                      </ButtonIcon>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}

            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Button block type="submit">
                  Random
                </Button>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>

      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid xs={2} sm={2} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="topic" variant="h4">
              Create Test Set
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="headline" variant="h6">
              1. Input Test Set Information
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Test Set Name *"
                placeholder="Test Set Name"
                name="name"
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label="Subject *"
                name="subject_id"
                defaultValue="Select Subject"
                value={form?.subject_id}
                error={errors?.subject_id}
                items={masterDataSubject}
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="No. of Questions *"
                placeholder="How many questions in this test set?"
                name="amount_questions"
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={2}>
            <Grid item xs={4}>
              <Select
                label="Level of Difficulty *"
                name="subject_id"
                defaultValue="Level"
                value={form?.subject_id}
                error={errors?.subject_id}
                items={masterDataSubject}
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Author *"
                placeholder="Author"
                name="author"
                value={form?.author}
                error={errors?.author}
              />
            </Grid>
            <Grid item xs={4}>
              <Select
                label="Exam Mode *"
                defaultValue="Exam Mode"
                name="exam_mode"
                value={form?.exam_mode}
                error={errors?.exam_mode}
                // required
                items={EXAM_MODE_DDL}
                onChange={handleChangeForm}
              />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={2}>
            <Grid item xs={8}>
              <TextFieldArea
                label="Description"
                name="description"
                placeholder="Brief description about this Test Set"
                minRows={3}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Total Time to Complete Test Set(min) *"
                placeholder="Time to Complete (Minute)"
                name="time-complete"
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="headline" variant="h6">
              2. Add Questions to Test Set
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">
              There are 2 ways to add questions into Test Set : (1) Specifically
              select questions from Library or (2) Random from Library. For
              Adaptive Testing, you can re-level of each questions to distribute
              all 605 questions into 11 level of exam difficulties.
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item sm={3}>
              <Button block variant="outlined">
                Select Questions
              </Button>
            </Grid>
            <Grid item sm={3}>
              <Button block variant="outlined">
                Random From Center Library
              </Button>
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={4}>
            <Grid item sm={3}>
              <Button block variant="outlined">
                Auto Average Level
              </Button>
            </Grid>
            <Grid item sm={3}>
              <Button block>Ordering</Button>
            </Grid>
          </Grid>
          <Grid item xs={12} spacing={2}>
            <Box sx={{ height: 600, width: "100%" }}>
              <Table
                columns={columnsTest}
                rows={form?.question}
                checkboxSelection={false}
                disablecBorderContent
                headerNameRunningNumber="ลำดับ"
                showRunningNumber
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="headline" variant="h6">
              3. Specify Scores and Weight
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">
              Please give the scores when each answer scenario. You can also
              give weight on harder questions by giving higher scores.
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="topic" variant="subtitle1">
              Top 10 Questions Selected
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Difficulty Level
            </Grid>
            <Grid item xs={2}>
              No. of Questions
            </Grid>
            <Grid item xs={2}>
              Score: Correct Answer
            </Grid>
            <Grid item xs={2}>
              Score: Wrong Answer
            </Grid>
            <Grid item xs={2}>
              Score: No Answer
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 1
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 2
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 3
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 4
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 5
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 6
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 7
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 8
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 9
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item container xs={12} sm={12} spacing={4}>
            <Grid item xs={2}>
              Level 10
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
            <Grid item xs={2}>
              <TextField />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="headline" variant="h6">
              4. Specify Performance Measurement
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">
              Specify performance measurement for each individual test result.
              Measurement range is base on 100 percent score
            </Typography>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={3}></Grid>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              Description
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={3}>
              Good Performance : Score Range
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField />
                <Typography variant="body1">To</Typography>
                <TextField />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <TextField placeholder="You have good performance on this subject" />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={3}>
              Intermediate Performance : Score Range
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField />
                <Typography variant="body1">To</Typography>
                <TextField />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <TextField placeholder="You have intermediate performance on this subject" />
            </Grid>
          </Grid>
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={3}>
              Bad Performance : Score Range
            </Grid>
            <Grid item xs={4}>
              <Stack direction="row" spacing={2} alignItems="center">
                <TextField />
                <Typography variant="body1">To</Typography>
                <TextField />
              </Stack>
            </Grid>
            <Grid item xs={4}>
              <TextField placeholder="You have bad performance on this subject" />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography className="headline" variant="h6">
              5. More setting
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Typography variant="subtitle1">
              Do you want to have this Test Set to be in Center Library for
              other to use too?
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Checkbox
              // checked={filter?.is_published === 1}
              label="Add this test set to center library"
              // onChange={() => onChangeFilter("is_published", 1)}
            />
          </Grid>
          <Grid item container xs={12} sm={12}>
            <Grid item xs={4}></Grid>
            <Grid item xs={4}>
              <Stack
                direction="row"
                spacing={4}
                justifyContent="center"
                alignItems="center"
              >
                <Button block variant="outlined">
                  Cancle
                </Button>
                <Button block>Create Test Set</Button>
              </Stack>
            </Grid>
            <Grid item xs={4}></Grid>
          </Grid>
        </Grid>
      </form>
    </Div>
  );
};

export default AssessmentCreateTestNew;
