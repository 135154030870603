import React, { useState, useEffect, useCallback, useId } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Typography } from "@mui/material";
import { Theaters, Image } from "@mui/icons-material";

import { DropzoneAreaBase } from "material-ui-dropzone";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";

import Stepper from "../../components/navigation/Stepper";

import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
import Checkbox from "../../components/form/Checkbox";
import ButtonUploadVideo from "../../components/form/ButtonUploadVideo";

import Table from "../../components/table/Table";
import {
  createCampaign,
  saveFormAssessmentReview,
  updateCampaign,
  saveFormAssessmentSelcetTest,
  saveFormAssessmentCreateExam,
} from "../../redux/actions/assessments";

import { uploadVideo } from "redux/actions/share";

import ConfirmDialog from "../../components/dialog/ConfirmDialog";
import { INITIAL_SCENARIOS } from "utils/dataConfig";
import TextField from "components/form/TextField";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;

  .background-menu-filter {
    background-color: #f5f7fc;
    border-radius: 8px;
    /* margin: 0 auto; */
  }
`;

const steps = [
  "Assessment Details",
  "Select Test",
  "Add Question",
  "Review & Finishing",
];

const DataMock = [
  {
    id: 1,
    name: "DISC",
    time: "10 Minutes",
  },
  {
    id: 2,
    name: "Custom Question #1",
    time: "10 Minutes",
  },
];

const AssessmentReview = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    formAssessmentDetails,
    formAssessmentSelectTest,
    formAssessmentCreateExam,
    formAssessmentReview,
    isModeEditCampaign,
  } = useSelector((state) => state.assessments);
  const [mapExamSet, setMapExamSet] = useState([]);
  const [reviewExamList, setReviewExamList] = useState([]);

  const [form, setForm] = useState(formAssessmentReview);

  // const [fileObjects, setFileObjects] = useState([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentData, setCurrentData] = useState("");

  const columns = [
    {
      field: "name",
      headerName: "Name ",
      minWidth: 300,
    },
    {
      field: "duration",
      headerName: "Duration",
      minWidth: 200,
    },
  ];

  useEffect(() => {
    console.log({ formAssessmentDetails });
  }, [formAssessmentDetails]);

  useEffect(() => {
    console.log("reviewExamList", reviewExamList);

    setForm((prev) => ({
      ...prev,
      duration_time: reviewExamList.reduce(
        (accumulator, curr) => accumulator + curr.duration,
        0
      ),
    }));
  }, [reviewExamList]);

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  useEffect(() => {
    console.log({ formAssessmentCreateExam });
    const mapNewExamSet = formAssessmentSelectTest.filter(
      (item) => item.exam_sets_id
    );

    const mergeArr = mapNewExamSet.concat(formAssessmentCreateExam);

    const tempArray = [...mergeArr].map((item, index) => {
      let mapField = {
        name: item.question_title || item.label,
        duration: item.exam_set_time || +item.question_time,
      };

      return !item.id
        ? { id: index + 999, ...mapField }
        : { id: item.id, ...mapField };
    });

    setMapExamSet(mapNewExamSet);
    setReviewExamList(tempArray);
  }, [formAssessmentSelectTest, formAssessmentCreateExam]);

  const onChangeForm = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const onChangeCheckbox = (e) => {
    const { name, checked } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  // const uploadImage = (path) => {
  //   setForm((prev) => ({
  //     ...prev,
  //     vdo_url: path,
  //   }));
  // };

  const mappingFormBody = () => {
    return {
      ...formAssessmentDetails,
      exam_set: mapExamSet,
      custom_question: formAssessmentCreateExam.map((item) => ({
        question_type: item?.question_type,
        question_time: item?.question_time,
        video_url: item?.video_url,
        image_url: item?.image_url,
        question_title: item?.question_title,
        question_description: item?.question_description,
        choice: item?.choice,
        is_shuffle_answer: item?.is_shuffle_answer,
        is_exist: item?.is_exist,
      })),
      ...form,
    };
  };

  const mappingFormBodyEdit = () => {
    return {
      ...formAssessmentDetails,
      exam_set_data: mapExamSet,
      custom_question_data: mappingCustomQuestionBodyEdit(
        formAssessmentCreateExam
      ),
      ...form,
    };
  };

  const mappingCustomQuestionBodyEdit = (rawList) => {
    let newArr = rawList.map((item) => {
      let body = {
        question_type: item?.question_type,
        question_time: item?.question_time,
        exam_campaigns_id: form?.id,
        video_url: item?.video_url,
        image_url: item?.image_url,
        question_title: item?.question_title,
        question_description: item?.question_description,
        choice: item?.choice,
        is_shuffle_answer: item?.is_shuffle_answer,
        is_exist: item?.is_exist,
      };

      return !item?.new_custom
        ? {
            id: item?.id,
            ...body,
          }
        : {
            ...body,
          };
    });

    return newArr;
  };

  // const handlePreviewIcon = (fileObject, classes) => {
  //   console.log("fileObject preview:", fileObject);
  //   const { type } = fileObject.file;

  //   if (type.startsWith("video/")) return <Theaters />;
  // };

  // const handleChangeDropzone = (newFileObjs) => {
  //   console.log("onAdd", newFileObjs);
  //   dispatch(uploadImage(newFileObjs[0].file)).then(({ path }) => {
  //     let tempFile = [...newFileObjs];

  //     tempFile[0].pathPublish = path;

  //     setFileObjects([].concat(fileObjects, tempFile));
  //   });
  // };

  const handleUploadImage = (event) => {
    const { files } = event.target;
    let file = files[0];
    dispatch(uploadVideo(file)).then((data) => {
      console.log("response video upload", data);
      setForm((prev) => ({
        ...prev,
        vdo_url: data.path,
      }));
    });
  };

  const handleClearVideo = (event) => {
    setForm((prev) => ({
      ...prev,
      vdo_url: null,
    }));
  };

  const handleSubmit = (event) => {
    // TODO: Handle;
    event.preventDefault();
    dispatch(saveFormAssessmentReview(form));

    let body = !isModeEditCampaign ? mappingFormBody() : mappingFormBodyEdit();
    console.log("final body", body);

    if (!isModeEditCampaign) {
      dispatch(createCampaign(body)).then((id) => {
        history.push(`/assessments/${id}`);
      });
    } else {
      dispatch(updateCampaign(body)).then(() => {
        history.push(`/assessments`);
      });
    }
  };

  const handleOpenConfirmDialog = (data) => {
    setCurrentData(data);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleDelete = () => {
    const formStep2 = [...INITIAL_SCENARIOS];
    let arrExam = [...formAssessmentSelectTest].filter(
      (item) => item?.exam_sets_id && item?.label !== currentData?.name
    );
    console.log("formStep2", formStep2);
    console.log("arrExam", arrExam);
    let tempStep3 = [...formAssessmentCreateExam];
    console.log("tempStep3", tempStep3);
    // let tempSum = [...reviewExamList];

    for (const item of arrExam) {
      formStep2.splice(item?.order_no - 1, 1, item);
    }
    tempStep3 = tempStep3.filter(
      (item) => item?.question_title !== currentData?.name
    );

    console.log("formStep2 after", formStep2);
    console.log("tempStep3 after", tempStep3);

    dispatch(saveFormAssessmentSelcetTest(formStep2));
    dispatch(saveFormAssessmentCreateExam(tempStep3));

    handleCloseConfirmDialog();

    // delete(currentId)
  };

  return (
    <Div>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
        title="Delete"
        content={currentData?.name}
        confirmName={"Delete"}
      />
      <form onSubmit={handleSubmit}>
        <Grid container direction="column" spacing={4}>
          <Grid item xs sm container>
            <Grid xs={2} item>
              <Button outlined block onClick={() => history.goBack()}>
                Back
              </Button>
            </Grid>
            <Grid xs={7} item></Grid>
            <Grid xs={3} item>
              <Button block type="submit">
                Finish
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{ display: { xs: "flex", sm: "none" } }}
            item
            container
            xs={12}
            sm={12}
          >
            <Stack spacing={2}>
              <Typography variant="h5" color="initial" align="center">
                Create New Assessment
              </Typography>
              <Typography variant="h6" color="#606266" align="center">
                Review & Finishing
              </Typography>
            </Stack>
          </Grid>
          <Grid item container xs sm>
            <Grid item xs={12} sm></Grid>
            <Grid item xs={12} sm={8}>
              <Stepper listStep={steps} activeStep={3} />
            </Grid>
            <Grid item xs={12} sm></Grid>
          </Grid>

          <Grid item xs sm></Grid>

          <Grid container item xs sm spacing={3}>
            <Grid item xs sm></Grid>
            <Grid item xs={12} sm={9} direction="column">
              <Typography gutterBottom variant="h5" color="initial">
                Review and configure your assessment.
              </Typography>
              <Box sx={{ height: 400, width: "100%" }}>
                <Table
                  columns={columns}
                  rows={reviewExamList}
                  checkboxSelection={false}
                  onDeleteRow={(row) => handleOpenConfirmDialog(row)}
                />
              </Box>
            </Grid>
            <Grid item xs sm></Grid>
          </Grid>

          <Grid item container spacing={4} alignItems="stretch">
            <Grid item xs={12} sm={10} container>
              <Stack spacing={4} justifyContent="flex-start">
                <Stack spacing={2}>
                  <Typography variant="h5" color="initial">
                    Welcome Video
                  </Typography>
                  <Typography
                    sx={{ maxWidth: 700 }}
                    variant="body1"
                    paragraph
                    color="initial"
                  >
                    You can include a pre-recorded video to welcome your
                    candidates to your assessment. By default, we'll display
                    your video at the beginning of the assessment. If you prefer
                    to show it at the end, tick the checkbox below.
                  </Typography>

                  <Checkbox
                    id="show-video"
                    name="is_show_vdo"
                    checked={form?.is_show_vdo}
                    onChange={onChangeCheckbox}
                    label=" Show video at the end of assessment"
                  />
                </Stack>

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  alignItems="flex-end"
                >
                  {/* <DropzoneUploadBox
                        Icon={null}
                        fileObjects={fileObjects}
                        acceptedFiles={["video/*", "image/*"]}
                        dropzoneText={
                          <>
                            <strong className="blue-text">
                              Drop files here to upload
                            </strong>
                            <small>
                              To upload file size is (Less than 50 mb) and
                              allowed file type is MP4.
                            </small>
                            <Button>Upload file</Button>
                          </>
                        }
                        filesLimit={2}
                        getPreviewIcon={handlePreviewIcon}
                        maxFileSize={50000000}
                        onAdd={handleChangeDropzone}
                        onDelete={(deleteFileObj) => {
                          console.log("onDelete", deleteFileObj);
                          setFileObjects(
                            [...fileObjects].filter(
                              (fileObject) =>
                                JSON.stringify(fileObject) !==
                                JSON.stringify(deleteFileObj)
                            )
                          );
                        }}
                        onAlert={(message, variant) =>
                          console.log(`${variant}: ${message}`)
                        }
                      /> */}
                  <Box sx={{ width: "100%", flexGrow: 1 }}>
                    <ButtonUploadVideo
                      id="upload-image-or-video"
                      defaultValue={form?.vdo_url}
                      outlined
                      onClear={handleClearVideo}
                      onChange={(e) => handleUploadImage(e)}
                    />
                  </Box>
                  <TextField
                    label="or else, using embedded link"
                    name="vdo_embed"
                    value={form?.vdo_embed}
                    placeholder="link Youtube"
                    onChange={onChangeForm}
                  />
                </Stack>
                {/* <Grid item xs={12} sm={12} sx={{ mt: { xs: 1, sm: "auto" } }}>
                    <Stack spacing={2}>
                      <Typography variant="h5" color="initial">
                        Embed Video
                      </Typography>

                      <TextFieldArea
                        name="vdo_embed"
                        value={form?.vdo_embed}
                        placeholder="link Youtube"
                        onChange={onChangeForm}
                      />
                    </Stack>
                  </Grid> */}

                <Stack spacing={2}>
                  <Typography variant="h5" color="initial">
                    Anti-Cheating Setting
                  </Typography>

                  <Checkbox
                    id="snapshot"
                    name="is_snapshot"
                    checked={form?.is_snapshot}
                    onChange={onChangeCheckbox}
                    label="Snapshots of candidates will be taken every 30 seconds via webcam."
                  />
                </Stack>

                <Stack spacing={2}>
                  <Typography variant="h5" color="initial">
                    Extra time for test
                  </Typography>

                  <Typography variant="body1" color="#696969">
                    Give all candidates extra time on their tests.
                  </Typography>
                  <Typography variant="body1" color="#696969">
                    The extra time applies to all tests in the test library, but
                    not to custom questions.
                  </Typography>
                  <Typography variant="caption" color="#696969"></Typography>

                  <Grid container>
                    <Grid xs={12} sm={6} item>
                      <Select
                        defaultValue="Extra Time"
                        name="extra_time"
                        onChange={onChangeForm}
                        value={form?.extra_time}
                        items={[
                          { value: 10, text: "10 นาที" },
                          { value: 15, text: "15 นาที" },
                          { value: 30, text: "30 นาที" },
                          { value: 60, text: "60 นาที" },
                        ]}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
      </form>
    </Div>
  );
};

export default AssessmentReview;

// const Wrapper = styled.div`
//   height: auto;
//   .MuiDropzoneArea-root {
//     display: flex;
//     align-items: center;
//     flex-direction: column;
//     padding: 2vw 3vw;
//   }

//   .MuiDropzoneArea-text {
//     display: flex;
//     flex-direction: column;
//     row-gap: 0.5em;
//     color: inherit;
//   }

//   .MuiDropzoneArea-icon {
//     display: none;
//   }
// `;

// const DropzoneUploadBox = ({ ...props }) => {
//   return (
//     <Wrapper>
//       <DropzoneAreaBase {...props} />
//     </Wrapper>
//   );
// };
