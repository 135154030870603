import React from "react";
import styled from "styled-components";
import { Button as ButtonMui } from "@mui/material";

const StyledButton = styled(ButtonMui)`
  :hover {
    cursor: pointer;
  }
  min-height: 40px;
  border-radius: 30px;

  .MuiButton-root {
    background-color: #005cc9;
    padding: 1;
    text-transform: none;
  }

  .MuiButton-label {
    font-family: "IBM", "Manrope";
    font-size: var(--button);
    font-weight: normal;
    text-transform: none;

    @media screen and (max-width: 768px) {
      font-size: 16px;
      padding: 0 2vw;
    }
    @media screen and (max-width: 415px) {
      font-size: 14px;
    }
  }

  .MuiButton-containedSecondary {
    background-color: #1967d2 !important;
    color: #1967d2 !important;
    opacity: 0.07 !important;
  }
`;

const Button = ({
  className,
  block = false,
  color = "primary",
  outlined,
  variant = "contained",
  ...props
}) => {
  return (
    <StyledButton
      className={className}
      fullWidth={block}
      color={color}
      variant={outlined ? "outlined" : variant}
      {...props}
    />
  );
};

export default Button;
