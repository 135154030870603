import styled from "styled-components";
import { useHistory } from "react-router-dom";

import Button from "../components/form/Button";

import BgTop from "../assets/images/bg-top.png";
import BgTopMobile from "../assets/images/bg-top-mobile.png";
import BgSolution from "../assets/images/bg-solution.png";

import BgResult from "../assets/images/bg-result.png";
import Chart from "../assets/images/chart.png";

import BgFeature from "../assets/images/bg-feature.png";

import ProblemIcon1 from "../assets/icon/cv.png";
import ProblemIcon2 from "../assets/icon/audit.png";
import ProblemIcon3 from "../assets/icon/choice.png";
import ProblemIcon4 from "../assets/icon/consultation.png";

import SolutionIcon1 from "../assets/icon/solution1.png";
import SolutionIcon2 from "../assets/icon/solution2.png";
import SolutionIcon3 from "../assets/icon/solution3.png";
import SolutionIcon4 from "../assets/icon/solution4.png";

import Feature1 from "../assets/icon/feature1.png";
import Feature2 from "../assets/icon/feature2.png";
import Feature3 from "../assets/icon/feature3.png";
import Feature4 from "../assets/icon/feature4.png";
import Feature5 from "../assets/icon/feature5.png";

import Discount from "../assets/icon/discount.png";

const Div = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  button {
    border-radius: 30px;
  }

  > .top-banner {
    flex: 1;
    background-image: url(${BgTop});
    background-position: center center;
    background-repeat: no-repeat;
    width: 100%;
    background-size: cover;

    max-width: 100%;
    height: auto;
    min-height: 630px;
    display: flex;
    align-items: center;

    @media screen and (max-width: 768px) {
      min-height: 400px;
      background-position: left top;
      align-items: flex-start;
    }

    @media screen and (max-width: 415px) {
      background-image: url(${BgTopMobile});
      background-size: contain;
      background-position: bottom;
      min-height: 550px;
    }

    .text-banner {
      z-index: 99;
      padding: var(--padding);

      @media screen and (max-width: 415px) {
        padding: 7vw;
      }

      @media screen and (max-width: 768px) {
        padding: 2vw 7vw;
      }
      h1,
      .sub-header1 {
        text-transform: uppercase;
        color: var(--text-blue);
      }

      .sub-header1 {
        font-size: var(--subtitle1);
        font-weight: bold;
        @media screen and (max-width: 768px) {
          font-size: 30px;
        }
        @media screen and (max-width: 415px) {
          font-size: 26px;
        }
      }

      .sub-header2 {
        font-size: var(--subtitle1);
        color: var(--text-black);

        @media screen and (max-width: 768px) {
          font-size: 25px;
        }
        @media screen and (max-width: 415px) {
          font-size: 20px;
        }
      }
    }
  }

  > .banner-problems {
    flex: 1;
    flex-direction: column;
    background-color: var(--background1);
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 1vw;

    @media screen and (max-width: 768px) {
      padding: 7vw;
    }

    h2,
    .sub-header1 {
      color: var(--text-white);
      font-weight: bold;
      @media screen and (max-width: 768px) {
        margin-top: 10px;
        font-size: 30px;
      }
      @media screen and (max-width: 415px) {
        font-size: 20px;
      }
    }

    .sub-header1 {
      font-size: var(--body1);

      @media screen and (max-width: 768px) {
        font-size: 16px;
        margin-top: 10px;
      }
      @media screen and (max-width: 415px) {
        max-width: 250px;
        text-align: center;
      }
    }

    .group-icon {
      display: grid;
      grid-gap: 3vw;
      grid-template-columns: repeat(4, 1fr);
      padding: var(--padding);
      margin-top: 40px;
      width: 100%;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 5vw;
      }
      @media screen and (max-width: 415px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 10vw;
      }

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: var(--background2);
        box-shadow: -4px -2px 16px #ffffff,
          4px 2px 16px rgba(42, 104, 202, 0.48);
        height: auto;
        width: 100%;
        min-height: 250px;
        row-gap: 1vw;

        img {
          width: 4vw;
          @media screen and (max-width: 768px) {
            width: 10vw;
          }
          @media screen and (max-width: 415px) {
            width: 20vw;
          }
        }
        .detail {
          font-size: var(--overline);
          text-align: center;
          margin-top: 20px;
          color: #2684ff;
          @media screen and (max-width: 768px) {
            margin-top: 20px;
          }
        }
      }
    }
  }
  > .banner-solution {
    font-family: "Manrope";
    flex: 1;
    flex-direction: column;
    background-image: url(${BgSolution});
    background-position: center center;
    background-size: cover;

    background-repeat: no-repeat;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 1150px;
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 1vw;
    padding: var(--padding);
    text-align: center;

    @media screen and (max-width: 768px) {
      min-height: 800px;
      padding: 7vw;
    }

    h2 {
      color: var(--background1);
    }

    .sub-header1 {
      font-size: var(--body1);
      font-weight: bold;
      @media screen and (max-width: 768px) {
        font-size: 16px;
        margin-top: 10px;
      }
    }

    .group-icon {
      display: grid;
      grid-gap: 2vw;
      grid-template-columns: repeat(2, 1fr);
      width: 100%;
      margin-top: 4vw;

      @media screen and (max-width: 768px) {
        grid-gap: 5vw;
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 415px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 20px;
        background-color: var(--background2);
        box-shadow: -4px -2px 16px var(--text-white),
          4px 2px 16px rgba(45, 118, 235, 0.48);
        height: auto;
        width: 100%;
        min-height: 250px;
        row-gap: 1vw;
        align-items: center;
        padding: 2vw 0;

        @media screen and (max-width: 768px) {
          align-items: center;
          justify-content: center;
        }
        img {
          width: 7vw;

          @media screen and (max-width: 768px) {
            width: 15vw;
          }
          @media screen and (max-width: 415px) {
            width: 30vw;
          }
        }
        .detail {
          font-size: var(--subtitle3);
          text-align: center;
          font-weight: bold;
          margin: 0 2vw;

          @media screen and (max-width: 768px) {
            font-size: 16px;
          }
        }
      }
    }
  }
  > .banner-result {
    font-family: "Manrope";
    flex: 1;
    flex-direction: column;

    background-image: url(${BgSolution});
    background-position: center center;
    background-size: cover;

    background-repeat: no-repeat;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 700px;
    display: flex;
    align-items: center;
    row-gap: 1vw;
    padding: 4vw 7vw;

    @media screen and (max-width: 768px) {
      padding: 7vw;
    }

    h2 {
      color: var(--background1);
      font-weight: bold;
    }

    .sub-header1 {
      font-size: var(--body1);
      font-weight: bold;

      @media screen and (max-width: 768px) {
        margin-top: 20px;
        text-align: center;
        font-size: 16px;
      }
    }

    .content {
      display: flex;
      row-gap: 2vw;
      align-items: stretch;
      justify-content: flex-start;
      margin-top: 40px;

      width: 100%;
      max-width: 1200px;

      @media screen and (max-width: 415px) {
        flex-direction: column-reverse;
      }

      section {
        display: flex;
        flex-direction: column;
        flex: 1;

        &.image {
          margin-right: 3vw;
          img {
            object-fit: contain;
            height: auto;
            background: #fff;
            padding: 1vw;
            box-shadow: 0px 24px 48px 10px rgba(15, 86, 179, 0.06),
              0px 32px 64px 10px rgba(15, 86, 179, 0.06);
            border-radius: 16px;
          }
        }

        &.group-btn {
          display: flex;
          flex-direction: column;
          row-gap: 20px;

          button {
            margin-bottom: 20px;
            width: fit-content;
            text-align: left;

            @media screen and (max-width: 415px) {
              width: 100%;
            }
          }
        }
      }
    }
  }
  > .banner-feature {
    font-family: "Manrope";
    flex: 1;
    flex-direction: column;
    background-image: url(${BgFeature});
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;

    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 950px;
    display: flex;
    align-items: center;
    row-gap: 1vw;
    padding: 4vw 7vw;
    text-align: center;

    @media screen and (max-width: 415px) {
      padding: 12vw 7vw;
    }

    h2 {
      color: var(--background1);
      font-weight: bold;
    }

    .sub-header1 {
      font-size: var(--body1);
      font-weight: bold;

      @media screen and (max-width: 768px) {
        font-size: 16px;
        margin-top: 20px;
      }
    }

    .group-icon {
      display: grid;
      grid-gap: 2vw;
      grid-template-columns: repeat(3, 1fr);
      padding: var(--padding);
      margin-top: 40px;
      width: 100%;

      @media screen and (max-width: 768px) {
        padding: 0;
        grid-gap: 4vw;
      }
      @media screen and (max-width: 415px) {
        grid-template-columns: repeat(1, 1fr);
      }

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: var(--background2);
        box-shadow: -4px -2px 16px var(--text-white),
          4px 2px 16px rgba(45, 118, 235, 0.48);
        height: auto;
        width: 100%;
        min-height: 200px;
        row-gap: 1vw;
        align-items: center;
        img {
          width: 4vw;
          @media screen and (max-width: 768px) {
            width: 7vw;
          }
          @media screen and (max-width: 415px) {
            width: 20vw;
          }
        }
        .detail {
          font-size: var(--overline);
          color: var(--text-blue-strong);
          text-align: center;
          font-weight: bold;
          margin-top: 20px;
        }
      }
    }
    .group-icon2 {
      display: grid;
      grid-gap: 1vw;
      grid-template-columns: repeat(2, 1fr);
      padding: var(--padding);
      width: 100%;
      margin-top: 40px;

      @media screen and (max-width: 768px) {
        grid-gap: 5vw;
      }
      @media screen and (max-width: 415px) {
        grid-template-columns: repeat(1, 1fr);
        grid-gap: 4vw;
      }

      .icon {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        background-color: var(--background2);
        box-shadow: -4px -2px 16px var(--text-white),
          4px 2px 16px rgba(45, 118, 235, 0.48);
        height: auto;
        width: 100%;
        min-height: 275px;
        row-gap: 1vw;
        align-items: center;
        img {
          width: 7vw;

          @media screen and (max-width: 768px) {
            width: 15vw;
          }
          @media screen and (max-width: 415px) {
            width: 30vw;
          }
        }
        .detail {
          font-size: var(--subtitle3);
          text-align: center;
          font-weight: bold;
        }
        .overline {
          margin-top: 20px;
          font-size: var(--overline);
        }
      }
    }
  }
  > .banner-exam {
    font-family: "Manrope";
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    row-gap: 1vw;
    padding: 4vw 7vw;
    text-align: center;

    h2 {
      color: var(--background3);
      font-weight: bold;
    }

    .sub-header1 {
      font-size: var(--body1);
      font-weight: bold;
      margin-top: 20px;
      @media screen and (max-width: 768px) {
        font-size: 16px;
      }
    }

    .group-icon {
      display: grid;
      grid-gap: 1vw;
      grid-template-columns: repeat(4, 1fr);
      padding: var(--padding);
      width: 100%;
      max-width: 1400px;
      margin-top: 40px;

      @media screen and (max-width: 768px) {
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 4vw;
      }
      @media screen and (max-width: 415px) {
        grid-gap: 5vw;
        grid-template-columns: repeat(1, 1fr);
      }

      .icon {
        display: flex;
        flex-direction: column;
        text-align: left;
        border-radius: 20px;
        background-color: var(--background2);
        box-shadow: -4px -2px 16px var(--text-white),
          4px 2px 16px rgba(45, 118, 235, 0.48);
        height: auto;
        width: 100%;
        min-height: 330px;
        row-gap: 1vw;
        padding: 1vw;

        @media screen and (max-width: 768px) {
          padding: 4vw;
        }
        @media screen and (max-width: 415px) {
          padding: 6vw;
        }

        .topic {
          font-family: "IBM";
          font-size: var(--overline);
          text-align: center;
          font-weight: bold;
          margin-bottom: 1vw;
        }
        .detail {
          font-size: var(--caption);
          margin-top: 10px;
        }
      }
    }
  }
  > .banner-package {
    font-family: "Manrope";
    flex: 1;
    flex-direction: column;
    background-color: var(--background4);
    width: 100%;
    max-width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    row-gap: 1vw;
    padding: 4vw 7vw;
    text-align: center;

    @media screen and (max-width: 415px) {
      padding: 12vw 7vw;
    }

    h2,
    .sub-header1 {
      color: var(--background2);
      font-weight: bold;
      margin-top: 20px;
    }

    .sub-header1 {
      font-size: var(--subtitle2);

      &.for-mobile {
        display: none;
        @media screen and (max-width: 415px) {
          display: block;
          font-size: 16px;
        }
      }
      @media screen and (max-width: 768px) {
        font-size: 36px;
      }
    }

    .group-icon {
      display: grid;
      grid-gap: 2vw;
      grid-template-columns: repeat(3, 1fr);
      padding: 1vw 5vw;
      width: 100%;
      align-items: center;
      margin-top: 20px;
      justify-content: center;

      @media screen and (max-width: 768px) {
        grid-template-columns: none;
        grid-gap: 3vw;
        grid-template-areas:
          "top top"
          "left right";
      }

      @media screen and (max-width: 415px) {
        grid-template-areas: "left" " top" "right";
        grid-gap: 6vw;
      }

      .left-icon,
      .right-icon {
        min-height: 225px;
        flex: 1;
      }

      .center-icon {
        min-height: 300px;
        @media screen and (max-width: 768px) {
          grid-area: top;
        }
      }
      .left-icon {
        @media screen and (max-width: 768px) {
          grid-area: left;
        }
      }
      .right-icon {
        @media screen and (max-width: 768px) {
          grid-area: right;
        }
      }

      .left-icon,
      .center-icon,
      .right-icon {
        position: relative;
        font-family: "IBM";
        display: flex;
        flex-direction: column;
        text-align: center;
        border-radius: 20px;
        background-color: var(--background2);
        box-shadow: -4px -2px 16px var(--text-white),
          4px 2px 16px rgba(45, 118, 235, 0.48);
        height: auto;
        width: 100%;
        min-width: 250px;
        row-gap: 1vw;
        padding: 3vw 0;
        justify-content: center;
        color: var(--text-blue-500);

        @media screen and (max-width: 768px) {
          p {
            margin-bottom: 10px;
          }
        }

        .topic {
          font-size: var(--subtitle1);
          text-align: center;
          font-weight: bold;
        }
        .discount {
          position: absolute;
          top: -1vw;
          left: -1vw;
          width: 4.5vw;

          @media screen and (max-width: 768px) {
            width: 9vw;
          }
          @media screen and (max-width: 415px) {
            width: 10vw;
          }
        }
        .range {
          font-size: var(--subtitle3);
          @media screen and (max-width: 768px) {
            font-size: 20px;
          }
        }
        .detail {
          font-size: var(--body2);
        }
        .amount {
          font-size: var(--h1);
          font-weight: bold;
          @media screen and (max-width: 768px) {
            font-size: 60px;
          }
        }
      }
    }
  }
  > .banner-using-service {
    font-family: "Manrope";
    flex: 1;
    flex-direction: column;
    width: 100%;
    max-width: 100%;
    height: auto;
    min-height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
    row-gap: 1vw;
    text-align: center;

    .topic {
      color: #003cb2;
      font-weight: bold;
      line-height: 70px;
      font-size: var(--subtitle3);

      @media screen and (max-width: 415px) {
        font-size: 24px;
        line-height: 30px;
      }
      &.for-mobile {
        display: none;
        @media screen and (max-width: 415px) {
          display: inline;
        }
      }
    }

    button {
      background-color: #ffd913;
      color: #005cc9;
      max-width: 500px;
      width: 100%;
      height: auto;
      min-height: 4vw;
      text-align: center;
      border-radius: 4px;
      font-size: var(--body1);
      margin-top: 20px;

      @media screen and (max-width: 415px) {
        margin-top: 40px;
        width: 90%;
        height: 80px;
      }
    }
  }
`;

const Home = () => {
  const history = useHistory();

  const seeMore = () => {
    window.open("https://lin.ee/D1oQ2Lh", "_blank");
  };

  return (
    <Div>
      <section className="top-banner ">
        <div className="text-banner">
          <h1>hiring</h1>
          <h1>the right people</h1>
          <p className="sub-header1">from day one.</p>
          <br className="sm-none" />
          <p className="sub-header2">Future of HR Solution is here</p>
        </div>
      </section>
      <section className="banner-problems">
        <h2>ปัญหาของ HR ที่ต้องเจอทุกวัน</h2>
        <p className="sub-header1">
          {" "}
          หากคุณทำงานในสายงานบุคคล นี้คือปัญหาที่คุณต้องปวดหัวทุกวัน
        </p>

        <div className="group-icon">
          <div className="icon">
            <img src={ProblemIcon1} alt="" />
            <p className="detail">อ่าน resume วันละ 1000 ฉบับ</p>
          </div>
          <div className="icon">
            <img src={ProblemIcon2} alt="" />
            <p className="detail">
              สัมภาษณ์ ดูดีมาก <br /> แต่ทำงานไม่ได้
            </p>
          </div>
          <div className="icon">
            <img src={ProblemIcon3} alt="" />
            <p className="detail">
              มีคนโปรไฟล์ดีเยอะ <br /> จนเลือกไม่ถูก
            </p>
          </div>
          <div className="icon">
            <img src={ProblemIcon4} alt="" />
            <p className="detail">
              มีข้อสอบอยู่แล้ว แต่ไม่มีระบบสอบ <br />
              ไม่สะดวกเรียกเข้ามาสอบทีละคนที่ <br /> บริษัท
            </p>
          </div>
        </div>
      </section>
      <section className="banner-solution">
        <h2>Fuse-On Solution</h2>
        <p className="sub-header1">
          ระบบสอบออนไลน์ที่ช่วยคัดกรองผู้เข้าสมัครจากความรู้และความสามารถ{" "}
          <br className="md-none" />
          พร้อมแบบทดสอบวัดผลด้านบุคลิกภาพ เพื่อช่วยให้การทำงานของฝ่าย HR
          ง่ายขึ้น
        </p>

        <div className="group-icon">
          <div className="icon">
            <img src={SolutionIcon1} alt="" />
            <p className="detail">
              เลิกอ่าน resume วันละ 1000 ฉบับ <br /> แต่ให้สอบก่อน
              เพื่อเลือกคนที่ได้คะแนน <br /> top มาสัมภาษณ์
            </p>
          </div>
          <div className="icon">
            <img src={SolutionIcon2} alt="" />
            <p className="detail">
              ลดความเสี่ยง ของการรับพนักงาน <br /> มาแล้วแก้ปัญหาหน้างานไม่ได้
            </p>
          </div>
          <div className="icon">
            <img src={SolutionIcon3} alt="" />
            <p className="detail">
              เปรียบเทียบ ranking ของผู้สมัครด้วยกันเอง{" "}
              <br className="md-none" /> และ ผู้สมัครกับพนักงาน
              <br />
              ต้นแบบของเราได้
            </p>
          </div>
          <div className="icon">
            <img src={SolutionIcon4} alt="" />
            <p className="detail">
              ระบบสอบออนไลน์ สอบที่ไหนก็ได้ <br /> ไม่ต้องไปที่บริษัท
            </p>
          </div>
        </div>
      </section>

      <section className="banner-result">
        <h2>หน้ารายงานผล</h2>
        <p className="sub-header1">
          อ่านผลคะแนนได้ง่ายโดยการเปรียบเทียบลำดับคนที่ได้คะแนนสูงสุด
        </p>

        <div className="content">
          <section className="image">
            <img src={Chart} alt="" />
          </section>
          <section className="group-btn">
            <Button>แผนภูมิรูปภาพ บอก ภาพรวมความสามารถผู้สอบ</Button>
            <Button>Ranking คะแนนผู้สอบ</Button>
          </section>
        </div>
      </section>

      <section className="banner-feature">
        <h2>Feature</h2>
        <p className="sub-header1">
          ระบบสอบออนไลน์ที่มีข้อสอบวัดความรู้ความสามารถ{" "}
          <br className="sm-none" /> รายงานผลคะแนน และบริการ SMS แจ้งผู้สมัคร
        </p>

        <div className="group-icon">
          <div className="icon">
            <img src={Feature1} alt="" />
            <p className="detail">ข้อสอบ วัดผล</p>
          </div>
          <div className="icon">
            <img src={Feature2} alt="" />
            <p className="detail">report ผล และ เปรียบเทียบ</p>
          </div>
          <div className="icon">
            <img src={Feature3} alt="" />
            <p className="detail">บริการ SMS แจ้งผู้สมัคร</p>
          </div>
        </div>

        <br />
        <br />
        <h2>ชนิดข้อสอบ</h2>
        <p className="sub-header1">
          ระบบสอบที่ใช้วัดความรู้ความสามารถ จากคลังข้อสอบ{" "}
          <br className="sm-none" /> กว่า 10,000+ ข้อ
        </p>

        <div className="group-icon2">
          <div className="icon">
            <img src={Feature4} alt="" />
            <p className="detail">แบบสุ่ม</p>
            <p className="overline">
              ใช้วัดระดับความรู้ของผู้สอบจำนวนมาก บนพื้นฐานระดับเดียวกัน
            </p>
          </div>
          <div className="icon">
            <img src={Feature5} alt="" />
            <p className="detail">แบบ Adaptive</p>
            <p className="overline">
              ใช้วัดระดับความรู้ของผู้ทดสอบ ว่าอยู่ในระดับใด
            </p>
          </div>
        </div>
      </section>
      <section className="banner-exam">
        <h2>ข้อสอบ</h2>
        <p className="sub-header1">
          Fuse-On มีข้อสอบวัดความรู้และความสามารถระดับพื้นฐาน{" "}
          <br className="sm-none" /> ที่จำเป็นสำหรับการทำงาน
          ไม่ว่าจะเป็นตำแหน่งหรืออาชีพใดๆ <br className="sm-none" />
          เพราะทุกบริษัทต้องการคนที่มีศักยภาพ มีไหวพริบ และเหมาะกับตำแหน่งงาน
        </p>

        <div className="group-icon">
          <div className="icon">
            <p className="topic">
              คณิตศาสตร์ <br /> Math
            </p>
            <p className="detail">
              ตำแหน่งงานที่ต่างกันต้องใช้ความรู้ คณิตศาสตร์ ที่ไม่เท่ากันเช่น
              พนักงานขายควรแก้โจทย์ บวก ลบ คูณ หาร ได้ หรือวิศวะโยธาควรแก้ โจทย์
              sin cos tan ได้
            </p>
            <p className="detail">
              <br />
              ดังนั้นความรู้ด้านคณิตศาสตร์ เป็นเกณฑ์พื้นฐานที่ใช้วัดความ-
              สามารถด้านการคำนวน
            </p>
          </div>

          <div className="icon">
            <p className="topic">
              ตรรกะ
              <br />
              Logic
            </p>
            <p className="detail">วิชาตรรกะ เป็นส่วนนึงของ การทดสอบ IQ</p>
            <p className="detail">
              <br />
              ยิ่งได้คะแนนสูง ยิ่งบ่งบอกถึง <br /> 1. ความสามารถในการแก้ปัญหา
              เฉพาะหน้า
              <br /> 2. การตัดสินใจด้วยเหตุและผล
              <br /> 3. ความสามารถในการเชื่อมโยง
              <br /> 4. การจับใจความ
            </p>
          </div>

          <div className="icon">
            <p className="topic">
              ภาษาอังกฤษ <br />
              English
            </p>
            <p className="detail">
              วิชาภาษาอังกฤษ ช่วยให้เรา
              <br />
              รู้ถึงความสามารถทางการ
              <br /> ใช้ภาษาอังกฤษของผู้สมัคร
            </p>
          </div>

          <div className="icon">
            <p className="topic">
              บุคคลิกภาพการทำงาน <br />
              DISC Personality Test
            </p>
            <p className="detail">
              DISC เป็นแบบวัดบุคคลิกภาพ <br /> ว่าผู้สมัคร มีแนวโน้ม <br />{" "}
              ที่จะทำงานแบบไหน <br />
              เป็นคนมุ่งเป้าหมาย (D) <br />
              เป็นคนมีความคิดสร้างสรรค์ (I)
              <br />
              เป็นคนเน้นกระบวนการ (C) <br />
              เป็นคนเน้น team work (S)
            </p>
          </div>
        </div>
      </section>

      <section className="banner-package">
        <h2>Package</h2>
        <p className="sub-header1 for-mobile ">
          ซื้อวันนี้ ใช้ระบบ ได้ทันที ไม่ต้องรอ
        </p>
        <div className="group-icon">
          <div className="left-icon">
            <p className="topic">ทดลองใช้ฟรี </p>
            <p className="range">
              <span className="amount">50</span> คน{" "}
            </p>
          </div>
          <div className="center-icon">
            <p className="topic">เหมา 50 คน </p>
            <p className="detail">ราคา 2,000 บาท</p>
            <p className="range">
              <span className="amount">40</span> บาท/ ผู้สอบ{" "}
            </p>
            <img className="discount" src={Discount} alt="" />
          </div>
          <div className="right-icon">
            <p className="topic">1 คน </p>
            <p className="range">
              <span className="amount">200</span> บาท/ ผู้สอบ{" "}
            </p>
          </div>
        </div>
        <p className="sub-header1 sm-none">
          ซื้อวันนี้ ใช้ระบบ ได้ทันที ไม่ต้องรอ
        </p>
      </section>
      <section className="banner-using-service">
        <p className="topic sm-none">
          การรับสมัครพนักงานใหม่ ง่าย และ สะดวกสบายมากขึ้น{" "}
          <br className="sm-none" /> บอกลา การนั่งอ่าน resume เป็น 1000 ฉบับ
          ได้เลย
        </p>
        <p className="topic for-mobile">
          การรับสมัครพนักงานใหม่ <br /> ง่าย และ สะดวกสบายมากขึ้น <br /> <br />{" "}
          บอกลา การนั่งอ่าน resume เป็น 1000 ฉบับ ได้เลย
        </p>
        <Button onClick={() => seeMore()}>สนใจใช้บริการ</Button>
      </section>
    </Div>
  );
};

export default Home;
