import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Close } from "@material-ui/icons";

import Typography from "@mui/material/Typography";

import Box from "../../components/surfaces/Box";

import Grid from "../../components/layout/Grid";
import Stack from "components/layout/Stack";

import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Button from "components/form/Button";
import ButtonIcon from "components/form/ButtonIcon";

import Table from "components/table/Table";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;
`;

const mockData = [
  {
    id: 1,
    question_no: "test01",
    question: "จากโจทย์ที่กำหนดให้...",
    time: "",
    category: "Math",
    topic: "test01",
    center_level: "1",
    level: "1",
    author: "test01",
    create_at: "02/06/2022",
  },
  {
    id: 2,
    question_no: "test01",
    question: "จากโจทย์ที่กำหนดให้...",
    time: "",
    category: "Math",
    topic: "test01",
    center_level: "1",
    level: "1",
    author: "test01",
    create_at: "02/06/2022",
  },
  {
    id: 3,
    question_no: "test01",
    question: "จากโจทย์ที่กำหนดให้...",
    time: "",
    category: "Math",
    topic: "test01",
    center_level: "1",
    level: "1",
    author: "test01",
    create_at: "02/06/2022",
  },
];

const AssessmentOrdering = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { total } = useSelector((state) => state.assessments);

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ลำดับ ",
    // },
    {
      field: "time",
      headerName: "เวลา ",
      renderCell: ({ value }) => <TextField placeholder={"วินาที"} />,
    },
    {
      field: "question_no",
      headerName: "รหัสข้อสอบ ",
    },
    {
      field: "question",
      headerName: "โจทย์ ",
      minWidth: 200,
    },

    {
      field: "category",
      headerName: "หมวดวิชา ",
    },
    {
      field: "topic",
      headerName: "หัวข้อ ",
    },
    {
      field: "center_level",
      headerName: "Center Level ",
    },
    {
      field: "level",
      headerName: "My Level ",
      renderCell: ({ value }) => (
        <Select defaultValue={"1"} value="" items={[]} />
      ),
    },
    {
      field: "author",
      headerName: "ชื่อผู้ออกข้อสอบ ",
    },
    {
      field: "create_at",
      headerName: "วันที่สร้างข้อสอบ ",
    },
  ];

  return (
    <Div>
      <Grid container spacing={5}>
        <Grid
          item
          spacing={2}
          xs={12}
          sm={12}
          sx={{ display: { xs: "none", sm: "flex" }, position: "relative" }}
        >
          <Grid item xs={12} sm={2}>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>

          <Grid item xs={12} sm>
            <ButtonIcon
              aria-label="close"
              onClick={() => history.goBack()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </ButtonIcon>
          </Grid>
        </Grid>

        <Grid item xs={12} sm={12}>
          <Typography variant="h3" color="initial">
            Ordering
          </Typography>
        </Grid>

        <Grid item container spacing={2} xs={12} sm={12}>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="รหัสข้อสอบ"
              onChange={(e) => console.log("_:", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="โจทย์"
              onChange={(e) => console.log("_:", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="ชื่อผู้ออกข้อสอบ"
              onChange={(e) => console.log("_:", e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select defaultValue="วิชา" value="" items={[]} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select defaultValue="หัวข้อ" value="" items={[]} />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select defaultValue="ระดับความยาก" value="" items={[]} />
          </Grid>
        </Grid>

        <Grid item container spacing={2} xs={12} sm={12}>
          <Box sx={{ height: 300, width: "100%" }}>
            <Table
              columns={columns}
              rows={mockData}
              checkboxSelection={false}
              onSelectionChange={(e) => console.log(e)}
              disablecBorderContent
              rowReordering
              headerNameRunningNumber="ลำดับ"
              showRunningNumber
            />
          </Box>
        </Grid>

        <Grid item container xs={12} sm={12}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Button block onClick={() => history.goBack()}>
              ยืนยัน
            </Button>
          </Grid>
          <Grid item xs={2} sm={2}></Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default AssessmentOrdering;
