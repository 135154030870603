import { useEffect } from "react";
import Routes from "./routes/Routes";
import { useHistory } from "react-router-dom";
import { Provider } from "react-redux";
import store from "redux/store";
import {
  createTheme,
  ThemeProvider,
  responsiveFontSizes,
} from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import { ThemeProvider as ScThemeProvider } from "styled-components";
import MainLayout from "./layouts/MainLayout";

const App = () => {
  const history = useHistory();
  let theme = createTheme({
    breakpoints: {
      values: {
        xs: 0,
        sm: 415,
        md: 900,
        lg: 1280,
      },
    },
    palette: {
      mode: "light",
      common: {
        black: "#000",
        white: "#fff",
      },
      primary: {
        main: "#005CC9",
      },
      grey: {
        main: "#606266",
      },
      cancel: {
        main: "#e7e7e7",
      },
      stepperInactive: {
        main: "#D9D9D9",
      },
      white: {
        main: "#fff",
      },
    },
    typography: {
      fontFamily: "'IBM Plex Sans', sans-serif",
      fontSize: 14,
      h1: {
        fontWeight: 700,
      },
      h2: {
        fontWeight: 600,
      },
      h3: {
        fontWeight: 600,
      },
      h4: {
        fontWeight: 600,
      },
      h5: {
        fontWeight: 600,
      },
      subtitle1: {
        fontWeight: 600,
      },
    },
  });

  theme = responsiveFontSizes(theme);

  useEffect(() => {
    window.process = {
      ...window.process,
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <ScThemeProvider theme={theme} history={history}>
        <Provider store={store}>
          <CssBaseline />
          <Routes />
        </Provider>
      </ScThemeProvider>
    </ThemeProvider>
  );
};

export default App;
