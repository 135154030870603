import React from "react";
import styled from "styled-components";
import {
  FormControl,
  TextareaAutosize as TextAreaMui,
  FormHelperText,
} from "@mui/material";

const StyledFormControl = styled(FormControl)`
  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  textarea {
    padding: 14px 16px;
    font-family: Roboto;
    font-size: 16px;
    border-radius: 4px;
    border-color: #e8ecef;
    outline: none;
    max-height: 300px;
  }

  textarea:disabled {
    background-color: #eeeeee;
    color: #939393;
  }
`;

const TextFieldArea = ({
  className,
  minRows = 3,
  maxRows = 10,
  label,
  error = null,
  ...props
}) => {
  return (
    <StyledFormControl className={className} error={error} fullWidth>
      {Boolean(label) && <label>{label}</label>}
      <TextAreaMui
        style={error ? { border: "1px solid red", borderRadius: "4px" } : {}}
        minRows={minRows}
        maxRows={maxRows}
        {...props}
      />
      {error && <FormHelperText>{error}</FormHelperText>}
    </StyledFormControl>
  );
};

export default TextFieldArea;
