import React, { useState, useEffect, useCallback } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { Container, Stack, Typography } from "@mui/material";

import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";

import TextField from "../../components/form/TextField";
import Button from "../../components/form/Button";
import Select from "../../components/form/Select";
// import DatePicker from "../../components/form/DatePicker";

import Grid from "../../components/layout/Grid";

import Stepper from "../../components/navigation/Stepper";

import Box from "../../components/surfaces/Box";

import { saveFormAssessmentDetail } from "../../redux/actions/assessments";
import { JOB_ROLE } from "utils/dataConfig";
import { getUniqueListBy } from "utils/common";

const Div = styled.div`
  padding: 4vw 0;
`;

const steps = [
  "Assessment Details",
  "Select Test",
  "Add Question",
  "Review & Finishing",
];

const CAMPAIGN_TYPE_DDL = [
  {
    value: "evaluate",
    text: "Evaluate",
  },
  {
    value: "recruitment",
    text: "Recruitment",
  },
];

const CAMPAIGN_TARGET_DDL = [
  {
    value: "internal",
    text: "Internal",
  },
  {
    value: "external",
    text: "External",
  },
];
const INITIAL_FORM_ERROR = {
  campaign_name: false,
  job_role_id: false,
  job_role_level: false,
  campaign_start_date: false,
  campaign_end_date: false,
  campaign_type: false,
  use_in: false,
};

const AssessmentCreate = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { formAssessmentDetails } = useSelector((state) => state.assessments);
  const { masterDataJob, masterDataJobLevel } = useSelector(
    (state) => state.masterData
  );

  const [form, setForm] = useState(formAssessmentDetails);
  const [isSubmit, setIsSubmit] = useState(false);
  const [jobList, setJobList] = useState([]);
  const [errors, setErrors] = useState(INITIAL_FORM_ERROR);

  const flatProps = {
    options: JOB_ROLE.map((option) => option.label),
  };

  useEffect(() => {
    const newList = masterDataJob?.map((it) => ({
      label: it.text,
      id: it.value,
    }));

    const arrUnique = getUniqueListBy(newList, "id");

    setJobList(newList);
  }, [masterDataJob]);

  const validateForm = (
    fieldValues = { ...form, campaign_detail: 1, is_publish: 1 }
  ) => {
    let temp = { ...errors };
    if ("campaign_name" in fieldValues)
      temp.campaign_name =
        fieldValues.campaign_name !== "" ? "" : "กรุณากรอกชื่อ Campaign";
    if ("job_role_id" in fieldValues)
      temp.job_role_id =
        fieldValues.job_role_id !== "" ? "" : "กรุณาเลือก Job Role";
    if ("job_role_level" in fieldValues)
      temp.job_role_level =
        fieldValues.job_role_level !== "" ? "" : "กรุณาเลือก Job Level";
    if ("campaign_type" in fieldValues)
      temp.campaign_type =
        fieldValues.campaign_type !== "" ? "" : "กรุณาเลือก Campaign Type";
    if ("use_in" in fieldValues)
      temp.use_in =
        fieldValues.use_in !== "" ? "" : "กรุณาเลือก Campaign Target";
    if ("campaign_start_date" in fieldValues)
      temp.campaign_start_date =
        fieldValues.campaign_start_date !== "" ? "" : "กรุณาเลือกวันที่";
    if ("campaign_end_date" in fieldValues)
      temp.campaign_end_date =
        fieldValues.campaign_end_date !== "" ? "" : "กรุณาเลือกวันที่";

    setErrors({ ...temp });

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const onChangeForm = (event) => {
    const { value, name } = event.target;
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateForm({ [name]: value });
  };

  const handleJobChange = (event, value) => {
    setForm((prev) => ({
      ...prev,
      job_role_id: value?.id,
    }));
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();

    let submit = false;
    if (!isSubmit) {
      submit = true;
      setIsSubmit(true);
    } else {
      submit = true;
    }

    if (submit && isValid) {
      dispatch(saveFormAssessmentDetail(form));
      history.push("select-test");
    }
  };

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    ignoreAccents: true,
    stringify: (option) => option.label,
  });

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <form onSubmit={handleSubmit}>
              <Stack
                spacing={4}
                direction="column"
                alignItems="stretch"
                justifyContent="center"
              >
                <Typography variant="h2" color="initial" align="center">
                  Create New Assessment
                </Typography>
                <Stepper listStep={steps} />

                <TextField
                  label="Name Assessment"
                  placeholder="Name Assessment"
                  name="campaign_name"
                  value={form?.campaign_name}
                  error={errors?.campaign_name}
                  onChange={onChangeForm}
                  // required
                />

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <Autocomplete
                    style={{ width: "100%" }}
                    disablePortal
                    id="job_role_id"
                    options={JOB_ROLE}
                    // getOptionLabel={(option) => option.label}
                    // filterOptions={filterOptions}
                    value={
                      JOB_ROLE.find((it) => it.id === form?.job_role_id)?.label
                    }
                    // defaultValue={jobList[0]}
                    onChange={handleJobChange}
                    renderOption={(props, option, { selected }) => (
                      <li {...props} key={option.id}>
                        <span>{option.label}</span>
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Job Role"
                        name="job_role_id"
                        placeholder="Job Role (Optional)"
                        // value={form?.job_role_id}
                        error={errors?.job_role_id}
                      />
                    )}
                  />

                  <Select
                    label="Job Level"
                    defaultValue={"Job Level (Optional)"}
                    items={masterDataJobLevel}
                    name="job_role_level"
                    value={form?.job_role_level}
                    error={errors?.job_role_level}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <Select
                    label="Campaign Type"
                    defaultValue={"Campaign Type"}
                    items={CAMPAIGN_TYPE_DDL}
                    name="campaign_type"
                    value={form?.campaign_type}
                    error={errors?.campaign_type}
                    onChange={onChangeForm}
                    // required
                  />
                  <Select
                    label="Campaign Target"
                    defaultValue={"Campaign Target"}
                    items={CAMPAIGN_TARGET_DDL}
                    name="use_in"
                    value={form?.use_in}
                    error={errors?.use_in}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>

                <Stack
                  spacing={4}
                  direction={{ xs: "column", sm: "row" }}
                  justifyContent="space-between"
                >
                  <TextField
                    label="Start Date"
                    type="datetime-local"
                    placeholder={"Start Date"}
                    name="campaign_start_date"
                    value={form?.campaign_start_date}
                    error={errors?.campaign_start_date}
                    onChange={onChangeForm}
                    // required
                  />
                  <TextField
                    label="End Date"
                    type="datetime-local"
                    placeholder={"End Date"}
                    name="campaign_end_date"
                    value={form?.campaign_end_date}
                    error={errors?.campaign_end_date}
                    onChange={onChangeForm}
                    // required
                  />
                </Stack>

                <Box mt={4}>
                  <Button block type="submit">
                    Process to next step
                  </Button>
                </Box>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default AssessmentCreate;
