import { getAPI, postAPI, patchAPI } from "utils/api";
import { showLoading, hideLoading } from "redux/actions/loading";
import {
  GET_VERIFY_EMAIL,
  GET_MEMBER_INFO,
  CREATE_INDIVIDUAL_MEMBER,
  CREATE_REQUEST_RESET_PASSWORD,
  CREATE_NEW_PASSWORD,
  UPDATE_FORM_INDIVIDUAL,
  CLEAR_FORM_INDIVIDUAL_MEMBER,
  UPDATE_FORM_CONDITIONS,
  CREATE_MEMBER_BUSINESS,
  UPDATE_VERIFY_EMAIL_BUSINESS,
  UPDATE_TESTER_MEMBER,
  UPDATE_FORM_TESTER,
  UPDATE_FORM_CONDITIONS_TESTER,
  TRIGGER_LOGOUT,
} from "../actionTypes";

export const triggleLogoutFromOther = (bool) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: TRIGGER_LOGOUT, payload: bool });
  dispatch(hideLoading());
};

export const saveFormTester = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_TESTER, payload: form });
  dispatch(hideLoading());
};

export const saveFormConditionsTester = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_CONDITIONS_TESTER, payload: form });
  dispatch(hideLoading());
};

export const saveFormIndividual = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_INDIVIDUAL, payload: form });
  dispatch(hideLoading());
};

export const saveFormConditions = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: UPDATE_FORM_CONDITIONS, payload: form });
  dispatch(hideLoading());
};

export const clearFormIndividualMember = (form) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CLEAR_FORM_INDIVIDUAL_MEMBER });
  dispatch(hideLoading());
};

export const getMemberInfo = () => (dispatch) => {
  // const { limit } = getState().member;
  const { member } = JSON.parse(localStorage.getItem("user"));

  dispatch(showLoading());
  dispatch({ type: GET_MEMBER_INFO.REQUEST });
  return getAPI({
    url: `front/memberInfo/${member?.member_id}`,
  })
    .then((data) => dispatch({ type: GET_MEMBER_INFO.SUCCESS, payload: data }))
    .catch((error) => dispatch({ type: GET_MEMBER_INFO.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const verifyEmail = (token) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: GET_VERIFY_EMAIL.REQUEST });
  return getAPI({
    url: "front/verifyemail",
    params: {
      token,
    },
  })
    .then((data) => {
      dispatch({ type: GET_VERIFY_EMAIL.SUCCESS, payload: data });
      // localStorage.removeItem("access_token");
      // localStorage.removeItem("user");

      dispatch(triggleLogoutFromOther(true));
      return data;
    })
    .catch((error) => dispatch({ type: GET_VERIFY_EMAIL.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const verifyEmailBusiness = (token) => (dispatch) => {
  // const { limit } = getState().member;
  dispatch(showLoading());
  dispatch({ type: UPDATE_VERIFY_EMAIL_BUSINESS.REQUEST });
  return patchAPI({
    url: `front/memberUpgrade/${token}`,
  })
    .then((data) => {
      dispatch({ type: UPDATE_VERIFY_EMAIL_BUSINESS.SUCCESS, payload: data });
      // localStorage.removeItem("access_token");
      // localStorage.removeItem("user");
      dispatch(triggleLogoutFromOther(true));

      return data;
    })
    .catch((error) =>
      dispatch({ type: UPDATE_VERIFY_EMAIL_BUSINESS.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createIndividualMember = (body) => (dispatch) => {
  try {
    dispatch(showLoading());
    dispatch({ type: CREATE_INDIVIDUAL_MEMBER.REQUEST });
    return postAPI({
      url: "front/rigistester",
      data: body,
    })
      .then((data) => {
        localStorage.setItem("email", body.email);

        dispatch({
          type: CREATE_INDIVIDUAL_MEMBER.SUCCESS,
          showAlert: true,
          alertMessage: "Create Member Success",
        });

        return data;
      })
      .catch((error) =>
        dispatch({
          type: CREATE_INDIVIDUAL_MEMBER.FAILURE,
          error,
          alertMessage: "User already verified",
        })
      )
      .finally(() => {
        dispatch(hideLoading());
      });
  } catch (error) {
    dispatch({ type: CREATE_INDIVIDUAL_MEMBER.FAILURE, error });
  }
};

export const createRequestResetPassword = (body) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CREATE_REQUEST_RESET_PASSWORD.REQUEST });
  return postAPI({
    url: `front/sendResetPwd`,
    data: body,
  })
    .then((data) =>
      dispatch({ type: CREATE_REQUEST_RESET_PASSWORD.SUCCESS, payload: data })
    )
    .catch((error) =>
      dispatch({ type: CREATE_REQUEST_RESET_PASSWORD.FAILURE, error })
    )
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createNewPassword = (body) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CREATE_NEW_PASSWORD.REQUEST });
  return postAPI({
    url: `front/resetPwd`,
    data: body,
  })
    .then((data) =>
      dispatch({ type: CREATE_NEW_PASSWORD.SUCCESS, payload: data })
    )
    .catch((error) => dispatch({ type: CREATE_NEW_PASSWORD.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const createMemberBusiness = (body) => (dispatch) => {
  dispatch(showLoading());
  dispatch({ type: CREATE_MEMBER_BUSINESS.REQUEST });
  return postAPI({
    url: `front/memberUpgrade`,
    data: body,
  })
    .then((data) => {
      dispatch({
        type: CREATE_MEMBER_BUSINESS.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Upgrade Member Success",
      });
      return data;
    })
    .catch((error) => dispatch({ type: CREATE_MEMBER_BUSINESS.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};

export const upgradeTester = (body) => (dispatch) => {
  const { member } = JSON.parse(localStorage.getItem("user"));

  dispatch(showLoading());
  dispatch({ type: UPDATE_TESTER_MEMBER.REQUEST });
  return patchAPI({
    url: `front/testerupgrade/${member?.member_id}`,
    data: body,
  })
    .then((data) => {
      localStorage.setItem("email", body.email);
      dispatch({
        type: UPDATE_TESTER_MEMBER.SUCCESS,
        payload: data,
        showAlert: true,
        alertMessage: "Upgrade Tester Success",
      });
      return data;
    })
    .catch((error) => dispatch({ type: UPDATE_TESTER_MEMBER.FAILURE, error }))
    .finally(() => {
      dispatch(hideLoading());
    });
};
