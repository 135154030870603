import React, { useState, useEffect, useCallback, Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import formatValue from "../utils/formatValue";
import { useHistory } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Menu,
  Container,
  Avatar,
  Tooltip,
  MenuItem,
} from "@mui/material";

import Box from "../components/surfaces/Box";
import Button from "../components/form/Button";
import ButtonIcon from "../components/form/ButtonIcon";
import Stack from "../components/layout/Stack";

import { Menu as MenuIcon, Adb, KeyboardArrowDown } from "@mui/icons-material";

import imgLogo from "../assets/images/fuse-on-logo-ch.png";

import Credit from "../assets/icon/credit.png";
import AvatarIcon from "../assets/icon/user.png";
import SubContentDialog from "components/dialog/SubContentDialog";
import Grid from "components/layout/Grid";
import TextField from "components/form/TextField";
import { Link } from "react-router-dom";

import { loginFront, logout } from "redux/actions/auth";
import { toggleLogin } from "redux/actions/share";
import { triggleLogoutFromOther } from "redux/actions/register";

const pages = [
  { name: "My Assessments", path: "/assessments" },
  { name: "My Candidates", path: "#" },
  { name: "Test", path: "/test/testset" },
];
const settings = ["Profile", "Account", "Dashboard"];

const HeaderNew = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const accessTokenLS = localStorage.getItem("access_token");
  const memberLS = localStorage.getItem("user");

  const { openLogin, triggerLogout } = useSelector((state) => state.register);

  // const [auth, setAuth] = React.useState(true);
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPW, setErrorPW] = useState("");

  const [form, setForm] = useState({});
  const [isOpenLoginDialog, setIsOpenLoginDialog] = useState(false);
  const [isOpenWarningDialog, setIsOpenWarningDialog] = useState(false);
  const [isOpenWarningNotFoundDialog, setIsOpenWarningNotFoundDialog] =
    useState(false);
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const [member, setMember] = useState();
  const [token, setToken] = useState();

  useEffect(() => {
    setMember(JSON.parse(memberLS)?.member);
  }, [memberLS]);

  useEffect(() => {
    setToken(accessTokenLS);
  }, [accessTokenLS]);

  useEffect(() => {
    if (triggerLogout) {
      handleLogoutFromOther();
    }

    return () => {
      dispatch(triggleLogoutFromOther(false));
    };
  }, [triggerLogout]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const goToPage = (path) => {
    handleCloseUserMenu();
    history.push(`${path}`);
  };

  const handleOpenWarnVerifyEmail = (event) => {
    setIsOpenWarningDialog(true);
  };

  const handleCloseWarnVerifyEmail = (event) => {
    setIsOpenWarningDialog(false);
  };

  const handleOpenWarnNotFound = (event) => {
    setIsOpenWarningNotFoundDialog(true);
  };

  const handleCloseWarnNotFound = (event) => {
    setIsOpenWarningNotFoundDialog(false);
  };

  const handleOpenLogin = (event) => {
    dispatch(toggleLogin(true));

    // setIsOpenLoginDialog(true);
  };

  const handleCloseLogin = (event) => {
    dispatch(toggleLogin(false));
    // setIsOpenLoginDialog(false);
  };

  const actionLogin = useCallback(
    (e) => {
      e?.preventDefault();

      let body = {
        email: form?.email,
        password: form?.password,
        strategy: "front-local",
      };

      dispatch(loginFront(body)).then((data) => {
        if (data === "Invalid login") setErrorPW("Incorrect Password");
        else if (data === "Not found user.") setErrorEmail("No user Found");
        else {
          handleCloseLogin();
          if (data === "User not verify email.") handleOpenWarnVerifyEmail();
          else if (data?.member?.redirect_meminfo)
            history.replace("/register/tester");
          else if (data?.member?.member_level === "business")
            history.replace("/assessments");
          else history.replace("/welcome");
        }
      });
    },
    [dispatch, history, form]
  );

  const handleChangeForm = (event) => {
    const { name, value } = event.target;
    if (name === "email") setErrorEmail("");
    if (name === "password") setErrorPW("");
    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleLogin = (event) => {
    event.preventDefault();
    actionLogin();
    // handleOpenWarnVerifyEmail();
  };

  const handleLogout = () => {
    handleCloseUserMenu();
    handleCloseNavMenu();
    // setAuth(false);
    dispatch(logout());
  };

  const handleLogoutFromOther = () => {
    console.log("เข้า logout");
    handleCloseUserMenu();
    handleCloseNavMenu();
    localStorage.removeItem("access_token");
    localStorage.removeItem("user");
  };

  return (
    <AppBar position="static" color="transparent">
      <SubContentDialog
        // open={isOpenLoginDialog}
        open={openLogin}
        title={"Login"}
        alignTitle="center"
        onClose={handleCloseLogin}
        maxWidth="sm"
      >
        <form onSubmit={handleLogin}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={12}>
              <Stack spacing={2}>
                <TextField
                  placeholder="E-mail"
                  name="email"
                  type="email"
                  onChange={handleChangeForm}
                  value={form?.email}
                  error={errorEmail}
                />
                <TextField
                  placeholder="Password"
                  type="password"
                  name="password"
                  onChange={handleChangeForm}
                  value={form?.password}
                  error={errorPW}
                />
                <Typography variant="body1" align="right" color="initial">
                  <Link to="/forget-password" onClick={handleCloseLogin}>
                    forget password?
                  </Link>
                </Typography>
                <Button block type="submit">
                  Login
                </Button>

                <Typography variant="body1" align="center" color="initial">
                  Don't hanve an account :{" "}
                  <Link to="/register/individual" onClick={handleCloseLogin}>
                    Register
                  </Link>
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>
      <SubContentDialog
        open={isOpenWarningDialog}
        title={"Warning"}
        alignTitle="center"
        onClose={handleCloseWarnVerifyEmail}
        maxWidth="sm"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <Typography variant="body1" color="initial" align="center">
                คุณได้สร้างบัญชีแล้ว แต่ยังไม่ได้ Verify Email
                <Typography variant="body1" color="initial">
                  กรุณายืนยัน Email ก่อนที่จะเข้าสู่ระบบ
                </Typography>
              </Typography>

              <Button block onClick={handleCloseWarnVerifyEmail}>
                Close
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>
      <SubContentDialog
        open={isOpenWarningNotFoundDialog}
        title={"Warning"}
        alignTitle="center"
        onClose={handleCloseWarnNotFound}
        maxWidth="sm"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12}>
            <Stack spacing={2} alignItems="center" justifyContent="center">
              <Typography variant="body1" color="initial" align="center">
                Not Found User
                <Typography variant="body1" color="initial">
                  Please check user information.
                </Typography>
              </Typography>

              <Button block onClick={handleCloseWarnNotFound}>
                Close
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>
      <Container maxWidth="xl">
        <Toolbar sx={{ px: { xs: 0, sm: 2 } }}>
          <Box sx={{ mr: 4, flexGrow: { xs: 1, md: 0 }, cursor: "pointer" }}>
            <img
              src={imgLogo}
              className="clickable"
              alt="logo"
              onClick={() => history.push("/")}
            />
          </Box>
          {/* <Adb sx={{ display: { xs: "none", md: "flex" }, mr: 1 }} />
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: "none", md: "flex" },
              fontFamily: "monospace",
              fontWeight: 700,
              letterSpacing: ".3rem",
              color: "inherit",
              textDecoration: "none",
            }}
          >
            LOGO
          </Typography> */}

          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page, index) => {
              return (
                <Button
                  key={index}
                  sx={(theme) => ({
                    "&:hover": {
                      textDecoration: "underline",
                      color: theme.palette.primary.main,
                    },
                  })}
                  variant="text"
                  onClick={() => goToPage(page?.path)}
                  color="grey"
                >
                  {page?.name}
                </Button>
              );
            })}
            {!token ? (
              <Button
                sx={(theme) => ({
                  "&:hover": {
                    textDecoration: "underline",
                    color: theme.palette.primary.main,
                  },
                })}
                variant="text"
                onClick={() => goToPage("register/individual")}
                color="grey"
              >
                Register
              </Button>
            ) : (
              ""
            )}
          </Box>

          {token ? (
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <Stack
                sx={{ height: 30 }}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                {/* <Box sx={{ display: "flex" }}>
                  <Box sx={{ mr: 1 }}>
                    <img src={Credit} alt="credit-icon" />
                  </Box>
                  <Typography variant="body1">
                    {formatValue("Number", 1000)}
                  </Typography>
                </Box> */}
                <Tooltip title="Open settings">
                  <Button
                    onClick={handleOpenUserMenu}
                    sx={{ px: 1, my: 1, borderRadius: 10, opacity: 0.9 }}
                    outlined
                    endIcon={<KeyboardArrowDown />}
                    size="small"
                    color="grey"
                  >
                    <Avatar
                      sx={{ mr: 1, width: 24, height: 24 }}
                      alt="user-image"
                      src={AvatarIcon}
                    />
                    <Typography variant="body1" color="initial">
                      {`${member?.first_name} ${member?.last_name}`}
                    </Typography>
                  </Button>
                </Tooltip>
              </Stack>
            </Box>
          ) : (
            <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
              <Stack
                sx={{ height: 30 }}
                direction="row"
                spacing={2}
                alignItems="center"
              >
                <Button
                  sx={(theme) => ({
                    "&:hover": {
                      textDecoration: "underline",
                      color: theme.palette.primary.main,
                    },
                  })}
                  variant="text"
                  onClick={() => handleOpenLogin()}
                  color="grey"
                >
                  <Typography variant="body1">Login</Typography>
                </Button>
                <Button onClick={() => history.push("register/individual")}>
                  <Typography variant="body1">Register</Typography>
                </Button>
              </Stack>
            </Box>
          )}

          <Box sx={{ flexGrow: 0, display: { xs: "flex", md: "none" } }}>
            <ButtonIcon
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon />
            </ButtonIcon>
          </Box>

          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: "block", md: "none" },
            }}
          >
            {token &&
              pages.map((page, index) => {
                return (
                  <MenuItem
                    key={index}
                    onClick={() => {
                      goToPage(page?.path);
                      handleCloseNavMenu();
                    }}
                  >
                    <Typography textAlign="center">{page?.name}</Typography>
                  </MenuItem>
                );
              })}
            {!token ? (
              <Stack>
                <MenuItem
                  onClick={() => {
                    handleOpenLogin();
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Login</Typography>
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    goToPage("/register/individual");
                    handleCloseNavMenu();
                  }}
                >
                  <Typography textAlign="center">Register</Typography>
                </MenuItem>
              </Stack>
            ) : (
              <MenuItem onClick={() => handleLogout()}>
                {/* g??? */}
                <Typography textAlign="center">Logout</Typography>
              </MenuItem>
            )}
          </Menu>

          <Menu
            sx={{ mt: "45px" }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(anchorElUser)}
            onClose={() => handleCloseUserMenu()}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={() => handleCloseUserMenu()}>
                <Typography textAlign="center">{setting}</Typography>
              </MenuItem>
            ))}
            <MenuItem onClick={() => handleLogout()}>
              <Typography textAlign="center">Logout</Typography>
            </MenuItem>
          </Menu>
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default HeaderNew;
