import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Box from "components/surfaces/Box";

import Button from "../../components/form/Button";
import Grid from "../../components/layout/Grid";
import TextField from "../../components/form/TextField";

import { Container, Stack, Typography } from "@mui/material";
import { createRequestResetPassword } from "redux/actions/register";

import { validateEmail } from "utils/validation";

const Div = styled.div`
  padding: var(--padding);
  padding: 4vw 1vw;
`;

const ForgetPassword = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});

  const {} = useSelector((state) => state.assessments);

  const onChangeEmail = (event) => {
    const { name, value } = event.target;
    setEmail(value);
  };

  const validateForm = (fieldValues = { email }) => {
    let temp = {
      ...errors,
    };

    if ("email" in fieldValues)
      temp.email =
        fieldValues.email !== ""
          ? (temp.email = validateEmail(fieldValues.email)
              ? ""
              : "กรุณากรอกรูปแบบอีเมล์ให้ถูกต้อง")
          : "กรุณากรอกอีเมล์";

    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    let isValid = validateForm();

    if (isValid) {
      let body = {
        email,
      };

      dispatch(createRequestResetPassword(body)).then((res) => {
        if (!res?.error) {
          history.push("/success-forget");
        } else {
          setErrors({ ...errors, email: res?.error });
        }
      });
    }
  };
  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={4} justifyContent="center" alignItems="center">
                <Typography
                  sx={{ display: { xs: "none", sm: "flex" } }}
                  align="center"
                  variant={"h2"}
                  gutterBottom
                  color="#005cc9"
                >
                  Forget your password?
                </Typography>
                <Typography
                  sx={{ display: { xs: "flex", sm: "none" } }}
                  align="center"
                  variant={"h3"}
                  gutterBottom
                  color="#005cc9"
                >
                  Forget your password?
                </Typography>
                <Typography variant="h5" color="initial">
                  Please input your email
                </Typography>
                <Box sx={{ width: { xs: "100%", sm: 300 } }}>
                  <TextField
                    placeholder="E-mail"
                    name="email"
                    value={email}
                    onChange={onChangeEmail}
                    error={errors?.email}
                  />
                </Box>
                <Button sx={{ width: { xs: "100%", sm: 300 } }} type="submit">
                  Reset your password
                </Button>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default ForgetPassword;
