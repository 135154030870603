import React from "react";
import styled from "styled-components";
import { Tabs as TabsMui, Tab as TapMui } from "@mui/material";

const StyleTabs = styled(TabsMui)`
  .MuiTabs-flexContainer {
    justify-content: "center";
  }
`;

const Taps = ({ listTap, valueCurrentTab, ...props }) => {
  return (
    <StyleTabs
      allowScrollButtonsMobile
      value={valueCurrentTab}
      indicatorColor="primary"
      {...props}
    >
      {listTap.length > 0 &&
        listTap.map((item, index) => <TapMui label={item} key={index} />)}
    </StyleTabs>
  );
};

export default Taps;
