import React from "react";
import styled from "styled-components";
import {
  FormControl,
  TextField as TextFieldMui,
  InputAdornment,
} from "@mui/material";

const StyledFormControl = styled(FormControl)`
  label {
    font-size: 16px;
    margin-bottom: 13px;
  }

  .MuiInputBase-root-MuiOutlinedInput-root {
    & .MuiOutlinedInput-notchedOutline {
      border-color: #ecedf2;
      border-width: 1px;
      :hover {
        border-color: inherit;
      }
    }
  }

  .Mui-disabled {
    background-color: #eeeeee;
  }

  /* .MuiInputBase-root {
    &.Mui-error {
      border: 1px solid red;
    }
  } */
`;

const TextField = ({
  className,
  innerIconFieldStart = "",
  variant = "outlined",
  label,
  size = "small",
  error = false,
  ...props
}) => {
  const iconStartProp = innerIconFieldStart
    ? {
      startAdornment: (
        <InputAdornment position="start">
          {innerIconFieldStart}
        </InputAdornment>
      ),
    }
    : {};

  return (
    <StyledFormControl className={className} fullWidth>
      {Boolean(label) && <label>{label}</label>}
      <TextFieldMui
        size={size}
        variant={variant}
        error={error ? true : false}
        helperText={error ? error : ''}
        {...props}
        {...(innerIconFieldStart !== "" && { InputProps: iconStartProp })}
      />
    </StyledFormControl>
  );
};

export default TextField;
