import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "components/form/Button";

import Grid from "components/layout/Grid";
import Stack from "components/layout/Stack";

import Box from "components/surfaces/Box";

import { Container, Typography } from "@mui/material";

import SuccessIcon from "assets/icon/success.png";
import { toggleLogin } from "redux/actions/share";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const SuccessTester = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.register);
  const email = localStorage.getItem("email");
  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Stack
              spacing={4}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Typography variant="body1" align="center" color="initial">
                <img width="100" alt="success" src={SuccessIcon} />
              </Typography>

              <Typography
                variant="h2"
                color="initial"
                align="center"
                gutterBottom
              >
                Thank you
              </Typography>

              <Typography variant="body1" align="center" color="initial">
                We sent an email to
              </Typography>

              <Typography variant="h5" align="center" color="initial">
                {email}
              </Typography>

              <Typography variant="body1" align="center" color="initial">
                Click confirmation link in the mail to verify your account
              </Typography>

              <Button
                sx={{ width: { xs: "100%", sm: 300 } }}
                onClick={() => history.push("/")}
              >
                Home
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={2}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default SuccessTester;
