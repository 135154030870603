import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Close } from "@material-ui/icons";
import formatValue from "utils/formatValue";

import Typography from "@mui/material/Typography";

import Box from "../../components/surfaces/Box";

import Grid from "../../components/layout/Grid";
import Stack from "components/layout/Stack";

import TextField from "components/form/TextField";
import Select from "components/form/Select";
import Button from "components/form/Button";
import ButtonIcon from "components/form/ButtonIcon";

import Table from "components/table/Table";
import {
  getQuestionList,
  getQuestionListRaw,
  saveFormExamSet,
} from "redux/actions/assessments";
import { RenderHtmlToString } from "components/form/ConvertHtmlToString";
import { DisplyDeltaOrHtml } from "components/form/ConvertHtmlToString";
import RouterLinkCell from "components/table/RouterLinkCell";
import SubContentDialog from "components/dialog/SubContentDialog";
import Pagination from "components/navigation/Pagination";

const Div = styled.div`
  padding: 5vw 7vw;
  margin: 0 auto;
`;

const mockData = [
  {
    id: 1,
    question_no: "test01",
    question: "test01",
    category: "คณิตศาสตร์",
    topic: "test01",
    author: "test01",
    create_at: "02/06/2022",
    active_at: "02/06/2022",
  },
  {
    id: 2,
    question_no: "test01",
    question: "test01",
    category: "คณิตศาสตร์",
    topic: "test01",
    author: "test01",
    create_at: "02/06/2022",
    active_at: "02/06/2022",
  },
];

const INITIAL_FILTER = {
  skip: 0,
  code: "",
  exam_owner: "",
  subject_id: "",
  topic_name: "",
  level: "",
  question: ""
};

const AssessmentAddExamSet = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    questionList,
    questionListRaw,
    pageCount,
    limit,
    total,
    skip,
    formExamSet,
  } = useSelector((state) => state.assessments);
  const { masterDataSubject, masterDataLevel } = useSelector(
    (state) => state.masterData
  );

  const [page, setPage] = useState(1);

  const [rawQuestionList, setRawQuestionList] = useState([]);

  const [updateForm, setUpdateForm] = useState(formExamSet);
  const [selectedListControl, setSelectedListControl] = useState([]);
  const [isOpenModalQuestion, setIsOpenModalQuestion] = useState(false);
  const [currentExamSet, setCurrentExamSet] = useState("");

  const [filter, setFilter] = useState(INITIAL_FILTER);

  const [totalQuestion, setTotalQuestion] = useState(0);
  const columns = [
    {
      field: "code",
      headerName: "รหัสข้อสอบ ",
      renderCell: ({ value, row }) => {
        return (
          <RouterLinkCell onClick={() => handleOpenModalQuestion(row)}>
            {value}{" "}
          </RouterLinkCell>
        );
      },
    },
    {
      field: "question",
      headerName: "โจทย์ ",
      // renderCell: RenderHtmlToString,
      renderCell: ({ row, value }) => {
        return (
          <div>
            {RenderHtmlToString({ value })}
            {row?.question_image !== "" ? (
              <div>
                <img className="question-img" src={row?.question_image} />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
      minWidth: 300,
    },
    {
      field: "name_upper",
      headerName: "หมวดวิชา ",
    },
    {
      field: "topic_name",
      headerName: "หัวข้อ ",
    },
    {
      field: "exam_owner",
      headerName: "ชื่อผู้ออกข้อสอบ ",
    },
    {
      field: "created_at",
      headerName: "วันที่สร้างข้อสอบ ",
      valueGetter: ({ row }) => formatValue("Date", row?.created_at),
    },
    {
      field: "status",
      headerName: "การเปิดใช้งาน",
    },
  ];

  useEffect(() => {
    fetchRawQuestionList();
    
    let patchOrderId = [...formExamSet?.question].map((item) => item.id);
    setSelectedListControl(patchOrderId);
    setTotalQuestion(formExamSet?.question?.length)
  }, []);

  useEffect(() => {
    dispatch(getQuestionList(filter));
  }, [filter]);

  useEffect(() => {
    setRawQuestionList(questionListRaw);
  }, [questionListRaw]);

  const handleChangeFilter = (e) => {
    const { name, value } = e.target;

    setFilter((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const fetchRawQuestionList = () => {
    dispatch(getQuestionListRaw());
  };

  const handleUpdateQuestionList = (selectList) => {
    let newArr = rawQuestionList
      .filter((item) => selectList.includes(item?.id))
      .map((item) => ({
        ...item,
        my_level: item?.level,
      }));

    let newForm = { ...updateForm, question: newArr };

    setUpdateForm(newForm);
  };

  const handleOrderId = (list) => {
    handleUpdateQuestionList(list);
    setSelectedListControl(list);
    setTotalQuestion(list?.length);
  };

  const handleAddQuestion = () => {
    
    dispatch(saveFormExamSet(updateForm));
    history.goBack();
  };

  const handleOpenModalQuestion = (data) => {
    setCurrentExamSet({ ...data });
    setIsOpenModalQuestion(!isOpenModalQuestion);
  };

  const handleCloseModalQuestion = () => {
    setIsOpenModalQuestion(!isOpenModalQuestion);
  };

  const onChangePage = (event, value) => {
    setPage(value);
    setFilter((prev) => ({
      ...prev,
      skip: limit * (value - 1),
    }));
  };
  return (
    <Div>
      <SubContentDialog
        title=""
        open={isOpenModalQuestion}
        onClose={handleCloseModalQuestion}
        maxWidth="md"
      >
        <Grid container>
          <Grid item xs={12} sm={12}>
            <Stack spacing={2} sx={{}}>
              <Typography variant="subtitle1" color="initial" gutterBottom>
                Sample:{" "}
                <Typography
                  variant="subtitle1"
                  color="primary"
                  component={"span"}
                >
                  {currentExamSet?.topic_name}
                </Typography>
              </Typography>

              <Typography variant="body1" color="initial" gutterBottom>
                {DisplyDeltaOrHtml(currentExamSet?.question)}
                {currentExamSet?.question_image !== "" ? (
                  <div>
                    <img
                      className="question-img"
                      src={currentExamSet?.question_image}
                    />
                  </div>
                ) : (
                  ""
                )}
                <ol>
                  {currentExamSet?.choice?.map((choice, index) => (
                    <li key={index}>
                      {DisplyDeltaOrHtml(choice?.choice_description)}
                      {choice?.image_url !== "" ? (
                        <div>
                          <img
                            width={200}
                            className="question-img"
                            src={choice?.image_url}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                    </li>
                  ))}
                </ol>
              </Typography>

              <Typography variant="body1" color="initial" gutterBottom>
                Correct answer : {currentExamSet?.answer}
              </Typography>
            </Stack>
          </Grid>
        </Grid>
      </SubContentDialog>

      <Grid container spacing={5}>
        <Grid
          item
          spacing={2}
          xs={12}
          sm={12}
          sx={{ display: { xs: "none", sm: "flex" }, position: "relative" }}
        >
          <Grid item xs={12} sm={2}>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>

          <Grid item xs={12} sm>
            <ButtonIcon
              aria-label="close"
              onClick={() => history.goBack()}
              sx={{
                position: "absolute",
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <Close />
            </ButtonIcon>
          </Grid>
        </Grid>

        <Grid item spacing={2} xs={12} sm={12}>
          <Typography variant="h3" color="initial">
            เพิ่มข้อสอบเข้าสู่ชุดข้อสอบ
          </Typography>
        </Grid>

        <Grid item container spacing={2} xs={12} sm={12}>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="รหัสข้อสอบ"
              name="code"
              value={filter?.code}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="โจทย์"
              name="question"
              value={filter?.question}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="ชื่อผู้ออกข้อสอบ"
              name="exam_owner"
              value={filter?.exam_owner}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              defaultValue="วิชา"
              name="subject_id"
              value={filter?.subject_id}
              items={masterDataSubject}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="หัวข้อ"
              name="topic_name"
              value={filter?.topic_name}
              onChange={handleChangeFilter}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <Select
              defaultValue="ระดับความยาก"
              name="level"
              value={filter?.level}
              items={masterDataLevel}
              onChange={handleChangeFilter}
            />
          </Grid>
        </Grid>

        <Grid item spacing={2} xs={12} sm={12}>
          <Typography variant="body1" color="initial">
            Selected {totalQuestion} questions
          </Typography>
        </Grid>

        <Grid item container spacing={2} xs={12} sm={12}>
          <Box sx={{ height: 500, width: "100%" }}>
            <Table
              columns={columns}
              rows={questionList}
              checkboxSelection
              keepNonExistentRowsSelected
              onSelectionChange={(e) => handleOrderId(e)}
              selectionModel={selectedListControl}
              disablecBorderContent
              showRunningNumber
              disableSelectionOnClick
            />
          </Box>
        </Grid>
        {questionList?.length > 0 && (
          <Grid xs={12} item>
            <Pagination count={pageCount} page={page} onChange={onChangePage} />
          </Grid>
        )}

        <Grid item container xs={12} sm={12}>
          <Grid item xs={12} sm={2}></Grid>
          <Grid item xs={12} sm={8}>
            <Button block onClick={() => handleAddQuestion()}>
              เพิ่มข้อสอบ
            </Button>
          </Grid>
          <Grid item xs={2} sm={2}></Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default AssessmentAddExamSet;
