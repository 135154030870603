import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";

import Typography from "@mui/material/Typography";

import Box from "components/surfaces/Box";
import ConfirmDialog from "components/dialog/ConfirmDialog";

import BannerWelcome from "assets/images/banner-welcome.png";
import BannerWelcomeMobile from "assets/images/banner-welcome-mobile.png";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
  ul {
    padding-top: 0;
  }
`;

const Welcome = () => {
  const history = useHistory();
  const member = JSON.parse(localStorage.getItem("user"))?.member;
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const upgradeMember = () => {
    handleOpenConfirmDialog();
  };

  const handleOpenConfirmDialog = () => {
    setOpenConfirmDialog(true);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(false);
  };

  const handleUpgrade = () => {
    history.push("/register/business");
  };
  return (
    <Div>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleUpgrade}
        title="Free upgrade to business account"
        content={"Explore our easy and fast test creation."}
        confirmName={"Upgrade"}
        maxWidth="sm"
      />
      <Typography sx={{ xs: 1, sm: 0 }} variant="h3" color="primary">
        Welcome ! {`${member?.first_name} ${member?.last_name}`}
        <Typography variant="body1" color="initial">
          This is individual account. You can check your test result here, but
          we are currently on development.
        </Typography>
      </Typography>

      <br />
      <br />

      <Typography variant="body1" color="initial">
        Just hear me out!, do you like to be a test creator? , check out our
        business features.
      </Typography>
      <Box
        sx={{
          maxWidth: "100%",
          backgroundImage: {
            xs: `url(${BannerWelcomeMobile})`,
            sm: `url(${BannerWelcome})`,
          },
          backgroundPosition: { xs: "bottom", sm: "center" },
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          height: "auto",
          py: { xs: 3, sm: 5 },
          px: { xs: 4, sm: 7 },
          mb: { xs: 1, sm: 0 },
        }}
      >
        <Typography
          sx={{ textAlign: { xs: "center", sm: "left" } }}
          variant="h3"
          color="initial"
        >
          Easy and Fast Test Creation
        </Typography>
        <Typography variant="body1" color="initial" paragraph>
          Enjoy our fascination features.
          <ul>
            <li>Create assessment</li>
            <li>Create your own Questions and test</li>
            <li>Detail performance report for candidates</li>
          </ul>
        </Typography>

        <br />
        <br />
        <br />
        <Typography variant="body1" color="initial" gutterBottom>
          Only available in business account. No cost and Limited time
        </Typography>
        <Button onClick={() => upgradeMember()}>
          Free Upgrade to business account
        </Button>
      </Box>
    </Div>
  );
};

export default Welcome;
