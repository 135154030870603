import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Snackbar from "@mui/material/Snackbar";
import { Alert } from "@mui/material";
import Slide from "@mui/material/Slide";
import styled from "styled-components";

import { selectAlert } from "redux/selectors";
import { clearAlert } from "redux/actions/alert";

const StyledSnackbar = styled(Snackbar)`
  max-width: 30%;
  top: 24px;
  left: auto;
  right: 24px;
`;

const SlideTransition = (props) => <Slide {...props} direction="left" />;

export default () => {
  const { isShow, message, type } = useSelector(selectAlert);
  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason !== "clickaway") {
      dispatch(clearAlert());
    }
  };

  return (
    <StyledSnackbar
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      autoHideDuration={3000}
      open={isShow}
      onClose={handleClose}
      TransitionComponent={SlideTransition}
    >
      <Alert elevation={6} variant="filled" severity={type}>
        {message}
      </Alert>
    </StyledSnackbar>
  );
};
