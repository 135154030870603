import * as React from "react";
import { styled } from "@mui/material/styles";

import ButtonIcon from "../form/ButtonIcon";
import Stack from "../layout/Stack";

import Upload from "../../assets/icon/upload2.png";

const Input = styled("input")({
  display: "none",
});

const ButtonUploadImage = ({ id, onChange, ...props }) => {
  return (
    <label htmlFor={id}>
      <Input
        accept="image/*"
        id={id}
        //    multiple
        onChange={onChange}
        type="file"
      />
      <ButtonIcon component="span" {...props}>
        <img src={Upload} alt="" />
      </ButtonIcon>
    </label>
  );
};

export default ButtonUploadImage;
