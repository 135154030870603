const genRequest = (type) => ({
  REQUEST: `${type}_REQUEST`,
  SUCCESS: `${type}_SUCCESS`,
  FAILURE: `${type}_FAILURE`,
});

export const CLEAR_ALERT = "CLEAR_ALERT";

export const SHOW_LOADING = "SHOW_LOADING";
export const HIDE_LOADING = "HIDE_LOADING";
export const TOGGLE_LOGIN = "TOGGLE_LOGIN";
export const TRIGGER_LOGOUT = "TRIGGER_LOGOUT";

export const GET_MASTER_DATA_JOB = "GET_MASTER_DATA_JOB";
export const GET_MASTER_DATA_JOB_LEVEL = "GET_MASTER_DATA_JOB_LEVEL";
export const GET_MASTER_DATA_SUBJECT = "GET_MASTER_DATA_SUBJECT";
export const GET_MASTER_DATA_PROVINCE = "GET_MASTER_DATA_PROVINCE";
export const GET_MASTER_DATA_DISTRICT = "GET_MASTER_DATA_DISTRICT";
export const GET_MASTER_DATA_SUB_DISTRICT = "GET_MASTER_DATA_SUB_DISTRICT";

export const UPDATE_TO_MODE_EDIT_CAMPAIGN = "UPDATE_TO_MODE_EDIT_CAMPAIGN";
export const UPDATE_FORM_ASSESSMENT_DETAIL = "UPDATE_FORM_ASSESSMENT_DETAIL";
export const SET_SUMMARY_TEST = "SET_SUMMARY_TEST";
export const UPDATE_FORM_ASSESSMENT_SELECT_TEST =
  "UPDATE_FORM_ASSESSMENT_SELECT_TEST";
export const UPDATE_FORM_ASSESSMENT_CREATE_EXAM =
  "UPDATE_FORM_ASSESSMENT_CREATE_EXAM";
export const UPDATE_FORM_ASSESSMENT_REVIEW = "UPDATE_FORM_ASSESSMENT_REVIEW";
export const UPDATE_EXAM_CUSTOM_LIST = "UPDATE_EXAM_CUSTOM_LIST";
export const UPDATE_FORM_EXAM_SET = "UPDATE_FORM_EXAM_SET";

export const UPDATE_FORM_INDIVIDUAL = "UPDATE_FORM_INDIVIDUAL";
export const UPDATE_FORM_CONDITIONS = "UPDATE_FORM_CONDITIONS";
export const UPDATE_FORM_TESTER = "UPDATE_FORM_TESTER";
export const UPDATE_FORM_CONDITIONS_TESTER = "UPDATE_FORM_CONDITIONS_TESTER";

export const CLEAR_FORM_EXAM_SET = "CLEAR_FORM_EXAM_SET";
export const CLEAR_FORM_CAMPAIGN = "CLEAR_FORM_CAMPAIGN";
export const CLEAR_FORM_INDIVIDUAL_MEMBER = "CLEAR_FORM_INDIVIDUAL_MEMBER";

export const LOGIN = genRequest("LOGIN");

export const UPLOAD_IMAGE = genRequest("UPLOAD_IMAGE");
export const UPLOAD_VIDEO = genRequest("UPLOAD_VIDEO");

export const GET_CAMPAIGN_LIST = genRequest("GET_CAMPAIGN_LIST");
export const GET_EXAM_QUESTION_LIST = genRequest("GET_EXAM_QUESTION_LIST");
export const GET_CAMPAIGN_BY_ID = genRequest("GET_CAMPAIGN_BY_ID");
export const GET_QUESTION_BY_ID = genRequest("GET_QUESTION_BY_ID");
export const GET_CAMPAIGN_SUMMARY_BY_ID = genRequest(
  "GET_CAMPAIGN_SUMMARY_BY_ID"
);

export const GET_EXAM_HISTORY_BY_UID = genRequest("GET_EXAM_HISTORY_BY_UID");
export const GET_EXAM_HISTORY_DETAIL_BY_ID = genRequest(
  "GET_EXAM_HISTORY_DETAIL_BY_ID"
);
export const GET_EXAMSET_BY_ID = genRequest("GET_EXAMSET_BY_ID");
export const GET_EXAMSET_QUESTION_BY_ID = genRequest(
  "GET_EXAMSET_QUESTION_BY_ID"
);
export const GET_CHOICE_DETAIL = genRequest("GET_CHOICE_DETAIL");
export const GET_VERIFY_EMAIL = genRequest("GET_VERIFY_EMAIL");
export const GET_MEMBER_INFO = genRequest("GET_MEMBER_INFO");

export const GET_EXAM_SET_LIST = genRequest("GET_EXAM_SET_LIST");
export const GET_CUSTOM_QUESTION_LIST = genRequest("GET_CUSTOM_QUESTION_LIST");
export const GET_QUESTION_LIST = genRequest("GET_QUESTION_LIST");
export const GET_QUESTION_LIST_RAW = genRequest("GET_QUESTION_LIST_RAW");
export const GET_CAMPAIGN_INVITE_LIST = genRequest("GET_CAMPAIGN_INVITE_LIST");

export const CREATE_CAMPAIGN = genRequest("CREATE_CAMPAIGN");
export const CREATE_EXAM_QUESTION = genRequest("CREATE_EXAM_QUESTION");
export const CREATE_INVITE_EXAMER = genRequest("CREATE_INVITE_EXAMER");
export const CREATE_INVITE_SEND_EMAIL = genRequest("CREATE_INVITE_SEND_EMAIL");
export const CREATE_EXAM_SET = genRequest("CREATE_EXAM_SET");
export const CREATE_RANDOM_EXAM = genRequest("CREATE_RANDOM_EXAM");
export const CREATE_INDIVIDUAL_MEMBER = genRequest("CREATE_INDIVIDUAL_MEMBER");
export const CREATE_RESET_PASSWORD = genRequest("CREATE_RESET_PASSWORD");
export const CREATE_REQUEST_RESET_PASSWORD = genRequest(
  "CREATE_REQUEST_RESET_PASSWORD"
);
export const CREATE_MEMBER_BUSINESS = genRequest("CREATE_MEMBER_BUSINESS");
export const CREATE_NEW_PASSWORD = genRequest("CREATE_NEW_PASSWORD");

export const UPDATE_EXAMSET_BY_ID = genRequest("UPDATE_EXAMSET_BY_ID");
export const UPDATE_CAMPAIGN_EMAIL = genRequest("UPDATE_CAMPAIGN_EMAIL");
export const UPDATE_INVITE_EXAMER = genRequest("UPDATE_INVITE_EXAMER");
export const UPDATE_CAMPAIGN = genRequest("UPDATE_CAMPAIGN");
export const UPDATE_QUESTION_INFO = genRequest("UPDATE_QUESTION_INFO");
export const UPDATE_QUESTION_STATUS = genRequest("UPDATE_QUESTION_STATUS");
export const UPDATE_QUESTION_CHOICE = genRequest("UPDATE_QUESTION_CHOICE");
export const UPDATE_VERIFY_EMAIL_BUSINESS = genRequest(
  "UPDATE_VERIFY_EMAIL_BUSINESS"
);
export const UPDATE_TESTER_MEMBER = genRequest("UPDATE_TESTER_MEMBER");

export const DELETE_INVITE_EXAMER = genRequest("DELETE_INVITE_EXAMER");
export const DELETE_CAMPAIGN = genRequest("DELETE_CAMPAIGN");
export const DELETE_QUESTION = genRequest("DELETE_QUESTION");
export const DELETE_EXAMSET_BY_ID = genRequest("DELETE_EXAMSET_BY_ID");

export const APPROVE_QUESTION = genRequest("APPROVE_QUESTION");
