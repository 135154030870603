import styled from "styled-components";
import imgLogo from "../assets/images/Fuse-On-logo3.png";
import { useHistory } from "react-router-dom";

import Facebook from "../assets/icon/facebook.png";
import Mail from "../assets/icon/mail.png";
import Line from "../assets/icon/line.png";

import Box from "components/surfaces/Box";
import Grid from "components/layout/Grid";
import Stack from "components/layout/Stack";
import { Typography, Link } from "@mui/material";

const Div = styled.footer`
  background-color: #005cc9;
  padding: 20px 5vw;
  height: auto;
`;

const FooterNew = () => {
  const history = useHistory();
  return (
    <Div>
      <Grid
        container
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item xs={12} sm={3}>
          <img
            src={imgLogo}
            className="clickable"
            width={200}
            alt="logo"
            onClick={() => history.push("/")}
          />
        </Grid>
        <Grid item xs={12} sm={4}>
          <Typography variant="body1" color="#fff">
            Copyright © 2022 - Fuse on. All Right Reserved.
          </Typography>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Stack
            spacing={2}
            direction={{ xs: "column", sm: "row" }}
            alignItems={{ xs: "flex-start", sm: "center" }}
            justifyContent="flex-end"
          >
            <Box>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img src={Facebook} width="20" alt="" />

                <Link
                  variant="body1"
                  underline="hover"
                  href="https://www.facebook.com/fuseonai"
                  color="#fff"
                >
                  Fuseon
                </Link>
              </Stack>
            </Box>
            <Box>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img src={Line} alt="" width="20" />

                <Link
                  href="http://line.me/ti/p/@fuseon"
                  underline="hover"
                  variant="body1"
                  color="#fff"
                >
                  @fuseon
                </Link>
              </Stack>
            </Box>
            <Box>
              <Stack
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
              >
                <img src={Mail} alt="" width="20" />

                <Link
                  variant="body1"
                  color="#fff"
                  underline="hover"
                  href="mailto: info@fuse-on.co"
                >
                  info@fuse-on.co
                </Link>
              </Stack>
            </Box>
          </Stack>
        </Grid>
      </Grid>
      {/* <section className="logo-panel">
          <img className="logo" src={imgLogo} alt="" />
        </section>
        <section className="contract-panel">
          <p className="topic">ติดต่อ</p>
          <p className="contract">
            <img src={Facebook} alt="" />
  
            <a href="https://www.facebook.com/fuseonai" target="_blank">
              Fuseon
            </a>
          </p>
          <p className="contract">
            <img src={Line} alt="" />
            <a href="http://line.me/ti/p/@fuseon" target="_blank">
              @fuseon
            </a>
          </p>
          <p className="contract">
            <img src={Mail} alt="" />
  
            <a href="mailto: info@fuse-on.co"> info@fuse-on.co</a>
          </p>
        </section> */}
      {/* <section className="legal-panel">
          <p className="topic">LEGAL</p>
          <p>Terms of Use</p>
          <p>Privacy Policy</p>
        </section> */}
    </Div>
  );
};

export default FooterNew;
