import React from "react";
import ButtonIcon from "../components/form/ButtonIcon";
import Stack from "../components/layout/Stack";
import Box from "../components/surfaces/Box";
import Typography from "@mui/material/Typography";

const BoxIcon = ({
  icon,
  label,
  widthButton = "",
  fontSizeTextVariant = "h6",
  ...props
}) => {
  return (
    <Box
      sx={{
        border: "1px solid #ECEDF2",
        py: 3,
        borderRadius: 4,
      }}
      {...props}
    >
      <Stack alignItems="center" justifyContent="center" spacing={2}>
        <ButtonIcon>
          <img width={widthButton} src={icon} alt="" />
        </ButtonIcon>
        <Typography
          variant={fontSizeTextVariant}
          color="initial"
          align="center"
        >
          {label}
        </Typography>
      </Stack>
    </Box>
  );
};

export default BoxIcon;
