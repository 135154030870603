import React from "react";
// import { styled } from "@mui/material";

import styled from "styled-components";
import {
  Stepper as StepperMui,
  Step,
  StepLabel,
  Typography,
} from "@mui/material";
import Box from "../surfaces/Box";
const StyleStepper = styled(StepperMui)(({ theme }) => ({
  "& .MuiStep-alternativeLabel": {
    // zIndex: 10,
    "& .MuiSvgIcon-root": {
      fontSize: "3em",
      zIndex: 99,
      backgroundColor: "#FFFFFF",
    },

    "& .MuiStepConnector-root": {
      top: `calc(10% + ${theme.typography.fontSize}px)`,
      left: `calc(-47% + ${theme.typography.fontSize}px)`,
      right: `calc(53% + ${theme.typography.fontSize}px)`,

      ".MuiStepConnector-line": {
        borderTopWidth: "5px",
      },
    },

    "& .Mui-completed , .Mui-active": {
      ".MuiStepConnector-line": {
        borderColor: theme.palette.primary.main,
        borderTopWidth: "5px",
      },
    },
    "& .MuiStepLabel-label": {
      fontSize: "1.2em",
    },
    "& .MuiStepLabel-label.Mui-completed , .Mui-active": {
      color: theme.palette.primary.main,
    },
  },
}));

const Stepper = ({ activeStep = 0, listStep, ...props }) => {
  return (
    <Box sx={{ width: "100%" }}>
      <StyleStepper activeStep={activeStep} alternativeLabel {...props}>
        {listStep?.length > 0 &&
          listStep?.map((item, index) => (
            <Step key={item}>
              <StepLabel
                sx={(theme) => ({
                  "&:active": {
                    borderColor: theme.palette.primary.main,
                  },
                })}
              >
                <Typography
                  sx={{
                    display: { xs: "none", sm: "block" },
                  }}
                  variant="body1"
                  color="initial"
                  align="center"
                >
                  {item}
                </Typography>
              </StepLabel>
            </Step>
          ))}
      </StyleStepper>
    </Box>
  );
};

export default Stepper;
