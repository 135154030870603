import React from "react";
import styled from "styled-components";
import { Checkbox as CheckboxMui } from "@mui/material";

const Div = styled.div`
  display: inline;
  position: relative;
  min-width: 0;
  flex-direction: column;
  vertical-align: top;
`;

const StyledCheckbox = styled(CheckboxMui)`
  padding: 11px;

  .MuiButton-label {
    font-size: var(--overline);
    font-weight: normal;
    text-transform: none;
  }
`;

const Checkbox = ({
  label,
  id,
  name,
  color = "primary",
  className,
  ...props
}) => {
  return (
    <Div>
      <StyledCheckbox
        id={id}
        name={name}
        className={className}
        color={color}
        {...props}
      />
      {Boolean(label) && <label htmlFor={id}>{label}</label>}
    </Div>
  );
};

export default Checkbox;
