import React from "react";
import styled from "styled-components";
import { Switch as SwitchMui } from "@mui/material";

const StyleSwitch = styled(SwitchMui)``;

const Toggle = ({ classname, color = "primary", ...props }) => {
  return <StyleSwitch className={classname} color={color} {...props} />;
};

export default Toggle;
