import React from "react";

import { AccessTime as Clock, RemoveRedEye as Eye } from "@mui/icons-material";

import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Chip,
  Link,
} from "@mui/material";

import Box from "components/surfaces/Box";

import Grid from "components/layout/Grid";
import Stack from "components/layout/Stack";

import ButtonIcon from "../components/form/ButtonIcon";
import Button from "../components/form/Button";
import DeleteIcon from "@mui/icons-material/Delete";

const TestCard = ({
  onInsert = {},
  onDetail,
  data,
  headerLink = "",
  isDisabled,
  textBtn = "Add",
  onDelete = false,
  isDisabledDelete = false,
}) => {
  return (
    <Card
      sx={{
        width: "100%",
        minHeight: { xs: 150, sm: 300 },
        maxWidth: { xs: "100%", sm: 265 },
        display: "flex",
        flexDirection: "column",
        p: 1,
      }}
    >
      <CardHeader
        sx={{ p: 1, display: { xs: "none", sm: "flex" } }}
        action={
          <Grid container alignItems="center">
            <ButtonIcon size="small" color="grey">
              <Clock fontSize="small" />
            </ButtonIcon>
            <small>{data?.exam_time} Mins</small>
            {onDelete && !isDisabledDelete ? (
              <ButtonIcon
                color="grey"
                disabled={isDisabledDelete}
                onClick={onDelete}
              >
                <DeleteIcon />
              </ButtonIcon>
            ) : (
              ""
            )}
          </Grid>
        }
      />
      <CardContent sx={{ p: 1 }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          spacing={2}
        >
          {headerLink ? (
            <Link
              href={headerLink}
              underline="hover"
              variant="subtitle1"
              color="primary"
            >
              {data?.name}
            </Link>
          ) : (
            <Typography variant="subtitle1" color="primary">
              {data?.name}
            </Typography>
          )}

          <Box sx={{ display: { xs: "flex", sm: "none" } }}>
            <Stack
              direction="row"
              alignItems="center"
              spacing={0}
              justifyContent="flex-end"
            >
              <ButtonIcon color="grey">
                <Eye />
              </ButtonIcon>
              <ButtonIcon color="grey">
                <Clock />
              </ButtonIcon>
              {data?.name} Mins
            </Stack>
          </Box>
        </Stack>

        <Typography
          variant="body1"
          sx={{ fontWeight: 600 }}
          color="initial"
          paragraph
        >
          วิชา {data?.subject_name}, จำนวนข้อ {data?.amount_question} ข้อ
        </Typography>

        <Chip
          label={`${data?.exam_mode
            .charAt(0)
            .toUpperCase()}${data?.exam_mode.slice(1)}`}
          sx={{ borderRadius: "4px" }}
        />
        <Stack sx={{ marginTop: "40px" }}>
          <small>{data?.description}</small>
        </Stack>
      </CardContent>

      <CardActions sx={{ p: 1, mt: "auto" }} disableSpacing>
        <Grid
          container
          justifyContent="space-between"
          spacing={2}
          alignItems="center"
        >
          <Grid item xs sm={6}>
            <Button outlined block onClick={() => onDetail()}>
              <Typography variant="caption" color="inherit">
                Details
              </Typography>
            </Button>
          </Grid>

          <Grid item xs sm={6}>
            <Button block onClick={onInsert} disabled={isDisabled}>
              <Typography variant="caption" color="inherit">
                {/* {data?.amount_question} Credit */}
                {textBtn}
              </Typography>
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );
};

export default TestCard;
