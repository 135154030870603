import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import { Typography } from "@mui/material";
import { Close } from "@mui/icons-material";

import Button from "../../components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import TextField from "../../components/form/TextField";
import TextEditor from "../../components/form/TextEditor";
import ButtonUpload from "../../components/form/ButtonUpload";
import Checkbox from "../../components/form/Checkbox";
import Radio from "../../components/form/Radio";
import Select from "../../components/form/Select";
import ButtonUploadImage from "../../components/form/ButtonUploadImage";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Box from "../../components/surfaces/Box";

import BannerEmail from "../../assets/images/banner-email.png";
import { saveFormAssessmentCreateExam } from "../../redux/actions/assessments";
import { uploadImage } from "../../redux/actions/share";

import { INITIAL_CHOICE_LIST } from "utils/dataConfig";

const Div = styled.div`
  padding: 2vw 7vw;
`;

const CustomQuestion = () => {
  const { type: questionType, id: editId } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { formAssessmentCreateExam } = useSelector(
    (state) => state.assessments
  );

  const [selectedAnswerChoice, setSelectedAnswerChoice] = useState("");

  const [form, setForm] = useState({
    choice: questionType === "multiple-choice" ? INITIAL_CHOICE_LIST : [],
    question_type: questionType,
    question_time: "",
    video_url: "",
    image_url: "",
    question_title: "",
    question_description: "",
    is_shuffle_answer: false,
    is_exist: false,
  });
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState({});

  const [editorData, setEditorData] = useState("");

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  useEffect(() => {
    console.log("id", editId);
    if (editId) {
      console.log("formAssessmentCreateExam", formAssessmentCreateExam);

      let formEdit = formAssessmentCreateExam.find((it) => it.id === +editId);
      console.log("formEdit", formEdit);
      setForm(formEdit);
    }
  }, [editId, formAssessmentCreateExam]);

  useEffect(() => {
    console.log("selectedAnswerChoice", selectedAnswerChoice);
  }, [selectedAnswerChoice]);

  const onChangeForm = (e) => {
    const { name, value } = e.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateForm({ [name]: value });
  };

  const onChangeDescription = (value) => {
    setForm((prev) => ({
      ...prev,
      question_description: value,
    }));
  };

  const onChangeCheckbox = (e) => {
    const { checked } = e.target;

    setForm((prev) => ({
      ...prev,
      is_shuffle_answer: checked,
    }));
  };

  const handleChangeAnswerChoice = (event, index) => {
    const { value, checked } = event.target;
    setSelectedAnswerChoice(value);

    let newArrChoice = form?.choice?.map((item) => ({
      ...item,
      is_answer: false,
    }));

    newArrChoice[index] = { ...newArrChoice[index], is_answer: checked };

    setForm((prev) => ({
      ...prev,
      choice: newArrChoice,
    }));
  };

  const onChangeChoiceDescription = (e, index) => {
    const { value } = e.target;

    let newArrChoice = [...form?.choice];

    newArrChoice[index] = { ...newArrChoice[index], choice_description: value };

    setForm((prev) => ({
      ...prev,
      choice: newArrChoice,
    }));
  };

  const handleAddAnswer = () => {
    // TODO: Handle;
    let newArrChoice = [...form?.choice];
    newArrChoice.push({
      choice_no: newArrChoice.length + 1,
      choice_description: "",
      choice_type: "answer",
      is_answer: false,
      answer_point: 1,
      image_url: "",
      vdo_url: "",
    });

    setForm((prev) => ({
      ...prev,
      choice: newArrChoice,
    }));
  };

  const handleUploadImage = (event) => {
    const { files } = event.target;
    let file = files[0];
    dispatch(uploadImage(file)).then(({ path }) => {
      if (file?.type?.includes("video")) {
        setForm((prev) => ({
          ...prev,
          video_url: path,
        }));
      } else if (file?.type?.includes("image")) {
        setForm((prev) => ({
          ...prev,
          image_url: path,
        }));
      }
    });
  };

  const handleUploadImageForChoice = (event, index) => {
    const { files } = event.target;
    let file = files[0];

    let newArrChoice = [...form?.choice];

    dispatch(uploadImage(file)).then(({ path }) => {
      if (file?.type?.includes("video")) {
        newArrChoice[index] = { ...newArrChoice[index], vdo_url: path };
      } else if (file?.type?.includes("image")) {
        newArrChoice[index] = { ...newArrChoice[index], image_url: path };
      }

      setForm((prev) => ({
        ...prev,
        choice: newArrChoice,
      }));
    });
  };

  const validateForm = (
    fieldValues = {
      ...form,
      question_type: "not-required",
      video_url: "not-required",
      image_url: "not-required",
      question_description: "not-required",
      choice: "not-required",
      is_shuffle_answer: "not-required",
      is_exist: "not-required",
    }
  ) => {
    let temp = { ...errors };
    if ("question_title" in fieldValues)
      temp.question_title =
        fieldValues.question_title !== "" ? "" : "กรุณากรอกชื่อ";
    if ("question_time" in fieldValues)
      temp.question_time = fieldValues.question_time !== "" ? "" : "กรุณาเลือก";

    setErrors({ ...temp });

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const handleSubmit = (event) => {
    // TODO: Handle;
    event.preventDefault();
    const isValid = validateForm();

    let submit = false;
    if (!isSubmit) {
      submit = true;
      setIsSubmit(true);
    } else {
      submit = true;
    }

    if (submit && isValid) {
      if (editId) {
        let newList = [...formAssessmentCreateExam];
        console.log("newList", newList);
        let index = newList.findIndex((it) => it.id === +editId);
        console.log("index", index);
        newList[index] = form;
        console.log("newList after", newList);
        dispatch(saveFormAssessmentCreateExam(newList));
      } else {
        dispatch(
          saveFormAssessmentCreateExam([...formAssessmentCreateExam, form])
        );
      }
      history.goBack();
    }
  };

  const mapTitleTypeQuestion = () => {
    switch (questionType) {
      case "multiple-choice":
        return "Multiple Choice";

      case "essay":
        return "Essay ";

      default:
        return "";
    }
  };
  return (
    <Div>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid
            item
            xs
            sm
            container
            sx={{ display: { xs: "flex", sm: "none" } }}
          >
            <Grid xs={2} sm item>
              <Button outlined block onClick={() => history.goBack()}>
                Back
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{ display: { xs: "none", sm: "flex" } }}
            item
            container
            justifyContent="space-between"
            xs={12}
            sm={12}
          >
            <Stack>
              <Typography
                sx={{ position: "relative" }}
                variant="h2"
                color="initial"
                gutterBottom
              >
                <Stack direction="row" spacing={4} alignItems="flex-end">
                  Create custom question&ensp;
                  <Typography variant="h6" color="initial" components="span">
                    ({mapTitleTypeQuestion()})
                  </Typography>
                  <ButtonIcon
                    aria-label="close"
                    onClick={() => history.goBack()}
                    sx={{
                      position: "absolute",
                      right: 8,
                      top: 8,
                      color: (theme) => theme.palette.grey[500],
                    }}
                  >
                    <Close />
                  </ButtonIcon>
                </Stack>
              </Typography>

              <Stack direction="row" justifyContent="space-between">
                <Typography variant="h4" color="initial">
                  Question
                </Typography>
                <Button sx={{ width: "200px" }} type="submit">
                  Save
                </Button>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            sx={{ display: { xs: "flex", sm: "none" } }}
            item
            container
            xs={12}
            sm={12}
          >
            <Stack spacing={2}>
              <Typography variant="h5" color="initial" align="center">
                Create custom question
                <Typography
                  variant="h6"
                  color="initial"
                  components="span"
                ></Typography>
              </Typography>
              <Typography variant="h6" color="#606266" align="center">
                ({mapTitleTypeQuestion()})
              </Typography>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={7}>
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Typography variant="h5" color="initial" gutterBottom>
                  Title question
                </Typography>

                <Stack spacing={2} direction={{ xs: "column", sm: "row" }}>
                  <Box sx={{ flexGrow: 3 }}>
                    <TextField
                      placeholder="Title test"
                      name="question_title"
                      error={errors?.question_title}
                      onChange={onChangeForm}
                      value={form?.question_title}
                    />
                  </Box>
                  <Box sx={{ flexGrow: 1 }}>
                    <ButtonUpload
                      id="upload-image-or-video"
                      block
                      outlined
                      onChange={(e) => handleUploadImage(e)}
                    />
                  </Box>
                </Stack>
              </Stack>
            </Stack>
          </Grid>

          <Grid item xs={12} sm={5}>
            <Stack spacing={4}>
              <Stack spacing={2}>
                <Typography variant="h5" color="initial" gutterBottom>
                  Select Time to answer
                </Typography>
                {/* <Select
                  defaultValue="เวลาในการทำข้อสอบ"
                  name="question_time"
                  onChange={onChangeForm}
                  error={errors?.question_time}
                  value={form?.question_time}
                  items={[
                    { value: 10, text: "10 นาที" },
                    { value: 15, text: "15 นาที" },
                    { value: 30, text: "30 นาที" },
                    { value: 60, text: "60 นาที" },
                  ]}
                /> */}
                <TextField
                  type="number"
                  placeholder={"เวลาในการทำข้อสอบ"}
                  name="question_time"
                  error={errors?.question_time}
                  value={form?.question_time}
                  onChange={onChangeForm}
                />
              </Stack>

              {questionType === "multiple-choice" && (
                <Stack spacing={2}>
                  <Stack
                    spacing={2}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography variant="h5" color="initial" gutterBottom>
                      Select right answer
                    </Typography>

                    <Checkbox
                      id="shuffle"
                      checked={form?.is_shuffle_answer}
                      onChange={onChangeCheckbox}
                      label={
                        <Typography variant="caption" color="#616161">
                          Shuffle Answer
                        </Typography>
                      }
                    />
                  </Stack>

                  <Stack spacing={4}>
                    {form?.choice?.map((item, index) => (
                      <Stack
                        direction="row"
                        alignItems="center"
                        spacing={2}
                        key={index}
                      >
                        <Radio
                          checked={
                            selectedAnswerChoice === (index + 1).toString()
                          }
                          value={index + 1}
                          onChange={(e) => handleChangeAnswerChoice(e, index)}
                        />
                        <TextField
                          size="medium"
                          placeholder="Name Assessment"
                          onChange={(e) => onChangeChoiceDescription(e, index)}
                          value={item?.choice_description}
                        />
                        <ButtonUploadImage
                          id={`answer-choice:${index + 1}`}
                          onChange={(e) => handleUploadImageForChoice(e, index)}
                        />
                      </Stack>
                    ))}
                  </Stack>

                  <Button block outlined onClick={handleAddAnswer}>
                    Add answer to choice
                  </Button>
                </Stack>
              )}
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Div>
  );
};

export default CustomQuestion;
