import {
  GET_MASTER_DATA_JOB,
  GET_MASTER_DATA_JOB_LEVEL,
  GET_MASTER_DATA_SUBJECT,
  GET_MASTER_DATA_PROVINCE,
  GET_MASTER_DATA_DISTRICT,
  GET_MASTER_DATA_SUB_DISTRICT,
} from "redux/actionTypes";

const INITIAL_LEVEL = [
  { value: 1, text: "Level 1" },
  { value: 2, text: "Level 2" },
  { value: 3, text: "Level 3" },
  { value: 4, text: "Level 4" },
  { value: 5, text: "Level 5" },
  { value: 6, text: "Level 6" },
  { value: 7, text: "Level 7" },
  { value: 8, text: "Level 8" },
  { value: 9, text: "Level 9" },
  { value: 10, text: "Level 10" },
  { value: 11, text: "Level 11" },
];

const INITIAL_CHOICE = [
  { value: 1, text: "1" },
  { value: 2, text: "2" },
  { value: 3, text: "3" },
  { value: 4, text: "4" },
  { value: 5, text: "5" },
  { value: 6, text: "6" },
  { value: 7, text: "7" },
  { value: 8, text: "8" },
  { value: 9, text: "9" },
  { value: 10, text: "10" },
];

const INITIAL_STATUS = [
  { value: "active", text: "Active" },
  { value: "pending", text: "Pending" },
  { value: "disabled", text: "Disabled" },
];

const INITIAL_ORGANIZATION = [
  { value: "บริษัท จำกัด", text: "บริษัท จำกัด" },
  { value: "บริษัท มหาชน จำกัด", text: "บริษัท มหาชน จำกัด" },
  { value: "ห้างหุ้นส่วนจำกัด", text: "ห้างหุ้นส่วนจำกัด" },
  { value: "สมาคม องค์กร", text: "สมาคม องค์กร" },
  { value: "อื่นๆ", text: "อื่นๆ" },
];

const initialState = {
  masterDataJob: [],
  masterDataJobLevel: [],
  masterDataSubject: [],
  masterDataLevel: INITIAL_LEVEL,
  masterDataNumberOfChoice: INITIAL_CHOICE,
  masterDataStatus: INITIAL_STATUS,
  masterDataProvince: [],
  masterDataDistrict: [],
  masterDataSubDistrict: [],
  masterDataOrganization: INITIAL_ORGANIZATION,
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_MASTER_DATA_JOB:
      return {
        ...state,
        masterDataJob: action.payload,
      };
    case GET_MASTER_DATA_JOB_LEVEL:
      return {
        ...state,
        masterDataJobLevel: action.payload,
      };
    case GET_MASTER_DATA_SUBJECT:
      return {
        ...state,
        masterDataSubject: action.payload,
      };

    case GET_MASTER_DATA_PROVINCE: {
      return {
        ...state,
        masterDataProvince: action.payload,
      };
    }
    case GET_MASTER_DATA_DISTRICT: {
      return {
        ...state,
        masterDataDistrict: action.payload,
      };
    }
    case GET_MASTER_DATA_SUB_DISTRICT: {
      return {
        ...state,
        masterDataSubDistrict: action.payload,
      };
    }
    default:
      return state;
  }
};
