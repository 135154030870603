import styled from "styled-components";
import imgLogo from "../assets/images/logo-footer.png";

import Facebook from "../assets/icon/facebook.png";
import Mail from "../assets/icon/mail.png";
import Line from "../assets/icon/line.png";

const Div = styled.footer`
  display: flex;
  background-color: #005cc9;
  border-radius: 30px 30px 0px 0px;
  padding: 6vw 7vw;
  height: auto;
  min-height: 350px;
  min-width: var(--width-screen);
  margin: 0 auto;
  justify-content: center;
  align-items: stretch;
  color: var(--text-white);
  column-gap: 1vw;

  @media screen and (max-width: 768px) {
    display: grid;
    width: 100%;
    justify-content: space-between;
    grid-template-areas:
      "top top"
      "left right";

    padding: 6vw 12vw;
  }

  @media screen and (max-width: 415px) {
    grid-template-areas:
      "top "
      "left" " right";
    /* min-height: 500px; */
    padding: 6vw 7vw;
  }

  section {
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 20px;

    &.logo-panel {
      flex: 1;
      @media screen and (max-width: 768px) {
        grid-area: top;
        height: 10px;
      }
      @media screen and (max-width: 415px) {
        grid-area: top;
        height: 0px;
      }
    }

    &.contract-panel {
      flex: 2;
      @media screen and (max-width: 768px) {
        grid-area: left;
      }
    }
    &.legal-panel {
      @media screen and (max-width: 768px) {
        grid-area: right;
      }
    }
  }

  .topic {
    margin-top: 20px;
    font-weight: bold;
    @media screen and (max-width: 768px) {
      margin-top: 0;
    }
    @media screen and (max-width: 415px) {
      margin-top: 20px;
    }
  }
  .contract {
    display: flex;
    font-weight: bold;
    margin-top: 10px;
    img {
      margin-right: 20px;
    }
    > a {
      color: inherit;
      text-decoration: none;
      :hover {
        text-decoration: underline;
      }
    }
  }
  .logo {
    width: 225px;

    @media screen and (max-width: 768px) {
      width: 20vw;
    }

    @media screen and (max-width: 415px) {
      width: 40vw;
    }
  }
`;

const Footer = () => {
  return (
    <Div>
      <section className="logo-panel">
        <img className="logo" src={imgLogo} alt="" />
      </section>
      <section className="contract-panel">
        <p className="topic">ติดต่อ</p>
        <p className="contract">
          <img src={Facebook} alt="" />

          <a href="https://www.facebook.com/fuseonai" target="_blank">
            Fuseon
          </a>
        </p>
        <p className="contract">
          <img src={Line} alt="" />
          <a href="http://line.me/ti/p/@fuseon" target="_blank">
            @fuseon
          </a>
        </p>
        <p className="contract">
          <img src={Mail} alt="" />
         
          <a href="mailto: info@fuse-on.co"> info@fuse-on.co</a> 
        </p>
      </section>
      {/* <section className="legal-panel">
        <p className="topic">LEGAL</p>
        <p>Terms of Use</p>
        <p>Privacy Policy</p>
      </section> */}
    </Div>
  );
};

export default Footer;
