import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import moment from "moment";
import { ExportToCsv } from "export-to-csv";

import { Typography } from "@mui/material";

import Button from "../../components/form/Button";
import TextField from "../../components/form/TextField";
import Select from "../../components/form/Select";
// import TextFieldArea from "../../components/form/TextFieldArea";

import Stack from "components/layout/Stack";
import Grid from "../../components/layout/Grid";

import Box from "components/surfaces/Box";

// import Pagination from "../../components/navigation/Pagination";

import Table from "../../components/table/Table";
import BarChart from "../../components/chart/BarChart";

import SubContentDialog from "../../components/dialog/SubContentDialog";
import { getCampaignSummaryById } from "redux/actions/assessments";

import { Search } from "@mui/icons-material";

const options = {
  fieldSeparator: ",",
  quoteStrings: '"',
  decimalSeparator: ".",
  showLabels: true,
  showTitle: false,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
  filename: `report${new Date().getTime()}-${Math.floor(
    Math.random() * 100000000 + 1
  )}`,
};

const csvExporter = new ExportToCsv(options);

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const MOCK = [
  {
    campaign_set_id: 28,
    order_no: 1,
    code: "FU18072022-00000014",
    name: "ลองสร้าง Examset7",
    amount_question: 10,
    exam_time: 178,
    description: "test",
    subject_name: "math",
    topic_name: "topic edit",
  },
  {
    campaign_set_id: 29,
    order_no: 2,
    code: "FU15072022-00000010",
    name: "ลองสร้าง Examset6",
    amount_question: 10,
    exam_time: 120,
    description: "testtest",
    subject_name: "math",
    topic_name: "topic edit",
  },
];

const initialFilter = {
  user_id: "",
  first_name: "",
  last_name: "",
  email: "",
  phone_number: "",
  exam_doing_time: "",
};

const Report = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { campaignSummary } = useSelector((state) => state.assessments);

  const [isShowFilter, setIsShowFilter] = useState(false);

  const [userExamListDisplay, setUserExamListDisplay] = useState([]);
  const [filter, setFilter] = useState(initialFilter);

  useEffect(() => {
    dispatch(getCampaignSummaryById(id));
  }, []);

  useEffect(() => {
    console.log({ filter });
  }, [filter]);

  useEffect(() => {
    setUserExamListDisplay(campaignSummary?.examUserResult);
  }, [campaignSummary?.examUserResult]);

  const getSubjectColumns = (subject = []) => {
    let keys = Object.keys(subject);

    let newArr = keys.map((it, index) => ({
      field: `subject.${it}`,
      headerName: it,
      value: subject[it],
    }));

    return newArr;
  };

  const columns = [
    {
      field: "email",
      headerName: "Email ",
      minWidth: 200,
    },
    {
      field: "first_name",
      headerName: "ชื่อจริง ",
      minWidth: 150,
    },

    {
      field: "last_name",
      minWidth: 150,
      headerName: "นามสกุล ",
    },
    {
      field: "phone_number",
      minWidth: 150,
      headerName: "เบอร์โทร ",
    },
    {
      field: "start_time",
      headerName: "Start time ",
      minWidth: 200,
    },
    {
      field: "end_time",
      minWidth: 200,
      headerName: "Leave time ",
    },
    {
      field: "submitted",
      headerName: "Submitted ",
      minWidth: 100,
    },
    {
      field: "time_in",
      headerName: "Time In Session ",
      sortable: true,
    },
    // {
    //   field: "math",
    //   headerName: "Math Score ",

    //   sortable: true,
    // },
    // {
    //   field: "english",
    //   headerName: "Eng Score ",

    //   sortable: true,
    // },
    // {
    //   field: "logic",
    //   headerName: "Logic Score ",

    //   sortable: true,
    // },
    {
      field: "subject",
      headerName: "Subject",
      renderCell: ({ value }) => {
        const arr = getSubjectColumns(value);
        return (
          <div style={{ width: "100%" }}>
            <table>
              <thead>
                {arr.map((it) => (
                  <th align="center">{it.headerName}</th>
                ))}
              </thead>
              <tbody>
                {arr.map((it) => (
                  <td align="center">{it.value}</td>
                ))}
              </tbody>
            </table>
          </div>
        );
      },
    },
    {
      field: "total_score",
      headerName: "Total Score ",

      sortable: true,
    },
    {
      field: "percent",

      headerName: "%Score ",
    },
    {
      field: "seeFullReport",
      headerName: "",
      renderCell: ({ row }) => (
        <Button
          block
          outlined
          onClick={() =>
            window.open(
              `https://uatreport.fuse-on.co/report/${row?.campaign_uid}/${row?.user_id}/1`,
              "_blank"
            )
          }
        >
          ดูผลคะแนน
        </Button>
      ),
      minWidth: 100,
    },
  ];

  const columnsExamset = [
    {
      field: "name",
      headerName: "Name",
    },
    {
      field: "exam_time",
      headerName: "Duration",
      minWidth: 200,
    },
    {
      field: "amount_question",
      headerName: "No. of questions",
      minWidth: 200,
    },
  ];

  const handleOpenFilter = () => {
    setIsShowFilter(true);
  };
  const handleCloseFilter = () => {
    setIsShowFilter(false);
  };

  const onChangeFilter = (event) => {
    const { value, name } = event.target;

    setFilter((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const clearFilter = () => {
    setFilter(initialFilter);
    setUserExamListDisplay(campaignSummary?.examUserResult);
    handleCloseFilter();
  };

  const search = () => {
    let rawList = campaignSummary?.examUserResult;
    let newArr = [...rawList].filter((it) => {
      if (JSON.stringify(filter) === JSON.stringify(initialFilter)) return true;

      let result =
        checkFilterUser("user_id", it) ||
        checkFilterUser("first_name", it) ||
        checkFilterUser("last_name", it) ||
        checkFilterUser("email", it) ||
        checkFilterUser("phone_number", it) ||
        calculateTimeExam(it?.start_time, it?.end_time);

      console.log(
        checkFilterUser("user_id", it),
        checkFilterUser("first_name", it),
        checkFilterUser("last_name", it),
        checkFilterUser("email", it),
        checkFilterUser("phone_number", it),
        calculateTimeExam(it?.start_time, it?.end_time)
      );
      return result;
    });

    console.log("search", { newArr });
    setUserExamListDisplay(newArr);
    handleCloseFilter();
  };

  const checkFilterUser = (name, value) => {
    let result =
      filter[name] === ""
        ? false
        : value[name]?.toLowerCase()?.includes(filter[name]);
    return result;
  };

  const calculateTimeExam = (start, end) => {
    let startTime = moment(new Date(start));
    let endTime = moment(new Date(end));
    let searchTime = moment(new Date(filter?.exam_doing_time));

    console.log({ startTime, endTime, searchTime });

    const isConditionTime = searchTime.isBetween(startTime, endTime);

    return isConditionTime;
  };

  const onClickRow = (list) => {
    history.push(`/report/${campaignSummary.slug}/detail/${list?.[0]}`);
  };

  const mappingValueStatus = (status) => {
    switch (status) {
      case "active":
        return calculateTimingExam();

      default:
        return "Canceled";
    }
  };

  const calculateTimingExam = () => {
    let now = moment(new Date());
    let start = moment(new Date(campaignSummary?.campaign_start_date));
    let end = moment(new Date(campaignSummary?.campaign_end_date));

    const between = now.isBetween(start, end);
    const before = now.isBefore(start);

    return between ? "Ongoing" : before ? "Incoming" : "Completed";
  };

  const exportReportCSV = () => {
    let mapDataToCSV = userExamListDisplay?.map((it) => ({
      first_name: it?.first_name,
      last_name: it?.last_name,
      phone_number: it?.phone_number,
      email: it?.email,
      start_time: it?.start_time,
      end_time: it?.end_time,
      time_in: it?.time_in,
      status: it?.status,
      subject: Object.keys(it?.subject)
        .map((key) => `${key}:${it?.subject[key]}`)
        .join(","),
      total_score: it?.total_score,
      percent: it?.percent,
      customQuationAnswer: it?.customQuationAnswer,
    }));
    csvExporter.generateCsv(mapDataToCSV || []);
  };

  return (
    <Div>
      <SubContentDialog
        open={isShowFilter}
        title={"Filter Search"}
        onClose={handleCloseFilter}
        maxWidth="md"
      >
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="รหัสผู้เข้าสอบ"
              name="user_id"
              type="search"
              value={filter?.user_id}
              onChange={(e) => onChangeFilter(e)}
              innerIconFieldStart={<Search />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="ชื่อ"
              name="first_name"
              type="search"
              value={filter?.first_name}
              onChange={(e) => onChangeFilter(e)}
              innerIconFieldStart={<Search />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="นามสกุล"
              name="last_name"
              type="search"
              value={filter?.last_name}
              onChange={(e) => onChangeFilter(e)}
              innerIconFieldStart={<Search />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="Email"
              type="search"
              name="email"
              value={filter?.email}
              onChange={(e) => onChangeFilter(e)}
              innerIconFieldStart={<Search />}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              placeholder="เบอร์โทร"
              type="search"
              name="phone_number"
              value={filter?.phone_number}
              onChange={(e) => onChangeFilter(e)}
              innerIconFieldStart={<Search />}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4}>
            <Select
              defaultValue="ประเภทผู้เข้าสอบ"
              items={[]}
              value={""}
              onChange={(e) => console.log(e.target.value)}
            />
          </Grid> */}
          <Grid item xs={12} sm={4}>
            <TextField
              name="exam_doing_time"
              type="datetime-local"
              placeholder={"วันเวลาที่เริ่มสอบ "}
              onChange={(e) => onChangeFilter(e)}
              // required
              value={filter?.exam_doing_time}
            />
          </Grid>

          <Grid item container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={2}></Grid>
            <Grid item xs={12} sm={4}>
              <Button block outlined onClick={() => clearFilter()}>
                Clear Filter
              </Button>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Button block onClick={() => search()}>
                Search
              </Button>
            </Grid>
            <Grid item xs={2} sm={2}></Grid>
          </Grid>
        </Grid>
      </SubContentDialog>
      <Grid container direction="column" spacing={4}>
        <Grid item xs container>
          <Grid xs={2} sm={2} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
        </Grid>

        <Grid xs={12} sm item>
          <Typography variant="h4" color="initial">
            {campaignSummary?.campaign_name || "-"}
          </Typography>
        </Grid>

        <Grid xs={12} sm item container spacing={1}>
          <Grid item xs={12} sm={6}>
            <Stack spacing={2}>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  บริษัท :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.organization_name || "-"}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  ตำแหน่ง :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.name_th}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  ประเภท :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.campaign_type}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Campaign Start :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.campaign_start_date}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Campaign Finish :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.campaign_end_date}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Status :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {mappingValueStatus(campaignSummary?.status)}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Job Level :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.job_level_name}
                </Typography>
              </Stack>
              <Stack direction="row" alignItems="center">
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Link :
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  {campaignSummary?.vdo_url || campaignSummary?.vdo_embed ? (
                    <a
                      href={
                        campaignSummary?.vdo_url || campaignSummary?.vdo_embed
                      }
                      target="_blank"
                    >
                      {campaignSummary?.vdo_url || campaignSummary?.vdo_embed}
                    </a>
                  ) : (
                    "-"
                  )}
                </Typography>
              </Stack>
            </Stack>
          </Grid>
          <Grid
            sx={{
              backgroundColor: "#F5F7FC",
              marginTop: { xs: 2, sm: 0 },
            }}
            item
            xs={12}
            sm={6}
            container
            alignItems="center"
            justifyContent="center"
          >
            <Grid item xs={12} sm={12}>
              <Stack spacing={1} alignItems="center" justifyContent="center">
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Box sx={{ flex: { xs: 0, sm: 1 } }}></Box>
                  <Typography sx={{ flex: 2 }} variant="body1" color="initial">
                    จำนวนผู้เข้าสอบทั้งหมด :
                  </Typography>
                  <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                    {campaignSummary?.examerTotal?.total} คน
                  </Typography>
                  <Box sx={{ flex: { xs: 0, sm: 1 } }}></Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ flex: { xs: 0, sm: 1 } }}></Box>
                  <Typography sx={{ flex: 2 }} variant="body1" color="initial">
                    จำนวนคนที่ทำข้อสอบแล้ว :
                  </Typography>
                  <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                    {campaignSummary?.examerTotal?.examer} คน
                  </Typography>
                  <Box sx={{ flex: { xs: 0, sm: 1 } }}></Box>
                </Stack>
                <Stack direction="row" alignItems="center">
                  <Box sx={{ flex: { xs: 0, sm: 1 } }}></Box>
                  <Typography sx={{ flex: 2 }} variant="body1" color="initial">
                    (%) ทำข้อสอบแล้ว :
                  </Typography>
                  <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                    {campaignSummary?.examerTotal?.percent || 0}%
                  </Typography>
                  <Box sx={{ flex: { xs: 0, sm: 1 } }}></Box>
                </Stack>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        <Grid xs={12} sm item container spacing={1}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" color="initial">
              ข้อมูลเกี่ยวกับข้อสอบ
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Box
              sx={{
                height: "100%",
                minHeight: 280,
                // maxHeight: 300,
                width: "100%",
              }}
            >
              <Table
                getRowId={(row) => row?.campaign_set_id}
                columns={columnsExamset}
                // rows={MOCK}
                rows={campaignSummary?.exam_set}
                checkboxSelection={false}
                // onSelectionChange={(e) => onClickRow(e)}
                onEditRow={false}
                onDeleteRow={false}
                // autoHeight
                hideFooter
              />
            </Box>
          </Grid>
        </Grid>

        <Grid xs={12} sm item container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Typography variant="h5" color="initial">
              Histrogram Distribution
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <BarChart dataResult={userExamListDisplay} />
            {/* <img src={Chart} alt="chart mock" width="100%" /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* <TextFieldArea placeholder="ข้อความ" /> */}
            <Stack spacing={2}>
              <Typography variant="subtitle1" color="initial">
                หัวข้อทดสอบ
              </Typography>
              {campaignSummary?.exam_set?.map((item, index) => (
                <Typography
                  key={index}
                  variant="body1"
                  paragraph
                  color="initial"
                >
                  <Typography variant="subtitle1" color="initial">
                    {item?.subject_name}
                  </Typography>
                  {item?.topic_name}
                </Typography>
              ))}
            </Stack>
          </Grid>
        </Grid>

        <Grid xs={12} sm item container spacing={2}>
          <Grid item xs={12} sm={12}>
            <Stack
              direction={{ xs: "column", sm: "row" }}
              alignItems="center"
              justifyContent="space-between"
              spacing={2}
            >
              <Typography variant="h5" color="initial">
                Test Result
              </Typography>

              <Box>
                <Stack
                  direction="row"
                  alignItems="center"
                  spacing={{ xs: 2, sm: 5 }}
                >
                  <Button
                    outlined
                    disabled={campaignSummary?.examUserResult?.length === 0}
                    onClick={exportReportCSV}
                  >
                    Download CSV{" "}
                  </Button>
                  <Button
                    onClick={() => handleOpenFilter()}
                    disabled={campaignSummary?.examUserResult?.length === 0}
                  >
                    Search{" "}
                  </Button>
                  <Button
                    onClick={() =>
                      history.push(`/assessments/${campaignSummary?.id}`)
                    }
                  >
                    Invite
                  </Button>
                </Stack>
              </Box>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={12} spacing={1}>
            <Box sx={{ height: 400, width: "100%" }}>
              <Table
                columns={columns}
                rows={userExamListDisplay}
                checkboxSelection={false}
                // onSelectionChange={(e) => onClickRow(e)}
                onEditRow={false}
                onDeleteRow={false}
              />
            </Box>
          </Grid>
          <Grid xs={12} sm={12} item>
            {/* <Pagination
            count={pageCount} page={page} onChange={onChangePage}
            /> */}
          </Grid>
        </Grid>
      </Grid>
    </Div>
  );
};

export default Report;
