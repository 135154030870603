import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Stack from "components/layout/Stack";
import Grid from "../../components/layout/Grid";
import Button from "../../components/form/Button";
import Typography from "@mui/material/Typography";
import ButtonIcon from "../../components/form/ButtonIcon";
import Table from "../../components/table/Table";

import Edit from "../../assets/icon/edit-report.png";
import { Box, Container } from "@mui/material";
import SubContentDialog from "components/dialog/SubContentDialog";
import Select from "components/form/Select";
import TextField from "components/form/TextField";

const Div = styled.div`
  padding: var(--padding);
  margin: 0 auto;
`;

const reportType = [
  { text: "Select Report Type", value: "" },
  { text: "Math", value: "Math" },
];
const SubDetailReport = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);

  const [historyFrm, setHistoryFrm] = useState([
    {
      id: 1,
      company: "2BSimple",
      campaign: "สอบวัดผลหานักบัญชี",
      position: "บัญชี",
      testSet: "",
      report: "",
      testDate: "10/June/2022",
    },
    {
      id: 2,
      company: "2BSimple",
      campaign: "สอบวัดผลหานักบัญชี",
      position: "บัญชี",
      testSet: "",
      report: "",
      testDate: "11/June/2022",
    },
    {
      id: 3,
      company: "2BSimple",
      campaign: "สอบวัดผลหานักบัญชี",
      position: "บัญชี",
      testSet: "",
      report: "",
      testDate: "12/June/2022",
    },
    {
      id: 4,
      company: "2BSimple",
      campaign: "สอบวัดผลหานักบัญชี",
      position: "บัญชี",
      testSet: "",
      report: "",
      testDate: "12/June/2022",
    },
    {
      id: 5,
      company: "2BSimple",
      campaign: "สอบวัดผลหานักบัญชี",
      position: "บัญชี",
      testSet: "",
      report: "",
      testDate: "12/June/2022",
    },
  ]);
  const [results, setResults] = useState([
    {
      id: 1,
      code: "Test001",
      name: "Math 01",
      company: "2BSimple",
      examTotal: 15,
      originateBy: "Center",
      author: "พิชญุตม์",
      testResult: "70%",
    },
    {
      id: 2,
      code: "Test001",
      name: "Math 01",
      company: "2BSimple",
      examTotal: 15,
      originateBy: "Center",
      author: "พิชญุตม์",
      testResult: "70%",
    },
    {
      id: 3,
      code: "Test001",
      name: "Math 01",
      company: "2BSimple",
      examTotal: 15,
      originateBy: "Center",
      author: "พิชญุตม์",
      testResult: "70%",
    },
  ]);

  const [isOpenModalViewResult, setIsOpenModalViewResult] = useState(false);
  const [isOpenModalSelectReport, setIsOpenModalSelectReport] = useState(false);
  const [isOpenModalSelectReportGenerate, setIsOpenModalSelectReportGenerate] =
    useState(false);

  const handleOpenModalViewResult = () => {
    setIsOpenModalViewResult(true);
  };

  const handleCloseModalViewResult = () => {
    setIsOpenModalViewResult(false);
  };

  const handleOpenModalSelectReport = () => {
    setIsOpenModalSelectReport(true);
  };

  const handleCloseModalSelectReport = () => {
    setIsOpenModalSelectReport(false);
  };

  const handleOpenModalSelectReportGenerate = () => {
    setIsOpenModalSelectReportGenerate(true);
    setIsOpenModalSelectReport(false);
  };

  const handleCloseModalSelectReportGenerate = () => {
    setIsOpenModalSelectReportGenerate(false);
  };

  const handleChangeSelectReportType = () => {};

  const columns = [
    // {
    //   field: "id",
    //   headerName: "ลำดับ",
    //   minWidth: 150,
    // },
    {
      field: "company",
      headerName: "Company",
      minWidth: 150,
    },
    {
      field: "campaign",
      headerName: "Campaign",
      minWidth: 250,
    },
    {
      field: "position",
      headerName: "Position",
      minWidth: 150,
    },
    {
      field: "testSet",
      headerName: "Test Set & Result",
      minWidth: 150,

      renderCell: ({ value, row }) => (
        <Button
          // style={{ width: "150px" }}
          onClick={() => handleOpenModalViewResult()}
          outlined
          block
        >
          View
        </Button>
      ),
    },
    {
      field: "report",
      headerName: "Report",
      renderCell: ({ value, row }) => (
        <Button
          // style={{ width: "150px" }}
          block
          onClick={() => handleOpenModalSelectReport()}
        >
          View Report
        </Button>
      ),
      minWidth: 150,
    },
    {
      field: "testDate",
      minWidth: 150,
      headerName: "Test Date",
    },
  ];

  const columnsResult = [
    // {
    //   field: "id",
    //   headerName: "ลำดับ",
    //   minWidth: 100,
    // },
    {
      field: "code",
      headerName: "รหัสชุดข้อสอบ",
    },
    {
      field: "name",
      headerName: "ชื่อชุดข้อสอบ",
      minWidth: 200,
    },
    {
      field: "company",
      headerName: "บริษัท",
    },

    {
      field: "examTotal",
      headerName: "จำนวนข้อสอบ",
    },
    {
      field: "originateBy",
      headerName: "Position",
    },
    {
      field: "author",
      headerName: "Author",
    },
    {
      field: "testResult",
      headerName: "ผลการสอบ",
    },
  ];

  return (
    <Div>
      <SubContentDialog
        open={isOpenModalViewResult}
        title={"Campaign : สอบวัดผลหานักบัญชี"}
        subTitle={"Test Set & Result"}
        alignTitle="center"
        alignSubTitle="left"
        onClose={handleCloseModalViewResult}
        maxWidth="lg"
      >
        <Box sx={{ height: 450, width: "100%" }}>
          <Table
            columns={columnsResult}
            rows={results}
            checkboxSelection={false}
            disablecBorderContent
            headerNameRunningNumber="ลำดับ"
            showRunningNumber
          />
        </Box>
      </SubContentDialog>
      <SubContentDialog
        open={isOpenModalSelectReport}
        title={"Select Report Type"}
        subTitle={""}
        alignTitle="center"
        alignSubTitle="left"
        onClose={handleCloseModalSelectReport}
        maxWidth="sm"
      >
        <Box>
          <Select
            defaultValue="วิชา"
            name="subject_id"
            items={reportType}
            onChange={handleChangeSelectReportType}
          />

          <Button
            style={{ width: "100%", marginTop: "8px", marginBotton: "8px" }}
            onClick={() => handleOpenModalSelectReportGenerate()}
          >
            Confirm
          </Button>
        </Box>
      </SubContentDialog>
      <SubContentDialog
        open={isOpenModalSelectReportGenerate}
        title={"Generate Report"}
        subTitle={""}
        alignTitle="center"
        alignSubTitle="left"
        onClose={handleCloseModalSelectReportGenerate}
        maxWidth="md"
      >
        <Box sx={{ height: 50, width: "100%" }}>
          <TextField />
        </Box>
      </SubContentDialog>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={12} container spacing={2} align="center">
          <Grid xs={2} sm={2} item>
            <Button outlined block onClick={() => history.goBack()}>
              Back
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Typography variant="h4" color="initial">
            พิชญุตม์ ประเสริฐวิทย์
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} container spacing={2} item>
          <Grid xs={12} sm={6} item container spacing={2}>
            <Grid item xs={12} sm>
              <Stack
                spacing={2}
                sx={{ border: "1px solid #ECEDF2", padding: 1 }}
              >
                <Typography variant="h6" color="initial">
                  ข้อมูลเกี่ยวกับผู้สอน
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  ชื่อ
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  พิชญุตม์
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  นามสกุล
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  ประเสริฐวิทย์
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Email
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  strike@gmail.com
                </Typography>
              </Stack>
            </Grid>
          </Grid>
          <Grid xs={12} sm={6} item container spacing={2}>
            <Grid item xs={12} sm>
              <Stack
                spacing={2}
                sx={{ border: "1px solid #ECEDF2", padding: 1 }}
              >
                <Typography variant="h6" color="initial">
                  สถานะข้อสอบ
                </Typography>
              </Stack>
            </Grid>

            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  สถานะ
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  Active
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  วันที่สมัคร
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  06/Jun/2022
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  วันที่ใช้งานล่าสุด
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  06/Jun/2022
                </Typography>
              </Stack>
            </Grid>
            <Grid item xs={12} sm={12}>
              <Stack direction="row" alignItems="center" sx={{ px: 1 }}>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  เบอร์โทร
                </Typography>
                <Typography sx={{ flex: 1 }} variant="body1" color="initial">
                  081-111-1111
                </Typography>
              </Stack>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Typography variant="h5" color="initial">
            Exam history
          </Typography>
        </Grid>
        <Grid xs={12} sm={12} item>
          <Box sx={{ height: 600, width: "100%" }}>
            <Table
              columns={columns}
              rows={historyFrm}
              checkboxSelection={false}
              disablecBorderContent
              headerNameRunningNumber="ลำดับ"
              showRunningNumber
            />
          </Box>
        </Grid>
      </Grid>
    </Div>
  );
};

export default SubDetailReport;
