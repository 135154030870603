import React, { useEffect } from "react";
import { Route, useHistory } from "react-router-dom";

const SecureRouteBusiness = (props) => {
  const history = useHistory();
  const accessToken = localStorage.getItem("access_token");
  const member = JSON.parse(localStorage.getItem("user"))?.member;

  useEffect(() => {
    if (accessToken && member?.member_level !== "business") {
      history.replace("/welcome");
    } else if (!accessToken) history.replace("/");
  }, [accessToken, member, history]);

  return accessToken ? <Route {...props} /> : null;
};

export default SecureRouteBusiness;
