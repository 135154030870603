import React, { useState, useEffect, Fragment } from "react";
import styled from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import formatValue from "utils/formatValue";

import { Typography } from "@mui/material";
import {
  AddCircle as AddIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";

import Box from "../../components/surfaces/Box";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Table from "../../components/table/Table";

import TextField from "../../components/form/TextField";
import TextFieldArea from "../../components/form/TextFieldArea";
import Select from "../../components/form/Select";
import Checkbox from "../../components/form/Checkbox";
import Button from "../../components/form/Button";
import ButtonIcon from "components/form/ButtonIcon";

import Pagination from "../../components/navigation/Pagination";
import SubContentDialog from "components/dialog/SubContentDialog";

import {
  updateExamSetById,
  saveFormExamSet,
  clearFormExamSet,
  randomQuestion,
  getExamSetById,
} from "../../redux/actions/assessments";

import { RenderHtmlToString } from "components/form/ConvertHtmlToString";
import useDebounce from "utils/useDebounce";
import { getUniqueListBy, isNumber } from "utils/common";
const Div = styled.div`
  padding: 5vw 7vw;
`;

const RANGE_SCORE = [
  { value: "-5", text: "-5" },
  { value: "-4", text: "-4" },
  { value: "-3", text: "-3" },
  { value: "-2", text: "-2" },
  { value: "-1", text: "-1" },
  { value: "0", text: "0" },
  { value: "1", text: "1" },
  { value: "2", text: "2" },
  { value: "3", text: "3" },
  { value: "4", text: "4" },
  { value: "5", text: "5" },
];

const EXAM_MODE_DDL = [
  { value: "adt", text: "Adaptive AI Test" },
  { value: "shuffle", text: "Shuffle" },
  { value: "normal", text: "Normal" },
];

const INITIAL_RANDOM_FORM = {
  random_type: "adt",
  subject_id: "",
  topic_name: "",
  job_position_id: "",
  levels: [
    {
      level: "",
      total_question: "",
    },
  ],
};

const AssessmentCreateTestEdit = () => {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { masterDataSubject, masterDataLevel, masterDataJob } = useSelector(
    (state) => state.masterData
  );
  const { formExamSet, examsetById } = useSelector(
    (state) => state.assessments
  );

  const [form, setForm] = useState(formExamSet);
  const [isSubmit, setIsSubmit] = useState(false);
  const [errors, setErrors] = useState({});

  const [randomForm, setRandomForm] = useState(INITIAL_RANDOM_FORM);
  const [isSubmitRandom, setIsSubmitRandom] = useState(false);
  const [errorsRandom, setErrorsRandom] = useState({});

  const [isOpenModalRandomShuffle, setIsOpenModalRandomShuffle] =
    useState(false);
  const [isOpenModalRandomADT, setIsOpenModalRandomADT] = useState(false);

  const columnsTest = [
    // {
    //   field: "id",
    //   headerName: "ลำดับ ",
    //   minWidth: 100,
    // },
    {
      field: "code",
      headerName: "รหัสข้อสอบ",
      minWidth: 200,
    },
    {
      field: "question",
      headerName: "โจทย์",
      minWidth: 550,
      renderCell: ({ row, value }) => {
        return (
          <div>
            {RenderHtmlToString({ value })}
            {row?.question_image !== "" ? (
              <div>
                <img className="question-img" src={row?.question_image} />
              </div>
            ) : (
              ""
            )}
          </div>
        );
      },
    },
    {
      field: "name_upper",
      headerName: "หมวดวิชา",
      minWidth: 120,
    },
    {
      field: "topic_name",
      headerName: "หัวข้อ",
      minWidth: 120,
    },
    {
      field: "level",
      headerName: "Center Level",
      minWidth: 120,
    },
    {
      field: "my_level",
      headerName: "My Level",
      renderCell: ({ value, row }) => (
        <Select
          defaultValue={"My Level"}
          value={value}
          items={masterDataLevel}
          onChange={(e) => handleChangeMyLevel(e, row?.id)}
        />
      ),
    },
    {
      field: "exam_owner",
      headerName: "ชื่อผู้ออกข้อสอบ",
    },
    {
      field: "created_at",
      headerName: "วันที่สร้างข้อสอบ",
      renderCell: ({ value }) => formatValue("Date", value),
    },
  ];

  useEffect(() => {
    dispatch(getExamSetById(id));
  }, []);

  useEffect(() => {
    console.log({ form });
  }, [form]);

  useEffect(() => {
    // console.log("examsetById", examsetById);
    let newList = examsetById?.examSetQuestion?.map((item) => ({
      ...item.detail,
    }));
    let mapSummary = [...form?.summary].map((it) => {
      let item = formExamSet?.examSetSummary?.find(
        (i) => i?.exam_level === it?.exam_level
      );
      return item
        ? {
            exam_level: item?.exam_level,
            right_weight: item?.right_weight,
            wrong_weight: item?.wrong_weight,
            no_answer_weight: item?.no_answer_weight,
          }
        : { ...it };
    });
    // .concat(
    //   examsetById?.examSetSummary?.length > 0
    //     ? examsetById?.examSetSummary
    //     : []
    // )
    // console.log({ mapSummary });
    // console.log({ formExamSet });
    let newForm = {
      ...examsetById,
      question: newList?.map((item) => ({
        ...item,
        question: item?.choice_description,
        my_level: item?.myLevel,
        level: item?.center_level,
        created_at: item?.exam_create,
      })),
      summary: mapSummary,
      result_mapping: examsetById?.examSetResultMapping,
    };
    setForm(
      formExamSet?.question?.length > 0
        ? { ...formExamSet, summary: mapSummary }
        : newForm
    );
  }, [examsetById, formExamSet?.question]);

  // useEffect(() => {
  //   console.log("randomForm", randomForm);
  // }, [randomForm]);

  // useEffect(() => {
  //   console.log("errors", errors);
  // }, [errors]);

  useEffect(() => {
    if (form?.question?.length > 0) {
      setForm((prev) => ({
        ...prev,
        amount_question: form?.question?.length,
      }));
    }
  }, [form.question]);

  useEffect(() => {
    dispatch(saveFormExamSet(form));
  }, [form]);

  const validateForm = (
    fieldValues = { ...form, company_id: "fix", language: "fix", client: "fix" }
  ) => {
    let temp = {
      ...errors,
      result_mapping: errors?.result_mapping
        ? errors?.result_mapping
        : [
            {
              min_score: 0,
              max_score: "",
            },
            {
              min_score: "",
              max_score: "",
            },
            {
              min_score: "",
              max_score: "",
            },
          ],
      summary: errors?.summary
        ? errors?.summary
        : Array.from(Array(11)).map((it) => ({
            right_weight: "",
            wrong_weight: "",
            no_answer_weight: "",
          })),
    };

    if ("name" in fieldValues)
      temp.name = fieldValues.name !== "" ? "" : "กรุณากรอกชื่อชุดข้อสอบ";
    if ("author" in fieldValues)
      temp.author = fieldValues.author !== "" ? "" : "กรุณากรอก Author";
    if ("amount_question" in fieldValues)
      temp.amount_question =
        +fieldValues.amount_question === form?.question?.length
          ? fieldValues.amount_question !== ""
            ? ""
            : "กรุณากรอกจำนวนข้อสอบ"
          : "จำนวนที่เลือกต้องเท่ากับจำนวนข้อสอบ";
    if ("subject_id" in fieldValues)
      temp.subject_id = fieldValues.subject_id !== "" ? "" : "กรุณาเลือกวิชา";
    if ("level" in fieldValues)
      temp.level = fieldValues.level !== "" ? "" : "กรุณาเลือก Lever";
    if ("description" in fieldValues)
      temp.description =
        fieldValues.description !== "" ? "" : "กรุณากรอกรายละเอียดข้อสอบ";
    if ("exam_mode" in fieldValues)
      temp.exam_mode =
        fieldValues.exam_mode !== "" ? "" : "กรุณาเลือก Exam Mode";
    if ("exam_time" in fieldValues)
      temp.exam_time =
        fieldValues.exam_time !== "" ? "" : "กรุณากรอกเวลาในการทำข้อสอบ ";

    if ("result_mapping" in fieldValues) {
      for (let index = 0; index < 3; index++) {
        // if (index !== 0) {
        temp.result_mapping[index] = {
          ...temp.result_mapping[index],
          min_score:
            fieldValues?.result_mapping?.[index]?.min_score !== ""
              ? ""
              : "กรุณากรอกช่วงคะแนน",
        };
        // }
        temp.result_mapping[index] = {
          ...temp.result_mapping[index],
          max_score:
            fieldValues?.result_mapping?.[index]?.max_score !== ""
              ? ""
              : "กรุณากรอกช่วงคะแนน",
        };
      }
    }

    if ("summary" in fieldValues) {
      for (let index = 0; index < fieldValues?.summary?.length; index++) {
        if (!countExam(index + 1) > 0) {
          let checkBy = Object.values(fieldValues?.summary[index]).filter(
            (it) => typeof it === "string"
          );
          let isHasValue = checkBy?.some((y) => y !== "");
          if (isHasValue) {
            let newArr = [...form?.summary];
            newArr[index] = {
              exam_level: index + 1,
              right_weight: "",
              wrong_weight: "",
              no_answer_weight: "",
            };

            setForm((prev) => ({
              ...prev,
              summary: newArr,
            }));
          }
          continue;
        }
        temp.summary[index] = {
          ...temp.summary[index],
          right_weight:
            fieldValues?.summary?.[index]?.right_weight !== ""
              ? ""
              : "กรุณาเลือก",
        };
        temp.summary[index] = {
          ...temp.summary[index],
          wrong_weight:
            fieldValues?.summary?.[index]?.wrong_weight !== ""
              ? ""
              : "กรุณาเลือก",
        };
        temp.summary[index] = {
          ...temp.summary[index],
          no_answer_weight:
            fieldValues?.summary?.[index]?.no_answer_weight !== ""
              ? ""
              : "กรุณาเลือก",
        };
      }
    }

    setErrors({ ...temp });

    let summary = [...temp.summary];
    let result = [...temp.result_mapping];

    let otherField = {
      amount_question: temp?.amount_question,
      author: temp?.author,
      description: temp?.description,
      exam_mode: temp?.exam_mode,
      exam_time: temp?.exam_time,
      level: temp?.level,
      name: temp?.name,
      subject_id: temp?.subject_id,
    };

    return (
      Object.values(otherField).every((x) => x === "") &&
      checkValidSummary(summary) &&
      checkValidResultMapping(result)
    );
  };

  const checkValidSummary = (arr) => {
    for (const item of arr) {
      if (Object.values(item).every((y) => y === "") !== true) {
        return false;
      }
    }

    return true;
  };

  const checkValidResultMapping = (arr) => {
    let index = 0;
    for (const item of arr) {
      if (Object.values(item).every((y) => y === "") !== true) {
        return false;
      }
      index++;
    }

    return true;
  };

  const validateFormRandom = (
    fieldValues = { ...randomForm, topic_name: "not-required" }
  ) => {
    let temp = {
      ...errorsRandom,
      levels: errorsRandom?.levels
        ? errorsRandom?.levels
        : [
            {
              level: "",
              total_question: "",
            },
          ],
    };

    if ("subject_id" in fieldValues)
      temp.subject_id = fieldValues.subject_id !== "" ? "" : "กรุณาเลือก";
    if ("job_position_id" in fieldValues)
      temp.job_position_id =
        fieldValues.job_position_id !== "" ? "" : "กรุณาเลือก";

    if ("levels" in fieldValues) {
      for (let index = 0; index < fieldValues?.levels?.length; index++) {
        temp.levels[index] = {
          ...temp.levels[index],
          level: fieldValues?.levels?.[index]?.level !== "" ? "" : "กรุณาเลือก",
        };

        temp.levels[index] = {
          ...temp.levels[index],
          total_question:
            fieldValues?.levels?.[index]?.total_question !== ""
              ? ""
              : "กรุณากรอกจำนวน",
        };
      }
    }
    setErrorsRandom({ ...temp });

    return Object.values(fieldValues).every((x) => x !== "");
  };

  const handleChangeMyLevel = (e, id) => {
    const { value } = e.target;

    let newArr = [...form?.question];
    let tempIndex = newArr.findIndex((it) => it.id === id);

    newArr[tempIndex] = { ...newArr[tempIndex], my_level: +value };

    setForm((prev) => ({
      ...prev,
      question: newArr,
    }));
  };

  const addQuestionToExam = () => {
    dispatch(saveFormExamSet(form));
    history.push("/assessments/create-test/add-exam-set");
  };

  const countExam = (level) => {
    return form?.question?.filter((item) => item.my_level === level).length;
  };

  const handleRandomExam = () => {
    form?.exam_mode === "adt"
      ? setIsOpenModalRandomADT(true)
      : setIsOpenModalRandomShuffle(true);

    setRandomForm((prev) => ({
      ...prev,
      random_type: form?.exam_mode,
    }));
  };

  const handleSubmitRandomForm = (e) => {
    e.preventDefault();

    const isValid = validateFormRandom();

    let submit = false;
    if (!isSubmitRandom) {
      submit = true;
      setIsSubmitRandom(true);
    } else {
      submit = true;
    }

    if (submit && isValid) {
      dispatch(randomQuestion(randomForm))
        .then(({ payload }) => {
          let mapArr = payload?.data.map((item) => ({
            ...item,
            my_level: item?.level,
          }));

          let tempArr = [].concat(form?.question, mapArr);
          let arrDistinct = getUniqueListBy(tempArr, "id");

          setForm((prev) => ({
            ...prev,
            question: arrDistinct,
          }));
        })
        .then(() =>
          randomForm?.random_type === "adt"
            ? handleCloseModalADT()
            : handleCloseModalShuffle()
        );
    }
    setIsSubmitRandom(true);
  };

  const handleCloseModalShuffle = () => {
    setIsOpenModalRandomShuffle(!isOpenModalRandomShuffle);
  };

  const handleCloseModalADT = () => {
    setIsOpenModalRandomADT(!isOpenModalRandomADT);
  };

  const handleChangeRandomForm = (e) => {
    const { name, value } = e.target;

    setRandomForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateFormRandom({ [name]: value });
  };

  const handleChangeLevel = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...randomForm?.levels];
    newArr[index] = {
      ...newArr[index],
      [name]: +value,
    };

    setRandomForm((prev) => ({
      ...prev,
      levels: newArr,
    }));

    validateFormRandom({
      levels: newArr,
    });
  };

  const handleAddLevel = () => {
    let newArr = [...randomForm?.levels];

    newArr.push({
      level: "",
      total_question: "",
    });

    setRandomForm((prev) => ({
      ...prev,
      levels: newArr,
    }));
  };

  const handleDeleteLevel = (indexDelete) => {
    let newArr = randomForm?.levels?.filter(
      (item, index) => index !== indexDelete
    );

    setRandomForm((prev) => ({
      ...prev,
      levels: newArr,
    }));
  };

  const handleChangeForm = (e) => {
    const { name, value } = e.target;

    if (name === "is_share") {
      const { checked } = e.target;
      setForm((prev) => ({
        ...prev,
        is_share: checked,
      }));
    } else {
      setForm((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
    validateForm({ [name]: value });
  };

  const handleChangeResultMapping = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...form?.result_mapping];
    newArr[index] = { ...newArr[index], [name]: value };

    setForm((prev) => ({
      ...prev,
      result_mapping: newArr,
    }));

    validateForm({
      result_mapping: newArr,
    });
  };

  const handleChangeQuestion = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...form?.question];
    newArr[index] = { ...newArr[index], [name]: value };

    setForm((prev) => ({
      ...prev,
      question: newArr,
    }));
  };

  const handleChangeSummary = (e, index) => {
    const { name, value } = e.target;

    let newArr = [...form?.summary];
    newArr[index] = { ...newArr[index], [name]: +value };

    setForm((prev) => ({
      ...prev,
      summary: newArr,
    }));

    validateForm({ summary: newArr });
  };

  const mappingBody = (prevForm) => {
    let question_time_avg = +prevForm?.exam_time / +prevForm?.question?.length;
    let mapSummary = prevForm?.summary
      ?.filter((item) => isNumber(item?.right_weight))
      .map((it) => ({
        ...it,
        right_weight: +it?.right_weight,
        wrong_weight: +it?.wrong_weight,
        no_answer_weight: +it?.no_answer_weight,
      }));

    return {
      ...prevForm,
      question: prevForm.question.map((item) => ({
        exam_question_id: item?.id,
        level: item?.level !== item?.my_level ? item?.my_level : item?.level,
        question_time: question_time_avg,
      })),
      summary: mapSummary,
    };
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();

    if (form?.question?.length === 0) {
      alert("กรุณาเพิ่มข้อสอบเข้าชุดข้อสอบ");
      document.getElementById("add-exam-btn").scrollIntoView();
    }

    let submit = false;
    if (!isSubmit) {
      submit = true;
      setIsSubmit(true);
    } else {
      submit = true;
    }

    console.log({ isValid });
    console.log({ submit });

    if (submit && isValid) {
      let body = mappingBody(form);
      dispatch(updateExamSetById(body)).then(() => {
        history.goBack();
        dispatch(clearFormExamSet());
      });
    }

    setIsSubmit(true);
  };

  const goBack = () => {
    dispatch(clearFormExamSet());
    history.goBack();
  };

  return (
    <Div>
      <SubContentDialog
        open={isOpenModalRandomShuffle}
        title={"สุ่มข้อสอบเข้าสู่ชุดข้อสอบ"}
        subTitle={"แบบ Shuffle"}
        alignTitle="center"
        alignSubTitle="center"
        onClose={handleCloseModalShuffle}
        maxWidth="md"
      >
        <form onSubmit={handleSubmitRandomForm}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="วิชา"
                name="subject_id"
                onChange={handleChangeRandomForm}
                error={errorsRandom?.subject_id}
                items={masterDataSubject}
                // required
                value={randomForm?.subject_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="หัวข้อ"
                name="topic_name"
                onChange={handleChangeRandomForm}
                value={randomForm?.topic_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="ตำแหน่งงาน"
                items={masterDataJob}
                // required
                name="job_position_id"
                error={errorsRandom?.job_position_id}
                onChange={handleChangeRandomForm}
                value={randomForm?.job_position_id}
              />
            </Grid>

            {randomForm?.levels.map((item, index) => (
              <Grid item container xs={12} sm={12} spacing={4} key={index}>
                <Grid item xs={12} sm={6}>
                  <Select
                    defaultValue="level"
                    items={masterDataLevel}
                    // required
                    name="level"
                    error={errorsRandom?.levels?.[index]?.level}
                    value={item?.level}
                    onChange={(e) => handleChangeLevel(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    placeholder="จำนวนข้อสอบ "
                    // required
                    type="number"
                    name="total_question"
                    error={errorsRandom?.levels?.[index]?.total_question}
                    value={item?.total_question}
                    onChange={(e) => handleChangeLevel(e, index)}
                  />
                </Grid>
              </Grid>
            ))}

            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Button block type="submit">
                  Random
                </Button>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>

      <SubContentDialog
        open={isOpenModalRandomADT}
        title={"สุ่มข้อสอบเข้าสู่ชุดข้อสอบ"}
        subTitle={"แบบ ADT"}
        alignTitle="center"
        alignSubTitle="center"
        onClose={handleCloseModalADT}
        maxWidth="md"
      >
        <form onSubmit={handleSubmitRandomForm}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="วิชา"
                // required
                name="subject_id"
                onChange={handleChangeRandomForm}
                items={masterDataSubject}
                error={errorsRandom?.subject_id}
                value={randomForm?.subject_id}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="หัวข้อ"
                name="topic_name"
                onChange={handleChangeRandomForm}
                value={randomForm?.topic_name}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="ตำแหน่งงาน"
                // required
                items={masterDataJob}
                name="job_position_id"
                onChange={handleChangeRandomForm}
                error={errorsRandom?.job_position_id}
                value={randomForm?.job_position_id}
              />
            </Grid>

            {randomForm?.levels.map((item, index) => (
              <Grid item container xs={12} sm={12} spacing={4} key={index}>
                <Grid item xs={12} sm={6}>
                  <Select
                    defaultValue="level"
                    items={masterDataLevel}
                    value={item?.level}
                    error={errorsRandom?.levels?.[index]?.level}
                    // required
                    name="level"
                    onChange={(e) => handleChangeLevel(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <TextField
                      placeholder="จำนวนข้อสอบ "
                      value={item?.total_question}
                      error={errorsRandom?.levels?.[index]?.total_question}
                      type="number"
                      // required
                      name="total_question"
                      onChange={(e) => handleChangeLevel(e, index)}
                    />
                    {index === 0 ? (
                      <ButtonIcon
                        type="button"
                        onClick={() => handleAddLevel()}
                      >
                        <AddIcon />
                      </ButtonIcon>
                    ) : (
                      <ButtonIcon
                        type="button"
                        onClick={() => handleDeleteLevel(index)}
                      >
                        <DeleteIcon />
                      </ButtonIcon>
                    )}
                  </Stack>
                </Grid>
              </Grid>
            ))}

            <Grid item container xs={12} sm={12}>
              <Grid item xs={12} sm={2}></Grid>
              <Grid item xs={12} sm={8}>
                <Button block type="submit">
                  Random
                </Button>
              </Grid>
              <Grid item xs={2} sm={2}></Grid>
            </Grid>
          </Grid>
        </form>
      </SubContentDialog>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} container>
            <Grid xs={2} sm={2} item>
              <Button outlined block onClick={() => goBack()}>
                Back
              </Button>
            </Grid>
            <Grid xs={8} sm={8} item></Grid>

            <Grid xs={2} sm={2} item>
              <Button
                block
                type="submit"
                disabled={!form?.question && form?.question?.length === 0}
              >
                Save
              </Button>
            </Grid>
          </Grid>
          <Grid
            sx={{ display: { xs: "flex", sm: "none" } }}
            item
            container
            xs={12}
            sm={12}
          >
            <Stack spacing={2}>
              <Typography variant="h5" color="primary" align="center">
                {examsetById?.name}
              </Typography>
              <Typography variant="h6" color="#606266" align="center">
                กรอกรายละเอียด
              </Typography>
            </Stack>
          </Grid>
          <Grid
            sx={{ display: { xs: "none", sm: "flex" } }}
            item
            container
            xs={12}
            sm={12}
          >
            <Typography variant="h3" color="primary">
              {examsetById?.name}
            </Typography>
          </Grid>
          <Grid item container xs={12} sm={5} spacing={2}>
            <Grid sx={{ display: { xs: "none", sm: "flex" } }} item xs={12}>
              <Typography variant="h4" color="initial">
                กรอกรายละเอียด
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="ชื่อชุดข้อสอบ"
                name="name"
                value={form?.name}
                error={errors?.name}
                onChange={handleChangeForm}
                // required
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Author"
                name="author"
                value={form?.author}
                error={errors?.author}
                // required
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                type="number"
                placeholder="จำนวนข้อ"
                name="amount_question"
                value={form?.amount_question}
                error={errors?.amount_question}
                disabled
                // required
                onChange={handleChangeForm}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Select
                name="subject_id"
                defaultValue="วิชา"
                value={form?.subject_id}
                error={errors?.subject_id}
                // required
                items={masterDataSubject}
                onChange={handleChangeForm}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
              <Select defaultValue="Select Client" value={""} items={[]} />{" "}
            </Grid> */}

            <Grid item xs={12} sm={12}>
              <Stack spacing={2}>
                <Typography variant="h5" color="initial">
                  รายละเอียดการประเมินคะแนน
                </Typography>
                <Typography variant="body1" color="initial">
                  กำหนดช่วงคะแนนเป็นเปอร์เซ็นต์ตั้งแต่ 0-100 เช่น 80 - 100
                  อยู่ในระดับดี
                </Typography>

                {form?.result_mapping?.map((item, index) => (
                  <Stack spacing={2} key={index}>
                    <Stack
                      direction="row"
                      spacing={4}
                      alignItems="center"
                      key={index}
                    >
                      <Typography
                        sx={{ width: 100 }}
                        variant="body1"
                        color="initial"
                      >
                        {" "}
                        {item?.name}
                      </Typography>

                      <Box sx={{ width: 150 }}>
                        <TextField
                          value={item?.min_score}
                          // required
                          min="0"
                          error={errors?.result_mapping?.[index]?.min_score}
                          max={item?.max_score}
                          name="min_score"
                          type="number"
                          onChange={(e) => handleChangeResultMapping(e, index)}
                        />
                      </Box>

                      <Typography variant="body1" color="initial">
                        {" "}
                        to
                      </Typography>

                      <Box sx={{ width: 150 }}>
                        <TextField
                          value={item?.max_score}
                          // required
                          min={item?.min_score}
                          error={errors?.result_mapping?.[index]?.max_score}
                          max="100"
                          name="max_score"
                          type="number"
                          onChange={(e) => handleChangeResultMapping(e, index)}
                        />
                      </Box>
                    </Stack>
                    <TextFieldArea
                      value={item?.description}
                      name="description"
                      // required
                      placeholder="รายละเอียด"
                      onChange={(e) => handleChangeResultMapping(e, index)}
                      minRows={3}
                    />
                  </Stack>
                ))}
              </Stack>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Checkbox
                label="Add to default test"
                checked={form?.is_share}
                name="is_share"
                onChange={handleChangeForm}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <Select
                defaultValue="Level"
                name="level"
                value={form?.level}
                error={errors?.level}
                // required
                onChange={handleChangeForm}
                items={masterDataLevel}
              />{" "}
            </Grid>
            <Grid item xs={12} sm={12}>
              <TextFieldArea
                placeholder="รายละเอียดชุดข้อสอบ"
                name="description"
                onChange={handleChangeForm}
                error={errors?.description}
                // required
                value={form?.description}
              />
            </Grid>
            <Grid item xs={12} sm={8}>
              <Stack spacing={4}>
                {/* <Stack spacing={2}>
                <Typography variant="h5" color="initial">
                  ประเมินความสามารถด้านทักษะวิชา
                </Typography>
                <Select
                  defaultValue="ตัวดำเนินการเปรียบเทียบ"
                  value={""}
                  items={[]}
                />{" "}
              </Stack> */}
                <Stack spacing={2}>
                  <Typography variant="h5" color="initial">
                    Exam Mode
                  </Typography>
                  <Select
                    defaultValue="Exam Mode"
                    name="exam_mode"
                    value={form?.exam_mode}
                    error={errors?.exam_mode}
                    // required
                    items={EXAM_MODE_DDL}
                    onChange={handleChangeForm}
                  />{" "}
                </Stack>
              </Stack>
            </Grid>
          </Grid>

          <Grid item xs={12} sm={7}>
            <Box
              sx={{
                p: 2,
                border: " 1px solid",
                borderColor: "#F5F7FC",
                backgroundColor: "#F5F7FC",
                borderRadius: 2,
              }}
            >
              <Stack spacing={2}>
                <Typography variant="h4" color="initial">
                  Summary
                </Typography>

                <Stack direction="row" alignItems="center">
                  <Typography
                    sx={{ width: "100%" }}
                    variant="body1"
                    color="initial"
                  >
                    เวลารวมในการทำข้อสอบทั้งหมด
                  </Typography>

                  <TextField
                    placeholder="นาที"
                    name="exam_time"
                    type="number"
                    // required
                    min="0"
                    max={"180"}
                    error={errors?.exam_time}
                    value={form?.exam_time}
                    onChange={handleChangeForm}
                  />
                </Stack>
                <Typography variant="h5" color="initial" gutterBottom>
                  จำนวนข้อสอบทั้งหมด : {form?.question?.length} ข้อ
                </Typography>
                {form?.summary?.map((item, index) =>
                  countExam(item?.exam_level) > 0 ? (
                    <Fragment key={index}>
                      <Typography variant="body2" color="initial">
                        จำนวนข้อสอบ Level {item?.exam_level} :{" "}
                        {countExam(item?.exam_level) || 0} ข้อ
                      </Typography>
                      <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        spacing={2}
                        key={index}
                      >
                        <Stack
                          direction="row"
                          alignItems="center"
                          justifyContent="center"
                          spacing={2}
                        >
                          <Typography variant="subtitle1" color="initial">
                            ตอบถูก
                          </Typography>
                          <Box sx={{ width: { xs: "initial", sm: 100 } }}>
                            <Select
                              defaultValue="คะแนน"
                              name="right_weight"
                              error={
                                countExam(item?.exam_level) > 0
                                  ? errors?.summary?.[index]?.right_weight
                                  : ""
                              }
                              value={item?.right_weight}
                              onChange={(e) => handleChangeSummary(e, index)}
                              items={RANGE_SCORE}
                            />
                          </Box>
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="subtitle1" color="initial">
                            ตอบผิด
                          </Typography>
                          <Box sx={{ width: 100 }}>
                            <Select
                              defaultValue="คะแนน"
                              name="wrong_weight"
                              // required={countExam(item?.exam_level) > 0}
                              error={
                                countExam(item?.exam_level) > 0
                                  ? errors?.summary?.[index]?.wrong_weight
                                  : ""
                              }
                              value={item?.wrong_weight}
                              onChange={(e) => handleChangeSummary(e, index)}
                              items={RANGE_SCORE}
                            />
                          </Box>{" "}
                        </Stack>
                        <Stack
                          direction="row"
                          justifyContent="center"
                          alignItems="center"
                          spacing={2}
                        >
                          <Typography variant="subtitle1" color="initial">
                            ไม่ตอบ
                          </Typography>
                          <Box sx={{ width: 100 }}>
                            <Select
                              defaultValue="คะแนน"
                              // required={countExam(item?.exam_level) > 0}
                              error={
                                countExam(item?.exam_level) > 0
                                  ? errors?.summary?.[index]?.no_answer_weight
                                  : ""
                              }
                              name="no_answer_weight"
                              value={item?.no_answer_weight}
                              items={RANGE_SCORE}
                              onChange={(e) => handleChangeSummary(e, index)}
                            />
                          </Box>{" "}
                        </Stack>
                      </Stack>
                    </Fragment>
                  ) : (
                    ""
                  )
                )}
              </Stack>
            </Box>
          </Grid>

          <Grid item xs={12} spacing={2}>
            <Stack spacing={2}>
              <Stack
                sx={{ display: { xs: "none", sm: "flex" } }}
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ flexGrow: 1 }} variant="h5" color="initial">
                  ตารางข้อสอบ
                </Typography>

                <Box sx={{ flexGrow: 1 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    {/* <Box sx={{ flexGrow: 1 }}>
                      <Select
                        disabled
                        defaultValue={"10 ข้อ"}
                        value={""}
                        items={[]}
                      />
                    </Box> */}
                    <Button
                      type="button"
                      disabled
                      sx={{ flexGrow: 1 }}
                      outlined
                    >
                      Auto Average Level
                    </Button>
                    <Button
                      sx={{ flexGrow: 1 }}
                      type="button"
                      disabled
                      onClick={() => history.push("create-test/ordering")}
                    >
                      Ordering
                    </Button>
                  </Stack>
                </Box>
              </Stack>

              <Stack
                sx={{ display: { xs: "flex", sm: "none" } }}
                spacing={2}
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography sx={{ flexGrow: 1 }} variant="h5" color="initial">
                  ตารางข้อสอบ
                </Typography>

                <Box sx={{ flexGrow: 1 }}>
                  <Stack
                    direction="row"
                    spacing={2}
                    alignItems="center"
                    justifyContent="flex-end"
                  >
                    <Box sx={{ flexGrow: 1 }}>
                      <Select defaultValue={"10 ข้อ"} value={""} items={[]} />
                    </Box>
                  </Stack>
                </Box>
              </Stack>

              <Stack
                sx={{ display: { xs: "flex", sm: "none" } }}
                spacing={2}
                direction="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Button sx={{ flexGrow: 1 }} type="button" outlined>
                  Auto Average Level
                </Button>
                <Button sx={{ flexGrow: 1 }} type="button">
                  Ordering
                </Button>
              </Stack>
              <Box sx={{ height: 600, width: "100%" }}>
                <Table
                  columns={columnsTest}
                  rows={form?.question}
                  checkboxSelection={false}
                  disablecBorderContent
                  headerNameRunningNumber="ลำดับ"
                  showRunningNumber
                />
              </Box>
              {/* <Pagination /> */}
              <Stack
                direction={{ xs: "column", sm: "row" }}
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Button
                  id="add-exam-btn"
                  outlined
                  type="button"
                  onClick={() => handleRandomExam()}
                  disabled={!form?.exam_mode}
                >
                  สุ่มข้อสอบเข้าชุดข้อสอบจาก Center Library
                </Button>
                <Button
                  outlined
                  type="button"
                  onClick={() => addQuestionToExam()}
                >
                  เพิ่มข้อสอบเข้าชุดข้อสอบ
                </Button>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </form>
    </Div>
  );
};

export default AssessmentCreateTestEdit;
