import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import Button from "../../components/form/Button";

import Grid from "../../components/layout/Grid";

import { Container, Stack, Typography } from "@mui/material";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const SuccessForget = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <Stack justifyContent="center" alignItems="center" spacing={4}>
              <Typography
                sx={{ display: { xs: "none", sm: "flex" } }}
                align="center"
                variant={"h2"}
                gutterBottom
                color="#005cc9"
              >
                Forget your password?
              </Typography>
              <Typography
                sx={{ display: { xs: "flex", sm: "none" } }}
                align="center"
                variant={"h3"}
                gutterBottom
                color="#005cc9"
              >
                Forget your password?
              </Typography>
              <Typography variant="h5" align="center" color="initial">
                We have send an email to you.
                <br />
                Click set up new password to reset your password.
              </Typography>
              <br />

              <Button
                sx={{ width: { xs: "100%", sm: 300 } }}
                onClick={() => history.push("/")}
              >
                Home
              </Button>
            </Stack>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default SuccessForget;
