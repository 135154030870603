import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";

import { useHistory } from "react-router-dom";
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
} from "@mui/material";

import Button from "components/form/Button";
import ButtonIcon from "../../components/form/ButtonIcon";
import Toggle from "../../components/form/Toggle";

import Box from "../../components/surfaces/Box";

import Grid from "../../components/layout/Grid";
import Stack from "../../components/layout/Stack";

import Table from "../../components/table/Table";
import RouterLinkCell from "../../components/table/RouterLinkCell";

import ConfirmDialog from "../../components/dialog/ConfirmDialog";

import Delete from "../../assets/icon/delete.png";
import Edit from "../../assets/icon/edit.png";
import User from "../../assets/icon/user.png";

import {
  getCampaignList,
  deleteCampaign,
  getCampaignById,
  saveFormAssessmentDetail,
  saveFormAssessmentSelcetTest,
  saveFormAssessmentCreateExam,
  saveFormAssessmentReview,
  updateToModeEditCampaign,
  clearFormCampaign,
} from "redux/actions/assessments";

import { INITIAL_CHOICE_LIST, INITIAL_SCENARIOS } from "utils/dataConfig";

const Div = styled.div`
  padding: var(--padding);
`;

const DataMock = [
  {
    id: 1,
    name: "..Test Name..",
    candidates: "10 / 15",
    lastActive: "1 Day Ago",
    createAt: "30/05/3022",
    Status: false,
    invitation: "Create",
  },
  {
    id: 2,
    name: "..Test Name..",
    candidates: "10 / 15",
    lastActive: "1 Day Ago",
    createAt: "30/05/3022",
    Status: true,
    invitation: "Edit",
  },
  {
    id: 3,
    name: "..Test Name..",
    candidates: "10 / 15",
    lastActive: "1 Day Ago",
    createAt: "30/05/3022",
    Status: true,
    invitation: "Edit",
  },
];

const Assessmentmain = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { campaignList, campaignById } = useSelector(
    (state) => state.assessments
  );

  const [dataTable, setDataTable] = useState([]);

  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentData, setCurrentData] = useState("");
  const columns = [
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      renderCell: ({ value, row }) => {
        return (
          <RouterLinkCell to={`report/${decodeURI(row?.slug)}`}>
            {value}{" "}
          </RouterLinkCell>
        );
      },
      minWidth: 300,
    },
    {
      field: "count_examer",
      minWidth: 200,
      headerName: "Candidates",
      valueGetter: ({ row, value }) =>
        value
          ? `${row.count_examer || ""} / ${row.count_invite || ""}`
          : "ยังไม่มีผู้เข้าสอบ",
    },
    {
      field: "last_activity",
      headerName: "Last Activity",
      minWidth: 200,
      valueGetter: ({ value }) => value || "-",
    },
    { field: "created_at", headerName: "Date Created", minWidth: 200 },
    {
      field: "status",
      headerName: "Status",
      renderCell: ({ value }) => <Toggle checked={value === "active"} />,
      minWidth: 200,
    },
    {
      field: "invitation",
      headerName: "Invitation",
      valueGetter: ({ value }) => `${"Edit" || "-"}`,
      minWidth: 200,
    },
  ];

  useEffect(() => {
    dispatch(getCampaignList());
  }, []);

  useEffect(() => {
    setDataTable(campaignList);
  }, [campaignList]);

  const handleOpenConfirmDialog = (data) => {
    setCurrentData(data);
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleCloseConfirmDialog = () => {
    setOpenConfirmDialog(!openConfirmDialog);
  };

  const handleEditCampaign = (id) => {
    dispatch(getCampaignById(id)).then((data) => {
      dispatch(updateToModeEditCampaign());
      mappingFormEdit(data);
      history.push("/assessments/create");
    });
  };

  // useEffect(() => {
  //   console.log("campaignById effect", campaignById);

  //   if (campaignById) {
  //     mappingFormEdit();
  //   }
  // }, [campaignById]);

  const mappingFormEdit = (rawData) => {
    let formStep1 = {
      campaign_name: rawData?.campaign_name,
      job_role_id: rawData?.job_role_id,
      job_role_level: rawData?.job_role_level,
      campaign_start_date: rawData?.campaign_start_date,
      campaign_end_date: rawData?.campaign_end_date,
      campaign_type: rawData?.campaign_type,
      use_in: rawData?.use_in,
    };

    const formStep2 = [...INITIAL_SCENARIOS];

    let arrExam = rawData?.examSetList.map((item) => ({
      label: item?.exam_set_name,
      exam_sets_id: item?.exam_sets_id,
      order_no: item?.order_no,
      exam_set_time: item?.exam_set_time,
    }));

    for (const item of arrExam) {
      formStep2.splice(item?.order_no - 1, 1, item);
    }

    let formStep3 = rawData?.customQuestionList.map((item) => ({
      ...item,
      is_exist: false,
      choice:
        item?.question_type === "multiple-choice" ? INITIAL_CHOICE_LIST : [],
    }));

    let formStep4 = {
      id: rawData?.id,
      duration_time: rawData?.duration_time,
      extra_time: rawData?.extra_time,
      is_show_vdo: rawData?.is_show_vdo,
      is_snapshot: rawData?.is_snapshot,
      vdo_url: rawData?.vdo_url,
      vdo_embed: rawData?.vdo_embed,
    };

    dispatch(saveFormAssessmentDetail(formStep1));
    dispatch(saveFormAssessmentSelcetTest(formStep2));
    dispatch(saveFormAssessmentCreateExam(formStep3));
    dispatch(saveFormAssessmentReview(formStep4));
  };

  const handleDelete = () => {
    handleCloseConfirmDialog();
    dispatch(deleteCampaign(currentData?.id)).then(() => {
      setOpenConfirmDialog(!openConfirmDialog);
      dispatch(getCampaignList());
    });
    // delete(currentId)
  };

  // const onClickRow = (list) => {
  //   history.push(`report/${list[0]}`);
  // };

  const handleCreateAssessment = () => {
    dispatch(clearFormCampaign());
    history.push("/assessments/create");
  };

  return (
    <Div>
      <ConfirmDialog
        open={openConfirmDialog}
        onClose={handleCloseConfirmDialog}
        onConfirm={handleDelete}
        title="Delete"
        content={currentData?.campaign_name}
        confirmName={"Delete"}
      />
      <Grid
        container
        spacing={1}
        justifyContent="space-between"
        alignItems="center"
      >
        <Grid item>
          <Typography variant="h2" color="initial">
            My Assessments
          </Typography>
        </Grid>
        <Grid item sx={{ display: { xs: "none", sm: "flex" } }}>
          <Button onClick={() => handleCreateAssessment()}>
            Create New Assessment
          </Button>
        </Grid>
        <Grid item xs={12} sm={12}></Grid>
        <Grid item xs={12} sm={12}></Grid>
      </Grid>

      <Grid container spacing={1} sx={{ display: { xs: "none", sm: "flex" } }}>
        <Box sx={{ height: 700, width: "100%" }}>
          <Table
            columns={columns}
            rows={dataTable}
            checkboxSelection={false}
            onEditRow={(row) => handleEditCampaign(row?.id)}
            onDeleteRow={(row) => handleOpenConfirmDialog(row)}
          />
        </Box>
      </Grid>

      <Grid
        container
        spacing={1}
        sx={{ display: { xs: "flex", sm: "none" } }}
        mt={4}
      >
        <Stack spacing={4}>
          {dataTable?.map((item, index) => (
            <AssessmentItem
              key={index}
              data={item}
              onDelete={handleOpenConfirmDialog}
            />
          ))}
        </Stack>
      </Grid>

      <Grid container my={4} sx={{ display: { xs: "flex", sm: "none" } }}>
        <Button block onClick={() => handleCreateAssessment()}>
          Create New Assessment
        </Button>
      </Grid>
    </Div>
  );
};

export default Assessmentmain;

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  "& .MuiCardContent-root :last-child": {
    paddingBottom: 0,
  },
}));

const AssessmentItem = ({ data, onDelete }) => {
  const history = useHistory();

  return (
    <Card
      sx={{
        width: "100%",
      }}
    >
      <StyledCardContent sx={{ px: 2 }}>
        <Grid container>
          <Stack spacing={2}>
            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography
                variant="h5"
                color="initial"
                onClick={() => history.push(`/report/${data?.id}`)}
              >
                {data?.campaign_name}
              </Typography>

              <Box>
                <ButtonIcon type="button" color="grey">
                  <img src={Edit} alt="" />
                </ButtonIcon>
                <ButtonIcon
                  type="button"
                  color="grey"
                  onClick={() => onDelete(data)}
                >
                  <img src={Delete} alt="" />
                </ButtonIcon>
              </Box>
            </Stack>

            <Stack spacing={2} direction="row" justifyContent="space-between">
              <Typography variant="h6" color="#616161">
                {data?.created_at}
              </Typography>

              <Box>
                <Stack spacing={2} direction="row" justifyContent="flex-end">
                  <ButtonIcon color="grey">
                    <img src={User} alt="" />
                  </ButtonIcon>
                  <Typography variant="h6" color="#616161">
                    {data?.accept_candidates} / {data?.invite_candidates}
                  </Typography>
                </Stack>
              </Box>
            </Stack>
          </Stack>
        </Grid>
      </StyledCardContent>
    </Card>
  );
};
