import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";

import Button from "../../components/form/Button";
import TextField from "../../components/form/TextField";
import Grid from "../../components/layout/Grid";
import { Container, Stack, Typography } from "@mui/material";
import { createNewPassword } from "redux/actions/register";
import { validatePasswords } from "utils/validation";
import Box from "components/surfaces/Box";

const Div = styled.div`
  padding: 4vw 0;
  margin: 0 auto;
`;

const INITIAL_FORM = { password: "", confirmpassword: "" };

const ResetPassword = () => {
  const search = useLocation().search;
  const token = new URLSearchParams(search).get("token");
  const history = useHistory();
  const dispatch = useDispatch();
  const {} = useSelector((state) => state.assessments);

  const [form, setForm] = useState(INITIAL_FORM);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    console.log("form", form);
  }, [form]);

  const onChangeForm = (event) => {
    const { name, value } = event.target;

    setForm((prev) => ({
      ...prev,
      [name]: value,
    }));

    validateForm({ [name]: value });
  };

  const validateForm = (fieldValues = { ...form }) => {
    let temp = { ...errors };

    if ("password" in fieldValues)
      temp.password =
        fieldValues.password !== ""
          ? validatePasswords(fieldValues.password)
            ? ""
            : "password must be greater than 8 characters, 1 uppercase, 1 special character, 1 number"
          : "กรุณากรอก";

    if ("confirmpassword" in fieldValues)
      temp.confirmpassword =
        fieldValues.confirmpassword !== ""
          ? fieldValues.confirmpassword.localeCompare(form?.password) === 0
            ? ""
            : "กรุณากรอกให้ถูกต้อง"
          : "กรุณากรอก";

    setErrors({ ...temp });

    return Object.values(temp).every((x) => x === "");
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const isValid = validateForm();

    if (isValid) {
      let body = {
        token,
        newpassword: form?.password,
      };

      dispatch(createNewPassword(body)).then(() => {
        history.push("/success-reset");
      });
    }
  };

  return (
    <Container maxWidth="xl">
      <Div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}></Grid>
          <Grid item xs={12} sm={6}>
            <form onSubmit={handleSubmit}>
              <Stack spacing={2} justifyContent="center" alignItems="center">
                <Typography
                  sx={{ display: { xs: "none", sm: "flex" } }}
                  align="center"
                  variant={"h2"}
                  gutterBottom
                  color="#005cc9"
                >
                  Reset New Password
                </Typography>
                <Typography
                  sx={{ display: { xs: "flex", sm: "none" } }}
                  align="center"
                  variant={"h3"}
                  gutterBottom
                  color="#005cc9"
                >
                  Reset New Password
                </Typography>
                <br />

                <TextField
                  type="password"
                  placeholder="Password"
                  name="password"
                  value={form?.password}
                  error={errors?.password}
                  onChange={onChangeForm}
                />

                <TextField
                  type="password"
                  placeholder="Re-confirm Password"
                  name="confirmpassword"
                  value={form?.confirmpassword}
                  error={errors?.confirmpassword}
                  onChange={onChangeForm}
                />

                <Typography
                  sx={{ width: "100%" }}
                  variant="body1"
                  color="initial"
                >
                  Condition <br />
                  Must be at least 6 characters <br />
                  Use at least 1 number <br />
                  Use at least 1 symbol
                </Typography>

                <br />

                <Button type="submit">Reset your password</Button>
              </Stack>
            </form>
          </Grid>
          <Grid item xs={12} sm={3}></Grid>
        </Grid>
      </Div>
    </Container>
  );
};

export default ResetPassword;
