import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DataGrid, useGridApiContext } from "@mui/x-data-grid";
import { IconButton } from "@mui/material";
import {
  DeleteOutlined as DeleteIcon,
  Edit as EditIcon,
} from "@mui/icons-material";
import ReactPaginate from "react-paginate";

const StyledDataGrid = styled(DataGrid)`
  border: none !important;

  .MuiDataGrid-columnHeader,
  .MuiDataGrid-cell {
    outline: none !important;
    /* white-space: initial !important; */
  }

  .MuiDataGrid-iconSeparator {
    display: none;
  }

  .MuiDataGrid-virtualScrollerContent {
    height: fit-content;
  }

  .MuiDataGrid-virtualScrollerRenderZone {
    row-gap: 5px;
  }

  .MuiDataGrid-row {
    border: ${(props) =>
      props.disablecBorderContent ? "none" : "1px solid #ecedf2"};
    border-radius: 4px;
    /* margin-bottom: 10px; */
  }

  .MuiDataGrid-columnHeaders {
    border: none;
  }

  .Mui-checked {
    color: #080808 !important;
  }

  .MuiDataGrid-columnHeaderTitle {
    font-size: 16px;
    font-weight: bold;
    color: #080808;
  }

  .MuiDataGrid-columnHeaderTitleContainer {
    padding: 0 !important;
  }

  .MuiDataGrid-columnSeparator {
    /* display: none !important; */
  }

  .MuiDataGrid-cell {
    font-size: 16px;
    color: #080808;
    border: ${(props) =>
      props.disablecBorderContent ? "none" : "initial"} !important;
  }

  .MuiDataGrid-footerContainer {
    border-top: none;
    justify-content: center;

    ul {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0;

      li {
        margin: 0 5px;
        list-style: none;

        a {
          display: inline-block;
          width: 40px;
          height: 40px;
          padding: 10px 0;
          text-align: center;
          font-size: 14px;
          color: #080808;
          border-radius: 4px;
          cursor: pointer;
        }

        &.selected {
          a {
            color: #ffffff;
            background: #080808;
          }
        }

        &.previous,
        &.next {
          margin: 0 14px;

          a {
            padding: 0;
            width: auto;
            height: auto;
          }

          svg {
            position: relative;
            font-size: 16px;
            margin: 0 7px;
            top: 1px;
          }
        }

        &.disabled {
          a {
            color: #b6b8c1;
            cursor: default;
          }
        }
      }
    }
  }
`;

// const appendRunningNumber = (columns, onClick = () => {}) => {
//   const DeleteMenuCell = ({ row }) => {
//     const handleClick = () => onClick(row);
//     return (
//       <IconButton size="small" onClick={handleClick}>
//         <DeleteIcon fontSize="small" />
//       </IconButton>
//     );
//   };

//   return [
//     ...columns,
//     {
//       field: "removeButton",
//       headerName: "",
//       width: 50,
//       minWidth: 0,
//       flex: 0,
//       renderCell: DeleteMenuCell,
//     },
//   ];
// };

const appendDeleteButton = (columns, onClick = () => {}) => {
  const DeleteMenuCell = ({ row }) => {
    const handleClick = () => onClick(row);
    return (
      <IconButton size="small" onClick={handleClick}>
        <DeleteIcon fontSize="small" />
      </IconButton>
    );
  };

  return [
    ...columns,
    {
      field: "removeButton",
      headerName: "",
      width: 50,
      minWidth: 0,
      flex: 0,
      renderCell: DeleteMenuCell,
    },
  ];
};

const appendEditButton = (columns, onClick = () => {}) => {
  const EditMenuCell = ({ row }) => {
    const handleClick = () => onClick(row);
    return (
      <IconButton size="small" onClick={handleClick}>
        <EditIcon fontSize="small" />
      </IconButton>
    );
  };

  return [
    ...columns,
    {
      field: "editButton",
      headerName: "",
      width: 50,
      minWidth: 0,
      flex: 0,
      renderCell: EditMenuCell,
    },
  ];
};

const CustomPagination = () => {
  const apiRef = useGridApiContext();
  const { state, setPage } = apiRef.current;

  const nextLabel = (
    <span>
      Next <FontAwesomeIcon icon={["fas", "chevron-right"]} />
    </span>
  );
  const previousLabel = (
    <span>
      <FontAwesomeIcon icon={["fas", "chevron-left"]} /> Prev
    </span>
  );

  return (
    <ReactPaginate
      breakLabel="..."
      nextLabel={nextLabel}
      onPageChange={({ selected }) => setPage(selected)}
      pageRangeDisplayed={3}
      pageCount={state.pagination.pageCount}
      previousLabel={previousLabel}
      forcePage={state.pagination.page}
      renderOnZeroPageCount={null}
    />
  );
};

const Table = ({
  columns = [],
  rows = [],
  rowCount = 0,
  pageSize = 10,
  page,
  checkboxSelection = true,
  onPageChange,
  onSelectionChange,
  onDeleteRow,
  onEditRow,
  autoHeight = false,
  disableSelectionOnClick = false,
  disablecBorderContent = false,
  headerNameRunningNumber = "No.",
  showRunningNumber = false,
  ...props
}) => {
  // ...Array(10).keys()
  const columnNo = {
    field: "no",
    headerName: headerNameRunningNumber,
    minWidth: 80,
  };

  const customRows = [...rows].map((item, index) => ({
    no: index + 1,
    ...item,
  }));

  let customColumns = showRunningNumber ? [columnNo, ...columns] : [...columns];

  if (onEditRow) {
    customColumns = appendEditButton(customColumns, onEditRow);
  }
  if (onDeleteRow) {
    customColumns = appendDeleteButton(customColumns, onDeleteRow);
  }

  const mapColumnProp = (props) => {
    const { minWidth = 150, sortable = false } = props;
    return {
      sortable: sortable,
      // flex: width ? 0 : 1,
      // headerAlign: "center",
      flex: 1,
      minWidth: minWidth,

      // align: "center",
      ...props,
    };
  };

  return (
    <StyledDataGrid
      columns={customColumns.map(mapColumnProp)}
      rows={customRows}
      rowCount={rowCount}
      pageSize={pageSize}
      page={page}
      paginationMode="server"
      checkboxSelection={checkboxSelection}
      onSelectionModelChange={onSelectionChange}
      disableSelectionOnClick={disableSelectionOnClick}
      autoHeight={autoHeight}
      disableColumnFilter
      disableColumnMenu
      rowsScrollEnd
      columnResize
      hideFooterSelectedRowCount
      onPageChange={onPageChange}
      components={{
        Pagination: CustomPagination,
      }}
      disablecBorderContent={disablecBorderContent}
      {...props}
    />
  );
};

export default Table;
